import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ValidationErrors, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { UserService, AlertService, ValidationFormsService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';

function confirmPasswordValidator(control: UntypedFormGroup): ValidationErrors | null {
  const password = control.get('password');
  const confirm = control.get('confirmPassword');
  return password?.value && password?.value === confirm?.value ? null : { passwordMismatch: true };
};

@Component({
  selector: 'app-register',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss'],
})
export class UpdatePasswordComponent implements OnInit  {
  protected formUpdatePassword!: UntypedFormGroup;
  protected currentUser: any;
  protected submitted: boolean = false;
  protected checkPass: boolean = false;
  protected loading: boolean = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private alertService: AlertService,
    private angularFireAuth: AngularFireAuth,
    private validationFormService: ValidationFormsService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.getCurrentUser();
    this.createForm();
  }

  private createForm(): void {
    this.formUpdatePassword = this.fb.group(
      {
        oldPassword: ['', [Validators.required, Validators.pattern(this.validationFormService.formRules.passwordPattern)]],
        password: ['', [Validators.required, Validators.pattern(this.validationFormService.formRules.passwordPattern)]],
        confirmPassword: ['', [Validators.required]],
      },
      { validators: confirmPasswordValidator }
    );
  }

  get f() {
    return this.formUpdatePassword.controls;
  }

  private onValidate(): boolean {
    this.submitted = true;
    return this.formUpdatePassword.valid;
  }

  protected updatePassword() {
    this.alertService.clear();
    if (this.onValidate() && !this.loading) {
      this.loading = true;
      this.userService.updatePassword(this.formUpdatePassword.value.oldPassword, this.formUpdatePassword.value.password)
        .then((res: any) => this.router.navigate(['account'], { state: { update: true } }))
        .then(() => this.alertService.success(this.translateService.instant('alertMessages.changePassword.success')))
        .catch((error) => this.alertService.error(this.translateService.instant('alertMessages.changePassword.failed')))
        .finally(() => this.loading = false);
    }
  }

  private async getCurrentUser(): Promise<void> {
    const currentUser = await this.angularFireAuth.currentUser;
    if (currentUser) {
      this.currentUser = currentUser;
      await this.emailValidate(currentUser.email);
    }
  }

  private async emailValidate(email: any): Promise<void> {
    const user = await this.angularFireAuth.fetchSignInMethodsForEmail(email);
    if (user) {
      user.forEach((value) => {
        if (value == 'password') this.checkPass = true;
        else this.router.navigate(['account']);
      });
    }
  }
}
