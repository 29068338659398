import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "@environments/environment";
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class Api {
  constructor(
    private http: HttpClient,
    private alertService: AlertService,

  ) { }

  public post(path: string, data: any) {
    return new Promise((resolve, reject) => {
      try {
        this.http.post(`${environment.baseUrl}${path}`, data).subscribe({
          next: (data: any) => { this.alertService.success(data.message), resolve(data); },
          error: (error) => { this.alertService.error(error.error.error)
          , reject(error); }
        });
      } catch {
        reject('api failed');
      }
    });
  }

  public get(path: string, option: any) {
    return new Promise((resolve, reject) => {
      try {
        this.http.get(`${environment.baseUrl}${path}`, option).subscribe({
          next: (data) => { resolve(data); },
          error: (error) => { reject(error); }
        });
      } catch {
        reject('api failed');
      }
    });
  }
}
