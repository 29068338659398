import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { getFirstError } from '@app/helpers';
import { ValidationFormsService } from '@app/services';

@Component({
  selector: 'app-input-base-with-button',
  templateUrl: './input-base-with-button.component.html',
  styleUrls: ['./input-base-with-button.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class InputBaseWithButtonComponent implements OnInit {
  getFirstError = getFirstError;
  @Input('form') form: any = [];
  @Input('label') label: string = '';
  @Input('ngClass') ngClass: NgClass['ngClass'];
  @Input('formControlNameInput') formControlNameInput: string = '';
  @Input('placeHolderInput') placeHolderInput: string = '';
  @Input('inputType') inputType: string = '';
  @Input('maxLength') maxLength: string = '';
  @Input('minLength') minLength: string = '';
  @Input('max') max: string = '';
  @Input('min') min: string = '';
  @Input('submitted') submitted: boolean = false;
  @Input('requiredText') requiredText: boolean = true;
  @Input('disabled') disabled: boolean = false;
  @Input('inputGroupText') inputGroupText: string = '';
  @Input('idModal') idModal: string = '';
  @Input('titleModal') titleModal: string = '';
  @Input('items') items: any = [];
  @Input('splitColumnModal') splitColumnModal: boolean = false;

  @Output('addItem') addItem: EventEmitter<any> = new EventEmitter<any>();

  protected listCheckbox: any = [];
  protected listValue: any = [];

  constructor(private validationFormService: ValidationFormsService) {}

  ngOnInit(): void {}

  get errorMessages() {
    return this.validationFormService.errorMessages;
  }

  clearList() {
    this.listValue = this.listCheckbox;
    this.listCheckbox = [];
  }

  changeInput(event: any) {
    if (this.listCheckbox.indexOf(event.target.value) == -1) {
      this.listCheckbox.push(event.target.value);
    } else {
      this.listCheckbox = this.listCheckbox.filter((item: string) => item !== event.target.value);
    }
  }

  changeLabel(event: any, id: string) {
    if (this.listCheckbox.indexOf(id) == -1) {
      this.listCheckbox.push(id);
    } else {
      this.listCheckbox = this.listCheckbox.filter((item: string) => item !== id);
    }
  }

  handleAddItem() {
    let result = this.items
      .filter((item: any) => this.listValue.includes(item.id))
      .sort((a: any, b: any) => b.sort - a.sort);
    this.addItem.emit({items: result});
  }
}
