import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      if(state.root.queryParams["iframe"] == "false") {
        localStorage.setItem("iframe","false")
     }
    if (await this.authService.userValue()) return true;
    else this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }}).then(r => {});
    return false;
  }
}
