<div [formGroup]="formGroup">
  <div class="form_item">
    <div class="form_item--title">マルチ</div>
    <div class="form_item--content">

      <!-- a_rotorcraftMulti -->
      <app-input-checkbox
        [ngClass]="'checkbox_group--inline'"
        [label]="'student.createStudent.studentCourseMulti.a_rotorcraftMulti' | translate"
        [formControlNameInput]="'a_rotorcraftMulti'"
        [submitted]="submitted"
        [formGroup]="formGroup"
        (changeValue)="changeCourse()"
        [form]="form">
      </app-input-checkbox>

      <div [@slideInOut]="formGroup.get('a_rotorcraftMulti')?.value ? 'in': 'out'">
        <!-- a_firstClassBasic -->
        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.studentCourseMulti.a_firstClassBasic' | translate"
          [formControlNameInput]="'a_firstClassBasic'"
          [submitted]="submitted"
          [formGroup]="formGroup"
          [disabled]="checkTypeOrderCourse('second', form)"
          (changeValue)="changeCourseTypes('a_firstClassBasic', form)"
          [form]="form">
        </app-input-checkbox>

        <c-row [@slideInOut]="formGroup.get('a_firstClassBasic')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- a_firstClassBasicDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_firstClassBasicDate' | translate"
              [formControlNameInput]="'a_firstClassBasicDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['a_firstClassBasicDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- a_firstClassBasicNo -->
            <app-input-base
              [label]="'student.createStudent.studentCourseMulti.a_firstClassBasicNo' | translate"
              class="input-group-container"
              [formControlNameInput]="'a_firstClassBasicNo'"
              [placeHolderInput]="'student.createStudent.studentCourseMulti.a_firstClassBasicNo' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-base>
          </c-col>
        </c-row>
        <c-row [@slideInOut]="formGroup.get('a_firstClassBasic')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- a_firstClassBasicPlace -->
            <app-input-base
              [label]="'student.createStudent.studentCourseMulti.a_firstClassBasicPlace' | translate"
              class="input-group-container"
              [formControlNameInput]="'a_firstClassBasicPlace'"
              [placeHolderInput]="'student.createStudent.studentCourseMulti.a_firstClassBasicPlace' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- a_firstClassBasicResponsible -->
            <app-input-base
              [label]="'student.createStudent.studentCourseMulti.a_firstClassBasicResponsible' | translate"
              class="input-group-container"
              [formControlNameInput]="'a_firstClassBasicResponsible'"
              [placeHolderInput]="'student.createStudent.studentCourseMulti.a_firstClassBasicResponsible' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- a_firstClassBasicDeliveryDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_firstClassBasicDeliveryDate' | translate"
              [formControlNameInput]="'a_firstClassBasicDeliveryDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['a_firstClassBasicDeliveryDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form"
              (changeValueDate)="changeDate($event)">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- a_firstClassBasicSendingDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_firstClassBasicSendingDate' | translate"
              [formControlNameInput]="'a_firstClassBasicSendingDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['a_firstClassBasicSendingDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col md="6">
            <!-- a_firstClassBasicExpirationDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_firstClassBasicExpirationDate' | translate"
              [formControlNameInput]="'a_firstClassBasicExpirationDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['a_firstClassBasicExpirationDate'].value"
              [disabled]="true"
              [form]="form"
              >
            </app-input-date-picker>
          </c-col>
        </c-row>

        <!-- a_firstClassDaytimeOnly -->
        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.studentCourseMulti.a_firstClassDaytimeOnly' | translate"
          [formControlNameInput]="'a_firstClassDaytimeOnly'"
          [submitted]="submitted"
          [disabled]="checkTypeOrderCourse('second', form)"
          (changeValue)="changeCourseTypes('a_firstClassDaytimeOnly', form)"
          [formGroup]="formGroup"
          [form]="form">
        </app-input-checkbox>

        <c-row [@slideInOut]="formGroup.get('a_firstClassDaytimeOnly')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- a_firstClassDaytimeOnlyDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_firstClassDaytimeOnlyDate' | translate"
              [formControlNameInput]="'a_firstClassDaytimeOnlyDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['a_firstClassDaytimeOnlyDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- a_firstClassDaytimeOnlyNo -->
            <app-input-base
              [label]="'student.createStudent.studentCourseMulti.a_firstClassDaytimeOnlyNo' | translate"
              class="input-group-container"
              [formControlNameInput]="'a_firstClassDaytimeOnlyNo'"
              [placeHolderInput]="'student.createStudent.studentCourseMulti.a_firstClassDaytimeOnlyNo' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('second', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
        </c-row>
        <c-row [@slideInOut]="formGroup.get('a_firstClassDaytimeOnly')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- a_firstClassDaytimeOnlyPlace -->
            <app-input-base
              [label]="'student.createStudent.studentCourseMulti.a_firstClassDaytimeOnlyPlace' | translate"
              class="input-group-container"
              [formControlNameInput]="'a_firstClassDaytimeOnlyPlace'"
              [placeHolderInput]="'student.createStudent.studentCourseMulti.a_firstClassDaytimeOnlyPlace' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('second', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- a_firstClassDaytimeOnlyResponsible -->
            <app-input-base
              [label]="'student.createStudent.studentCourseMulti.a_firstClassDaytimeOnlyResponsible' | translate"
              class="input-group-container"
              [formControlNameInput]="'a_firstClassDaytimeOnlyResponsible'"
              [placeHolderInput]="'student.createStudent.studentCourseMulti.a_firstClassDaytimeOnlyResponsible' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('second', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- a_firstClassDaytimeOnlyDeliveryDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_firstClassDaytimeOnlyDeliveryDate' | translate"
              [formControlNameInput]="'a_firstClassDaytimeOnlyDeliveryDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['a_firstClassDaytimeOnlyDeliveryDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              (changeValueDate)="changeDate($event)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- a_firstClassDaytimeOnlySendingDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_firstClassDaytimeOnlySendingDate' | translate"
              [formControlNameInput]="'a_firstClassDaytimeOnlySendingDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['a_firstClassDaytimeOnlySendingDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- a_firstClassDaytimeOnlyExpirationDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_firstClassDaytimeOnlyExpirationDate' | translate"
              [formControlNameInput]="'a_firstClassDaytimeOnlyExpirationDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['a_firstClassDaytimeOnlyExpirationDate'].value"
              [disabled]="true"
              [form]="form">
            </app-input-date-picker>
          </c-col>
        </c-row>

        <!-- a_firstClassVisualLineOfSight -->
        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.studentCourseMulti.a_firstClassVisualLineOfSight' | translate"
          [formControlNameInput]="'a_firstClassVisualLineOfSight'"
          [submitted]="submitted"
          [disabled]="checkTypeOrderCourse('second', form)"
          (changeValue)="changeCourseTypes('a_firstClassVisualLineOfSight', form)"
          [formGroup]="formGroup"
          [form]="form">
        </app-input-checkbox>

        <c-row [@slideInOut]="formGroup.get('a_firstClassVisualLineOfSight')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- a_firstClassVisualLineOfSightDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_firstClassVisualLineOfSightDate' | translate"
              [formControlNameInput]="'a_firstClassVisualLineOfSightDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('second', form)"
              [initialDate]="form['a_firstClassVisualLineOfSightDate'].value"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- a_firstClassVisualLineOfSightNo -->
            <app-input-base
              [label]="'student.createStudent.studentCourseMulti.a_firstClassVisualLineOfSightNo' | translate"
              class="input-group-container"
              [formControlNameInput]="'a_firstClassVisualLineOfSightNo'"
              [placeHolderInput]="'student.createStudent.studentCourseMulti.a_firstClassVisualLineOfSightNo' | translate"
              [inputType]="'text'"
              [disabled]="checkTypeOrderCourse('second', form)"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
        </c-row>
        <c-row [@slideInOut]="formGroup.get('a_firstClassVisualLineOfSight')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- a_firstClassVisualLineOfSightPlace -->
            <app-input-base
              [label]="'student.createStudent.studentCourseMulti.a_firstClassVisualLineOfSightPlace' | translate"
              class="input-group-container"
              [formControlNameInput]="'a_firstClassVisualLineOfSightPlace'"
              [placeHolderInput]="'student.createStudent.studentCourseMulti.a_firstClassVisualLineOfSightPlace' | translate"
              [inputType]="'text'"
              [disabled]="checkTypeOrderCourse('second', form)"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- a_firstClassVisualLineOfSightResponsible -->
            <app-input-base
              [label]="'student.createStudent.studentCourseMulti.a_firstClassVisualLineOfSightResponsible' | translate"
              class="input-group-container"
              [formControlNameInput]="'a_firstClassVisualLineOfSightResponsible'"
              [placeHolderInput]="'student.createStudent.studentCourseMulti.a_firstClassVisualLineOfSightResponsible' | translate"
              [inputType]="'text'"
              [disabled]="checkTypeOrderCourse('second', form)"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- a_firstClassVisualLineOfSightDeliveryDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_firstClassVisualLineOfSightDeliveryDate' | translate"
              [formControlNameInput]="'a_firstClassVisualLineOfSightDeliveryDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['a_firstClassVisualLineOfSightDeliveryDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              (changeValueDate)="changeDate($event)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- a_firstClassVisualLineOfSightSendingDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_firstClassVisualLineOfSightSendingDate' | translate"
              [formControlNameInput]="'a_firstClassVisualLineOfSightSendingDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['a_firstClassVisualLineOfSightSendingDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- a_firstClassVisualLineOfSightExpirationDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_firstClassVisualLineOfSightExpirationDate' | translate"
              [formControlNameInput]="'a_firstClassVisualLineOfSightExpirationDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['a_firstClassVisualLineOfSightExpirationDate'].value"
              [disabled]="true"
              [form]="form">
            </app-input-date-picker>
          </c-col>
        </c-row>

        <!-- a_firstClassWeightLimited -->
        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.studentCourseMulti.a_firstClassWeightLimited' | translate"
          [formControlNameInput]="'a_firstClassWeightLimited'"
          [submitted]="submitted"
          [disabled]="checkTypeOrderCourse('second', form)"
          (changeValue)="changeCourseTypes('a_firstClassWeightLimited', form)"
          [formGroup]="formGroup"
          [form]="form">
        </app-input-checkbox>

        <c-row [@slideInOut]="formGroup.get('a_firstClassWeightLimited')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- a_firstClassWeightLimitedDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_firstClassWeightLimitedDate' | translate"
              [formControlNameInput]="'a_firstClassWeightLimitedDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('second', form)"
              [requiredText]="false"
              [initialDate]="form['a_firstClassWeightLimitedDate'].value"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- a_firstClassWeightLimitedNo -->
            <app-input-base
              [label]="'student.createStudent.studentCourseMulti.a_firstClassWeightLimitedNo' | translate"
              class="input-group-container"
              [formControlNameInput]="'a_firstClassWeightLimitedNo'"
              [placeHolderInput]="'student.createStudent.studentCourseMulti.a_firstClassWeightLimitedNo' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('second', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
        </c-row>
        <c-row [@slideInOut]="formGroup.get('a_firstClassWeightLimited')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- a_firstClassWeightLimitedPlace -->
            <app-input-base
              [label]="'student.createStudent.studentCourseMulti.a_firstClassWeightLimitedPlace' | translate"
              class="input-group-container"
              [formControlNameInput]="'a_firstClassWeightLimitedPlace'"
              [placeHolderInput]="'student.createStudent.studentCourseMulti.a_firstClassWeightLimitedPlace' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('second', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- a_firstClassWeightLimitedResponsible -->
            <app-input-base
              [label]="'student.createStudent.studentCourseMulti.a_firstClassWeightLimitedResponsible' | translate"
              class="input-group-container"
              [formControlNameInput]="'a_firstClassWeightLimitedResponsible'"
              [placeHolderInput]="'student.createStudent.studentCourseMulti.a_firstClassWeightLimitedResponsible' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('second', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- a_firstClassWeightLimitedDeliveryDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_firstClassWeightLimitedDeliveryDate' | translate"
              [formControlNameInput]="'a_firstClassWeightLimitedDeliveryDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['a_firstClassWeightLimitedDeliveryDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              (changeValueDate)="changeDate($event)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- a_firstClassWeightLimitedSendingDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_firstClassWeightLimitedSendingDate' | translate"
              [formControlNameInput]="'a_firstClassWeightLimitedSendingDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [initialDate]="form['a_firstClassWeightLimitedSendingDate'].value"
              [requiredText]="false"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- a_firstClassWeightLimitedExpirationDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_firstClassWeightLimitedExpirationDate' | translate"
              [formControlNameInput]="'a_firstClassWeightLimitedExpirationDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['a_firstClassWeightLimitedExpirationDate'].value"
              [disabled]="true"
              [form]="form">
            </app-input-date-picker>
          </c-col>
        </c-row>

        <!-- a_secondClassBasic -->
        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.studentCourseMulti.a_secondClassBasic' | translate"
          [formControlNameInput]="'a_secondClassBasic'"
          [submitted]="submitted"
          [formGroup]="formGroup"
          [disabled]="checkTypeOrderCourse('first', form)"
          (changeValue)="changeCourseTypes('a_secondClassBasic', form)"
          [form]="form">
        </app-input-checkbox>

        <c-row [@slideInOut]="formGroup.get('a_secondClassBasic')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- a_secondClassBasicDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_secondClassBasicDate' | translate"
              [formControlNameInput]="'a_secondClassBasicDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [initialDate]="form['a_secondClassBasicDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- a_secondClassBasicNo -->
            <app-input-base
              [label]="'student.createStudent.studentCourseMulti.a_secondClassBasicNo' | translate"
              class="input-group-container"
              [formControlNameInput]="'a_secondClassBasicNo'"
              [placeHolderInput]="'student.createStudent.studentCourseMulti.a_secondClassBasicNo' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [disabled]="checkTypeOrderCourse('first', form)"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
        </c-row>
        <c-row [@slideInOut]="formGroup.get('a_secondClassBasic')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- a_secondClassBasicPlace -->
            <app-input-base
              [label]="'student.createStudent.studentCourseMulti.a_secondClassBasicPlace' | translate"
              class="input-group-container"
              [formControlNameInput]="'a_secondClassBasicPlace'"
              [placeHolderInput]="'student.createStudent.studentCourseMulti.a_secondClassBasicPlace' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [disabled]="checkTypeOrderCourse('first', form)"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- a_secondClassBasicResponsible -->
            <app-input-base
              [label]="'student.createStudent.studentCourseMulti.a_secondClassBasicResponsible' | translate"
              class="input-group-container"
              [formControlNameInput]="'a_secondClassBasicResponsible'"
              [placeHolderInput]="'student.createStudent.studentCourseMulti.a_secondClassBasicResponsible' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [disabled]="checkTypeOrderCourse('first', form)"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- a_secondClassBasicDeliveryDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_secondClassBasicDeliveryDate' | translate"
              [formControlNameInput]="'a_secondClassBasicDeliveryDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['a_secondClassBasicDeliveryDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              (changeValueDate)="changeDate($event)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- a_secondClassBasicSendingDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_secondClassBasicSendingDate' | translate"
              [formControlNameInput]="'a_secondClassBasicSendingDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [initialDate]="form['a_secondClassBasicSendingDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- a_secondClassBasicExpirationDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_secondClassBasicExpirationDate' | translate"
              [formControlNameInput]="'a_secondClassBasicExpirationDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [initialDate]="form['a_secondClassBasicExpirationDate'].value"
              [disabled]="true"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
        </c-row>

        <!-- a_secondClassDaytimeOnly -->
        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.studentCourseMulti.a_secondClassDaytimeOnly' | translate"
          [formControlNameInput]="'a_secondClassDaytimeOnly'"
          [submitted]="submitted"
          [formGroup]="formGroup"
          [disabled]="checkTypeOrderCourse('first', form)"
          (changeValue)="changeCourseTypes('a_secondClassDaytimeOnly', form)"
          [form]="form">
        </app-input-checkbox>

        <c-row [@slideInOut]="formGroup.get('a_secondClassDaytimeOnly')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- a_secondClassDaytimeOnlyDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_secondClassDaytimeOnlyDate' | translate"
              [formControlNameInput]="'a_secondClassDaytimeOnlyDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['a_secondClassDaytimeOnlyDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- a_secondClassDaytimeOnlyNo -->
            <app-input-base
              [label]="'student.createStudent.studentCourseMulti.a_secondClassDaytimeOnlyNo' | translate"
              class="input-group-container"
              [formControlNameInput]="'a_secondClassDaytimeOnlyNo'"
              [placeHolderInput]="'student.createStudent.studentCourseMulti.a_secondClassDaytimeOnlyNo' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('first', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
        </c-row>
        <c-row [@slideInOut]="formGroup.get('a_secondClassDaytimeOnly')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- a_secondClassDaytimeOnlyPlace -->
            <app-input-base
              [label]="'student.createStudent.studentCourseMulti.a_secondClassDaytimeOnlyPlace' | translate"
              class="input-group-container"
              [formControlNameInput]="'a_secondClassDaytimeOnlyPlace'"
              [placeHolderInput]="'student.createStudent.studentCourseMulti.a_secondClassDaytimeOnlyPlace' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('first', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- a_secondClassDaytimeOnlyResponsible -->
            <app-input-base
              [label]="'student.createStudent.studentCourseMulti.a_secondClassDaytimeOnlyResponsible' | translate"
              class="input-group-container"
              [formControlNameInput]="'a_secondClassDaytimeOnlyResponsible'"
              [placeHolderInput]="'student.createStudent.studentCourseMulti.a_secondClassDaytimeOnlyResponsible' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('first', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- a_secondClassDaytimeOnlyDeliveryDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_secondClassDaytimeOnlyDeliveryDate' | translate"
              [formControlNameInput]="'a_secondClassDaytimeOnlyDeliveryDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['a_secondClassDaytimeOnlyDeliveryDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              (changeValueDate)="changeDate($event)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- a_secondClassDaytimeOnlySendingDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_secondClassDaytimeOnlySendingDate' | translate"
              [formControlNameInput]="'a_secondClassDaytimeOnlySendingDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [initialDate]="form['a_secondClassDaytimeOnlySendingDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- a_secondClassDaytimeOnlyExpirationDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_secondClassDaytimeOnlyExpirationDate' | translate"
              [formControlNameInput]="'a_secondClassDaytimeOnlyExpirationDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [initialDate]="form['a_secondClassDaytimeOnlyExpirationDate'].value"
              [disabled]="true"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
        </c-row>

        <!-- a_secondClassVisualLineOfSight -->
        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.studentCourseMulti.a_secondClassVisualLineOfSight' | translate"
          [formControlNameInput]="'a_secondClassVisualLineOfSight'"
          [disabled]="checkTypeOrderCourse('first', form)"
          (changeValue)="changeCourseTypes('a_secondClassVisualLineOfSight', form)"
          [submitted]="submitted"
          [formGroup]="formGroup"
          [form]="form">
        </app-input-checkbox>

        <c-row [@slideInOut]="formGroup.get('a_secondClassVisualLineOfSight')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- a_secondClassVisualLineOfSightDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_secondClassVisualLineOfSightDate' | translate"
              [formControlNameInput]="'a_secondClassVisualLineOfSightDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [initialDate]="form['a_secondClassVisualLineOfSightDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- a_secondClassVisualLineOfSightNo -->
            <app-input-base
              [label]="'student.createStudent.studentCourseMulti.a_secondClassVisualLineOfSightNo' | translate"
              class="input-group-container"
              [formControlNameInput]="'a_secondClassVisualLineOfSightNo'"
              [placeHolderInput]="'student.createStudent.studentCourseMulti.a_secondClassVisualLineOfSightNo' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [disabled]="checkTypeOrderCourse('first', form)"
              [form]="form">
            </app-input-base>
          </c-col>
        </c-row>
        <c-row [@slideInOut]="formGroup.get('a_secondClassVisualLineOfSight')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- a_secondClassVisualLineOfSightPlace -->
            <app-input-base
              [label]="'student.createStudent.studentCourseMulti.a_secondClassVisualLineOfSightPlace' | translate"
              class="input-group-container"
              [formControlNameInput]="'a_secondClassVisualLineOfSightPlace'"
              [placeHolderInput]="'student.createStudent.studentCourseMulti.a_secondClassVisualLineOfSightPlace' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [disabled]="checkTypeOrderCourse('first', form)"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- a_secondClassVisualLineOfSightResponsible -->
            <app-input-base
              [label]="'student.createStudent.studentCourseMulti.a_secondClassVisualLineOfSightResponsible' | translate"
              class="input-group-container"
              [formControlNameInput]="'a_secondClassVisualLineOfSightResponsible'"
              [placeHolderInput]="'student.createStudent.studentCourseMulti.a_secondClassVisualLineOfSightResponsible' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [disabled]="checkTypeOrderCourse('first', form)"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- a_secondClassVisualLineOfSightDeliveryDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_secondClassVisualLineOfSightDeliveryDate' | translate"
              [formControlNameInput]="'a_secondClassVisualLineOfSightDeliveryDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['a_secondClassVisualLineOfSightDeliveryDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              (changeValueDate)="changeDate($event)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- a_secondClassVisualLineOfSightSendingDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_secondClassVisualLineOfSightSendingDate' | translate"
              [formControlNameInput]="'a_secondClassVisualLineOfSightSendingDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [initialDate]="form['a_secondClassVisualLineOfSightSendingDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- a_secondClassVisualLineOfSightExpirationDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_secondClassVisualLineOfSightExpirationDate' | translate"
              [formControlNameInput]="'a_secondClassVisualLineOfSightExpirationDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [initialDate]="form['a_secondClassVisualLineOfSightExpirationDate'].value"
              [disabled]="true"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
        </c-row>

        <!-- a_secondClassWeightLimited -->
        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.studentCourseMulti.a_secondClassWeightLimited' | translate"
          [formControlNameInput]="'a_secondClassWeightLimited'"
          [submitted]="submitted"
          [formGroup]="formGroup"
          [disabled]="checkTypeOrderCourse('first', form)"
          (changeValue)="changeCourseTypes('a_secondClassWeightLimited', form)"
          [form]="form">
        </app-input-checkbox>

        <c-row [@slideInOut]="formGroup.get('a_secondClassWeightLimited')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- a_secondClassWeightLimitedDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_secondClassWeightLimitedDate' | translate"
              [formControlNameInput]="'a_secondClassWeightLimitedDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['a_secondClassWeightLimitedDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>

          <c-col [md]="6">
            <!-- a_secondClassWeightLimitedNo -->
            <app-input-base
              [label]="'student.createStudent.studentCourseMulti.a_secondClassWeightLimitedNo' | translate"
              class="input-group-container"
              [formControlNameInput]="'a_secondClassWeightLimitedNo'"
              [placeHolderInput]="'student.createStudent.studentCourseMulti.a_secondClassWeightLimitedNo' | translate"
              [inputType]="'text'"
              [disabled]="checkTypeOrderCourse('first', form)"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
        </c-row>
        <c-row [@slideInOut]="formGroup.get('a_secondClassWeightLimited')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- a_secondClassWeightLimitedPlace -->
            <app-input-base
              [label]="'student.createStudent.studentCourseMulti.a_secondClassWeightLimitedPlace' | translate"
              class="input-group-container"
              [formControlNameInput]="'a_secondClassWeightLimitedPlace'"
              [placeHolderInput]="'student.createStudent.studentCourseMulti.a_secondClassWeightLimitedPlace' | translate"
              [inputType]="'text'"
              [disabled]="checkTypeOrderCourse('first', form)"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- a_secondClassWeightLimitedResponsible -->
            <app-input-base
              [label]="'student.createStudent.studentCourseMulti.a_secondClassWeightLimitedResponsible' | translate"
              class="input-group-container"
              [formControlNameInput]="'a_secondClassWeightLimitedResponsible'"
              [placeHolderInput]="'student.createStudent.studentCourseMulti.a_secondClassWeightLimitedResponsible' | translate"
              [inputType]="'text'"
              [disabled]="checkTypeOrderCourse('first', form)"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- a_secondClassWeightLimitedDeliveryDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_secondClassWeightLimitedDeliveryDate' | translate"
              [formControlNameInput]="'a_secondClassWeightLimitedDeliveryDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['a_secondClassWeightLimitedDeliveryDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              (changeValueDate)="changeDate($event)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- a_secondClassWeightLimitedSendingDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_secondClassWeightLimitedSendingDate' | translate"
              [formControlNameInput]="'a_secondClassWeightLimitedSendingDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [initialDate]="form['a_secondClassWeightLimitedSendingDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- a_secondClassWeightLimitedExpirationDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseMulti.a_secondClassWeightLimitedExpirationDate' | translate"
              [formControlNameInput]="'a_secondClassWeightLimitedExpirationDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [initialDate]="form['a_secondClassWeightLimitedExpirationDate'].value"
              [disabled]="true"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
        </c-row>

      </div>

    </div>
  </div>
</div>
