<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <c-col class="justify-content-center mb-5">
    <h3>{{ 'account.title'|translate }}</h3>
  </c-col>
  <c-col class="mb-5">
    <c-col>
      <form cForm [formGroup]="formUpdateAccount" (ngSubmit)="updateAccount()" class="needs-validate">
        <c-card class="mb-3 card-no-border">
          <c-card-body class="card-border-bottom d-flex">
            <c-col>{{ 'account.name'|translate }}
              <c-badge color="danger" shape="rounded-pill">{{ 'account.required'|translate }}</c-badge>
            </c-col>
            <c-col>
              <c-col class="d-flex justify-content-end">
                <input cFormControl type="text" placeholder="{{ 'account.name'|translate }}" formControlName="name"/>
              </c-col>
              <c-col *ngIf="submitted && attribute['name'].invalid" class="invalid-feedback">
                <c-col *ngIf="attribute['name'].errors && attribute['name'].errors?.['maxlength']">
                  {{ 'errorMessages.name.maxLength'|translate }}
                </c-col>
                <c-col *ngIf="attribute['name'].errors && attribute['name'].errors?.['required']">
                  {{ 'errorMessages.name.required'|translate }}
                </c-col>
              </c-col>
            </c-col>
          </c-card-body>
          <c-card-body class="card-border-bottom d-flex">
            <c-col>{{ 'account.member_code'|translate }}
              <c-badge color="danger" shape="rounded-pill">{{ 'account.required'|translate }}</c-badge>
            </c-col>
            <c-col>
              <c-col class="d-flex justify-content-end">
                <input
                  cFormControl
                  type="text"
                  placeholder="{{ 'account.member_code'|translate }}"
                  formControlName="memberCode"
                />
              </c-col>
              <c-col *ngIf="submitted && attribute['memberCode'].invalid" class="invalid-feedback">
                <c-col *ngIf="attribute['memberCode'].errors && attribute['memberCode'].errors?.['maxlength']">
                  {{ 'errorMessages.memberCode.maxLength'|translate }}
                </c-col>
                <c-col *ngIf="attribute['memberCode'].errors && attribute['memberCode'].errors?.['required']">
                  {{ 'errorMessages.memberCode.required'|translate }}
                </c-col>
              </c-col>
            </c-col>
          </c-card-body>
          <c-card-body class="card-border-bottom d-flex">
            <c-col>{{ 'account.branch'|translate }}
              <c-badge color="danger" shape="rounded-pill">{{ 'account.required'|translate }}</c-badge>
            </c-col>
            <c-col>
              <c-col class="d-flex justify-content-end">
                <input
                  cFormControl
                  type="text"
                  placeholder="{{ 'account.branch'|translate }}"
                  formControlName="branch"
                />
              </c-col>
              <c-col *ngIf="submitted && attribute['branch'].invalid" class="invalid-feedback">
                <c-col *ngIf="attribute['branch'].errors && attribute['branch'].errors?.['maxlength']">
                  {{ 'errorMessages.branch.maxLength'|translate }}
                </c-col>
                <c-col *ngIf="attribute['branch'].errors && attribute['branch'].errors?.['required']">
                  {{ 'errorMessages.branch.required'|translate }}
                </c-col>
              </c-col>
            </c-col>
          </c-card-body>
          <c-card-body class="card-border-bottom d-flex">
            <c-col>{{ 'account.address'|translate }}</c-col>
            <c-col>
              <c-col class="d-flex justify-content-end">
                <input
                  cFormControl
                  type="text"
                  placeholder="{{ 'account.address'|translate }}"
                  formControlName="address"
                />
              </c-col>
              <c-col *ngIf="submitted && attribute['address'].invalid" class="invalid-feedback">
                <c-col *ngIf="attribute['address'].errors && attribute['address'].errors?.['maxlength']">
                  {{ 'errorMessages.address.maxLength'|translate }}
                </c-col>
              </c-col>
            </c-col>
          </c-card-body>
          <c-card-body class="card-border-bottom d-flex">
            <c-col>{{ 'account.phone_number'|translate }}</c-col>
            <c-col>
              <c-col class="d-flex justify-content-end">
                <input
                  cFormControl
                  type="text"
                  placeholder="{{ 'account.phone_number'|translate }}"
                  formControlName="phoneNumber"
                />
              </c-col>
              <c-col *ngIf="submitted && attribute['phoneNumber'].invalid" class="invalid-feedback">
                <c-col *ngIf="attribute['phoneNumber'].errors">
                  {{ 'errorMessages.formValidate.tel.pattern'|translate }}
                </c-col>
              </c-col>
            </c-col>
          </c-card-body>
          <c-card-body class="card-border-bottom d-flex">
            <c-col>{{ 'account.person_name'|translate }}
              <c-badge color="danger" shape="rounded-pill">{{ 'account.required'|translate }}</c-badge>
            </c-col>
            <c-col>
              <c-col class="d-flex justify-content-end">
                <input
                  cFormControl
                  type="text"
                  placeholder="{{ 'account.person_name'|translate }}"
                  formControlName="personName"
                />
              </c-col>
              <c-col *ngIf="submitted && attribute['personName'].invalid" class="invalid-feedback">
                <c-col *ngIf="attribute['personName'].errors && attribute['personName'].errors?.['maxlength']">
                  {{ 'errorMessages.personName.maxLength'|translate }}
                </c-col>
                <c-col *ngIf="attribute['personName'].errors && attribute['personName'].errors?.['required']">
                  {{ 'errorMessages.personName.required'|translate }}
                </c-col>
              </c-col>
            </c-col>
          </c-card-body>
        </c-card>
        <button type="submit" class="btn-common width-100">{{ 'account.btn_update'|translate }}</button>
      </form>
    </c-col>
  </c-col>

  <c-col class="mb-5">
    <c-col class="mb-2">{{ 'account.environment_setting'|translate }}</c-col>
    <c-col>
      <c-card class="card-no-border">
        <c-card-body class="card-border-bottom">
          <c-col>
            <button class="btn-logout text-color-base" (click)="redirectToInstructorScreen()">
              <span class="ms-2">{{ 'account.btn_instructor'|translate }}</span>
            </button>
          </c-col>
        </c-card-body>
        <c-card-body>
          <c-col>
            <button class="btn-logout text-color-base" (click)="redirectToAircraftUsedScreen()">
              <span class="ms-2">{{ 'account.btn_aircraft_used'|translate }}</span>
            </button>
          </c-col>
        </c-card-body>
      </c-card>
    </c-col>
  </c-col>

  <c-col class="mb-5">
    <c-col class="mb-2">{{ 'account.support'|translate }}</c-col>
    <c-col>
      <c-card class="card-no-border">
        <c-card-body>
          <c-col>{{'account.support_title'|translate:{email: 'branchschool@unlc.jp'} }}</c-col>
        </c-card-body>
      </c-card>
    </c-col>
  </c-col>

  <c-col>
    <c-col class="mb-2">{{ 'account.title'|translate }}</c-col>
    <c-col>
      <c-card class="card-no-border">
        <c-card-body class="card-border-bottom">
          <c-col class="d-flex">
            <c-col>{{ 'account.email'|translate }}</c-col>
            <c-col class="d-flex justify-content-end">
              <span>{{ email }}</span>
            </c-col>
          </c-col>
        </c-card-body>
        <c-card-body class="card-border-bottom">
          <c-col class="d-flex">
            <c-col>{{ 'account.password'|translate }}</c-col>
            <c-col class="d-flex justify-content-end">
              <a routerLink="/account/update-password" class="btn-link" [hidden]="!this.checkPass">
                <button class="btn-change-password text-color-base">
                  <span class="me-3">{{ 'account.btn_password'|translate }}</span>
                  <img src="assets/svg/flight-record/icon-arrow-right.svg" alt="arrow-right"/>
                </button>
              </a>
            </c-col>
          </c-col>
        </c-card-body>
        <c-card-body>
          <c-col>
            <button class="btn-logout text-color-base" (click)="logout()">
              <img src="assets/svg/logout.svg" alt="logout" />
              <span class="ms-2">{{ 'account.btn_logout'|translate }}</span>
            </button>
          </c-col>
        </c-card-body>
      </c-card>
    </c-col>
  </c-col>
</c-container>
