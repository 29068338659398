import { NgModule } from '@angular/core';
import {
  AsyncPipe,
  HashLocationStrategy,
  LocationStrategy,
  NgOptimizedImage,
} from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {
  AvatarModule,
  BadgeModule,
  BreadcrumbModule,
  ButtonGroupModule,
  ButtonModule,
  CardModule,
  DropdownModule,
  FooterModule,
  FormModule,
  GridModule,
  HeaderModule,
  ListGroupModule,
  ModalModule,
  ProgressModule,
  SharedModule,
  UtilitiesModule,
  SpinnerModule,
  PopoverModule,
  ToastModule,
  TooltipModule,
} from '@coreui/angular';
import {
  CollapseModule,
  DatePickerModule,
  LoadingButtonModule,
  SmartTableModule,
  TimePickerModule,
  TableModule,
  TabsModule,
  NavModule,
  SidebarModule,
} from '@coreui/angular-pro';
import { LoginComponent } from './views/pages/login/login.component';
import { AlertComponent } from './components/alert/alert.component';
import { InputPasswordComponent } from './components/input/input-password/input-password.component';
import { LayoutsModule } from './views/layouts/layouts.module';
import { AccountComponent } from './views/pages/account/account.component';
import { ForgotPasswordComponent } from './views/pages/forgot-password/forgot-password.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { InputEmailComponent } from './components/input/input-email/input-email.component';
import { UpdatePasswordComponent } from './views/pages/update-password/update-password.component';
import { FooterComponent } from './components/footer/footer.component';
import { TermsofuseComponent } from './views/pages/termsofuse/termsofuse.component';
import { PrivacyComponent } from './views/pages/privacy/privacy.component';
import { NotificationComponent } from './views/pages/notification/notification.component';
import { StudentComponent } from './views/pages/student/student.component';
import { StudentItemComponent } from './components/student/student-item/student-item.component';
import { CreateStudentComponent } from './views/pages/student/create-student/create-student.component';
import { InputBaseComponent } from './components/input/input-base/input-base.component';
import { InputBaseWithButtonComponent } from './components/input/input-base-with-button/input-base-with-button.component';
import { InputDatePickerComponent } from './components/input/input-date-picker/input-date-picker.component';
import { InputSelectOptionComponent } from './components/input/input-select-option/input-select-option.component';
import { StudentBasicComponent } from './views/pages/student/create-student/tab/student-basic/student-basic.component';
import { StudentConfirmationComponent } from './views/pages/student/create-student/tab/student-confirmation/student-confirmation.component';
import { StudentCourseMultiComponent } from './views/pages/student/create-student/tab/student-course-multi/student-course-multi.component';
import { StudentCourseAirplaneComponent } from './views/pages/student/create-student/tab/student-course-airplane/student-course-airplane.component';
import { StudentCourseHelicopterComponent } from './views/pages/student/create-student/tab/student-course-helicopter/student-course-helicopter.component';
import { StudentCompletionComponent } from './views/pages/student/create-student/tab/student-completion/student-completion.component';
import { InputFileImageUploadComponent } from './components/input/input-file-image-upload/input-file-image-upload.component';
import { InputUploadComponent } from './components/input/input-upload/input-upload.component';
import { InputRadioComponent } from './components/input/input-radio/input-radio.component';
import { InputCheckboxComponent } from './components/input/input-checkbox/input-checkbox.component';
import { InputCheckboxRadioComponent } from './components/input/input-checkbox-radio/input-checkbox-radio.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { EditStudentComponent } from './views/pages/student/edit-student/edit-student.component';
import { TextAreaComponent } from './components/input/text-area/text-area.component';
import { CompletionRecordComponent } from './views/pages/student/completion-record/completion-record.component';
import { CreateCompletionRecordComponent } from './views/pages/student/completion-record/create-completion-record/create-completion-record.component';
import { StudentAttendancesComponent } from './views/pages/student/student-attendances/student-attendances.component';
import { CreateAttendancesComponent } from './views/pages/student/student-attendances/create-attendances/create-attendances.component';
import { AircraftUsedComponent } from './views/pages/aircraft-used/aircraft-used.component';
import { InstructorComponent } from './views/pages/instructor/instructor.component';
import { InstructorItemComponent } from './components/instructor-item/instructor-item.component';
import { InputTimeComponent } from './components/input/input-time/input-time.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { StudentAttendanceItemComponent } from './components/student/student-attendance-item/student-attendance-item.component';
import { UpdateAttendancesComponent } from './views/pages/student/student-attendances/update-attendances/update-attendances.component';
import { StudentCompletionRecordItemComponent } from './components/student/student-completion-record-item/student-completion-record-item.component';
import { ModalDeleteCommonComponent } from './components/modal/modal-delete-common/modal-delete-common.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { StudentSubItemComponent } from './components/student/student-item/student-sub-item/student-sub-item.component';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { LoadingComponent } from './components/loading/loading.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { IframeComponent } from './views/pages/iframe/iframe-component/iframe.component';
import { SafePipe } from './pipe/safe.pipe';
import { StudentApprovalComponent } from './views/pages/student/student-approval/student-approval.component';
import { CreateQuotationComponent } from './views/pages/quotation/create-quotation/create-quotation.component';
import { IconModule, IconSetService } from '@coreui/icons-angular';
import { QuotationItemComponent } from './components/quotation/quotation-item/quotation-item.component';
import { QuotationComponent } from './views/pages/quotation/quotation.component';
import { QuotationStudentItemComponent } from './components/quotation/quotation-student-item/quotation-student-item.component';
import { EditQuotationComponent } from './views/pages/quotation/edit-quotation/edit-quotation.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AlertComponent,
    InputPasswordComponent,
    AccountComponent,
    ForgotPasswordComponent,
    InputEmailComponent,
    UpdatePasswordComponent,
    FooterComponent,
    TermsofuseComponent,
    PrivacyComponent,
    NotificationComponent,
    CreateStudentComponent,
    StudentComponent,
    StudentItemComponent,
    CreateStudentComponent,
    InputBaseComponent,
    InputBaseWithButtonComponent,
    InputDatePickerComponent,
    InputSelectOptionComponent,
    InputUploadComponent,
    InputRadioComponent,
    StudentBasicComponent,
    StudentConfirmationComponent,
    StudentCourseMultiComponent,
    StudentCourseAirplaneComponent,
    StudentCourseHelicopterComponent,
    StudentCompletionComponent,
    InputFileImageUploadComponent,
    InputCheckboxComponent,
    InputCheckboxRadioComponent,
    EditStudentComponent,
    TextAreaComponent,
    CompletionRecordComponent,
    CreateCompletionRecordComponent,
    StudentAttendancesComponent,
    CreateAttendancesComponent,
    AircraftUsedComponent,
    InstructorComponent,
    InstructorItemComponent,
    InputTimeComponent,
    PaginationComponent,
    StudentAttendanceItemComponent,
    UpdateAttendancesComponent,
    StudentCompletionRecordItemComponent,
    ModalDeleteCommonComponent,
    BreadcrumbComponent,
    StudentSubItemComponent,
    LoadingComponent,
    SidebarComponent,
    IframeComponent,
    SafePipe,
    StudentApprovalComponent,
    CreateQuotationComponent,
    QuotationItemComponent,
    QuotationComponent,
    QuotationStudentItemComponent,
    EditQuotationComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    HeaderModule,
    FooterModule,
    AvatarModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ButtonModule,
    BreadcrumbModule,
    ButtonGroupModule,
    CardModule,
    DropdownModule,
    FormModule,
    GridModule,
    ModalModule,
    NavModule,
    SpinnerModule,
    BadgeModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutsModule,
    HttpClientModule,
    PopoverModule,
    ToastModule,
    ProgressModule,
    SharedModule,
    SidebarModule,
    TabsModule,
    TableModule,
    TooltipModule,
    UtilitiesModule,
    ListGroupModule,
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json');
        },
        deps: [HttpClient],
      },
    }),
    CollapseModule,
    DatePickerModule,
    LoadingButtonModule,
    SmartTableModule,
    TimePickerModule,
    NgxPaginationModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.pulse,
      primaryColour: '#0BA5EC',
      backdropBorderRadius: '3px',
      backdropBackgroundColour: 'rgba(0, 0, 0, 0.3)',
      fullScreenBackdrop: true,
    }),
    NgOptimizedImage,
    IconModule,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    AsyncPipe,
    IconSetService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
