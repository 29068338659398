import { Component, OnInit } from '@angular/core';
import { NotificationService, UserService, AlertService } from '@app/services';
import { INotification } from '@app/models';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Subject } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-notify-popup',
  templateUrl: './notify-popup.component.html',
  styleUrls: ['./notify-popup.component.scss']
})

export class NotifyPopupComponent implements OnInit {
  protected email: any;
  protected id: any;
  protected isNewMessage: boolean = false;
  protected countResponse: number = 0;
  protected notifications: INotification[] = [];
  protected isShow: boolean = false;
  public isHasUnreadMessage = new Subject<boolean>();

  constructor(
    private notificationService: NotificationService,
    private userService: UserService,
    private angularFireAuth: AngularFireAuth,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.currentUser().then();
  }

  private async currentUser() {
    const currentUser = await this.angularFireAuth.currentUser;
    if (currentUser) {
      this.email = currentUser.email;
      this.id = await this.userService.getDocId(this.email);
      await this.getNotification(this.email, this.id);
    }
  }

  private async getNotification(email: string, id: string) {
    this.notificationService.getNotification(email, id).subscribe((response) => {
      new Promise((resolve, reject) => {
        this.notifications = response.map((item) => {
          const data = item.payload.doc.data() as INotification;
          data.timeAgo = this.notificationService.timeSince(new Date(data.createdAt!.seconds * 1000));
          this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
            data.timeAgo = this.notificationService.timeSince(new Date(data.createdAt!.seconds * 1000));
          });
          return data;
        });
        this.isHasUnreadMessage.next(this.notifications.some(notification => !notification.isRead));
        resolve(true);
      });
    });
    this.notificationService.getNotificationEvent(email, id).subscribe((data) => {
      const alert = new Promise((resolve, reject) => {
        const notices = data.map((item) => {
          const data = item.payload.doc.data() as INotification;
          data.timeAgo = this.notificationService.timeSince(new Date(data.createdAt!.seconds * 1000));
          this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
            data.timeAgo = this.notificationService.timeSince(new Date(data.createdAt!.seconds * 1000));
          });
          return data;
        });
        const haveNoticeUnread = Boolean(notices.filter(item => !item.isRead).length);
        if (haveNoticeUnread) {
          this.showAlert(this.translateService.instant('notification.youHaveNewNotify'));
        }
        resolve('');
      });
      alert.then(() => {
        this.countResponse = data.length;
        this.isNewMessage = true;
      });
    });
  }

  private showAlert(msg: string) {
    this.alertService.clear();
    this.alertService.success(msg);
  }

  toggle(): void {
    this.isShow = !this.isShow;
  }

  handleNavigate(): void {
    this.isShow = false;
  }
}
