import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ICompletionRecord } from "@app/models";
import { DEFAULT_ITEMS_PER_PAGE, endTimeOfDay, formatDatePicker, startTimeOfDay } from '@app/constants';
import { TranslateService } from "@ngx-translate/core";
import { StudentService } from "@app/services";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { CompletionRecordService } from "@app/services/completion-record.service";
import { formatDate, getLocaleDatePicker } from "@app/helpers";
import { IStatus, StatusCompletionRecords } from "@app/models/statusStudent";
import { Timestamp } from "firebase/firestore";
import { IStudent } from "@app/models/student";
import { IBreadcrumbItem } from "@coreui/angular-pro";

@Component({
  selector: 'app-completion-record',
  templateUrl: './completion-record.component.html',
  styleUrls: ['./completion-record.component.scss'],
})
export class CompletionRecordComponent implements OnInit {
  @Output() pageOwner = new EventEmitter<number>();
  protected visibleCollapse: boolean = false;
  protected id!: string;
  protected course!: string;
  protected date: Date = new Date();
  protected formSearch!: FormGroup;
  protected visible: boolean = false;
  protected currentDatepickerLocale: string = '';
  protected currentLanguage: string = this.translate.currentLang;
  protected formatDatePicker: string = formatDatePicker;
  protected queryParams: any;
  protected readonly status: IStatus[] = StatusCompletionRecords;
  protected currentPage: number = 1;
  protected readonly itemsPerPage: number = DEFAULT_ITEMS_PER_PAGE;
  protected currentUser!: any;
  protected studentCompletionRecords: ICompletionRecord[] = [];
  protected student?: IStudent;
  protected userId?: string;
  protected numberRecordFilter: number = 0;
  protected breadcrumbItems: IBreadcrumbItem[] = [];
  protected isLoading: boolean = false;

  constructor(
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private studentService: StudentService,
    private auth: AngularFireAuth,
    private activatedRoute: ActivatedRoute,
    private completionRecordService: CompletionRecordService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id') as string;
    this.course = this.activatedRoute.snapshot.paramMap.get('course') as string;
    this.initForm();
    this.visibleCollapse = false;
    this.currentDatepickerLocale = getLocaleDatePicker(this.currentLanguage);
    this.getUser().then(() => {
      this.userId = this.activatedRoute.snapshot.queryParamMap.get('user_id') ?? this.currentUser.uid;
      this.studentService.getStudent(this.userId, this.id).then((res) => {
        if (!!res) this.student = res;
        this.handleQueryParams();
      })
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) this.navigateParams()
    });

    this.translate.get([
      'student.listStudent.title',
      'student.completionRecord.list.title',
    ]).subscribe(translations => {
      this.breadcrumbItems = [
        { label: translations['student.listStudent.title'], url: '/student' },
        { label: translations['student.completionRecord.list.title'], url: `` },
      ];
    });
  }

  private handleQueryParams() {
    let params: any = localStorage.getItem('queryString.completion');
    localStorage.removeItem('queryString.completion');
    if (params) {
      params = JSON.parse(params);
      this.f['querySearch'].setValue(params['querySearch']);
      this.f['status'].setValue(params['status']);

      if (!!params['startDate']) this.f['startDate'].setValue(new Date(params['startDate']));

      if (!!params['endDate']) this.f['endDate'].setValue(new Date(params['endDate']));

      this.visibleCollapse = params['visibleCollapse'];
      this.search().then(() => this.changePage(params['page']));
    } else this.search();
  }

  private initForm(): void {
    this.formSearch = this.formBuilder.group({
      querySearch: [''],
      startDate: [new Date(new Date().getFullYear(), 0, 1)],
      endDate: [new Date(new Date().getFullYear(), 11, 31)],
      status: ['all']
    });
  }

  public toggleCollapse(): void {
    this.visibleCollapse = !this.visibleCollapse;
  }

  get f() {
    return this.formSearch.controls;
  }

  redirectCreate() {
    this.router.navigateByUrl('/student/' + this.id + '/completion-records/' + this.course + '/create');
  }

  protected async search() {
    this.currentPage = 1;
    (this.completionRecordService.getCompletionRecords(this.formSearch.getRawValue(), this.userId, this.id, this.course)
      .subscribe(completionRecords => {
        let dataFilterCompletionRecord: any = [];
        let dataNotFilterUniqueCompletionRecords = [...new Map(completionRecords.map(item => [item.id, item])).values()];
        dataFilterCompletionRecord = [...dataNotFilterUniqueCompletionRecords];
        this.studentCompletionRecords = dataFilterCompletionRecord.filter((data: any) => {
          if (this.f['querySearch'].value.length > 0) {
            return data.examName.indexOf(this.f['querySearch'].value) > -1 || data.airframeTitle.indexOf(this.f['querySearch'].value) > -1;
          }
          return true;
        }).filter((data: any) => {
          const date = this.setDate(data.examDate);
          if (this.f['startDate'].value instanceof Date && date instanceof Date) {
            return date.getTime() >= new Date(formatDate(this.f['startDate'].value, formatDatePicker) + startTimeOfDay).getTime();
          }
          return true;
        }).filter((data: any) => {
          const date = this.setDate(data.examDate);
          if (this.f['endDate'].value instanceof Date && date instanceof Date) {
            return date.getTime() <= new Date(formatDate(this.f['endDate'].value, formatDatePicker) + endTimeOfDay).getTime();
          }
          return true;
        });
        this.numberRecordFilter = this.studentCompletionRecords.length;
      })
    )
  }

  private setDate(date: any): string | Date {
    return date ? new Date((date as Timestamp).toDate()) : '';
  }

  protected dayFormat = (date: Date) => date.getDate();

  protected async getUser() {
    const currentUser = await this.auth.currentUser;
    if (currentUser) this.currentUser = currentUser;
  }

  protected changePage = (page: number): void => {
    this.currentPage = page;
    this.pageOwner.emit(page);
  }

  protected trackByCompletionRecord(index: number, item: ICompletionRecord): number {
    return index;
  }

  protected exportCsvCompletionRecord() {
    this.isLoading = true
    this.studentService.exportCsvCompletionRecord(this.currentUser.uid, this.id, this.course, this.student, this.formSearch.value)
      .finally(() => this.isLoading = false);
  }

  protected navigateParams(): void {
    let params = this.formSearch.getRawValue();
    params.startDate = formatDate(params.startDate, formatDatePicker);
    params.endDate = formatDate(params.endDate, formatDatePicker);
    params.visibleCollapse = this.visibleCollapse;
    params.page = this.currentPage;
    localStorage.setItem('queryString.completion', JSON.stringify(params));
  }
}
