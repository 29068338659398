<app-layout-auth>
  <img id="logo" src="./assets/img/logo-login.png" alt="logo" width="684" height="556"/>
  <h1>{{ 'login.login'|translate }}</h1>
  <form [formGroup]="form" (ngSubmit)="login()" novalidate>
    <app-input-email
      class="input-group-container"
      [formControlNameInput]="'email'"
      [placeHolderInput]="'login.email' | translate"
      [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"
      [submitted]="submitted"
      [form]="f"
    ></app-input-email>
    <app-input-password
      class="input-group-container"
      [formControlNameInput]="'password'"
      [placeHolderInput]="'login.password'|translate"
      [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"
      [submitted]="submitted"
      [formPassword]="f['password']"
    ></app-input-password>
    <a routerLink="/forgot-password">{{'login.ifYouForgotPassword'|translate}}</a>
    <button cButton [disabled]="loading" type="submit">{{ 'login.login'|translate }}</button>
  </form>
</app-layout-auth>
