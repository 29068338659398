<div class="mb-3" [formGroup]="formGroup">
  <div class="flex-lg-row d-flex gap-2 checkbox_group">
    <input
      cFormCheckInput
      type="checkbox"
      (change)="changeCheckbox($event)"
      [formControlName]="formControlNameInput"
      id="{{ formControlNameInput }}"
      [attr.disabled] ="disabled ? true : null"
    />
    <label class="form-label mb-1" for="{{ formControlNameInput }}">
      {{ label }}
    </label>
  </div>
  <c-form-feedback *ngIf="submitted && form[formControlNameInput].errors">
    <div *ngIf="form[formControlNameInput]?.errors[getFirstError(form[formControlNameInput]?.errors) || '']">
      <div *ngIf="getFirstError(form[formControlNameInput]?.errors) === 'required'">
        {{ "errorMessages.formValidate.input.required" | translate }}
      </div>
    </div>
  </c-form-feedback>
</div>
