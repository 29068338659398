import { Timestamp } from 'firebase/firestore';

export interface INotification {
  id?: string;
  messageTitle?: string;
  messageBody?: string;
  isRead?: boolean;
  isDelete?: boolean;
  sendAll?: boolean;
  timePushMessage?: Timestamp;
  createdAt?: Timestamp;
  timeAgo?: any;
  user_uid?: string[];
}
