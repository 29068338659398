<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <div class="custom-container">
    <form action="" [formGroup]="formEditQuotation" (ngSubmit)="editQuotation()">
    <c-col class="justify-content-center mt-4 mb-3">
      <div class="box-title">
        <h3>見積編集</h3>
        <div class="d-flex justify-content-between btn-row">
          <div class="d-flex gap-10px">
            <a (click)="exportPDFQuotation()" class="link btn-common btn-title btn-title-export">
              <span>PDF出力</span>
            </a>
            <button type="submit" style="width: 90px" class="btn-common width-100">{{ 'account.btn_update'|translate }}</button>
          </div>
        </div>
      </div>
    </c-col>
      <c-card class="card-no-border">
        <c-card-body>
            <c-row>
              <c-col [lg]="6" class="mb-2">
                <c-col>
                  <c-col>
                    <div>技能証明申請者番号</div>
                    <input formControlName="recipientName" cFormControl type="text"/>
                  </c-col>
                  <c-col>
                    <c-col *ngIf="submitted && attribute['recipientName'].invalid" class="invalid-feedback">
                      <c-col *ngIf="attribute['recipientName'].errors && attribute['recipientName'].errors?.['required']">
                        内容を入力してください
                      </c-col>
                    </c-col>
                  </c-col>
                </c-col>
              </c-col>
              <c-col [lg]="6">
                <c-col>
                  <div>敬称</div>
                  <select formControlName="selectHonorificTitle" aria-label="Default select example" cSelect (change)="changeSelectHonorificTitle($event)">
                    <option *ngFor="let item of listHonorificTitle" [value]="item?.id">
                      {{ item?.value }}
                    </option>
                  </select>
                  <c-col *ngIf="submitted && attribute['selectHonorificTitle'].invalid" class="invalid-feedback">
                    <c-col *ngIf="attribute['selectHonorificTitle'].errors && attribute['selectHonorificTitle'].errors?.['required']">
                      内容を入力してください
                    </c-col>
                  </c-col>
                </c-col>
              </c-col>
            </c-row>
        </c-card-body>
        <c-card-body>
          <c-row>
            <c-col [lg]="5" class="mb-2">
              <div>項目名</div>
              <select formControlName="selectQuotationItem" aria-label="Default select example" cSelect (change)="changeSelectQuotation($event)">
                <option *ngFor="let item of listQuotationItem" [value]="item?.id">
                  {{ item?.itemName }}
                </option>
              </select>
              <c-col *ngIf="submitted && attribute['selectQuotationItem'].invalid" class="invalid-feedback">
                <c-col *ngIf="attribute['selectQuotationItem'].errors && attribute['selectQuotationItem'].errors?.['required']">
                  内容を入力してください
                </c-col>
              </c-col>
            </c-col>
            <c-col [lg]="5" class="mb-2">
              <c-row>
                <c-col [lg]="4">
                  <div>単価</div>
                  <input class="inp-quotation" disabled cFormControl type="text" value="{{ unitPriceQuotationSelect | number:'1.0-3' }}"/>

                </c-col>
                <c-col [lg]="4">
                  <c-col>
                    <c-col>
                      <div>数量</div>
                      <input formControlName="valueQuantity" class="inp-quotation" cFormControl type="text" [(ngModel)]="inputValueQuantity" (blur)="fetchDataQuantity()"/>
                    </c-col>
                    <c-col>
                      <c-col *ngIf="submitted && attribute['valueQuantity'].invalid" class="invalid-feedback">
                        <c-col *ngIf="attribute['valueQuantity'].errors && attribute['valueQuantity'].errors?.['required']">
                          内容を入力してください
                        </c-col>
                        <c-col *ngIf="attribute['valueQuantity'].errors && attribute['valueQuantity'].errors?.['pattern']">
                          正しい形式で入力してください
                        </c-col>
                      </c-col>
                    </c-col>
                  </c-col>
                </c-col>
                <c-col [lg]="4">
                  <div>金額</div>
                  <input class="inp-quotation" disabled cFormControl type="text" value="{{ sumQuotation | number:'1.0-3' }}"/>

                </c-col>
              </c-row>
            </c-col>
            <c-col [lg]="2" class="mb-2">
              <div class="buton-add-quo-tiem" style="margin-top: 24px;">
                <a (click)="onSubmit()" class="form-create-data btn-common btn-title btn-quotation">
                  <span>追加</span>
                </a>
              </div>
            </c-col>
          </c-row>
        </c-card-body>

        <c-card-body>
          <h5>見積項目一覧</h5>
        </c-card-body>

        <c-card-body *ngIf="dataQuotationArr.length">
          <c-row class="d-none d-lg-flex">
            <c-col [lg]="5">
              <div>項目名</div>
            </c-col>
            <c-col [lg]="5" class="mb-2">
              <c-row>
                <c-col [lg]="4">
                  <div>単価</div>
                </c-col>
                <c-col [lg]="4">
                  <div>数量</div>
                </c-col>
                <c-col [lg]="4">
                  <div>金額</div>
                </c-col>
              </c-row>
            </c-col>
            <c-col [lg]="2" class="mb-2">
              <c-col xs="12" sm="2">
              </c-col>
            </c-col>
          </c-row>
          <app-quotation-item
            *ngFor="let item of dataQuotationArr; let i = index"
            [itemIndex]="i"
            [itemInput]="item"
            [lengthDataQuotationArr]="lengthDataQuotationArr"
            (deleteItem)="handleDeleteQuotationItem($event)"
            (moveItemUp)="handleMoveItemUp($event)"
            (moveItemDown)="handleMoveItemDown($event)"
          >
          </app-quotation-item>
        </c-card-body>
        <c-card-body>
            <c-row>
              <c-col [lg]="2"></c-col>
              <c-col [lg]="6"></c-col>
              <c-col [lg]="4" class="mb-2" style="text-align: -webkit-right;">
                <div class="input-amount">
                  <div class="title-amount">小計</div>
                  <input class="inp-mount" disabled cFormControl type="text" value="{{ totalMoney | number:'1.0-3' }}"/>
                </div>
                <div class="input-amount">
                  <div class="title-amount">消費税 {{ valueTax }}%</div>
                  <input class="inp-mount" disabled cFormControl type="text" value="{{ totalTax | number:'1.0-3' }}"/>
                </div>
                <div class="input-amount">
                  <div class="title-amount">合計</div>
                  <input class="inp-mount" disabled cFormControl type="text" value="{{ totalMoney + totalTax | number:'1.0-3'}}"/>
                </div>
              </c-col>
            </c-row>
        </c-card-body>
        <c-card-body>
          <app-text-area
            [label]="'備考'"
            class="input-group-container"
            [formControlNameInput]="'remark'"
            [placeHolderInput]="'備考'"
            [inputType]="'text'"
            [requiredText]="false"
            [maxLength]="'1000'"
          >
        </app-text-area>
        </c-card-body>
      </c-card>
    </form>
  </div>
  <app-loading [isLoading]="isLoading"></app-loading>
</c-container>
