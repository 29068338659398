<c-container class="min-vh-90">
  <c-row class="justify-content-center">
    <c-col md="6">
      <span class="clearfix">
        <h1 class="float-start display-3 me-4">Terms Of Use</h1>
        <h4 class="pt-3">利用規約</h4>
        <p class="text-medium-emphasis float-start">
          株式会社タクト（以下「当社」）のサービスをご利用いただきありがとうございます。
        </p>
        <p class="text-medium-emphasis float-start">
          　お客様、およびお客様が代表している法人（以下、総称して「お客様」）は、当社のウェブサービスやソフトウェア（以下、総称して「本サービス」）を利用またはダウンロードすることで、この規約（以下「本規約」）に同意したものとします。
        </p>
        <p class="text-medium-emphasis float-start">
          Ⅰ．お客様データおよびお客様の行動データ
        </p>
        <p class="text-medium-emphasis float-start">
          電子ファイル、文字情報、本サービス上で制作された情報、その他お客様が本サービスの利用に際して提供するデータ（以下、総称して「お客様データ」）について、当社はお客様の保有している著作権その他の権利を侵害しません。
        </p>
        <p class="text-medium-emphasis float-start">
          当社はお客様データを保持し、加工し、表示し、バックアップするなどの、本サービス提供のために必要な処理を行う権利を持ちます。ただし、当社はお客様データの内容を検査することはありません。
        </p>
        <p class="text-medium-emphasis float-start">
          当社は本サービスの向上のために、お客様の本サービスの利用状況のモニタリング・分析をすることがあります。
        </p>
        <p class="text-medium-emphasis float-start">
          当社はお客様データについて漏洩対策などの適切な管理を行います。お客様の同意がある場合や法令により強制される場合を除き、当社はお客様データへのアクセスやお客様データの変更、開示などをいたしません。
        </p>
        <p class="text-medium-emphasis float-start">
          当社は行政手続における特定の個人を識別するための番号の利用等に関する法律（平成二十五年法律第二十七号、マイナンバー法）に定める個人番号を内容に含む電子データを取り扱いません。
        </p>
        <p class="text-medium-emphasis float-start">
          Ⅱ．お客様の責任
        </p>
        <p class="text-medium-emphasis float-start">
          お客様は本サービスの利用にあたり、本規約、ユーザーガイド、日本国および／またはお客様の国で適用される法令や政府その他の公的機関の規則、規制などに従ってください。
        </p>
        <p class="text-medium-emphasis float-start">
          お客様は本サービスの利用に当たり、お客様データの利用、共有、公開など一切の行為について自己の責任で行うものとします。他者の権利や名誉、プライバシーなどを侵害、毀損しないでください。これらの点に関して問題が発生した場合、お客様は自身の費用と責任でこれを解決する必要があります。
        </p>
        <p class="text-medium-emphasis float-start">
          本サービスを利用するためのお客様のアカウント（以下「お客様アカウント」）およびダウンロードしたソフトウェアの共有、譲渡、賃借、売却などをしないでください。お客様がお客様アカウントやダウンロードしたソフトウェアを提供した第三者の行動により問題が発生した場合、お客様はご自身の費用と責任でこれを解決する必要があります。
        </p>
        <p class="text-medium-emphasis float-start">
          本サービスの不正利用や本サービスの動作の妨害などを行わないでください。お客様がそれらの不正利用行為や妨害活動を発見した場合には速やかに当社に通知してくださるようお願いします。
        </p>
        <p class="text-medium-emphasis float-start">
          本サービスへのリバースエンジニアリングやデコンパイル、改変などの試みは行わないでください。また、それらを試みる第三者を発見した場合、その行為の黙認や支援をしないでください。
        </p>
        <p class="text-medium-emphasis float-start">
          Ⅲ．本サービスおよび本規約
        </p>
        <p class="text-medium-emphasis float-start">
          本サービスおよび本サービスに付随する文書、コンテンツ、名称やロゴなどの著作権、商標権その他一切の権利は、当社または当社が使用許諾を受けている第三者に帰属し、日本およびお客様の国で適用される法令により保護されています。
        </p>
        <p class="text-medium-emphasis float-start">
          当社は本サービスを予告なく任意のタイミングで変更しまたは一時停止することがあります。ただし、お客様の利用方法が変更される場合、または１時間以上の本サービスの一時停止が予定される場合には、変更または一時停止の1週間前までにお客様の登録メールアドレスにご案内します。
        </p>
        <p class="text-medium-emphasis float-start">
          更新は、本サービスの新しいバージョンをお客様に届ける仕組みであり、メンテナンスは本サービスの維持をするものであり、いずれも本サービスの障害の回避を保証するものではありません。
        </p>
        <p class="text-medium-emphasis float-start">
          当社は本規約を予告なく任意のタイミングで変更することがあります。ただし、変更がお客様の権利を縮小するものであった場合、緊急の場合を除き、変更の内容および変更の効力発生時期を、実施の1週間前までにお客様の登録メールアドレスにご案内します。お客様は変更の効力発生後にも本サービスの利用を続けることで、変更後の本規約に拘束されることをあらかじめ了承するものとします。
        </p>
        <p class="text-medium-emphasis float-start">
          Ⅳ．免責
        </p>
        <p class="text-medium-emphasis float-start">
          当社は本サービスの提供において、明示または黙示を問わず、いかなる保証も行いません。本サービスは現状有姿で提供されます。
        </p>
        <p class="text-medium-emphasis float-start">
          当社は本サービスの正常稼働とダウンタイム最小化のための合理的な努力をしますが、システム障害などのために本サービスを一定時間停止することがあります。これによりお客様に何らかの損害が生じた場合でも当社は一切の責任を負いません。
        </p>
        <p class="text-medium-emphasis float-start">
          当社は本サービスを24時間365日稼働させるための合理的な努力をしますが、以下の場合は除外されます。
        </p>
        <p class="text-medium-emphasis float-start">
          計画停止
        </p>
        <p class="text-medium-emphasis float-start">
          当社の合理的な管理を超える状況 （不可抗力、政府機関の行為、天変地異、暴動、テロ行為、インターネットサービスプロバイダの障害もしくは遅延、外部からの攻撃等を含みますが、それらに限定されません）により生じた停止
        </p>
        <p class="text-medium-emphasis float-start">
          当社は本サービスへのログイン認証などに第三者のサービスを利用することがありますが、第三者のサービスがお客様の情報をどう取り扱うかについて、当社は一切の責任を負いません。
        </p>
        <p class="text-medium-emphasis float-start">
          当社は、当社が本規約に違反しお客様に損害を生じさせた場合であっても、お客様の逸失利益、間接的損害、特別損害、偶発的損害、結果的損害、または懲罰的損害を含む一切の損害について責任を負いません。また、損害賠償額は、責任の発生原因となる出来事の発生日からさかのぼる12か月間にお客様が当社に支払った金額を上限とします。
        </p>
        <p class="text-medium-emphasis float-start">
          Ⅴ．アカウントやお客様データの停止、終了、削除
        </p>
        <p class="text-medium-emphasis float-start">
          お客様は本サービスの利用をいつでも終了できます。
        </p>
        <p class="text-medium-emphasis float-start">
          お客様が本規約および本規約の締結と同時に締結された契約（以下、総称して「本契約」）に違反した場合、当社は独自の判断で予告なく任意のタイミングでお客様の本サービスの利用を停止できます。
        </p>
        <p class="text-medium-emphasis float-start">
          本契約への違反あるいは有料サービスへの支払遅延により本サービスが停止となった場合、当社は、かかる違反状態や支払遅延の是正の勧告をお客様の登録メールアドレスに対して送信し、かかる送信より2週間を経ても違反状態等が是正されないときにはお客様に対する本サービスの提供を終了できるものとします。この場合、以下の各号が適用されます。
        </p>
        <p class="text-medium-emphasis float-start">
          当社がお客様への本サービスの提供終了措置を採る際、お客様は当社から提供されたソフ トウェア及びその複製物を全て破棄しなければなりません。
        </p>
        <p class="text-medium-emphasis float-start">
          当社がお客様への本サービスの停止あるいは終了の措置をとる際、当社はお客様が当社に 支払った金額の払戻には応じません。
        </p>
        <p class="text-medium-emphasis float-start">
          有料サービスを利用する場合を除き、お客様が継続して12か月間以上本サービスを利用しない場合、当社はお客様に対する本サービスの提供を終了できます。当社はかかる措置を取る場合、その実施の1週間前までにお客様の登録メールアドレスにご案内します。
        </p>
        <p class="text-medium-emphasis float-start">
          お客様に対する本サービスの提供が終了した日から2週間を経過した場合には、当社はお客様データやお客様の行動データ、お客様アカウントの情報などの一切（以下、総称して「お客様データ等」）を削除することができます。
        </p>
        <p class="text-medium-emphasis float-start">
          当社は、本サービスの停止や終了あるいはお客様データ等の削除によりお客様に何らかの損害が生じた場合でも、一切の責任を負いません。また、本サービスの利用が停止または終了された場合でも、お客様が当社に対して負うべき損害賠償責任は免責されません。
        </p>
        <p class="text-medium-emphasis float-start">
          Ⅵ．一般条項
        </p>
        <p class="text-medium-emphasis float-start">
          お客様は、あらかじめ当社の書面による承諾がない限り、本契約上の地位、権利または義務の全部または一部を第三者に譲渡し承継させまたは担保に供してはならないものとします。
        </p>
        <p class="text-medium-emphasis float-start">
          本サービスが実際に使用される国の如何に関わらず、本契約には日本国の法律が適用されるものとします。
        </p>
        <p class="text-medium-emphasis float-start">
          本規約および本サービスに関連する訴訟の第一審の専属的合意管轄裁判所は、大阪地方裁判所とします。
        </p>
        <p class="text-medium-emphasis float-start">
          本規約は、本規約以前に本規約または本サービスに関連してお客様と当社の間になされた全ての取り決めに優先して適用されます。
        </p>
        <p class="text-medium-emphasis float-start">
          本契約の一部の規定が公序良俗違反、強行法規違反その他事由によって効力を認められない場合であっても、他の規定の有効性には影響を及ぼさないものとします。
        </p>
        <p class="text-medium-emphasis float-start">
          本サービスのソフトウェアおよびその応用プログラムを日本国外に持ち出しまたは送信する場合には、日本国の輸出関連法令を遵守するものとします。
        </p>
        <p class="text-medium-emphasis float-start">
          本サービスのサービス向上のために当社がお客様から提供を受ける情報にお客様の個人情報が含まれているときは、当社のプライバシーポリシーに従って取り扱うものとします。
        </p>
        <p class="text-medium-emphasis float-start">
          お客様は、自己及び自己の親会社、子会社（これらの役員を含みます）が反社会的勢力（暴力、威力、詐欺的手法を駆使して経済的利益を追及する集団又は個人をいいます）に該当しないことを表明し、また、反社会的勢力の関係者と取引、交際しないことを約するものとします。お客様がこれらに反し、または反していたことが判明した場合には、当社は何らの催告を要せず、即時にお客様による本サービスの利用を停止することができるものとします。
        </p>
        <p class="text-medium-emphasis float-start">
          ※この利用規約の最終更新日は 2022年11月27日です。
        </p>
      </span>
    </c-col>
  </c-row>
</c-container>
