import { Component, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  @Input() isLoading: boolean = false;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.isLoading) this.renderer.setStyle(document.body, 'overflow', 'hidden');
    else this.renderer.removeStyle(document.body, 'overflow');
  }
}
