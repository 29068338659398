import { Component, Input, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { checkInputEnter, getFirstError } from '@app/helpers';

@Component({
  selector: 'app-input-time',
  templateUrl: './input-time.component.html',
  styleUrls: ['./input-time.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class InputTimeComponent implements OnInit {
  getFirstError = getFirstError;
  @Input('formGroup') formGroup: any;
  @Input('form') form: any = [];
  @Input('label') label: string = '';
  @Input('ngClass') ngClass: NgClass['ngClass'];
  @Input('formControlNameInput') formControlNameInput: string = '';
  @Input('placeHolderInput') placeHolderInput: string = '';
  @Input('submitted') submitted: boolean = false;
  @Input('requiredText') requiredText: boolean = true;
  @Input('disabled') disabled: boolean = false;
  protected currentDatepickerLocale: string = '';
  protected checkInputEnter = checkInputEnter;
  protected currentLanguage = this.translate.currentLang;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {}
  
  onPaste(e: any): void {
    e.preventDefault();
  }
}
