import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { AlertService, AttendanceService } from "@app/services";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { TranslateService } from "@ngx-translate/core";
import { IAttendances } from "@app/models";
import { numberOnly, getTime } from '@app/helpers';
import * as moment from 'moment';
import { IBreadcrumbItem } from '@coreui/angular';
import { IDataOption, STATUS_EXAM, formatDatePicker } from '@app/constants';

@Component({
  selector: 'app-update-attendances',
  templateUrl: './update-attendances.component.html',
  styleUrls: ['./update-attendances.component.scss']
})
export class UpdateAttendancesComponent implements OnInit {
  protected formCreateAttendances!: FormGroup;
  protected submitted: boolean = false;
  protected id: string | null = '';
  protected course: string | null = '';
  protected attendancesId: string | null = '';
  protected attendances: any;
  protected currentUser: any;
  protected userId: string = '';
  protected readonly statusExam: IDataOption[] = STATUS_EXAM;
  protected numberOnly = numberOnly;
  protected elementRef: ElementRef;
  protected courseContentItems: any;
  protected leaderNameItems: any;
  protected isRequest: boolean = false;
  protected breadcrumbItems: IBreadcrumbItem[] = [];

  constructor(
    private attendanceService: AttendanceService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private auth: AngularFireAuth,
    private alertService: AlertService,
    private translateService: TranslateService,
    private router: Router,
    @Inject(ElementRef) elementRef: ElementRef
  ) {
    this.elementRef = elementRef;
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.course = this.activatedRoute.snapshot.paramMap.get('course');

    this.attendancesId = this.activatedRoute.snapshot.paramMap.get('attendanceId');

    this.initForm();
    this.getCourseContentItems();

    this.getUser().then(() => {
      this.userId = this.activatedRoute.snapshot.queryParamMap.get('user_id') ?? this.currentUser.uid;

      this.getStudentAttendances(this.userId, this.id, this.attendancesId).then((data: any) => {
        if (data == undefined) {
          this.router.navigate(['student']);
          this.alertService.error(this.translateService.instant('student.not_found'));
        } else {
          this.attendances = data;

          if (this.attendances.course != this.course) this.back();

          this.f['courseContentCurriculum'].setValue(data.courseContentCurriculum);
          this.f['courseDate'].setValue(data.courseDate ? moment(data.courseDate?.toDate()).format(formatDatePicker) : '');
          this.f['courseStartTime'].setValue(data.courseStartTime);
          this.f['courseEndTime'].setValue(data.courseEndTime);
          this.f['actualFlightTime'].setValue(data.actualFlightTime);
          this.f['comment'].setValue(data.comment);
          this.f['leaderName'].setValue(data.leaderName);
          this.f['trainingLocation'].setValue(data.trainingLocation);
          this.f['remark'].setValue(data.remark);
          this.f['examPass'].setValue(data.examPass);
          this.f['score'].setValue(data.score);
          this.f['exam'].setValue(data.exam);
          this.f['examComment'].setValue(data.examComment);
          if (data.exam) {
            this.f['score'].setValidators([Validators.required, Validators.min(0), Validators.max(100)]);
            this.f['score'].updateValueAndValidity();
          }
        }
      });

      this.getLeaderNameItems();
    });
    this.translateService.get([
      'student.listStudent.title',
      'student.attendances.list.title',
      'student.attendances.update.title'
    ]).subscribe(translations => {
      this.breadcrumbItems = [
        {
          label: translations['student.listStudent.title'],
          url: '/student'
        },
        {
          label: translations['student.attendances.list.title'],
          url: `/student/${this.id}/attendances/${this.course}`
        },
        {
          label: translations['student.attendances.update.title'],
          url: ''
        },
      ];
    });
  }

  private async getCourseContentItems(): Promise<void> {
    const data = await this.attendanceService.getItems('course_content_items');
    if (data.length) this.courseContentItems = data;
  }

  private async getLeaderNameItems(): Promise<void> {
    const data = await this.attendanceService.getItemsWithUserId('instructors', this.currentUser.uid);
    if (data.length) this.leaderNameItems = data;
  }

  initForm() {
    this.formCreateAttendances = this.fb.group({
      courseContentCurriculum: new FormControl('', { validators: [Validators.required, Validators.minLength(1), Validators.maxLength(127)] }),
      courseDate: new FormControl('', { validators: [Validators.required] }),
      courseStartTime: new FormControl('', { validators: [Validators.required] }),
      courseEndTime: new FormControl('', { validators: [Validators.required] }),
      actualFlightTime: new FormControl('', { validators: [Validators.min(0), Validators.max(9999)] }),
      comment: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(1023)] }),
      leaderName: new FormControl('', { validators: [Validators.required, Validators.minLength(1), Validators.maxLength(127)] }),
      trainingLocation: new FormControl('', {validators: [Validators.required, Validators.minLength(1), Validators.maxLength(127)]}),
      remark: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(1023)] }),
      exam: new FormControl(false, { validators: [] }),
      examPass: new FormControl("合格", { validators: [] }),
      score: new FormControl('', { validators: [Validators.min(0), Validators.max(100)] }),
      examComment: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(1023)] }),
    })
  }

  async getUser(): Promise<void> {
    const currentUser = await this.auth.currentUser;
    if (currentUser) this.currentUser = currentUser;
  }

  get f() {
    return this.formCreateAttendances.controls;
  }

  async getStudentAttendances(userId: string, studentId: string | null, attendancesId: string | null) {
    studentId = studentId == null ? '' : studentId;
    attendancesId = attendancesId == null ? '' : attendancesId;
    return await this.attendanceService.getStudentAttendances(userId, studentId, attendancesId);
  }

  onPaste(e: any) {
    e.preventDefault();
  }

  private changeTime(stringTime: string): number {
    const timeArr: string[] = stringTime.split(':');
    const time = parseInt(timeArr[0]);
    const minute = parseInt(timeArr[1]);
    return time * 60 + minute;
  }

  protected checkValidCourseTime() {
    const courseStartTime = this.changeTime(getTime(this.f['courseStartTime'].value));
    const courseEndTime = this.changeTime(getTime(this.f['courseEndTime'].value));
    if (courseStartTime > courseEndTime) {
      this.alertService.error('開始時間は終了時間より前である必要があります。')
      return false;
    }
    return true;
  }

  submit(): void {
    this.submitted = true;
    this.focusInput(this.formCreateAttendances.controls)
    if (!this.isRequest && this.submitted && this.formCreateAttendances.valid && this.checkValidCourseTime()) {
      this.isRequest = true
      let dataForm = this.formCreateAttendances.getRawValue();
      const dataInsert: IAttendances = {
        courseContentCurriculum: dataForm.courseContentCurriculum,
        courseDate: dataForm.courseDate,
        courseStartTime: getTime(dataForm.courseStartTime),
        courseEndTime: getTime(dataForm.courseEndTime),
        actualFlightTime: dataForm.actualFlightTime,
        comment: dataForm.comment,
        leaderName: dataForm.leaderName,
        trainingLocation: dataForm.trainingLocation,
        remark: dataForm.remark,
        exam: dataForm.exam,
        examPass: dataForm.examPass,
        score: dataForm.score,
        examComment: dataForm.examComment,
        course: this.course,
        createAt: this.attendances.createAt,
      }
      const id = this.id != null ? this.id : ''
      const attendancesId = this.attendancesId == null ? '' : this.attendancesId
      this.attendanceService.updateAttendances(dataInsert, this.currentUser.uid, id, attendancesId)
        .then(() => this.router.navigateByUrl(`student/${id}/attendances/${this.course}`))
        .then(() => this.alertService.success(this.translateService.instant('student.attendances.update.success')))
        .finally(() => this.isRequest = false);
    }
  }

  private focusInput(form: any): void {
    for (const key of Object.keys(form)) {
      if (form[key].invalid) {
        const invalidControl = this.elementRef.nativeElement.querySelector('#' + key);
        invalidControl.focus();
        break;
      }
    }
  }

  back(): void {
    let params = {};

    if (this.userId && (this.userId !== this.currentUser.uid)) {
      params = { ...{ user_id: this.userId ?? '' } };
    }

    this.router.navigate(
      [`student/${this.id}/attendances/${this.course}`],
      {
        queryParams: params
      })
  }

  examChange(event: Event): void {
    if (this.f['exam'].value) {
      this.f['score'].setValidators([Validators.required, Validators.min(0), Validators.max(100)]);
      this.f['score'].updateValueAndValidity();
    } else {
      this.f['score'].setValidators([Validators.min(0), Validators.max(100)]);
      this.f['score'].updateValueAndValidity();
    }
  }

  handleAddItems(event: any, fieldName: string) {
    if (event.items.length > 0) {
      let value = '';
      event.items.forEach((item: any) => {
        if (value == '') {
          value = item.itemName;
        } else {
          value += '、' + item.itemName;
        }
      });
      this.f[fieldName].setValue(this.f[fieldName].value == ''
        ? value
        : this.f[fieldName].value + '、' + value);
    }
  }
}
