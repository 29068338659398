<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <div class="custom-container">
    <app-breadcrumb [items]="breadcrumbItems"></app-breadcrumb>
    <c-col class="justify-content-center mb-5">
      <div class="d-flex justify-content-between align-items-center box-title">
        <h3>{{ 'student.attendances.create.title' | translate }}</h3>
      </div>
    </c-col>
    <c-card class="card-no-border account-card">
      <div class="create_student">
        <form [formGroup]="formCreateAttendances" novalidate>
          <div class="form_item--title">{{ 'student.attendances.create.short'|translate }}</div>
          <!-- 講習内容 -->
          <app-input-base-with-button
            [label]="'student.attendances.create.courseContentCurriculum' | translate"
            class="input-group-container"
            [formControlNameInput]="'courseContentCurriculum'"
            [placeHolderInput]="'student.attendances.create.courseContentCurriculum' | translate"
            [inputType]="'text'"
            [submitted]="submitted"
            [maxLength]="'127'"
            [requiredText]="true"
            [form]="f"
            [inputGroupText]="'...'"
            [idModal]="'courseContentItemsModal'"
            [titleModal]="'student.attendances.create.courseContentItemsModal' | translate"
            [items]="courseContentItems"
            (addItem)="handleAddItems($event, 'courseContentCurriculum')"
          ></app-input-base-with-button>

          <!-- 講習日 -->
          <app-input-date-picker
            [label]="'student.attendances.create.courseDate' | translate"
            [formControlNameInput]="'courseDate'"
            [formGroup]="formCreateAttendances"
            [submitted]="submitted"
            [requiredText]="false"
            [disabled]="false"
            [requiredText]="true"
            [form]="f">

          </app-input-date-picker>
          <!-- 講習開始時間 -->
          <app-input-time
            [label]="'student.attendances.create.courseStartTime' | translate"
            [formControlNameInput]="'courseStartTime'"
            [formGroup]="formCreateAttendances"
            [submitted]="submitted"
            [placeHolderInput]="'HH:mm'"
            [requiredText]="true"
            [form]="f"
          ></app-input-time>
          <!-- 講習終了時間 -->
          <app-input-time
            [label]="'student.attendances.create.courseEndTime' | translate"
            [formControlNameInput]="'courseEndTime'"
            [formGroup]="formCreateAttendances"
            [submitted]="submitted"
            [placeHolderInput]="'HH:mm'"
            [form]="f"
            [requiredText]="true"
            ></app-input-time>
          <!-- 実飛行時間 -->
          <app-input-base
            [label]="'student.attendances.create.actualFlightTime' | translate"
            class="input-group-container"
            [formControlNameInput]="'actualFlightTime'"
            [placeHolderInput]="'student.attendances.create.actualFlightTime' | translate"
            [inputType]="'text'"
            [submitted]="submitted"
            (keypress)="numberOnly($event)"
            [form]="f"
            (paste)="onPaste($event)"
            [max]="'9999'"
            [min]="'0'"
            [requiredText]="false"
          ></app-input-base>
          <!-- コメント -->
          <app-text-area
            [label]="'student.attendances.create.comment' | translate"
            class="input-group-container"
            [formControlNameInput]="'comment'"
            [placeHolderInput]="'student.attendances.create.comment' | translate"
            [inputType]="'texarea'"
            [maxLength]="'1023'"
            [submitted]="submitted"
            [form]="f"
            [requiredText]="false"
          ></app-text-area>
          <!-- 指導者名 -->
          <app-input-base-with-button
            [label]="'student.attendances.create.leaderName' | translate"
            class="input-group-container"
            [formControlNameInput]="'leaderName'"
            [placeHolderInput]="'student.attendances.create.leaderName' | translate"
            [inputType]="'text'"
            [maxLength]="'127'"
            [form]="f"
            [submitted]="submitted"
            [requiredText]="true"
            [inputGroupText]="'...'"
            [idModal]="'leaderNameModal'"
            [titleModal]="'student.attendances.create.leaderNameModal' | translate"
            [items]="leaderNameItems"
            (addItem)="handleAddItems($event, 'leaderName')"
          ></app-input-base-with-button>
          <!-- 講習場所 -->
          <app-input-base
            [label]="'student.attendances.create.trainingLocation' | translate"
            class="input-group-container"
            [formControlNameInput]="'trainingLocation'"
            [placeHolderInput]="'student.attendances.create.trainingLocation' | translate"
            [inputType]="'text'"
            [maxLength]="'127'"
            [form]="f"
            [submitted]="submitted"
            [requiredText]="true"
          ></app-input-base>
          <!-- 備考 -->
          <app-text-area
            [label]="'student.attendances.create.remark' | translate"
            class="input-group-container"
            [formControlNameInput]="'remark'"
            [placeHolderInput]="'student.attendances.create.remark' | translate"
            [inputType]="'text'"
            [maxLength]="'1023'"
            [submitted]="submitted"
            [form]="f"
            [requiredText]="false"
          ></app-text-area>
          <!-- 試験 -->

          <div class="form_item--title">{{ 'student.attendances.create.exam' | translate }}</div>
          <app-input-checkbox
            [label]="'student.attendances.create.exam' | translate"
            class="input-group-container"
            [formControlNameInput]="'exam'"
            [form]="f"
            [formGroup]="formCreateAttendances"
            [submitted]="submitted"
            [requiredText]="false"
            (change)="examChange($event)"
          ></app-input-checkbox>

          <!-- 合格 -->
          <app-input-radio
            [label]="'student.attendances.create.examPass' | translate"
            [formControlNameInput]="'examPass'"
            [dataOption]="statusExam"
            [submitted]="submitted"
            [form]="f"
            [requiredText]="false"
          ></app-input-radio>

          <!-- 点数 -->
          <app-input-base
            [label]="'student.attendances.create.score' | translate"
            class="input-group-container"
            [formControlNameInput]="'score'"
            [placeHolderInput]="'student.attendances.create.score' | translate"
            [inputType]="'text'"
            [submitted]="submitted"
            (keypress)="numberOnly($event)"
            (paste)="onPaste($event)"
            [requiredText]="f['exam'].value"
            [max]="'100'"
            [min]="'0'"
            [form]="f"
          ></app-input-base>
          <!--  試験コメント -->
          <app-text-area
            [label]="'student.attendances.create.examComment' | translate"
            class="input-group-container"
            [formControlNameInput]="'examComment'"
            [placeHolderInput]="'student.attendances.create.examComment' | translate"
            [inputType]="'text'"
            [maxLength]="'1023'"
            [submitted]="submitted"
            [form]="f"
            [requiredText]="false"
          ></app-text-area>

          <div class="mb-3 d-flex justify-content-end">
            <button class="back-btn" (click)="back()">
              {{ "form.backButton" | translate }}
            </button>
            <button cButton type="submit" (click)="submit()" class="btn-submit">
              {{ "form.inputLabel.submit" | translate }}
            </button>
          </div>
        </form>
      </div>
    </c-card>
  </div>
</c-container>
