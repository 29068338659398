import { Injectable } from '@angular/core';
import { IAttendances, ISearch as ISearchAttendances, ISyncAttendances } from "@app/models";
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from "firebase/compat";
import { EXAM_PASS, endTimeOfDay, formatDatePicker, startTimeOfDay } from '@app/constants';
import { formatDate } from '@app/helpers';
import { Api } from "@app/services/api.service";
import { IStudent } from "@app/models/student";

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {
  readonly collectionName = 'students';
  readonly collectionAttendanceName = 'attendances';

  constructor(
    private db: AngularFirestore,
    private api: Api,
  ) {}

  async checkStudent(userId: string, studentId: string) {
    return new Promise((resolve, reject) => {
      this.db.collection('users')
        .doc(userId).collection(this.collectionName)
        .doc(studentId).valueChanges()
        .subscribe(result => resolve(result != undefined));
    });
  }

  async createAttendances(attendances: IAttendances, userDocId: string = '', studentDocId: string = '') {
    //todo create attendances
    return await this.db.collection('users').doc(userDocId).collection(this.collectionName).doc(studentDocId).collection(this.collectionAttendanceName).add(attendances);
  }

  async createSyncAttendances(attendances: IAttendances, userDocId: string = '', studentDocId: string = '') {
    //todo create sync attendances
    let dataInsert: ISyncAttendances = {
      userId: userDocId,
      studentDocId: studentDocId,
      attendances: attendances,
    };
    return await this.db.collection('sync_attendances').doc(userDocId).collection('log_attendances').add(dataInsert);
  }

  async getAttendances(userDocId: string = '', studentDocId: string = '', course = '') {
    //todo search attendances in user
    return this.db.collection('users').doc(userDocId).collection(this.collectionName).doc(studentDocId).collection(this.collectionAttendanceName, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('course', '==', course)
      return query.orderBy('courseDate', 'desc');
    }).valueChanges({ idField: 'id' });
  }

  async geAttendancesExamPass(userDocId: string = '', studentDocId: string = '') {
    return new Promise<any>((resolve, error) => {
      this.db.collection('users').doc(userDocId).collection(this.collectionName).doc(studentDocId).collection(this.collectionAttendanceName, ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.where('examPass', '==', EXAM_PASS)
        return query.orderBy('createAt', 'desc');
      }).valueChanges({ idField: 'id' })
      .subscribe((attendances) => resolve(attendances));
    })
  }

  async updateAttendances(attendances: IAttendances, userDocId: string = '', studentDocId: string = '', attendancesId: string) {
    //todo update student attendances
    return await this.db.collection('users').doc(userDocId)
      .collection(this.collectionName).doc(studentDocId)
      .collection(this.collectionAttendanceName).doc(attendancesId)
      .set(attendances);
  }

  async getStudentAttendances(userId: string, studentId: string, attendancesId: string) {
    //get student attendances
    return new Promise((resolve, reject) => {
      this.db.collection('users').doc(userId)
        .collection(this.collectionName).doc(studentId)
        .collection(this.collectionAttendanceName).doc(attendancesId)
        .valueChanges().subscribe(result => resolve(result));
    })
  }

  async deleteAttendance(userDocId: string = '', studentDocId: string = '', attendancesId: string) {
    return await this.db.collection('users')
      .doc(userDocId).collection(this.collectionName)
      .doc(studentDocId).collection(this.collectionAttendanceName)
      .doc(attendancesId).delete();
  }

  async deleteSyncAttendance(userDocId: string = '', studentDocId: string = '', attendancesId: string) {
    return await this.db.collection('sync_attendances')
      .doc(userDocId).collection("log_attendances")
      .doc(attendancesId).delete();
  }

  exportPDF(userId: string, student: IStudent,course: string, params: any) {
    params['startDateFormat'] = params.startDate instanceof Date ? formatDate(params.startDate, formatDatePicker) : '';
    params['endDateFormat'] = params.endDate instanceof Date ? formatDate(params.endDate, formatDatePicker) : '';

    return this.api.get(`/user/${userId}/student/${student.id}/attendances/${course}`, { responseType: 'blob', params: params })
      .then((res: any) => {
      const url = window["URL"].createObjectURL(new Blob([res]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `受講記録_${student?.studentInfo?.certificateNumber}_${student?.studentInfo?.name}.pdf`);
      document.body.appendChild(link);
      link.click();
    })
  }

  async getItems(collectionName: string, paramSort: string = 'sort', desc: boolean = true) {
    return new Promise<any>((resolve, error) => {
      this.db.collection(collectionName, ref => ref.orderBy(paramSort, desc ? 'desc' : 'asc'))
      .valueChanges({ idField: 'id' })
      .subscribe((courseContentItem) => resolve(courseContentItem));
    })
  }

  async getItemsWithUserId(collectionName: string, userId: string, paramSort: string = 'sort', desc: boolean = true) {
    return new Promise<any>((resolve, error) => {
      this.db.collection('users').doc(userId).collection(collectionName, ref => ref.orderBy(paramSort, desc ? 'desc' : 'asc'))
      .valueChanges({ idField: 'id' })
      .subscribe((courseContentItem) => resolve(courseContentItem));
    })
  }
}
