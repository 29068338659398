<div [formGroup]="formGroup">
  <div class="form_item">
    <div class="form_item--title">飛行機</div>
    <div class="form_item--content">

      <!-- b_plane -->
      <app-input-checkbox
        [ngClass]="'checkbox_group--inline'"
        [label]="'student.createStudent.studentCourseAirplane.b_plane' | translate"
        [formControlNameInput]="'b_plane'"
        [submitted]="submitted"
        [formGroup]="formGroup"
        (changeValue)="changeCourse()"
        [form]="form">
      </app-input-checkbox>

      <div [@slideInOut]="formGroup.get('b_plane')?.value ? 'in': 'out'">
        <!-- b_firstClassBasic -->
        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.studentCourseAirplane.b_firstClassBasic' | translate"
          [formControlNameInput]="'b_firstClassBasic'"
          [submitted]="submitted"
          [formGroup]="formGroup"
          [disabled]="checkTypeOrderCourse('second', form)"
          (changeValue)="changeCourseTypes('b_firstClassBasic', form)"
          [form]="form">
        </app-input-checkbox>

        <c-row [@slideInOut]="formGroup.get('b_firstClassBasic')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- b_firstClassBasicDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassBasicDate' | translate"
              [formControlNameInput]="'b_firstClassBasicDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['b_firstClassBasicDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- b_firstClassBasicNo -->
            <app-input-base
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassBasicNo' | translate"
              class="input-group-container"
              [formControlNameInput]="'b_firstClassBasicNo'"
              [placeHolderInput]="'student.createStudent.studentCourseAirplane.b_firstClassBasicNo' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-base>
          </c-col>
        </c-row>
        <c-row [@slideInOut]="formGroup.get('b_firstClassBasic')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- b_firstClassBasicPlace -->
            <app-input-base
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassBasicPlace' | translate"
              class="input-group-container"
              [formControlNameInput]="'b_firstClassBasicPlace'"
              [placeHolderInput]="'student.createStudent.studentCourseAirplane.b_firstClassBasicPlace' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- b_firstClassBasicResponsible -->
            <app-input-base
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassBasicResponsible' | translate"
              class="input-group-container"
              [formControlNameInput]="'b_firstClassBasicResponsible'"
              [placeHolderInput]="'student.createStudent.studentCourseAirplane.b_firstClassBasicResponsible' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- b_firstClassBasicDeliveryDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassBasicDeliveryDate' | translate"
              [formControlNameInput]="'b_firstClassBasicDeliveryDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['b_firstClassBasicDeliveryDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form"
              (changeValueDate)="changeDate($event)">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- b_firstClassBasicSendingDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassBasicSendingDate' | translate"
              [formControlNameInput]="'b_firstClassBasicSendingDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['b_firstClassBasicSendingDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col md="6">
            <!-- b_firstClassBasicExpirationDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassBasicExpirationDate' | translate"
              [formControlNameInput]="'b_firstClassBasicExpirationDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['b_firstClassBasicExpirationDate'].value"
              [disabled]="true"
              [form]="form"
              >
            </app-input-date-picker>
          </c-col>
        </c-row>

        <!-- b_firstClassDaytimeOnly -->
        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.studentCourseAirplane.b_firstClassDaytimeOnly' | translate"
          [formControlNameInput]="'b_firstClassDaytimeOnly'"
          [submitted]="submitted"
          [disabled]="checkTypeOrderCourse('second', form)"
          (changeValue)="changeCourseTypes('b_firstClassDaytimeOnly', form)"
          [formGroup]="formGroup"
          [form]="form">
        </app-input-checkbox>

        <c-row [@slideInOut]="formGroup.get('b_firstClassDaytimeOnly')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- b_firstClassDaytimeOnlyDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassDaytimeOnlyDate' | translate"
              [formControlNameInput]="'b_firstClassDaytimeOnlyDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['b_firstClassDaytimeOnlyDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- b_firstClassDaytimeOnlyNo -->
            <app-input-base
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassDaytimeOnlyNo' | translate"
              class="input-group-container"
              [formControlNameInput]="'b_firstClassDaytimeOnlyNo'"
              [placeHolderInput]="'student.createStudent.studentCourseAirplane.b_firstClassDaytimeOnlyNo' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('second', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
        </c-row>
        <c-row [@slideInOut]="formGroup.get('b_firstClassDaytimeOnly')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- b_firstClassDaytimeOnlyPlace -->
            <app-input-base
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassDaytimeOnlyPlace' | translate"
              class="input-group-container"
              [formControlNameInput]="'b_firstClassDaytimeOnlyPlace'"
              [placeHolderInput]="'student.createStudent.studentCourseAirplane.b_firstClassDaytimeOnlyPlace' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('second', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- b_firstClassDaytimeOnlyResponsible -->
            <app-input-base
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassDaytimeOnlyResponsible' | translate"
              class="input-group-container"
              [formControlNameInput]="'b_firstClassDaytimeOnlyResponsible'"
              [placeHolderInput]="'student.createStudent.studentCourseAirplane.b_firstClassDaytimeOnlyResponsible' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('second', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- b_firstClassDaytimeOnlyDeliveryDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassDaytimeOnlyDeliveryDate' | translate"
              [formControlNameInput]="'b_firstClassDaytimeOnlyDeliveryDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['b_firstClassDaytimeOnlyDeliveryDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              (changeValueDate)="changeDate($event)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- b_firstClassDaytimeOnlySendingDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassDaytimeOnlySendingDate' | translate"
              [formControlNameInput]="'b_firstClassDaytimeOnlySendingDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['b_firstClassDaytimeOnlySendingDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- b_firstClassDaytimeOnlyExpirationDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassDaytimeOnlyExpirationDate' | translate"
              [formControlNameInput]="'b_firstClassDaytimeOnlyExpirationDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['b_firstClassDaytimeOnlyExpirationDate'].value"
              [disabled]="true"
              [form]="form">
            </app-input-date-picker>
          </c-col>
        </c-row>

        <!-- b_firstClassVisualLineOfSight -->
        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.studentCourseAirplane.b_firstClassVisualLineOfSight' | translate"
          [formControlNameInput]="'b_firstClassVisualLineOfSight'"
          [submitted]="submitted"
          [disabled]="checkTypeOrderCourse('second', form)"
          (changeValue)="changeCourseTypes('b_firstClassVisualLineOfSight', form)"
          [formGroup]="formGroup"
          [form]="form">
        </app-input-checkbox>

        <c-row [@slideInOut]="formGroup.get('b_firstClassVisualLineOfSight')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- b_firstClassVisualLineOfSightDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassVisualLineOfSightDate' | translate"
              [formControlNameInput]="'b_firstClassVisualLineOfSightDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('second', form)"
              [initialDate]="form['b_firstClassVisualLineOfSightDate'].value"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- b_firstClassVisualLineOfSightNo -->
            <app-input-base
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassVisualLineOfSightNo' | translate"
              class="input-group-container"
              [formControlNameInput]="'b_firstClassVisualLineOfSightNo'"
              [placeHolderInput]="'student.createStudent.studentCourseAirplane.b_firstClassVisualLineOfSightNo' | translate"
              [inputType]="'text'"
              [disabled]="checkTypeOrderCourse('second', form)"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
        </c-row>
        <c-row [@slideInOut]="formGroup.get('b_firstClassVisualLineOfSight')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- b_firstClassVisualLineOfSightPlace -->
            <app-input-base
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassVisualLineOfSightPlace' | translate"
              class="input-group-container"
              [formControlNameInput]="'b_firstClassVisualLineOfSightPlace'"
              [placeHolderInput]="'student.createStudent.studentCourseAirplane.b_firstClassVisualLineOfSightPlace' | translate"
              [inputType]="'text'"
              [disabled]="checkTypeOrderCourse('second', form)"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- b_firstClassVisualLineOfSightResponsible -->
            <app-input-base
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassVisualLineOfSightResponsible' | translate"
              class="input-group-container"
              [formControlNameInput]="'b_firstClassVisualLineOfSightResponsible'"
              [placeHolderInput]="'student.createStudent.studentCourseAirplane.b_firstClassVisualLineOfSightResponsible' | translate"
              [inputType]="'text'"
              [disabled]="checkTypeOrderCourse('second', form)"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- b_firstClassVisualLineOfSightDeliveryDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassVisualLineOfSightDeliveryDate' | translate"
              [formControlNameInput]="'b_firstClassVisualLineOfSightDeliveryDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['b_firstClassVisualLineOfSightDeliveryDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              (changeValueDate)="changeDate($event)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- b_firstClassVisualLineOfSightSendingDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassVisualLineOfSightSendingDate' | translate"
              [formControlNameInput]="'b_firstClassVisualLineOfSightSendingDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['b_firstClassVisualLineOfSightSendingDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- b_firstClassVisualLineOfSightExpirationDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassVisualLineOfSightExpirationDate' | translate"
              [formControlNameInput]="'b_firstClassVisualLineOfSightExpirationDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['b_firstClassVisualLineOfSightExpirationDate'].value"
              [disabled]="true"
              [form]="form">
            </app-input-date-picker>
          </c-col>
        </c-row>

        <!-- b_firstClassWeightLimited -->
        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.studentCourseAirplane.b_firstClassWeightLimited' | translate"
          [formControlNameInput]="'b_firstClassWeightLimited'"
          [submitted]="submitted"
          [disabled]="checkTypeOrderCourse('second', form)"
          (changeValue)="changeCourseTypes('b_firstClassWeightLimited', form)"
          [formGroup]="formGroup"
          [form]="form">
        </app-input-checkbox>

        <c-row [@slideInOut]="formGroup.get('b_firstClassWeightLimited')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- b_firstClassWeightLimitedDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassWeightLimitedDate' | translate"
              [formControlNameInput]="'b_firstClassWeightLimitedDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('second', form)"
              [initialDate]="form['b_firstClassWeightLimitedDate'].value"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- b_firstClassWeightLimitedNo -->
            <app-input-base
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassWeightLimitedNo' | translate"
              class="input-group-container"
              [formControlNameInput]="'b_firstClassWeightLimitedNo'"
              [placeHolderInput]="'student.createStudent.studentCourseAirplane.b_firstClassWeightLimitedNo' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('second', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
        </c-row>
        <c-row [@slideInOut]="formGroup.get('b_firstClassWeightLimited')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- b_firstClassWeightLimitedPlace -->
            <app-input-base
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassWeightLimitedPlace' | translate"
              class="input-group-container"
              [formControlNameInput]="'b_firstClassWeightLimitedPlace'"
              [placeHolderInput]="'student.createStudent.studentCourseAirplane.b_firstClassWeightLimitedPlace' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('second', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- b_firstClassWeightLimitedResponsible -->
            <app-input-base
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassWeightLimitedResponsible' | translate"
              class="input-group-container"
              [formControlNameInput]="'b_firstClassWeightLimitedResponsible'"
              [placeHolderInput]="'student.createStudent.studentCourseAirplane.b_firstClassWeightLimitedResponsible' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('second', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- b_firstClassWeightLimitedDeliveryDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassWeightLimitedDeliveryDate' | translate"
              [formControlNameInput]="'b_firstClassWeightLimitedDeliveryDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['b_firstClassWeightLimitedDeliveryDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              (changeValueDate)="changeDate($event)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- b_firstClassWeightLimitedSendingDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassWeightLimitedSendingDate' | translate"
              [formControlNameInput]="'b_firstClassWeightLimitedSendingDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['b_firstClassWeightLimitedSendingDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- b_firstClassWeightLimitedExpirationDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_firstClassWeightLimitedExpirationDate' | translate"
              [formControlNameInput]="'b_firstClassWeightLimitedExpirationDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['b_firstClassWeightLimitedExpirationDate'].value"
              [disabled]="true"
              [form]="form">
            </app-input-date-picker>
          </c-col>
        </c-row>

        <!-- b_secondClassBasic -->
        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.studentCourseAirplane.b_secondClassBasic' | translate"
          [formControlNameInput]="'b_secondClassBasic'"
          [submitted]="submitted"
          [formGroup]="formGroup"
          [disabled]="checkTypeOrderCourse('first', form)"
          (changeValue)="changeCourseTypes('b_secondClassBasic', form)"
          [form]="form">
        </app-input-checkbox>

        <c-row [@slideInOut]="formGroup.get('b_secondClassBasic')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- b_secondClassBasicDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassBasicDate' | translate"
              [formControlNameInput]="'b_secondClassBasicDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('first', form)"
              [initialDate]="form['b_secondClassBasicDate'].value"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- b_secondClassBasicNo -->
            <app-input-base
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassBasicNo' | translate"
              class="input-group-container"
              [formControlNameInput]="'b_secondClassBasicNo'"
              [placeHolderInput]="'student.createStudent.studentCourseAirplane.b_secondClassBasicNo' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [disabled]="checkTypeOrderCourse('first', form)"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
        </c-row>
        <c-row [@slideInOut]="formGroup.get('b_secondClassBasic')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- b_secondClassBasicPlace -->
            <app-input-base
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassBasicPlace' | translate"
              class="input-group-container"
              [formControlNameInput]="'b_secondClassBasicPlace'"
              [placeHolderInput]="'student.createStudent.studentCourseAirplane.b_secondClassBasicPlace' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [disabled]="checkTypeOrderCourse('first', form)"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- b_secondClassBasicResponsible -->
            <app-input-base
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassBasicResponsible' | translate"
              class="input-group-container"
              [formControlNameInput]="'b_secondClassBasicResponsible'"
              [placeHolderInput]="'student.createStudent.studentCourseAirplane.b_secondClassBasicResponsible' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [disabled]="checkTypeOrderCourse('first', form)"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- b_secondClassBasicDeliveryDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassBasicDeliveryDate' | translate"
              [formControlNameInput]="'b_secondClassBasicDeliveryDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['b_secondClassBasicDeliveryDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              (changeValueDate)="changeDate($event)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- b_secondClassBasicSendingDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassBasicSendingDate' | translate"
              [formControlNameInput]="'b_secondClassBasicSendingDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['b_secondClassBasicSendingDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- b_secondClassBasicExpirationDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassBasicExpirationDate' | translate"
              [formControlNameInput]="'b_secondClassBasicExpirationDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [disabled]="true"
              [initialDate]="form['b_secondClassBasicExpirationDate'].value"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
        </c-row>

        <!-- b_secondClassDaytimeOnly -->
        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.studentCourseAirplane.b_secondClassDaytimeOnly' | translate"
          [formControlNameInput]="'b_secondClassDaytimeOnly'"
          [submitted]="submitted"
          [formGroup]="formGroup"
          [disabled]="checkTypeOrderCourse('first', form)"
          (changeValue)="changeCourseTypes('b_secondClassDaytimeOnly', form)"
          [form]="form">
        </app-input-checkbox>

        <c-row [@slideInOut]="formGroup.get('b_secondClassDaytimeOnly')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- b_secondClassDaytimeOnlyDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassDaytimeOnlyDate' | translate"
              [formControlNameInput]="'b_secondClassDaytimeOnlyDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['b_secondClassDaytimeOnlyDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- b_secondClassDaytimeOnlyNo -->
            <app-input-base
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassDaytimeOnlyNo' | translate"
              class="input-group-container"
              [formControlNameInput]="'b_secondClassDaytimeOnlyNo'"
              [placeHolderInput]="'student.createStudent.studentCourseAirplane.b_secondClassDaytimeOnlyNo' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('first', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
        </c-row>
        <c-row [@slideInOut]="formGroup.get('b_secondClassDaytimeOnly')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- b_secondClassDaytimeOnlyPlace -->
            <app-input-base
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassDaytimeOnlyPlace' | translate"
              class="input-group-container"
              [formControlNameInput]="'b_secondClassDaytimeOnlyPlace'"
              [placeHolderInput]="'student.createStudent.studentCourseAirplane.b_secondClassDaytimeOnlyPlace' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('first', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- b_secondClassDaytimeOnlyResponsible -->
            <app-input-base
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassDaytimeOnlyResponsible' | translate"
              class="input-group-container"
              [formControlNameInput]="'b_secondClassDaytimeOnlyResponsible'"
              [placeHolderInput]="'student.createStudent.studentCourseAirplane.b_secondClassDaytimeOnlyResponsible' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('first', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- b_secondClassDaytimeOnlyDeliveryDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassDaytimeOnlyDeliveryDate' | translate"
              [formControlNameInput]="'b_secondClassDaytimeOnlyDeliveryDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['b_secondClassDaytimeOnlyDeliveryDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              (changeValueDate)="changeDate($event)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- b_secondClassDaytimeOnlySendingDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassDaytimeOnlySendingDate' | translate"
              [formControlNameInput]="'b_secondClassDaytimeOnlySendingDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['b_secondClassDaytimeOnlySendingDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- b_secondClassDaytimeOnlyExpirationDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassDaytimeOnlyExpirationDate' | translate"
              [formControlNameInput]="'b_secondClassDaytimeOnlyExpirationDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [disabled]="true"
              [initialDate]="form['b_secondClassDaytimeOnlyExpirationDate'].value"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
        </c-row>

        <!-- b_secondClassVisualLineOfSight -->
        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.studentCourseAirplane.b_secondClassVisualLineOfSight' | translate"
          [formControlNameInput]="'b_secondClassVisualLineOfSight'"
          [disabled]="checkTypeOrderCourse('first', form)"
          (changeValue)="changeCourseTypes('b_secondClassVisualLineOfSight', form)"
          [submitted]="submitted"
          [formGroup]="formGroup"
          [form]="form">
        </app-input-checkbox>

        <c-row [@slideInOut]="formGroup.get('b_secondClassVisualLineOfSight')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- b_secondClassVisualLineOfSightDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassVisualLineOfSightDate' | translate"
              [formControlNameInput]="'b_secondClassVisualLineOfSightDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('first', form)"
              [initialDate]="form['b_secondClassVisualLineOfSightDate'].value"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- b_secondClassVisualLineOfSightNo -->
            <app-input-base
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassVisualLineOfSightNo' | translate"
              class="input-group-container"
              [formControlNameInput]="'b_secondClassVisualLineOfSightNo'"
              [placeHolderInput]="'student.createStudent.studentCourseAirplane.b_secondClassVisualLineOfSightNo' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [disabled]="checkTypeOrderCourse('first', form)"
              [form]="form">
            </app-input-base>
          </c-col>
        </c-row>
        <c-row [@slideInOut]="formGroup.get('b_secondClassVisualLineOfSight')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- b_secondClassVisualLineOfSightPlace -->
            <app-input-base
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassVisualLineOfSightPlace' | translate"
              class="input-group-container"
              [formControlNameInput]="'b_secondClassVisualLineOfSightPlace'"
              [placeHolderInput]="'student.createStudent.studentCourseAirplane.b_secondClassVisualLineOfSightPlace' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [disabled]="checkTypeOrderCourse('first', form)"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- b_secondClassVisualLineOfSightResponsible -->
            <app-input-base
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassVisualLineOfSightResponsible' | translate"
              class="input-group-container"
              [formControlNameInput]="'b_secondClassVisualLineOfSightResponsible'"
              [placeHolderInput]="'student.createStudent.studentCourseAirplane.b_secondClassVisualLineOfSightResponsible' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [disabled]="checkTypeOrderCourse('first', form)"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- b_secondClassVisualLineOfSightDeliveryDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassVisualLineOfSightDeliveryDate' | translate"
              [formControlNameInput]="'b_secondClassVisualLineOfSightDeliveryDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['b_secondClassVisualLineOfSightDeliveryDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              (changeValueDate)="changeDate($event)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- b_secondClassVisualLineOfSightSendingDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassVisualLineOfSightSendingDate' | translate"
              [formControlNameInput]="'b_secondClassVisualLineOfSightSendingDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['b_secondClassVisualLineOfSightSendingDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- b_secondClassVisualLineOfSightExpirationDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassVisualLineOfSightExpirationDate' | translate"
              [formControlNameInput]="'b_secondClassVisualLineOfSightExpirationDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [disabled]="true"
              [initialDate]="form['b_secondClassVisualLineOfSightExpirationDate'].value"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
        </c-row>

        <!-- b_secondClassWeightLimited -->
        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.studentCourseAirplane.b_secondClassWeightLimited' | translate"
          [formControlNameInput]="'b_secondClassWeightLimited'"
          [submitted]="submitted"
          [formGroup]="formGroup"
          [disabled]="checkTypeOrderCourse('first', form)"
          (changeValue)="changeCourseTypes('b_secondClassWeightLimited', form)"
          [form]="form">
        </app-input-checkbox>

        <c-row [@slideInOut]="formGroup.get('b_secondClassWeightLimited')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- b_secondClassWeightLimitedDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassWeightLimitedDate' | translate"
              [formControlNameInput]="'b_secondClassWeightLimitedDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['b_secondClassWeightLimitedDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"

              [form]="form">
            </app-input-date-picker>
          </c-col>

          <c-col [md]="6">
            <!-- b_secondClassWeightLimitedNo -->
            <app-input-base
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassWeightLimitedNo' | translate"
              class="input-group-container"
              [formControlNameInput]="'b_secondClassWeightLimitedNo'"
              [placeHolderInput]="'student.createStudent.studentCourseAirplane.b_secondClassWeightLimitedNo' | translate"
              [inputType]="'text'"
              [disabled]="checkTypeOrderCourse('first', form)"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
        </c-row>
        <c-row [@slideInOut]="formGroup.get('b_secondClassWeightLimited')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- b_secondClassWeightLimitedPlace -->
            <app-input-base
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassWeightLimitedPlace' | translate"
              class="input-group-container"
              [formControlNameInput]="'b_secondClassWeightLimitedPlace'"
              [placeHolderInput]="'student.createStudent.studentCourseAirplane.b_secondClassWeightLimitedPlace' | translate"
              [inputType]="'text'"
              [disabled]="checkTypeOrderCourse('first', form)"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- b_secondClassWeightLimitedResponsible -->
            <app-input-base
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassWeightLimitedResponsible' | translate"
              class="input-group-container"
              [formControlNameInput]="'b_secondClassWeightLimitedResponsible'"
              [placeHolderInput]="'student.createStudent.studentCourseAirplane.b_secondClassWeightLimitedResponsible' | translate"
              [inputType]="'text'"
              [disabled]="checkTypeOrderCourse('first', form)"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- b_secondClassWeightLimitedDeliveryDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassWeightLimitedDeliveryDate' | translate"
              [formControlNameInput]="'b_secondClassWeightLimitedDeliveryDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['b_secondClassWeightLimitedDeliveryDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              (changeValueDate)="changeDate($event)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- b_secondClassWeightLimitedSendingDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassWeightLimitedSendingDate' | translate"
              [formControlNameInput]="'b_secondClassWeightLimitedSendingDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['b_secondClassWeightLimitedSendingDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- b_secondClassWeightLimitedExpirationDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseAirplane.b_secondClassWeightLimitedExpirationDate' | translate"
              [formControlNameInput]="'b_secondClassWeightLimitedExpirationDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [disabled]="true"
              [initialDate]="form['b_secondClassWeightLimitedExpirationDate'].value"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
        </c-row>

      </div>



    </div>
  </div>
</div>
