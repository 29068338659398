<c-card class="mb-3 card-border">
  <c-card-body>
    <div>
      <div class="student_item">
        <div class="student_item--header">
          <div class="item_name_color">
           <span class="btn-list-attendances d-flex align-items-center">
            {{ itemInput.recipient_name + ' ' + itemInput.honorific_title }}
          </span>
          </div>
          <div class="student_item--action">
            <a class="btn-edit-student"
            [routerLink]="['/quotation/'+ itemInput.id + '/edit']"></a>
            <app-modal-delete-common
              [deleteId]="itemInput?.id"
              [titleDelete]="'student.attendances.delete.title' | translate"
              (handleDeleteCommon)="handleDeleteQuotationStudent($event)">
            >
            </app-modal-delete-common>
          </div>
        </div>
        <div class="student_info">
          <div class="date_time">
            <span>{{ 'quotation.listQuotation.courseDate' | translate }}: </span>
            <span class="d-inline-block" *ngIf="itemInput.createdAt">{{ formatDate(itemInput.createdAt.toDate(), formatDatePicker) }} </span>
          </div>
        </div>
      </div>
    </div>
  </c-card-body>
</c-card>
