import {
  courseTypeAttributes,
  courseTypes,
  KEYCODE_ENTER,
  RuleValidations,
  studentCourses,
} from '@app/constants';
import * as moment from 'moment';

export function getFirstError(errors: any) {
  if (errors != null) {
    const [firstError] = Object.keys(errors);
    return firstError;
  }
  return null;
}

export function formatUnitNumber(num: number) {
  return num < 10 ? '0' + num.toString() : num.toString();
}

export function formatDateString(date: Date, month: string | number) {
  return `${date.getFullYear()}\/${month}\/${date.getDate()}`;
}

export function getLocaleDatePicker(currentLanguage: string): string {
  if (currentLanguage === 'en') return 'en-US';
  else return 'ja-JP';
}

export function getTotalPropertyValue(key: string, list: any) {
  return list.reduce((a: any, b: any) => Number(a) + (Number(b[key].replace(RuleValidations.getNumberInString, '') || 0)), 0);
}

export function convertMbToB(size: number) {
  return 1024 * 1024 * size;
}

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

export function checkInputEnter(event: any) {
  if (event.keyCode == KEYCODE_ENTER) event.preventDefault();
}

export function numberOnly(event: any) {
  const charCode = event.which ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    event.preventDefault();
  }
}

export function getTime(dateTime: any): string {
  return !dateTime ? '' : moment(dateTime).format('HH:mm');
}

export function formatDate(date: Date, formatDate: string): string {
  return !date ? '' : moment(date).format(formatDate);
}

export function checkboxSecondClassChecked(form: any, key: string): boolean {
  let flat: boolean = false;
  courseTypes.forEach((type) => {
    if (form[`${key}${type}`].value) flat = true;
  });
  return flat;
}

export function checkTypeOrderCourse(order: string, form: any): boolean {
  let isCheck: boolean = false;
  studentCourses.forEach((studentCourse) => {
    courseTypes.forEach((courseType) => {
      let key: string = `${studentCourse.firstKey}_${order}${courseType}`;
      if (!!form[key].value) isCheck = true;
    });
  });
  return isCheck;
}

export function changeCourseTypes(key: string, form: any): void {
  if (!form[`${key}`].value) {
    courseTypeAttributes.forEach((courseTypeAttribute) => {
      form[`${key}${courseTypeAttribute}`].setValue('');
    });
  }
}

// export const navigateParams = (formSearch: any, queryString: string) => {
//   let params = this.formSearch.getRawValue();
//   params.startDate = formatDate(params.startDate, formatDatePicker);
//   params.endDate = formatDate(params.endDate, formatDatePicker);
//   params.visibleCollapse = this.visibleCollapse;
//   params.page = this.currentPage;
//   localStorage.setItem('queryString.completion', JSON.stringify(params));
// }
