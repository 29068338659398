<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <div class="custom-container">
    <div class="custom__header">
      <app-breadcrumb [items]="breadcrumbItems"></app-breadcrumb>
      <c-col class="justify-content-center mb-5">
        <div class="box-title">
          <h3>{{ "student.attendances.list.title" | translate }}</h3>
          <div class="d-flex justify-content-between btn-row">
            <div class="d-flex gap-10px">
              <span (click)="exportPDFAttendances()" class="link btn-common btn-title btn-title-export">
                <span>{{ "common.export_btn" | translate }}</span>
              </span>
              <span
                *ngIf="currentUser?.uid === student?.userId"
                (click)="redirectCreate()"
                class="form-create-data btn-common btn-title btn-title-create"
              >
                <span>{{ "common.create_btn" | translate }}</span>
              </span>
            </div>
          </div>
        </div>
      </c-col>
      <c-col class="mb-5">
        <c-card class="card-no-border">
          <c-card-body>
            <form cForm [formGroup]="formSearch" (ngSubmit)="search()">
              <c-col class="mb-3 d-flex justify-content-between">
                <span>{{ "form.index.searchTitle" | translate }}</span>
                <span class="button-collapse" (click)="toggleCollapse()">
                  <img
                    class="caret-down"
                    [class]="{ 'caret-down-open': visibleCollapse }"
                    height="20"
                    width="20"
                    src="assets/svg/caret-down.svg"
                    alt="caret-down"
                  />
                </span>
              </c-col>
              <div [visible]="visibleCollapse" cCollapse>
                <c-col class="mb-2 d-flex align-items-center col-border">
                  <c-input-group class="d-flex align-items-center">
                    <label for="querySearch">
                      <img
                        src="assets/svg/flight-record/icon-search.svg"
                        alt="icon"
                      />
                    </label>
                    <input
                      cFormControl
                      formControlName="querySearch"
                      placeholder=""
                      name="querySearch"
                      id="querySearch"
                    />
                  </c-input-group>
                </c-col>
                <c-col class="gap-2 search__date">
                  <c-col class="mb-6">
                    <c-col class="mb-3">{{ "form.index.start_time" | translate }}</c-col>
                    <c-col class="select-date">
                      <c-date-picker
                        [locale]="currentDatepickerLocale"
                        [placeholder]="formatDatePicker"
                        class="box-select-date"
                        formControlName="startDate"
                        [dayFormat]="dayFormat"
                      ></c-date-picker>
                    </c-col>
                  </c-col>
                  <c-col class="mb-6">
                    <c-col class="mb-3">&nbsp;</c-col>
                    <c-col class="select-date">
                      <c-date-picker
                        [locale]="currentDatepickerLocale"
                        [placeholder]="formatDatePicker"
                        formControlName="endDate"
                        [dayFormat]="dayFormat"
                        class="date-time-date box-select-date"
                      ></c-date-picker>
                    </c-col>
                  </c-col>
                </c-col>
                <div class="search_color">
                  <div class="text_title">
                    {{ "student.attendances.list.search" | translate }}
                  </div>
                  <div class="search_color--two">
                    <div *ngFor="let status of status" class="color_item_status">
                      <input
                        formControlName="status"
                        [value]="status.value"
                        class="check-box-color d-none"
                        id="color2{{ status.value }}"
                        name="status"
                        type="radio"/>
                      <label
                        cFormCheckLabel
                        for="color2{{ status.value }}"
                        class="color-icon-status"
                        [style.--border-color]="'1px solid #667085'"
                        [style.border]="'1px solid #667085'">
                      </label>
                      <label for="color2{{ status.value }}" class="cursor-pointer">{{ status.name }}</label>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-end mt-3">
                  <button
                    class="btn-common button-clear btn-search"
                    type="submit"
                  >
                    {{ "form.index.btn_search" | translate }}
                  </button>
                </div>
              </div>
            </form>
          </c-card-body>
        </c-card>
      </c-col>
    </div>

    <div class="custom__body">
      <div class="number_filter d-flex flex-column flex-sm-row">
        <div class="me-5">{{ "student.attendances.list.number_filter" | translate : { number: numberAttendanceFilter } }}</div>
        <div>
          <span [style]="{
            'color': totalClassTime > timeSetting ? 'red' : '#344054'
          }">{{ "student.attendances.list.total_course_time" | translate : { time: totalClassTimeFormatted, total: timeSettingFormatted } }}</span>
        </div>
      </div>
      <app-student-attendance-item
        *ngFor="let item of studentAttendances | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }; trackBy: trackByAttendance"
        [itemInput]="item"
        [studentId]="id"
        [student]="student"
        [currentUser]="currentUser"
      >
      </app-student-attendance-item>
    </div>
  </div>
  <!-- Pagination -->
  <app-pagination
    *ngIf="studentAttendances && studentAttendances.length != 0"
    (handleChangePage)="changePage($event)">
  </app-pagination>
  <app-loading [isLoading]="isLoading"></app-loading>
</c-container>
