<!-- main app container -->
<div class="app-container">
  <div class="body">
    <app-sidebar *ngIf="signInStatus"></app-sidebar>
    <div class="wrapper" id="signInStatusId" [class.signInStatus]="signInStatus && leftMenu">
      <alert></alert>
      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </div>
  </div>
</div>
