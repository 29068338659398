import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(
    private db: AngularFirestore,
    private storage: AngularFireStorage
  ) {}

  async uploadFile(uid: string, fileUpload: File) {
    return new Promise<any>((resolve) => {
      const filePath = `${uid}${fileUpload.name}`;
      const storage = this.storage.upload(filePath, fileUpload);
      storage.snapshotChanges().subscribe((snapshot: any) => {
        if (snapshot.state === 'success') {
          snapshot.ref.getDownloadURL().then((data: any) => resolve(data));
        }
      });
    });
  }
}
