import { Component, Input, OnInit } from '@angular/core';
import {
  changeCourseTypes,
  checkTypeOrderCourse,
  SlideInOutAnimation,
} from '@app/helpers';
import { courseTypeOrders, courseTypes } from '@app/constants';
import * as moment from 'moment';

@Component({
  selector: 'app-student-course-helicopter',
  templateUrl: './student-course-helicopter.component.html',
  styleUrls: ['./student-course-helicopter.component.scss'],
  animations: [SlideInOutAnimation],
})
export class StudentCourseHelicopterComponent implements OnInit {
  @Input('formGroup') formGroup: any;
  @Input('form') form: any = [];
  @Input('submitted') submitted = false;
  @Input('isEdit') isEdit = false;
  protected readonly checkTypeOrderCourse = checkTypeOrderCourse;
  protected readonly changeCourseTypes = changeCourseTypes;

  constructor() {}

  ngOnInit(): void {}

  public changeCourse(): void {
    if (!this.form['c_rotorcraft'].value) {
      courseTypeOrders.forEach((courseTypeOrder) => {
        courseTypes.forEach((courseType) => {
          let key: string = `c_${courseTypeOrder}${courseType}`;
          this.form[key].setValue(false);
          changeCourseTypes(key, this.form);
        });
      });
    }
  }

  public changeDate(event: any): void {
    if (event.data) {
        const deliveryDate = moment(event.data, 'YYYY/MM/DD');
        const expirationDate = deliveryDate.add(1, 'year').subtract(1, 'day');
        const controlMappings: any = {
            'c_firstClassBasicDeliveryDate': 'c_firstClassBasicExpirationDate',
            'c_firstClassDaytimeOnlyDeliveryDate': 'c_firstClassDaytimeOnlyExpirationDate',
            'c_firstClassVisualLineOfSightDeliveryDate': 'c_firstClassVisualLineOfSightExpirationDate',
            'c_firstClassWeightLimitedDeliveryDate': 'c_firstClassWeightLimitedExpirationDate',
            'c_secondClassBasicDeliveryDate': 'c_secondClassBasicExpirationDate',
            'c_secondClassDaytimeOnlyDeliveryDate': 'c_secondClassDaytimeOnlyExpirationDate',
            'c_secondClassVisualLineOfSightDeliveryDate': 'c_secondClassVisualLineOfSightExpirationDate',
            'c_secondClassWeightLimitedDeliveryDate': 'c_secondClassWeightLimitedExpirationDate',
        };
        const expirationControlName = controlMappings[event.value];
        if (expirationControlName) {
            this.form[expirationControlName].setValue(expirationDate.toDate());
        }
    }
  }

}
