<c-card class="mb-3 card-border">
  <c-card-body>
    <div class="student_item">
      <div class="student_item--header">
        <div class="item_name_color">
         <div class=" d-flex align-items-center"><div class="color-icon d-flex align-items-center" [style.background-color]="color" [style.border]="border"></div></div>
          <span class="btn-list-attendances d-flex align-items-center">{{ itemInput.studentInfo.name }}</span>
          <a class=" form-create-data btn-common btn-title" *ngIf="!studentApproval && itemInput.elearning && dataCurrentUser.connectElearning" href="{{urlElearning}}{{itemInput.studentInfo.certificateNumber}}" target="_blank"> 学科試験対策講座</a>

        </div>
        <div *ngIf="!studentApproval" class="student_item--action">
          <a *ngIf="itemInput.userId === currentUser.uid" class="btn-edit-student"
             [routerLink]="['/student/'+ itemInput.id + '/edit']"></a>
        </div>
      </div>
      <div class="listStudent">
        <div class="student_info col">
          <div class="date_time">
            <span>{{ 'student.listStudent.courseDate' | translate }}: </span>
            <span class="d-inline-block" *ngIf="itemInput.studentEnrollment.enrollmentDate">{{ formatDate(itemInput.studentEnrollment.enrollmentDate.toDate(), formatDatePicker) }} </span>
          </div>
          <div class="note" *ngIf="itemInput.studentInfo?.remark">
            <span>{{ 'student.listStudent.remark' | translate }}: </span>
            <span *ngIf="itemInput.studentInfo?.remark.length >= 100" [cPopoverTrigger]='"hover"'
                  cPopoverPlacement="top" [cPopover]="itemInput.studentInfo?.remark">
              {{ itemInput.studentInfo?.remark.substring(0, 100) + '...' }}
            </span>
            <span *ngIf="itemInput.studentInfo?.remark.length < 100">
              {{ itemInput.studentInfo?.remark }}
            </span>
          </div>
          <div *ngIf="itemInput.studentInfo.courseItems && courseItem" class="course-items d-flex align-items-center">
            <div
              class="color-icon d-flex align-items-center"
              [style.background-color]="courseItem.color"
              [style.border]="'1px solid #667085'"
            ></div>
            <span>{{ courseItem.itemName }}</span>
          </div>
        </div>
        <div *ngIf="studentCourseProcessed.length" class="row courseGraduated col">
          <div class="col-lg-4">
            <div class="item">修了年月日 {{ dataStudentCourse?.courseGraduated?.Date ? formatDate(dataStudentCourse?.courseGraduated?.Date.toDate(), formatDatePicker) : '' }}</div>
            <div class="item">修了審査実施会場 {{dataStudentCourse?.courseGraduated?.Place}}</div>
            <div class="item">交付日 {{ dataStudentCourse?.courseGraduated?.DeliveryDate ? formatDate(dataStudentCourse?.courseGraduated?.DeliveryDate.toDate(), formatDatePicker) : '' }}</div>
            <div>有効期限 {{ dataStudentCourse?.courseGraduated?.ExpirationDate ? formatDate(dataStudentCourse?.courseGraduated?.ExpirationDate.toDate(), formatDatePicker) : '' }}</div>
          </div>
          <div class="col-lg-4">
            <div class="item">講習修了書番号 {{dataStudentCourse?.courseGraduated?.No}}</div>
            <div>担当修了審査員 {{dataStudentCourse?.courseGraduated?.Responsible}}</div>
          </div>
          <div class="col-lg-4">
            <div class="item">送付日または通知日 {{ dataStudentCourse?.courseGraduated?.SendingDate ? formatDate(dataStudentCourse?.courseGraduated?.SendingDate.toDate(), formatDatePicker) : '' }}</div>
            <div class="item">
              {{ 'student.createStudent.confirmation.useElearning' | translate }} {{ (itemInput.studentClassification.useElearning) ? YES : NO }}
            </div>
            <div *ngIf="itemInput.studentClassification.useElearning" class="item">
              {{ 'student.createStudent.confirmation.eLearning' | translate }} {{ itemInput.studentClassification.statusElearning }}
            </div>
          </div>
        </div>
        <div *ngIf="studentCourseProcessed.length > 1">
          <p style="padding-top: 48px;">(他)</p>
        </div>
      </div>
      <div class="student_more" *ngIf="this.studentCourseProcessed.length > 0">
        <div class="btn-more-course" [class]="{'open': visible}" (click)="toggleCollapse()">
          <img *ngIf="!visible" class="add-square" height="20" width="20" src="assets/svg/add-square-color.svg"
               alt="add-square">
          <img *ngIf="visible" class="minus-square" height="20" width="20" src="assets/svg/minus.svg"
               alt="minus-square">
        </div>
      </div>
    </div>
  </c-card-body>
</c-card>

<div class="student-sub-item-list" [visible]="visible" cCollapse>
  <app-student-sub-item *ngFor="let studentCourse of this.studentCourseProcessed"
    [itemInput]="itemInput"
    [studentApproval]="studentApproval"
    [currentUser]="currentUser"
    (updateStatusRequest)="handleUpdateStatusRequest($event)"
    [studentCourse]="studentCourse"
  ></app-student-sub-item>
</div>
