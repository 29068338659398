<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <div class="custom-container">
    <div class="custom__header">
      <c-col class="justify-content-center mb-4">
        <div class="box-title">
          <h3>{{ 'student.approval.title' | translate }}</h3>
          <div class="d-flex justify-content-between btn-row">
            <div class="d-flex gap-10px">
              <a class="link btn-common btn-title btn-title-export" (click)="exportCsvStudentApproval()">
                <span>{{ 'common.export_btn' | translate }}</span>
              </a>
            </div>
          </div>
        </div>
      </c-col>
      <c-col class="mb-5">
        <c-card class="card-no-border">
          <c-card-body>
            <form cForm [formGroup]="formSearch" (ngSubmit)="search()">
              <c-col class="mb-3 d-flex justify-content-between">
                <span>{{ 'form.index.searchApproval' | translate }}</span>
                <span class="button-collapse" (click)="toggleCollapse()">
                  <img class="caret-down" [class]="{'caret-down-open': visibleCollapse}" height="20" width="20" src="assets/svg/caret-down.svg" alt="caret-down">
                </span>
              </c-col>
              <div [visible]="visibleCollapse" cCollapse>
                <c-col class="gap-2 search__date">
                  <c-col class="mb-6">
                    <c-col class="select-date">
                      <c-date-picker [locale]="currentDatepickerLocale" [placeholder]="formatDatePicker" class="box-select-date" formControlName="startDate" [dayFormat]="dayFormat"></c-date-picker>
                    </c-col>
                  </c-col>
                  <c-col class="mb-6">
                    <c-col class="select-date">
                      <c-date-picker [locale]="currentDatepickerLocale" [placeholder]="formatDatePicker" formControlName="endDate" [dayFormat]="dayFormat" class="date-time-date box-select-date"></c-date-picker>
                    </c-col>
                  </c-col>
                </c-col>
                <div class="d-flex justify-content-end mt-3">
                  <button class="btn-common button-clear btn-search " type="submit">{{ 'form.index.btn_search' |translate }}</button>
                </div>
              </div>
            </form>
          </c-card-body>
        </c-card>
      </c-col>
    </div>
    <div class="custom__body">
      <div class="number_filter">{{ "student.listStudent.number_filter" | translate : { number: numberStudentFilter } }}</div>
      <app-student-item
        *ngFor="let item of students| paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }"
        [studentApproval]="listStudentApproval"
        [itemInput]="item"
        [dataCurrentUser]="dataCurrentUser"
        [currentUser]="currentUser"
        (deleteItem)="handleDeleteItem($event)"
      >
      </app-student-item>
    </div>
  </div>
  <!-- Pagination -->
  <app-pagination
    *ngIf="students && students.length != 0"
    (handleChangePage)="changePage($event)">
  </app-pagination>
  <app-loading [isLoading]="isLoading"></app-loading>
</c-container>
