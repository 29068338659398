import { Component, Input, OnInit } from '@angular/core';
import { formatDatePicker } from '@app/constants';
import { formatDate } from '@app/helpers';
import { CompletionRecordService } from '@app/services/completion-record.service';
import { AlertService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';
import { IStudent } from '@app/models/student';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-student-completion-record-item',
  templateUrl: './student-completion-record-item.component.html',
  styleUrls: ['./student-completion-record-item.component.scss']
})
export class StudentCompletionRecordItemComponent implements OnInit {
  @Input('itemInput') itemInput: any;
  @Input('studentId') studentId: any;
  @Input('student') student?: IStudent | undefined;
  @Input('currentUser') currentUser: any;
  protected readonly formatDate = formatDate;
  protected readonly formatDatePicker = formatDatePicker;
  protected course: string | null = '';

  constructor(
    private completionRecordService: CompletionRecordService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.course = this.activatedRoute.snapshot.paramMap.get('course');
  }

  public async handleDeleteCompletion(deleteStatus: boolean): Promise<void> {
    this.alertService.clear();
    if (deleteStatus) {
      await this.completionRecordService.deleteCompletionRecord(this.currentUser.uid, this.studentId, this.itemInput.id)
        .then(() => this.alertService.success(this.translateService.instant('student.completionRecord.delete.success')))
        .catch(() => this.alertService.error(this.translateService.instant('student.completionRecord.delete.error')));
    }
  }

  public redirectEdit(): void {
    let params = {};

    if (this.student?.userId && (this.student?.userId !== this.currentUser.uid)) {
      params = { ...{ user_id: this.student?.userId ?? '' } };
    }

    this.route.navigate(
      [`/student/${this.studentId}/completion-records/${this.course}/edit/${this.itemInput.id}`],
      {
        queryParams: params
      });
  }
}
