import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { DEFAULT_ITEMS_PER_PAGE, EXAM_PASS, courseClassTypeSendingDate, courseTypeSendingDate, endTimeOfDay, formatDatePicker, startTimeOfDay, studentCourseCategories } from '@app/constants';
import { formatDate, getLocaleDatePicker } from '@app/helpers';
import { IStudent } from '@app/models/student';
import { StudentService, UserService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-student-approval',
  templateUrl: './student-approval.component.html',
  styleUrls: ['./student-approval.component.scss']
})
export class StudentApprovalComponent implements OnInit {
  protected formSearch!: FormGroup;
  protected currentDatepickerLocale: string = '';
  protected currentLanguage = this.translate.currentLang;
  protected formatDatePicker: string = formatDatePicker;
  protected currentUser: any;
  protected dataCurrentUser: any;
  protected students: Array<IStudent> = [];
  protected numberStudentFilter: number = 0;
  protected isLoading: boolean = false;
  protected listStudentApproval: boolean = true;
  protected visibleCollapse: boolean = false;
  protected currentPage: number = 1;
  readonly itemsPerPage: number = DEFAULT_ITEMS_PER_PAGE;
  @Output() pageOwner = new EventEmitter<number>();

  constructor(
    private router: Router,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private studentService: StudentService,
    private auth: AngularFireAuth,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.formSearch = this.formBuilder.group({
      startDate: [new Date(new Date().getFullYear(), 0, 1)],
      endDate: [new Date(new Date().getFullYear(), 11, 31)],
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) this.navigateParams();
    })
    this.visibleCollapse = true;
    this.currentDatepickerLocale = getLocaleDatePicker(this.currentLanguage);
    this.getUser().then(() => this.handleQueryParams());
  }

  protected dayFormat = (date: Date) => date.getDate();

  protected async search() {
    this.currentPage = 1;
    this.studentService.getAllStudentByUser(this.currentUser.uid).subscribe((students) => {
      const dataStudent = students.filter((student: any) => {
        for (const courseCategory of studentCourseCategories) {
          for (const classSendingDateType of courseClassTypeSendingDate) {
            const studentTimeSendingDate = this.setDate(student[courseCategory][classSendingDateType]);
            const startTime = new Date(formatDate(this.formSearch.value.startDate, 'YYYY/MM/DD') + startTimeOfDay);
            const endTime = new Date(formatDate(this.formSearch.value.endDate, 'YYYY/MM/DD') + endTimeOfDay);
            if (studentTimeSendingDate >= startTime && studentTimeSendingDate <= endTime) {
              return student;
            }
          }
        }
      });

      const uniqueStudents = this.getUniqueStudents(dataStudent.flat());
      this.students = uniqueStudents;
      this.numberStudentFilter = uniqueStudents.length;
    })
  }

  protected toggleCollapse() {
    this.visibleCollapse = !this.visibleCollapse;
  }

  protected changePage = (page: number): void => {
    this.currentPage = page;
    this.pageOwner.emit(page);
  }

  private setDate(date: any): string | Date {
    return date ? new Date((date as Timestamp).toDate()) : '';
  }

  private getUniqueStudents(students: any[]): any[] {
    const uniqueStudentsMap = new Map<string, any>();

    students.forEach(student => uniqueStudentsMap.set(student.id, student));

    return Array.from(uniqueStudentsMap.values());
  }


  private async getUser(): Promise<void> {
    const currentUser = await this.auth.currentUser;
    const user = await this.userService.getUserByMail(currentUser?.email);
    if (user && user.length > 0) {
      const [dataUser] = user;
      this.dataCurrentUser = dataUser;
    }
    if (currentUser) this.currentUser = currentUser;
  }

  protected exportCsvStudentApproval(): void {
    this.isLoading = true;
    this.studentService.exportCsvListStudentApproval(this.currentUser.uid, this.formSearch.value)
      .finally(() => (this.isLoading = false));
  }

  protected get f() {
    return this.formSearch.controls;
  }

  private handleQueryParams(): void {
    let params: any = localStorage.getItem('queryString.studentsApproval');
    localStorage.removeItem('queryString.studentsApproval');
    if (params) {
      params = JSON.parse(params);
      if (!!params['startDate']) this.f['startDate'].setValue(new Date(params['startDate']));
      if (!!params['endDate']) this.f['endDate'].setValue(new Date(params['endDate']));

      this.visibleCollapse = params['visibleCollapse'];
      this.search().then(() => this.changePage(params['page']));
    } else this.search();
  }

  private navigateParams(): void {
    let params = this.formSearch.getRawValue();
    params.startDate = formatDate(params.startDate, formatDatePicker);
    params.endDate = formatDate(params.endDate, formatDatePicker);
    params.visibleCollapse = this.visibleCollapse;
    params.page = this.currentPage;
    localStorage.setItem('queryString.studentsApproval', JSON.stringify(params));
  }

  async handleDeleteItem(e: any) {
    this.students = this.students.filter((a: any) => a.id != e.id);
    this.numberStudentFilter = this.students.length;
    this.cdr.detectChanges();
  }
}
