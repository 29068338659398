import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './views/pages/login/login.component';
import { AccountComponent } from './views/pages/account/account.component';
import { AuthGuard } from './helpers';
import { ForgotPasswordComponent } from './views/pages/forgot-password/forgot-password.component';
import { UpdatePasswordComponent } from './views/pages/update-password/update-password.component';
import { TermsofuseComponent } from './views/pages/termsofuse/termsofuse.component';
import { PrivacyComponent } from './views/pages/privacy/privacy.component';
import { NotificationComponent } from './views/pages/notification/notification.component';
import { StudentComponent } from '@app/views/pages/student/student.component';
import { CreateStudentComponent } from './views/pages/student/create-student/create-student.component';
import { EditStudentComponent } from '@app/views/pages/student/edit-student/edit-student.component';
import { CreateCompletionRecordComponent } from '@app/views/pages/student/completion-record/create-completion-record/create-completion-record.component';
import { CreateAttendancesComponent } from '@app/views/pages/student/student-attendances/create-attendances/create-attendances.component';
import { StudentAttendancesComponent } from './views/pages/student/student-attendances/student-attendances.component';
import { UpdateAttendancesComponent } from '@app/views/pages/student/student-attendances/update-attendances/update-attendances.component';
import { CompletionRecordComponent } from '@app/views/pages/student/completion-record/completion-record.component';
import { IframeComponent } from '@app/views/pages/iframe/iframe-component/iframe.component';
import { StudentApprovalComponent } from './views/pages/student/student-approval/student-approval.component';
import { CreateQuotationComponent } from './views/pages/quotation/create-quotation/create-quotation.component';
import { QuotationComponent } from './views/pages/quotation/quotation.component';
import { EditQuotationComponent } from './views/pages/quotation/edit-quotation/edit-quotation.component';
import { InstructorComponent } from './views/pages/instructor/instructor.component';
import { AircraftUsedComponent } from './views/pages/aircraft-used/aircraft-used.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'account'
  },
  {
    path: 'account',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: AccountComponent,
        data: {
          title: 'Account Page'
        },
      },
      {
        path: 'update-password',
        component: UpdatePasswordComponent,
        data: {
          title: 'Update password Page'
        }
      },
      {
        path: 'instructor',
        component: InstructorComponent,
        data: {
          title: 'Instructor Page'
        }
      },
      {
        path: 'aircraft-used',
        component: AircraftUsedComponent,
        data: {
          title: 'Aircraft Used Page'
        }
      },
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      title: 'Forgot password Page'
    }
  },
  {
    path: 'termsofuse',
    component: TermsofuseComponent,
    data: {
      title: 'Terms Of Use Page'
    }
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
    data: {
      title: 'Privacy Page'
    }
  },
  {
    path: 'notification',
    canActivate: [AuthGuard],
    component: NotificationComponent,
    data: {
      title: 'Notification Page',
    }
  },
  {
    path: 'quotation',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'create',
        component: CreateQuotationComponent,
        data: {
          title: 'Create Quotation Page',
        },
      },
      {
        path: '',
        component: QuotationComponent,
        data: {
          title: 'List Quotation Page'
        },
      },
      {
        path: ':id',
        children: [
          {
            path: 'edit',
            component: EditQuotationComponent,
            data: {
              title: 'Edit Quotation page',
            }
          }
        ]
      }
    ],
  },
  {
    path: 'student',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: StudentComponent,
        data: {
          title: 'list student Page'
        },
      },
      {
        path: 'create',
        component: CreateStudentComponent,
        data: {
          title: 'Create student page',
        }
      },
      {
        path: 'approval',
        component: StudentApprovalComponent,
        data: {
          title: 'search student Approval'
        }
      },
      {
        path: ':id',
        children: [
          {
            path: 'edit',
            component: EditStudentComponent,
            data: {
              title: 'Edit student page',
            }
          },
          {
            path: 'attendances/:course',
            children: [
              {
                path: '',
                component: StudentAttendancesComponent,
                data: {
                  title: 'List attendance page',
                }
              },
              {
                path: 'create',
                component: CreateAttendancesComponent,
                data: {
                  title: 'create attendance page',
                }
              },
              {
                path: 'edit/:attendanceId',
                component: UpdateAttendancesComponent,
                data: {
                  title: 'Edit attendance page',
                }
              }
            ]
          },
          {
            path: 'completion-records/:course',
            children: [
              {
                path: '',
                component: CompletionRecordComponent,
                data: {
                  title: 'List completion record page',
                }
              },
              {
                path: 'create',
                component: CreateCompletionRecordComponent,
                data: {
                  title: 'create completion record page',
                }
              },
              {
                path: 'edit/:idCompletionRecord',
                component: CreateCompletionRecordComponent,
                data: {
                  title: 'Edit completion record page',
                }
              }
            ]
          },
        ]
      }
    ]
  },
  {
    path: 'iframe',
    canActivate: [AuthGuard],
    component: IframeComponent,
    data: {
      title: 'Update password Page'
    }
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
