<c-card class="mb-3 card-border">
  <c-card-body>
    <div class="completion-records__item">
      <div class="completion-records__item--header">
        <div class="title">
          <span class="d-inline" *ngIf="itemInput.examDate">{{ formatDate(itemInput.examDate.toDate(), formatDatePicker) }} </span>
        </div>
        <div class="exam">
          <div class="passing">{{ itemInput.examPass }}</div>
          <div class="point">
            <span>{{ 'student.completionRecord.list.score' | translate }}: </span>
            <span>{{ itemInput.score }}</span>
          </div>
        </div>
        <div class="action">
          <span class="btn-edit" (click)="redirectEdit()"></span>
          <app-modal-delete-common
            *ngIf="student?.userId === currentUser?.uid"
            [deleteId]="itemInput?.id"
            [titleDelete]="'student.completionRecord.delete.title' | translate"
            (handleDeleteCommon)="handleDeleteCompletion($event)">
          </app-modal-delete-common>
        </div>
      </div>
      <div class="completion-records__item--body">
        <div class="item">
          <span>{{ 'student.completionRecord.list.exam_name' | translate }}: </span>
          <span class="d-inline">{{ itemInput.examName }} </span>
        </div>
        <div class="item">
          <span>{{ 'student.completionRecord.list.airframe_title' | translate }}: </span>
          <span class="d-inline">{{ itemInput.airframeTitle }}</span>
        </div>
        <div class="item">
          <span>{{ 'student.completionRecord.list.airframe_number' | translate }}:  </span>
          <span class="d-inline">{{ itemInput.airframeNumber }}</span>
        </div>
      </div>
    </div>
  </c-card-body>
</c-card>
