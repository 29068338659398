<div class="mb-3">
  <label for="{{ formControlNameInput }}" class="form-label mb-1">
    {{ label }}
    <c-badge *ngIf="requiredText" color="danger">{{ "form.inputLabel.must" | translate}}</c-badge>
  </label>
  <input
    cFormControl
    [ngClass]="ngClass"
    [ngClass]="{
      'is-invalid': (submitted || form[formControlNameInput]?.touched) && form[formControlNameInput]?.invalid,
      'is-valid': form[formControlNameInput]?.touched && form[formControlNameInput]?.valid
    }"
    [formControlName]="formControlNameInput"
    [placeholder]="placeHolderInput"
    [type]="inputType"
    autoComplete="true"
    [maxLength]="maxLength || 255"
    [minlength]="minLength"
    [max]="max"
    [min]="min"
    id="{{ formControlNameInput }}"
    [attr.disabled]="disabled ? true : null"
    (change)="inputChange($event)"
  />

  <c-form-feedback *ngIf="(submitted || form[formControlNameInput]?.touched) && form[formControlNameInput].errors">
    <div *ngIf="form[formControlNameInput]?.errors[getFirstError(form[formControlNameInput]?.errors) || '']">
      <div *ngIf="getFirstError(form[formControlNameInput]?.errors) === 'required'">
        {{ "errorMessages.formValidate.input.required" | translate }}
      </div>
      <div *ngIf="getFirstError(form[formControlNameInput]?.errors) === 'maxlength'">
        {{ "errorMessages.formValidate.input.maxlength" | translate : {maxlength: maxLength || 255} }}
      </div>
      <div *ngIf="minLength && getFirstError(form[formControlNameInput]?.errors) === 'minlength'">
        {{ "errorMessages.formValidate.input.minlength" | translate : {minlength: minLength || 4} }}
      </div>
      <div *ngIf="max && getFirstError(form[formControlNameInput]?.errors) === 'max'">
        {{ "errorMessages.formValidate.input.max" | translate : { max: max } }}
      </div>
      <div *ngIf="min && getFirstError(form[formControlNameInput]?.errors) === 'min'">
        {{ "errorMessages.formValidate.input.min" | translate : { min: min } }}
      </div>
      <div *ngIf="getFirstError(form[formControlNameInput]?.errors) === 'minlength'">
        {{ "errorMessages.formValidate.input.maxlength" | translate }}
      </div>
      <div *ngIf="getFirstError(form[formControlNameInput]?.errors) === 'pattern'">
        {{ "errorMessages.formValidate." + formControlNameInput + '.' + getFirstError(form[formControlNameInput]?.errors) | translate }}
      </div>
    </div>
  </c-form-feedback>
</div>
