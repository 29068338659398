import { ATTENDING_NAME, GRADUATION_NAME, NOT_ISSUED, NOT_USE, PUBLISHED, RESERVATION_GENERAL, RESERVATION_RIZAEN, UNABLE_GRADUATE_NAME } from "@app/constants";

export interface IStatus {
  name: string,
  status: string,
}

export const StatusStudent: IStatus[] = [
  {
    name: ATTENDING_NAME,
    status: "attending",
  },
  {
    name: GRADUATION_NAME,
    status: "graduation",
  },
  {
    name: UNABLE_GRADUATE_NAME,
    status: "unable_to_graduate",
  },
  {
    name: RESERVATION_GENERAL,
    status: "reservation_general",
  },
  {
    name: RESERVATION_RIZAEN,
    status: "reservation_rizaen",
  },
];

export const StatusElearningStudent: IStatus[] = [
  {
    name: NOT_ISSUED,
    status: "not_issued",
  },
  {
    name: PUBLISHED,
    status: "published",
  },
  {
    name: NOT_USE,
    status: "nit_use",
  },
];


export const StatusCompletionRecords: IStatus[] = [
  {
    name: "全て",
    status: "all",
  },
  {
    name: "合格のみ",
    status: "合格",
  },
  {
    name: "不合格のみ",
    status: "不合格",
  },
];
