import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BEGINNER, DEFAULT_ITEMS_PER_PAGE, EXPERIENCED, formatDatePicker, startTimeOfDay } from '@app/constants';
import { formatDate, getLocaleDatePicker } from '@app/helpers';
import { TranslateService } from '@ngx-translate/core';
import { StatusAttendances } from "@app/models/statusAttendances";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AttendanceService, SettingService, StudentService } from "@app/services";
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { IAttendances } from '@app/models';
import { IStudent } from "@app/models/student";
import { IBreadcrumbItem } from '@coreui/angular-pro';

@Component({
  selector: 'app-student-attendances',
  templateUrl: './student-attendances.component.html',
  styleUrls: ['./student-attendances.component.scss']
})
export class StudentAttendancesComponent implements OnInit {
  @Output() pageOwner = new EventEmitter<number>();
  protected visibleCollapse: boolean = false;
  protected date = new Date();
  protected formSearch!: FormGroup;
  protected visible: boolean = false;
  protected id: string = '';
  protected course: string = '';
  protected currentDatepickerLocale: string = "";
  protected currentLanguage = this.translate.currentLang;
  protected formatDatePicker: string = formatDatePicker;
  protected queryParams: any
  protected readonly status = StatusAttendances;
  protected currentPage: number = 1;
  protected readonly itemsPerPage: number = DEFAULT_ITEMS_PER_PAGE;
  protected currentUser?: any;
  protected studentAttendances?: any;
  protected student?: IStudent;
  protected userId?: string;
  protected numberAttendanceFilter: number = 0;
  protected breadcrumbItems: IBreadcrumbItem[] = [];
  protected isLoading: boolean = false;
  protected totalClassTime: number = 0;
  protected totalClassTimeFormatted: string = '';
  protected timeSetting: number = 0;
  protected timeSettingFormatted: string = '';

  constructor(
    private router: Router,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private attendanceService: AttendanceService,
    private auth: AngularFireAuth,
    private activatedRoute: ActivatedRoute,
    private studentService: StudentService,
    private settingService: SettingService,
  ) {}

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id') as string
    this.course = this.activatedRoute.snapshot.paramMap.get('course') as string
    this.initForm()
    this.visibleCollapse = false;
    this.currentDatepickerLocale = getLocaleDatePicker(this.currentLanguage);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) this.navigateParams();
    });

    this.getUser().then(() => {
      this.userId = this.activatedRoute.snapshot.queryParamMap.get('user_id') ?? this.currentUser.uid;
      this.studentService.getStudent(this.userId, this.id).then((res) => {
        if (!!res) this.student = res;
        this.getTimeSettings();
        this.handleQueryParams();
      });
    });

    this.translate.get([
      'student.listStudent.title',
      'student.attendances.list.title',
      'student.attendances.create.title'
    ]).subscribe(translations => {
      this.breadcrumbItems = [
        { label: translations['student.listStudent.title'], url: '/student' },
        { label: translations['student.attendances.list.title'], url: `` },
      ];
    });
  }

  private async getTimeSettings(): Promise<void> {
    (await this.settingService.getTimeSetting()).subscribe((items) => {
			if (items.length > 0) {
				let data: any = items[0].payload.doc.data();

        let key: string = '';
        const experiencedType = this.student?.studentClassification?.experiencedType;
        if (experiencedType == EXPERIENCED) {
          key += 'experienced';
        } else {
          key += 'beginner';
        }

        if (this.course.includes('first')) {
          key += 'First';
        } else if (this.course.includes('second')) {
          key += 'Second';
        }

        if (this.course.includes('ClassBasic')) {
          key += 'ClassBasic';
        } else if (this.course.includes('ClassDaytimeOnly')) {
          key += 'ClassDaytimeOnly';
        } else if (this.course.includes('ClassVisualLineOfSight')) {
          key += 'ClassVisualLineOfSight';
        } else if (this.course.includes('ClassWeightLimited')) {
          key += 'ClassWeightLimited';
        }
        this.timeSetting = data[key];
        this.timeSettingFormatted = (this.timeSetting / 60).toFixed(2);
			}
		});
  }

  private handleQueryParams() {
    let params: any = localStorage.getItem('queryString.attendances');
    localStorage.removeItem('queryString.attendances');
    if (params) {
      params = JSON.parse(params);
      this.f['querySearch'].setValue(params['querySearch']);
      this.f['status'].setValue(params['status']);

      if (!!params['startDate']) this.f['startDate'].setValue(new Date(params['startDate']));
      if (!!params['endDate']) this.f['endDate'].setValue(new Date(params['endDate']));

      this.visibleCollapse = params['visibleCollapse'];
      this.search().then(() => this.changePage(params['page']));
    } else this.search();
  }

  protected redirectCreate() {
    this.router.navigateByUrl('/student/' + this.id + '/attendances/' + this.course + '/create');
  }

  private initForm() {
    this.formSearch = this.formBuilder.group({
      querySearch: [''],
      startDate: [new Date(new Date().getFullYear(), 0, 1)],
      endDate: [new Date(new Date().getFullYear(), 11, 31)],
      status: ["all"]
    });
  }

  protected toggleCollapse() {
    this.visibleCollapse = !this.visibleCollapse
  }

  protected toggleModal(id: string) {
    this.id = id;
    this.visible = !this.visible;
  }

  protected dayFormat = (date: Date) => date.getDate();

  protected get f() {
    return this.formSearch.controls;
  }

  private changeTime(stringTime: string): number {
    const timeArr: string[] = stringTime.split(':');
    const time = parseInt(timeArr[0]);
    const minute = parseInt(timeArr[1]);
    return time * 60 + minute;
  }

  protected async search() {
    this.currentPage = 1;
    let params = this.formSearch.getRawValue();
    (await this.attendanceService.getAttendances(this.userId, this.id, this.course))
      .subscribe(attendances => {
        this.totalClassTime = 0;
        let dataFilterAttendance: any = [];
        let dataNotFilterUniqueAttendance = [...new Map(attendances.map(item => [item.id, item])).values()];
        dataFilterAttendance = [...dataNotFilterUniqueAttendance].sort((a: any, b: any) => {
          return b.createAt - a.createAt;
        }).map((item: any) => {
          if (item.courseStartTime && item.courseEndTime && !item.exam) {
            const courseStartMinutes: number = this.changeTime(item.courseStartTime);
            const courseEndMinutes: number = this.changeTime(item.courseEndTime);
            const classTime: number = Math.abs(courseEndMinutes - courseStartMinutes);
            this.totalClassTime += classTime;
            item.classTime = (classTime / 60).toFixed(2);
          }
          return item;
        });
        this.totalClassTimeFormatted = (this.totalClassTime / 60).toFixed(2);
        if (this.f['querySearch'].value.length) {
          dataFilterAttendance = dataFilterAttendance.filter((item: any) => {
            return (item.courseContentCurriculum && item.courseContentCurriculum.includes(this.f['querySearch'].value)) ||
              (item.examComment && item.examComment.includes(this.f['querySearch'].value));
          });
        }
        if (params.startDate instanceof Date) {
          dataFilterAttendance = dataFilterAttendance.filter((item: any) => {
            return item.courseDate.toDate() >= new Date(formatDate(params.startDate, formatDatePicker) + startTimeOfDay);
          });
        }
        if (params.endDate instanceof Date) {
          dataFilterAttendance = dataFilterAttendance.filter((item: any) => {
            return item.courseDate.toDate() <= new Date(formatDate(params.endDate, formatDatePicker) + startTimeOfDay);
          });
        }

        if (this.f['status'].value == 'exam') dataFilterAttendance = dataFilterAttendance.filter((item: any) => item.exam != '');
        if (this.f['status'].value == 'lectureOnly') dataFilterAttendance = dataFilterAttendance.filter((item: any) => item.exam == '');
        this.studentAttendances = dataFilterAttendance;
        this.numberAttendanceFilter = this.studentAttendances.length;
      })
  }

  private async getUser(): Promise<void> {
    const currentUser = await this.auth.currentUser;
    if (currentUser) this.currentUser = currentUser
  }

  protected changePage = (page: number): void => {
    this.currentPage = page;
    this.pageOwner.emit(page);
  }

  protected trackByAttendance(index: number, item: IAttendances): number {
    return index;
  }

  protected exportPDFAttendances(): void {
    this.isLoading = true;
    let params = this.formSearch.getRawValue();
    this.attendanceService.exportPDF(this.userId as string, this.student as IStudent, this.course, params)
      .finally(() => this.isLoading = false);
  }

  private navigateParams(): void {
    let params = this.formSearch.getRawValue();
    params.startDate = formatDate(params.startDate, formatDatePicker);
    params.endDate = formatDate(params.endDate, formatDatePicker);
    params.visibleCollapse = this.visibleCollapse;
    params.page = this.currentPage;
    localStorage.setItem('queryString.attendances', JSON.stringify(params));
  }
}
