<header>
  <a id="logo" routerLink="/login">
    <img src="./assets/img/logo.png" alt="logo" width="40" height="40"/>
    <div>{{ "layout.header.title" | translate }}</div>
  </a>
  <div id="auth">
    <a class="active" *ngIf="router.isActive('/forgot-password', true)" routerLink="/login">{{ 'register.login' | translate }}</a>
  </div>
</header>
<header id="tablet" *ngIf="router.isActive('/forgot-password', true)">
  <div id="auth">
    <a class="active" routerLink="/login">{{ 'register.login' | translate }}</a>
  </div>
</header>
