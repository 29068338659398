import { Injectable } from '@angular/core';
import { IStudent, ISearch } from "../models/student";
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { combineLatest } from 'rxjs';
import { map } from "rxjs/operators";
import firebase from "firebase/compat";
import {
  BEGINNER,
  EXPERIENCED,
  LEVEl_ONE,
  LEVEl_TWO,
  TEXT_TRUE,
  endTimeOfDay,
  startTimeOfDay,
  formatDatePicker,
  EXAM_PASS,
} from '@app/constants';
import { formatDate } from '@app/helpers';
import { LevelStudent } from '@app/models/levelStudent';
import { Api } from "@app/services/api.service";
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StudentService {
  readonly collectionName = 'students';

  constructor(
    private db: AngularFirestore,
    private api: Api,
    private http: HttpClient
  ) { }

  async create(Notification: IStudent, userDocId: string = '') {
    //todo create student
    Notification.userId = userDocId;
    return await this.db.collection('sync_students').doc(userDocId).collection("log_students").add(Notification);
  }

  async update(Notification: IStudent, userDocId: string = '', studentDocId: string = '') {
    //todo create student
    Notification.userId = userDocId;
    await this.db.collection('users').doc(userDocId).collection(this.collectionName).doc(studentDocId).update(Notification);
  }

  async updateLearning(userDocId: string, studentId: string | null = '', dataLearning: any) {
    if (!studentId) {
      return;
    }
    const studentDoc = await this.db.collection('users').doc(userDocId).collection(this.collectionName).doc(studentId);
    await studentDoc.set({
      studentClassification: {
        statusElearning: dataLearning.statusElearning,
        useElearning: dataLearning.useElearning,
      }
    }, { merge: true });
  }

  async updateStatusRequestReleased(userDocId: string, studentId: string ,status: string, studentCourse: any) {
    const studentDoc = await this.db.collection('users').doc(userDocId).collection(this.collectionName).doc(studentId);
    await studentDoc.set({
      [studentCourse.type]: {
        [`${studentCourse.key}Status`]: status,
        [`${studentCourse.key}ApplicationDate`]: new Date(),
      }
    }, { merge: true });
  }

  public getStudent(userDocId: string = '', studentDocId: string = '') {
    return new Promise<any>((resolve, error) => {
      this.db.collection('users').doc(userDocId).collection(this.collectionName).doc(studentDocId).valueChanges().subscribe((student) => {
        if (student) student = { ...student, ...{ id: studentDocId } };
        resolve(student);
      });
    })
  }

  async getStudentByCertificateNumber(userDocId: string = '', certificateNumber: any = '') {
    return new Promise<any>((resolve, error) => {
      this.db.collection('users').doc(userDocId).collection(this.collectionName, (ref) => ref.where('studentInfo.certificateNumber', '==', certificateNumber))
      .valueChanges({ idField: 'id' })
      .subscribe((student) => resolve(student));
    })
  }

  getAllStudentByUser(userDocId: string = '',) {
    return this.db.collection('users').doc(userDocId).collection(this.collectionName).valueChanges({ idField: 'id' });
  }

  public getList(param: ISearch, userDocId: string = '', searchGlobal: boolean = false) {
    //todo search students in user
    const searchStudentLocalUser = this.db.collection('users').doc(userDocId).collection(this.collectionName, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = this.searchQuery(param, query);
      return query.orderBy('studentEnrollment.enrollmentDate', 'desc');
    }).valueChanges({ idField: 'id' });

    if (searchGlobal) {
      //todo search students in searchGlobal
      const searchStudentGlobalUser = this.db.collectionGroup(this.collectionName, ref => {
        let query: any = ref;
        query = this.searchQuery(param, query)
        return query.orderBy('studentEnrollment.enrollmentDate', 'desc');
      }).valueChanges({ idField: 'id' });

      return combineLatest(searchStudentLocalUser, searchStudentGlobalUser).pipe(
        map(([searchStudentLocalUser, searchStudentGlobalUser]) => [...searchStudentLocalUser, ...searchStudentGlobalUser])
      )
    }

    return searchStudentLocalUser;
  }

  async countStudent(userId: string) {
    return new Promise((resolve, reject) => {
      this.db.collection('users').doc(userId).collection(this.collectionName).valueChanges()
        .subscribe(result => resolve(result.length + 1));
    });
  }

  async checkStudent(userId: string, studentId: string) {
    return new Promise((resolve, reject) => {
      this.db.collection('users').doc(userId).collection(this.collectionName).doc(studentId).valueChanges()
        .subscribe(result => resolve(result != undefined));
    });

  }

  searchQuery(param: ISearch, query: any) {
    if (param?.startDate instanceof Date) {
      query = query.where('studentEnrollment.enrollmentDate', '>=', new Date(formatDate(param.startDate, formatDatePicker) + startTimeOfDay));
    }

    if (param?.endDate instanceof Date) {
      query = query.where('studentEnrollment.enrollmentDate', '<=', new Date(formatDate(param.endDate, formatDatePicker) + endTimeOfDay));
    }

    if (param?.level) {
      if (param?.level === LevelStudent[0]?.name || param?.level === LevelStudent[1]?.name) query = query.where('studentClassification.experiencedType', '==', BEGINNER);
      if (param?.level === LevelStudent[2]?.name || param?.level === LevelStudent[3]?.name) query = query.where('studentClassification.experiencedType', '==', EXPERIENCED);
      if (param?.level === LevelStudent[2]?.name || param?.level === LevelStudent[0]?.name) query = query.where('level', '==', LEVEl_ONE);
      if (param?.level === LevelStudent[3]?.name || param?.level === LevelStudent[1]?.name) query = query.where('level', '==', LEVEl_TWO);
      if (param?.level === LevelStudent[4]?.name) query = query.where('studentClassification.privateSkillCertificate', '==', TEXT_TRUE);
    }
    if (param?.status) query = query.where('studentClassification.status', '==', param?.status);
    if (param?.statusElearning) query = query.where('studentClassification.statusElearning', '==', param?.statusElearning);
    if (param?.courseItems) query = query.where('studentInfo.courseItems', '==', param?.courseItems);

    return query;
  }

  serialize(obj: any) {
    let str = [];
    for (let p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  exportCsvListStudentApproval(userId: string, param: any) {
    param['startDateFormat'] = param.startDate instanceof Date ? formatDate(param.startDate, formatDatePicker) : '';
    param['endDateFormat'] = param.endDate instanceof Date ? formatDate(param.endDate, formatDatePicker) : '';
    let url = `/export-csv-list-student-approval/${userId}?${this.serialize(param)}`;
    const fileName = `承認リスト_${formatDate(new Date(), formatDatePicker)}.csv`;
    return this.api.get(url, { responseType: 'blob' })
      .then((res: any) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = window["URL"].createObjectURL(new Blob([res], {type: 'text/csv;charset=utf-8'}));
        downloadLink.download = fileName;
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(downloadLink.href);
      });
  }

  exportCsv(userId: string, param: any) {
    param['startDateFormat'] = param.startDate instanceof Date ? formatDate(param.startDate, formatDatePicker) : '';
    param['endDateFormat'] = param.endDate instanceof Date ? formatDate(param.endDate, formatDatePicker) : '';
    let url = `/export-csv-student/${userId}?${this.serialize(param)}`;
    const fileName = `受講者_${formatDate(new Date(), formatDatePicker)}.csv`;
    return this.api.get(url, { responseType: 'blob' })
      .then((res: any) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = window["URL"].createObjectURL(new Blob([res], {type: 'text/csv;charset=utf-8'}));
        downloadLink.download = fileName;
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(downloadLink.href);
      });
  }

  importCsv(csvData: string, userId: any) {
    let url = `/import-csv-student`;
    return this.api.post(url, { data: csvData, userId: userId });
  }

  exportCsvCompletionRecord(userId: string, studentId: string, course: string, student: any, param: any) {
    param['startDateFormat'] = param.startDate instanceof Date ? formatDate(param.startDate, formatDatePicker) : '';
    param['endDateFormat'] = param.endDate instanceof Date ? formatDate(param.endDate, formatDatePicker) : '';
    let url = `/user/${userId}/export-csv-student-completion-records/${studentId}/${course}?${this.serialize(param)}`;
    const fileName = `修了記録_${student.studentInfo.certificateNumber}_${student.studentInfo.name}.csv`;
    return this.api.get(url, { responseType: 'blob' })
      .then((res: any) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = window["URL"].createObjectURL(new Blob([res], {type: 'text/csv;charset=utf-8'}));
        downloadLink.download = fileName;
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(downloadLink.href);
      });
  }
}
