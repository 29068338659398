import { IDataOptionLabelName, IDataOptionStudentCourse } from "./interface"

export const x_rotorcraftMulti1: IDataOptionLabelName[] = [
  {
    label: '一等・基本',
    name: 'x_firstClassBasic'
  },
  {
    label: '一等・夜間',
    name: 'x_firstClassDaytimeOnly'
  },
  {
    label: '一等・目視外',
    name: 'x_firstClassVisualLineOfSight'
  },
  {
    label: '一等・25kg以上',
    name: 'x_firstClassWeightLimited'
  },
]

export const x_rotorcraftMulti2: IDataOptionLabelName[] = [
  {
    label: '二等・基本',
    name: 'x_secondClassBasic'
  },
  {
    label: '二等・夜間',
    name: 'x_secondClassDaytimeOnly'
  },
  {
    label: '二等・目視外',
    name: 'x_secondClassVisualLineOfSight'
  },
  {
    label: '二等・25kg以上',
    name: 'x_secondClassWeightLimited'
  },
]

export const y_plane1: IDataOptionLabelName[] = [
  {
    label: '一等・基本',
    name: 'y_firstClassBasic'
  },
  {
    label: '一等・夜間',
    name: 'y_firstClassDaytimeOnly'
  },
  {
    label: '一等・目視外',
    name: 'y_firstClassVisualLineOfSight'
  },
  {
    label: '一等・25kg以上',
    name: 'y_firstClassWeightLimited'
  }
]
export const y_plane2: IDataOptionLabelName[] = [
  {
    label: '二等・基本',
    name: 'y_secondClassBasic'
  },
  {
    label: '二等・夜間',
    name: 'y_secondClassDaytimeOnly'
  },
  {
    label: '二等・目視外',
    name: 'y_secondClassVisualLineOfSight'
  },
  {
    label: '二等・25kg以上',
    name: 'y_secondClassWeightLimited'
  }
]

export const z_rotorcraft1: IDataOptionLabelName[] = [
  {
    label: '一等・基本',
    name: 'z_firstClassBasic'
  },
  {
    label: '一等・夜間',
    name: 'z_firstClassDaytimeOnly'
  },
  {
    label: '一等・目視外',
    name: 'z_firstClassVisualLineOfSight'
  },
  {
    label: '一等・25kg以上',
    name: 'z_firstClassWeightLimited'
  }
]
export const z_rotorcraft2: IDataOptionLabelName[] = [
  {
    label: '二等・基本',
    name: 'z_secondClassBasic'
  },
  {
    label: '二等・夜間',
    name: 'z_secondClassDaytimeOnly'
  },
  {
    label: '二等・目視外',
    name: 'z_secondClassVisualLineOfSight'
  },
  {
    label: '二等・25kg以上',
    name: 'z_secondClassWeightLimited'
  }
]

export const studentCourses: IDataOptionStudentCourse[] = [
  {
    key: 'studentCourseMulti',
    firstKey: 'a',
    masterKey: 'a_rotorcraftMulti'
  },
  {
    key: 'studentCourseAirplane',
    firstKey: 'b',
    masterKey: 'b_plane'
  },
  {
    key: 'studentCourseHelicopter',
    firstKey: 'c',
    masterKey: 'c_rotorcraft'
  }
]

export const courseTypeOrders: string[] = [
  'first',
  'second',
]

export const courseTypes: string[] = [
  'ClassBasic',
  'ClassDaytimeOnly',
  'ClassVisualLineOfSight',
  'ClassWeightLimited',
]
export const courseTypeAttributes: string[] = [
  'Date',
  'No',
  'Place',
  'Responsible',
  'SendingDate',
  'DeliveryDate',
  'ExpirationDate',
  'Status',
]

export const studentCourseCategories: string[] = [
  'studentCourseMulti',
  'studentCourseAirplane',
  'studentCourseHelicopter'
]

export const courseTypeSendingDate: string[] = [
  'BasicSendingDate',
  'DaytimeOnlySendingDate',
  'VisualLineOfSightSendingDate',
  'WeightLimitedSendingDate'
]

export const courseClassType: string[] = [
  'a_firstClass',
  'a_secondClass',
  'b_firstClass',
  'b_secondClass',
  'c_firstClass',
  'c_secondClass'
];

export const courseClassTypeSendingDate: string[] = [
  'a_firstClassBasicSendingDate',
  'a_firstClassDaytimeOnlySendingDate',
  'a_firstClassVisualLineOfSightSendingDate',
  'a_firstClassWeightLimitedSendingDate',

  'a_secondClassBasicSendingDate',
  'a_secondClassDaytimeOnlySendingDate',
  'a_secondClassVisualLineOfSightSendingDate',
  'a_secondClassWeightLimitedSendingDate',

  'b_firstClassBasicSendingDate',
  'b_firstClassDaytimeOnlySendingDate',
  'b_firstClassVisualLineOfSightSendingDate',
  'b_firstClassWeightLimitedSendingDate',

  'b_secondClassBasicSendingDate',
  'b_secondClassDaytimeOnlySendingDate',
  'b_secondClassVisualLineOfSightSendingDate',
  'b_secondClassWeightLimitedSendingDate',

  'c_firstClassBasicSendingDate',
  'c_firstClassDaytimeOnlySendingDate',
  'c_firstClassVisualLineOfSightSendingDate',
  'c_firstClassWeightLimitedSendingDate',

  'c_secondClassBasicSendingDate',
  'c_secondClassDaytimeOnlySendingDate',
  'c_secondClassVisualLineOfSightSendingDate',
  'c_secondClassWeightLimitedSendingDate',

];
