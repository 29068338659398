import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AlertService, ValidationFormsService, FirebaseAnalyticService, AuthService } from '@app/services';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-register',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  protected formForgotPassword!: UntypedFormGroup;
  protected submitted: boolean = false;
  protected checkPass: boolean = false;
  protected loading: boolean = false;

  constructor(
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private validationFormService: ValidationFormsService,
    private alertService: AlertService,
    private angularFireAuth: AngularFireAuth,
    private analytics: AngularFireAnalytics,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.analytics.logEvent(this.firebaseAnalyticService.EVENT_NAME.FORGOT_PASSWORD, {
      'content_type': this.firebaseAnalyticService.CONTENT_TYPE,
      'develop_type': this.firebaseAnalyticService.TYPE,
    });
    this.createForm();
  }

  private createForm() {
    this.formForgotPassword = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(this.validationFormService.formRules.emailPattern)]],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.formForgotPassword.controls;
  }

  get errorMessages() {
    return this.validationFormService.errorMessages;
  }

  private onValidate() {
    this.submitted = true;
    return this.formForgotPassword.valid;
  }

  async forgotPassword() {
    if (this.onValidate() && !this.loading) {
      this.loading = true;
      const email = this.formForgotPassword.value.email;
      await this.emailValidate(email);
      if (!this.checkPass) {
        this.loading = false;
        this.alertService.error(this.translateService.instant('alertMessages.forgotPassword.emailNotRegister'));
        return;
      }

      this.authService.forgotPassword(email)
        .then((res: any) => {
          this.loading = false;
          this.alertService.success(this.translateService.instant('alertMessages.forgotPassword.emailSendPasswordReset', { email: email }));
        })
        .catch((error: any) => {
          this.alertService.error(this.translateService.instant('alertMessages.forgotPassword.emailNotRegister'));
          this.loading = false;
        })
        .finally(() => this.loading = false);
    }
  }

  private async emailValidate(email: string) {
    const user = await this.angularFireAuth.fetchSignInMethodsForEmail(email);
    if (user) {
      user.forEach((value) => {
        if (value == 'password') this.checkPass = true;
      });
    }
  }
}
