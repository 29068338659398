<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <c-col class="justify-content-center mb-5">
    <h3>{{ 'instructor.title'|translate }}</h3>
  </c-col>
  <c-col class="mb-5">
    <c-col class="mb-4">
      <form cForm [formGroup]="formInstructor" (ngSubmit)="onSubmit()" class="needs-validate">
        <c-card class="mb-3 card-no-border">
          <c-card-body class="card-border-bottom d-flex flex-wrap flex-sm-nowrap">
            <c-col class="input-row d-flex justify-content-start justify-content-sm-center flex-wrap flex-sm-nowrap">
              <div>
                <label cLabel="col" for="itemName" class="me-1">項目名</label>
                <c-badge color="danger" shape="rounded-pill">{{ 'account.required'|translate }}</c-badge>
              </div>
              <div class="input ms-0 ms-sm-4">
                <input
                  cFormControl
                  id="itemName"
                  type="text"
                  placeholder="項目名"
                  formControlName="itemName"
                  [class]="getValidationClass(formInstructorControls['itemName'])"
                />
                <c-form-feedback *ngIf="isInvalid(formInstructorControls['itemName'])">
                  <div>
                    {{ errorMessages['itemName'][getFirstError(formInstructorControls['itemName'].errors) || '']  }}
                  </div>
                </c-form-feedback>
              </div>
            </c-col>
          </c-card-body>
        </c-card>
        <button type="submit" class="btn-common width-100">{{ 'account.btn_update'|translate }}</button>
      </form>
    </c-col>
    <c-col>
      <c-card class="mb-3 card-no-border">
        <c-card-body>
          <div>項目一覧</div>
        </c-card-body>
        <c-card-body>
          <c-row class="mb-3">
            <c-col sm="8" md="9" lg="10" xs="6">
              項目名
            </c-col>
            <c-col sm="4" md="3" lg="2" xs="6">
            </c-col>
          </c-row>
          <app-instructor-item
            *ngFor="let item of instructors"
            [itemInput]="item"
            [maxSort]="maxSort"
            [minSort]="minSort"
            (deleteItem)="handleDeleteItem($event)"
            (upItem)="handleUpItem($event)"
            (downItem)="handleDownItem($event)"
          >
          </app-instructor-item>
        </c-card-body>
      </c-card>
    </c-col>
  </c-col>
</c-container>
