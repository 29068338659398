import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { cilArrowThickTop, cilArrowThickBottom} from '@coreui/icons';

@Component({
  selector: 'app-quotation-item',
  templateUrl: './quotation-item.component.html',
  styleUrls: ['./quotation-item.component.scss']
})
export class QuotationItemComponent implements OnInit {
  icons = { cilArrowThickTop, cilArrowThickBottom };
  @Input('itemInput') itemInput: any;
  @Input('itemIndex') itemIndex: any;

  @Input('lengthDataQuotationArr') lengthDataQuotationArr: any;

  @Output('deleteItem') deleteItem: EventEmitter<any> = new EventEmitter<any>();
  @Output('moveItemUp') moveItemUp: EventEmitter<any> = new EventEmitter<any>();
  @Output('moveItemDown') moveItemDown: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  public handleDeleteItemQuotation(index: number): void {
    this.deleteItem.emit({index: index});
  }

  public handleMoveItemUp(index: number): void {
    this.moveItemUp.emit({index: index});
  }

  public handleMoveItemDown(index: number): void {
    this.moveItemDown.emit({index: index});
  }

}
