<div [formGroup]="formGroup">
  <div class="form_item">
    <div class="form_item--title">ヘリ</div>
    <div class="form_item--content">

      <!-- c_rotorcraft -->
      <app-input-checkbox
        [ngClass]="'checkbox_group--inline'"
        [label]="'student.createStudent.studentCourseHelicopter.c_rotorcraft' | translate"
        [formControlNameInput]="'c_rotorcraft'"
        [submitted]="submitted"
        [formGroup]="formGroup"
        (changeValue)="changeCourse()"
        [form]="form">
      </app-input-checkbox>

      <div [@slideInOut]="formGroup.get('c_rotorcraft')?.value ? 'in': 'out'">
        <!-- c_firstClassBasic -->
        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.studentCourseHelicopter.c_firstClassBasic' | translate"
          [formControlNameInput]="'c_firstClassBasic'"
          [submitted]="submitted"
          [formGroup]="formGroup"
          [disabled]="checkTypeOrderCourse('second', form)"
          (changeValue)="changeCourseTypes('c_firstClassBasic', form)"
          [form]="form">
        </app-input-checkbox>

        <c-row [@slideInOut]="formGroup.get('c_firstClassBasic')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- c_firstClassBasicDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassBasicDate' | translate"
              [formControlNameInput]="'c_firstClassBasicDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['c_firstClassBasicDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- c_firstClassBasicNo -->
            <app-input-base
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassBasicNo' | translate"
              class="input-group-container"
              [formControlNameInput]="'c_firstClassBasicNo'"
              [placeHolderInput]="'student.createStudent.studentCourseHelicopter.c_firstClassBasicNo' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-base>
          </c-col>
        </c-row>
        <c-row [@slideInOut]="formGroup.get('c_firstClassBasic')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- c_firstClassBasicPlace -->
            <app-input-base
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassBasicPlace' | translate"
              class="input-group-container"
              [formControlNameInput]="'c_firstClassBasicPlace'"
              [placeHolderInput]="'student.createStudent.studentCourseHelicopter.c_firstClassBasicPlace' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- c_firstClassBasicResponsible -->
            <app-input-base
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassBasicResponsible' | translate"
              class="input-group-container"
              [formControlNameInput]="'c_firstClassBasicResponsible'"
              [placeHolderInput]="'student.createStudent.studentCourseHelicopter.c_firstClassBasicResponsible' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- c_firstClassBasicDeliveryDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassBasicDeliveryDate' | translate"
              [formControlNameInput]="'c_firstClassBasicDeliveryDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['c_firstClassBasicDeliveryDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form"
              (changeValueDate)="changeDate($event)">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- c_firstClassBasicSendingDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassBasicSendingDate' | translate"
              [formControlNameInput]="'c_firstClassBasicSendingDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['c_firstClassBasicSendingDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col md="6">
            <!-- c_firstClassBasicExpirationDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassBasicExpirationDate' | translate"
              [formControlNameInput]="'c_firstClassBasicExpirationDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['c_firstClassBasicExpirationDate'].value"
              [disabled]="true"
              [form]="form"
              >
            </app-input-date-picker>
          </c-col>
        </c-row>

        <!-- c_firstClassDaytimeOnly -->
        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.studentCourseHelicopter.c_firstClassDaytimeOnly' | translate"
          [formControlNameInput]="'c_firstClassDaytimeOnly'"
          [submitted]="submitted"
          [disabled]="checkTypeOrderCourse('second', form)"
          (changeValue)="changeCourseTypes('c_firstClassDaytimeOnly', form)"
          [formGroup]="formGroup"
          [form]="form">
        </app-input-checkbox>

        <c-row [@slideInOut]="formGroup.get('c_firstClassDaytimeOnly')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- c_firstClassDaytimeOnlyDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassDaytimeOnlyDate' | translate"
              [formControlNameInput]="'c_firstClassDaytimeOnlyDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['c_firstClassDaytimeOnlyDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- c_firstClassDaytimeOnlyNo -->
            <app-input-base
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassDaytimeOnlyNo' | translate"
              class="input-group-container"
              [formControlNameInput]="'c_firstClassDaytimeOnlyNo'"
              [placeHolderInput]="'student.createStudent.studentCourseHelicopter.c_firstClassDaytimeOnlyNo' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('second', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
        </c-row>
        <c-row [@slideInOut]="formGroup.get('c_firstClassDaytimeOnly')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- c_firstClassDaytimeOnlyPlace -->
            <app-input-base
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassDaytimeOnlyPlace' | translate"
              class="input-group-container"
              [formControlNameInput]="'c_firstClassDaytimeOnlyPlace'"
              [placeHolderInput]="'student.createStudent.studentCourseHelicopter.c_firstClassDaytimeOnlyPlace' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('second', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- c_firstClassDaytimeOnlyResponsible -->
            <app-input-base
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassDaytimeOnlyResponsible' | translate"
              class="input-group-container"
              [formControlNameInput]="'c_firstClassDaytimeOnlyResponsible'"
              [placeHolderInput]="'student.createStudent.studentCourseHelicopter.c_firstClassDaytimeOnlyResponsible' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('second', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- c_firstClassDaytimeOnlyDeliveryDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassDaytimeOnlyDeliveryDate' | translate"
              [formControlNameInput]="'c_firstClassDaytimeOnlyDeliveryDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['c_firstClassDaytimeOnlyDeliveryDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              (changeValueDate)="changeDate($event)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- c_firstClassDaytimeOnlySendingDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassDaytimeOnlySendingDate' | translate"
              [formControlNameInput]="'c_firstClassDaytimeOnlySendingDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['c_firstClassDaytimeOnlySendingDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- c_firstClassDaytimeOnlyExpirationDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassDaytimeOnlyExpirationDate' | translate"
              [formControlNameInput]="'c_firstClassDaytimeOnlyExpirationDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['c_firstClassDaytimeOnlyExpirationDate'].value"
              [disabled]="true"
              [form]="form">
            </app-input-date-picker>
          </c-col>
        </c-row>

        <!-- c_firstClassVisualLineOfSight -->
        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.studentCourseHelicopter.c_firstClassVisualLineOfSight' | translate"
          [formControlNameInput]="'c_firstClassVisualLineOfSight'"
          [submitted]="submitted"
          [disabled]="checkTypeOrderCourse('second', form)"
          (changeValue)="changeCourseTypes('c_firstClassVisualLineOfSight', form)"
          [formGroup]="formGroup"
          [form]="form">
        </app-input-checkbox>

        <c-row [@slideInOut]="formGroup.get('c_firstClassVisualLineOfSight')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- c_firstClassVisualLineOfSightDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassVisualLineOfSightDate' | translate"
              [formControlNameInput]="'c_firstClassVisualLineOfSightDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('second', form)"
              [initialDate]="form['c_firstClassVisualLineOfSightDate'].value"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- c_firstClassVisualLineOfSightNo -->
            <app-input-base
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassVisualLineOfSightNo' | translate"
              class="input-group-container"
              [formControlNameInput]="'c_firstClassVisualLineOfSightNo'"
              [placeHolderInput]="'student.createStudent.studentCourseHelicopter.c_firstClassVisualLineOfSightNo' | translate"
              [inputType]="'text'"
              [disabled]="checkTypeOrderCourse('second', form)"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
        </c-row>
        <c-row [@slideInOut]="formGroup.get('c_firstClassVisualLineOfSight')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- c_firstClassVisualLineOfSightPlace -->
            <app-input-base
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassVisualLineOfSightPlace' | translate"
              class="input-group-container"
              [formControlNameInput]="'c_firstClassVisualLineOfSightPlace'"
              [placeHolderInput]="'student.createStudent.studentCourseHelicopter.c_firstClassVisualLineOfSightPlace' | translate"
              [inputType]="'text'"
              [disabled]="checkTypeOrderCourse('second', form)"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- c_firstClassVisualLineOfSightResponsible -->
            <app-input-base
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassVisualLineOfSightResponsible' | translate"
              class="input-group-container"
              [formControlNameInput]="'c_firstClassVisualLineOfSightResponsible'"
              [placeHolderInput]="'student.createStudent.studentCourseHelicopter.c_firstClassVisualLineOfSightResponsible' | translate"
              [inputType]="'text'"
              [disabled]="checkTypeOrderCourse('second', form)"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- c_firstClassVisualLineOfSightDeliveryDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassVisualLineOfSightDeliveryDate' | translate"
              [formControlNameInput]="'c_firstClassVisualLineOfSightDeliveryDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['c_firstClassVisualLineOfSightDeliveryDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              (changeValueDate)="changeDate($event)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- c_firstClassVisualLineOfSightSendingDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassVisualLineOfSightSendingDate' | translate"
              [formControlNameInput]="'c_firstClassVisualLineOfSightSendingDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['c_firstClassVisualLineOfSightSendingDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- c_firstClassVisualLineOfSightExpirationDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassVisualLineOfSightExpirationDate' | translate"
              [formControlNameInput]="'c_firstClassVisualLineOfSightExpirationDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['c_firstClassVisualLineOfSightExpirationDate'].value"
              [disabled]="true"
              [form]="form">
            </app-input-date-picker>
          </c-col>
        </c-row>

        <!-- c_firstClassWeightLimited -->
        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.studentCourseHelicopter.c_firstClassWeightLimited' | translate"
          [formControlNameInput]="'c_firstClassWeightLimited'"
          [submitted]="submitted"
          [disabled]="checkTypeOrderCourse('second', form)"
          (changeValue)="changeCourseTypes('c_firstClassWeightLimited', form)"
          [formGroup]="formGroup"
          [form]="form">
        </app-input-checkbox>

        <c-row [@slideInOut]="formGroup.get('c_firstClassWeightLimited')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- c_firstClassWeightLimitedDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassWeightLimitedDate' | translate"
              [formControlNameInput]="'c_firstClassWeightLimitedDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('second', form)"
              [initialDate]="form['c_firstClassWeightLimitedDate'].value"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- c_firstClassWeightLimitedNo -->
            <app-input-base
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassWeightLimitedNo' | translate"
              class="input-group-container"
              [formControlNameInput]="'c_firstClassWeightLimitedNo'"
              [placeHolderInput]="'student.createStudent.studentCourseHelicopter.c_firstClassWeightLimitedNo' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('second', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
        </c-row>
        <c-row [@slideInOut]="formGroup.get('c_firstClassWeightLimited')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- c_firstClassWeightLimitedPlace -->
            <app-input-base
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassWeightLimitedPlace' | translate"
              class="input-group-container"
              [formControlNameInput]="'c_firstClassWeightLimitedPlace'"
              [placeHolderInput]="'student.createStudent.studentCourseHelicopter.c_firstClassWeightLimitedPlace' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('second', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- c_firstClassWeightLimitedResponsible -->
            <app-input-base
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassWeightLimitedResponsible' | translate"
              class="input-group-container"
              [formControlNameInput]="'c_firstClassWeightLimitedResponsible'"
              [placeHolderInput]="'student.createStudent.studentCourseHelicopter.c_firstClassWeightLimitedResponsible' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('second', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- c_firstClassWeightLimitedDeliveryDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassWeightLimitedDeliveryDate' | translate"
              [formControlNameInput]="'c_firstClassWeightLimitedDeliveryDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['c_firstClassWeightLimitedDeliveryDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              (changeValueDate)="changeDate($event)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- c_firstClassWeightLimitedSendingDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassWeightLimitedSendingDate' | translate"
              [formControlNameInput]="'c_firstClassWeightLimitedSendingDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['c_firstClassWeightLimitedSendingDate'].value"
              [disabled]="checkTypeOrderCourse('second', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- c_firstClassWeightLimitedExpirationDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_firstClassWeightLimitedExpirationDate' | translate"
              [formControlNameInput]="'c_firstClassWeightLimitedExpirationDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['c_firstClassWeightLimitedExpirationDate'].value"
              [disabled]="true"
              [form]="form">
            </app-input-date-picker>
          </c-col>
        </c-row>

        <!-- c_secondClassBasic -->
        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.studentCourseHelicopter.c_secondClassBasic' | translate"
          [formControlNameInput]="'c_secondClassBasic'"
          [submitted]="submitted"
          [formGroup]="formGroup"
          [disabled]="checkTypeOrderCourse('first', form)"
          (changeValue)="changeCourseTypes('c_secondClassBasic', form)"
          [form]="form">
        </app-input-checkbox>

        <c-row [@slideInOut]="formGroup.get('c_secondClassBasic')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- c_secondClassBasicDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassBasicDate' | translate"
              [formControlNameInput]="'c_secondClassBasicDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('first', form)"
              [initialDate]="form['c_secondClassBasicDate'].value"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- c_secondClassBasicNo -->
            <app-input-base
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassBasicNo' | translate"
              class="input-group-container"
              [formControlNameInput]="'c_secondClassBasicNo'"
              [placeHolderInput]="'student.createStudent.studentCourseHelicopter.c_secondClassBasicNo' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [disabled]="checkTypeOrderCourse('first', form)"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
        </c-row>
        <c-row [@slideInOut]="formGroup.get('c_secondClassBasic')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- c_secondClassBasicPlace -->
            <app-input-base
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassBasicPlace' | translate"
              class="input-group-container"
              [formControlNameInput]="'c_secondClassBasicPlace'"
              [placeHolderInput]="'student.createStudent.studentCourseHelicopter.c_secondClassBasicPlace' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [disabled]="checkTypeOrderCourse('first', form)"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- c_secondClassBasicResponsible -->
            <app-input-base
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassBasicResponsible' | translate"
              class="input-group-container"
              [formControlNameInput]="'c_secondClassBasicResponsible'"
              [placeHolderInput]="'student.createStudent.studentCourseHelicopter.c_secondClassBasicResponsible' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [disabled]="checkTypeOrderCourse('first', form)"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- c_secondClassBasicDeliveryDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassBasicDeliveryDate' | translate"
              [formControlNameInput]="'c_secondClassBasicDeliveryDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['c_secondClassBasicDeliveryDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              (changeValueDate)="changeDate($event)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- c_secondClassBasicSendingDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassBasicSendingDate' | translate"
              [formControlNameInput]="'c_secondClassBasicSendingDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['c_secondClassBasicSendingDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- c_secondClassBasicExpirationDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassBasicExpirationDate' | translate"
              [formControlNameInput]="'c_secondClassBasicExpirationDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [disabled]="true"
              [initialDate]="form['c_secondClassBasicExpirationDate'].value"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
        </c-row>

        <!-- c_secondClassDaytimeOnly -->
        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.studentCourseHelicopter.c_secondClassDaytimeOnly' | translate"
          [formControlNameInput]="'c_secondClassDaytimeOnly'"
          [submitted]="submitted"
          [formGroup]="formGroup"
          [disabled]="checkTypeOrderCourse('first', form)"
          (changeValue)="changeCourseTypes('c_secondClassDaytimeOnly', form)"
          [form]="form">
        </app-input-checkbox>

        <c-row [@slideInOut]="formGroup.get('c_secondClassDaytimeOnly')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- c_secondClassDaytimeOnlyDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassDaytimeOnlyDate' | translate"
              [formControlNameInput]="'c_secondClassDaytimeOnlyDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['c_secondClassDaytimeOnlyDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- c_secondClassDaytimeOnlyNo -->
            <app-input-base
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassDaytimeOnlyNo' | translate"
              class="input-group-container"
              [formControlNameInput]="'c_secondClassDaytimeOnlyNo'"
              [placeHolderInput]="'student.createStudent.studentCourseHelicopter.c_secondClassDaytimeOnlyNo' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('first', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
        </c-row>
        <c-row [@slideInOut]="formGroup.get('c_secondClassDaytimeOnly')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- c_secondClassDaytimeOnlyPlace -->
            <app-input-base
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassDaytimeOnlyPlace' | translate"
              class="input-group-container"
              [formControlNameInput]="'c_secondClassDaytimeOnlyPlace'"
              [placeHolderInput]="'student.createStudent.studentCourseHelicopter.c_secondClassDaytimeOnlyPlace' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('first', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- c_secondClassDaytimeOnlyResponsible -->
            <app-input-base
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassDaytimeOnlyResponsible' | translate"
              class="input-group-container"
              [formControlNameInput]="'c_secondClassDaytimeOnlyResponsible'"
              [placeHolderInput]="'student.createStudent.studentCourseHelicopter.c_secondClassDaytimeOnlyResponsible' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('first', form)"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- c_secondClassDaytimeOnlyDeliveryDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassDaytimeOnlyDeliveryDate' | translate"
              [formControlNameInput]="'c_secondClassDaytimeOnlyDeliveryDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['c_secondClassDaytimeOnlyDeliveryDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              (changeValueDate)="changeDate($event)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- c_secondClassDaytimeOnlySendingDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassDaytimeOnlySendingDate' | translate"
              [formControlNameInput]="'c_secondClassDaytimeOnlySendingDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['c_secondClassDaytimeOnlySendingDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- c_secondClassDaytimeOnlyExpirationDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassDaytimeOnlyExpirationDate' | translate"
              [formControlNameInput]="'c_secondClassDaytimeOnlyExpirationDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [disabled]="true"
              [initialDate]="form['c_secondClassDaytimeOnlyExpirationDate'].value"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
        </c-row>

        <!-- c_secondClassVisualLineOfSight -->
        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.studentCourseHelicopter.c_secondClassVisualLineOfSight' | translate"
          [formControlNameInput]="'c_secondClassVisualLineOfSight'"
          [disabled]="checkTypeOrderCourse('first', form)"
          (changeValue)="changeCourseTypes('c_secondClassVisualLineOfSight', form)"
          [submitted]="submitted"
          [formGroup]="formGroup"
          [form]="form">
        </app-input-checkbox>

        <c-row [@slideInOut]="formGroup.get('c_secondClassVisualLineOfSight')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- c_secondClassVisualLineOfSightDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassVisualLineOfSightDate' | translate"
              [formControlNameInput]="'c_secondClassVisualLineOfSightDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [disabled]="checkTypeOrderCourse('first', form)"
              [initialDate]="form['c_secondClassVisualLineOfSightDate'].value"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- c_secondClassVisualLineOfSightNo -->
            <app-input-base
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassVisualLineOfSightNo' | translate"
              class="input-group-container"
              [formControlNameInput]="'c_secondClassVisualLineOfSightNo'"
              [placeHolderInput]="'student.createStudent.studentCourseHelicopter.c_secondClassVisualLineOfSightNo' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [disabled]="checkTypeOrderCourse('first', form)"
              [form]="form">
            </app-input-base>
          </c-col>
        </c-row>
        <c-row [@slideInOut]="formGroup.get('c_secondClassVisualLineOfSight')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- c_secondClassVisualLineOfSightPlace -->
            <app-input-base
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassVisualLineOfSightPlace' | translate"
              class="input-group-container"
              [formControlNameInput]="'c_secondClassVisualLineOfSightPlace'"
              [placeHolderInput]="'student.createStudent.studentCourseHelicopter.c_secondClassVisualLineOfSightPlace' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [disabled]="checkTypeOrderCourse('first', form)"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- c_secondClassVisualLineOfSightResponsible -->
            <app-input-base
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassVisualLineOfSightResponsible' | translate"
              class="input-group-container"
              [formControlNameInput]="'c_secondClassVisualLineOfSightResponsible'"
              [placeHolderInput]="'student.createStudent.studentCourseHelicopter.c_secondClassVisualLineOfSightResponsible' | translate"
              [inputType]="'text'"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [disabled]="checkTypeOrderCourse('first', form)"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- c_secondClassVisualLineOfSightDeliveryDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassVisualLineOfSightDeliveryDate' | translate"
              [formControlNameInput]="'c_secondClassVisualLineOfSightDeliveryDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['c_secondClassVisualLineOfSightDeliveryDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              (changeValueDate)="changeDate($event)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- c_secondClassVisualLineOfSightSendingDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassVisualLineOfSightSendingDate' | translate"
              [formControlNameInput]="'c_secondClassVisualLineOfSightSendingDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['c_secondClassVisualLineOfSightSendingDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- c_secondClassVisualLineOfSightExpirationDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassVisualLineOfSightExpirationDate' | translate"
              [formControlNameInput]="'c_secondClassVisualLineOfSightExpirationDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [disabled]="true"
              [initialDate]="form['c_secondClassVisualLineOfSightExpirationDate'].value"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
        </c-row>

        <!-- c_secondClassWeightLimited -->
        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.studentCourseHelicopter.c_secondClassWeightLimited' | translate"
          [formControlNameInput]="'c_secondClassWeightLimited'"
          [submitted]="submitted"
          [formGroup]="formGroup"
          [disabled]="checkTypeOrderCourse('first', form)"
          (changeValue)="changeCourseTypes('c_secondClassWeightLimited', form)"
          [form]="form">
        </app-input-checkbox>

        <c-row [@slideInOut]="formGroup.get('c_secondClassWeightLimited')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- c_secondClassWeightLimitedDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassWeightLimitedDate' | translate"
              [formControlNameInput]="'c_secondClassWeightLimitedDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['c_secondClassWeightLimitedDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"

              [form]="form">
            </app-input-date-picker>
          </c-col>

          <c-col [md]="6">
            <!-- c_secondClassWeightLimitedNo -->
            <app-input-base
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassWeightLimitedNo' | translate"
              class="input-group-container"
              [formControlNameInput]="'c_secondClassWeightLimitedNo'"
              [placeHolderInput]="'student.createStudent.studentCourseHelicopter.c_secondClassWeightLimitedNo' | translate"
              [inputType]="'text'"
              [disabled]="checkTypeOrderCourse('first', form)"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
        </c-row>
        <c-row [@slideInOut]="formGroup.get('c_secondClassWeightLimited')?.value ? 'in': 'out'">
          <c-col [md]="6">
            <!-- c_secondClassWeightLimitedPlace -->
            <app-input-base
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassWeightLimitedPlace' | translate"
              class="input-group-container"
              [formControlNameInput]="'c_secondClassWeightLimitedPlace'"
              [placeHolderInput]="'student.createStudent.studentCourseHelicopter.c_secondClassWeightLimitedPlace' | translate"
              [inputType]="'text'"
              [disabled]="checkTypeOrderCourse('first', form)"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- c_secondClassWeightLimitedResponsible -->
            <app-input-base
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassWeightLimitedResponsible' | translate"
              class="input-group-container"
              [formControlNameInput]="'c_secondClassWeightLimitedResponsible'"
              [placeHolderInput]="'student.createStudent.studentCourseHelicopter.c_secondClassWeightLimitedResponsible' | translate"
              [inputType]="'text'"
              [disabled]="checkTypeOrderCourse('first', form)"
              [submitted]="submitted"
              [requiredText]="false"
              [maxLength]="'15'"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- c_secondClassWeightLimitedDeliveryDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassWeightLimitedDeliveryDate' | translate"
              [formControlNameInput]="'c_secondClassWeightLimitedDeliveryDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['c_secondClassWeightLimitedDeliveryDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              (changeValueDate)="changeDate($event)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- c_secondClassWeightLimitedSendingDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassWeightLimitedSendingDate' | translate"
              [formControlNameInput]="'c_secondClassWeightLimitedSendingDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [requiredText]="false"
              [initialDate]="form['c_secondClassWeightLimitedSendingDate'].value"
              [disabled]="checkTypeOrderCourse('first', form)"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- c_secondClassWeightLimitedExpirationDate -->
            <app-input-date-picker
              [label]="'student.createStudent.studentCourseHelicopter.c_secondClassWeightLimitedExpirationDate' | translate"
              [formControlNameInput]="'c_secondClassWeightLimitedExpirationDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [disabled]="true"
              [initialDate]="form['c_secondClassWeightLimitedExpirationDate'].value"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
        </c-row>

      </div>


    </div>
  </div>
</div>
