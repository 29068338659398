import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { cilArrowThickTop, cilArrowThickBottom} from '@coreui/icons';

@Component({
  selector: 'app-instructor-item',
  templateUrl: './instructor-item.component.html',
  styleUrls: ['./instructor-item.component.scss']
})
export class InstructorItemComponent implements OnInit {
  icons = { cilArrowThickTop, cilArrowThickBottom };
  @Input('itemInput') itemInput: any;
  @Input('maxSort') maxSort: any;
  @Input('minSort') minSort: any;
  @Input('haveRegistrationNumber') haveRegistrationNumber: boolean = false;
  
  @Output('deleteItem') deleteItem: EventEmitter<any> = new EventEmitter<any>();
  @Output('upItem') upItem: EventEmitter<any> = new EventEmitter<any>();
  @Output('downItem') downItem: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  public handleDeleteItem(): void {
    this.deleteItem.emit({id: this.itemInput.id});
  }

  public clickUpItem() {
    this.upItem.emit({id: this.itemInput.id, sort: this.itemInput.sort, type: 'up'});
  }

  public clickDownItem() {
    this.downItem.emit({id: this.itemInput.id, sort: this.itemInput.sort, type: 'down'});
  }
}