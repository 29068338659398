<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <div class="custom-container">
    <app-breadcrumb [items]="breadcrumbItems"></app-breadcrumb>
    <c-col class="justify-content-center mb-4">
      <div class="d-flex justify-content-between align-items-center box-title">
        <h3>{{ 'student.editStudent.title' | translate }}</h3>
      </div>
    </c-col>
    <c-card class="card-no-border account-card">
      <div class="edit_student">
        <form [formGroup]="formEditStudent" novalidate>
          <c-nav variant="underline">
            <c-nav-item>
              <a [active]="true" [cTabContent]="tabContent" [routerLink] [tabPaneIdx]="0" cNavLink [class.tab-invalid]="checkValidTabBasic()">
                {{ "formCreateUpdateStudent.tabName.basic" | translate }}
              </a>
            </c-nav-item>
            <c-nav-item>
              <a [cTabContent]="tabContent" [routerLink] [tabPaneIdx]="1" cNavLink [class.tab-invalid]="checkValidConfirmation()">
                {{ "formCreateUpdateStudent.tabName.confirmation" | translate }}
              </a>
            </c-nav-item>

            <c-nav-item>
              <a [cTabContent]="tabContent" [routerLink] [tabPaneIdx]="2" cNavLink  [class.tab-invalid]="checkValidMulti()">
                {{ "formCreateUpdateStudent.tabName.course_multi" | translate }}
              </a>
            </c-nav-item>

            <c-nav-item>
              <a [cTabContent]="tabContent" [routerLink] [tabPaneIdx]="3" cNavLink  [class.tab-invalid]="checkValidAirplane()">
                {{ "formCreateUpdateStudent.tabName.course_airplane" | translate }}
              </a>
            </c-nav-item>

            <!-- <c-nav-item>
              <a [cTabContent]="tabContent" [routerLink] [tabPaneIdx]="4" cNavLink  [class.tab-invalid]="checkValidHelicopter()">
                {{ "formCreateUpdateStudent.tabName.course_helicopter" | translate }}
              </a>
            </c-nav-item> -->

          </c-nav>
          <c-tab-content #tabContent="cTabContent" [activeTabPaneIdx]="0">
            <c-tab-pane class="p-3">
              <!-- tab basic -->
              <app-student-basic
                [formGroup]="formEditStudent"
                [submitted]="submitted"
                [form]="f"
                [imageUrl]="imageUrl"
                [eLearnings]="learning"
                [learningStatus]="learningStatus"
                [courseItems]="courseItems"
                [studentId]="id"
                (fileEvent)="onDataReceived($event)">
              </app-student-basic>
            </c-tab-pane>
            <c-tab-pane class="p-3">
              <!-- tab confirmation -->
              <app-student-confirmation
                [formGroup]="formEditStudent"
                [submitted]="submitted"
                [isEdit]="false"
                [student]="student"
                (changeEtcTextCheckbox)="changeEtcTextCheckbox()"
                [form]="f">
              </app-student-confirmation>
            </c-tab-pane>
            <c-tab-pane class="p-3">
              <!-- tab student course multi -->
              <app-student-course-multi
                [formGroup]="formEditStudent"
                [submitted]="submitted"
                [isEdit]="false"
                [form]="f"
              >
              </app-student-course-multi>
            </c-tab-pane>
            <c-tab-pane class="p-3">
              <!-- tab student course airplane -->
              <app-student-course-airplane
                [formGroup]="formEditStudent"
                [submitted]="submitted"
                [isEdit]="false"
                [form]="f"
              >
              </app-student-course-airplane>
            </c-tab-pane>
            <c-tab-pane class="p-3">
              <!-- tab student course helicopter -->
              <app-student-course-helicopter
                [formGroup]="formEditStudent"
                [submitted]="submitted"
                [isEdit]="false"
                [form]="f"
              >
              </app-student-course-helicopter>
            </c-tab-pane>

          </c-tab-content>

          <div class="mb-3 d-flex justify-content-end">
            <button class="back-btn" (click)="backToListStudent()">
              {{ "form.backButton" | translate }}
            </button>
            <button cButton type="submit" class="btn-submit" (click)="submit()">
              {{ "form.inputLabel.save" | translate }}
            </button>
          </div>
        </form>
      </div>
    </c-card>
  </div>
  <app-loading [isLoading]="isLoading"></app-loading>
</c-container>
