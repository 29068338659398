export interface IStatus {
  name: string,
  value: string,
}

export const StatusAttendances: IStatus[] = [
  {
    name: "全て",
    value: "all",
  },
  {
    name: "講習のみ",
    value: "lectureOnly",
  },
  {
    name: "試験",
    value: "exam",
  },
];
