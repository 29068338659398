import { NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { getFirstError } from '@app/helpers';
import { ValidationFormsService } from '@app/services';

@Component({
  selector: 'app-input-checkbox-radio',
  templateUrl: './input-checkbox-radio.component.html',
  styleUrls: ['./input-checkbox-radio.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class InputCheckboxRadioComponent implements OnInit {
  getFirstError = getFirstError;
  @Input('form') form: any = [];
  @Input('dataOption') dataOption: any = [];
  @Input('label') label: string = '';
  @Input('ngClass') ngClass: NgClass['ngClass'];
  @Input('formControlNameInput') formControlNameInput: string = '';
  @Input('submitted') submitted: boolean = false;
  @Input('requiredText') requiredText: boolean = true;

  constructor(private validationFormService: ValidationFormsService) {}

  ngOnInit(): void {}
}
