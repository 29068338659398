import { Component, Input, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { getFirstError } from '@app/helpers';
import { ValidationFormsService } from '@app/services';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class TextAreaComponent implements OnInit {
  getFirstError = getFirstError;
  @Input('form') form: any = [];
  @Input('label') label: string = '';
  @Input('ngClass') ngClass: NgClass['ngClass'];
  @Input('formControlNameInput') formControlNameInput: string = '';
  @Input('placeHolderInput') placeHolderInput: string = '';
  @Input('inputType') inputType: string = '';
  @Input('maxLength') maxLength: string = '';
  @Input('minLength') minLength: string = '';
  @Input('submitted') submitted: boolean = false;
  @Input('requiredText') requiredText: boolean = true;
  @Input('disabled') disabled: boolean = false;
  constructor(private validationFormService: ValidationFormsService) {}

  ngOnInit(): void {}

  get errorMessages() {
    return this.validationFormService.errorMessages;
  }
}
