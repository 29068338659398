import { Component } from '@angular/core';
import {
  AlertService,
  UserService,
  ValidationFormsService,
  FirebaseAnalyticService,
  AuthService,
  StateService
} from "@app/services";
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent {
  public currentUser: any;
  protected checkPass: any;
  protected formUpdateAccount!: FormGroup;
  protected submitted: boolean = false;
  protected formErrors: any;
  protected email: string = '';
  protected id: string = '';
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    public angularFireAuth: AngularFireAuth,
    private alertService: AlertService,
    public vf: ValidationFormsService,
    private analytics: AngularFireAnalytics,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private translateService: TranslateService,
    private stateService: StateService
  ) {}

  ngOnInit() {
    this.analytics.logEvent(this.firebaseAnalyticService.EVENT_NAME.TAB_ACCOUNT, {
      'content_type': this.firebaseAnalyticService.CONTENT_TYPE,
      'develop_type': this.firebaseAnalyticService.TYPE,
    });
    this.initForm();
    this.getUser();
    this.formErrors = this.vf.errorMessages;
  }
  initForm() {
    this.formUpdateAccount = new FormGroup<any>({
      name: new FormControl('', [Validators.maxLength(this.vf.formRules.nameMax), Validators.required]),
      dips: new FormControl('', [Validators.maxLength(this.vf.formRules.DIPSMax)]),
      memberCode: new FormControl('', [Validators.maxLength(this.vf.formRules.memberCode), Validators.required]),
      branch: new FormControl('', [Validators.maxLength(this.vf.formRules.branch), Validators.required]),
      address: new FormControl('', [Validators.maxLength(this.vf.formRules.address)]),
      phoneNumber: new FormControl('', [
        Validators.pattern(this.vf.formRules.phoneNumber),
        Validators.minLength(this.vf.formRules.minPhoneNumber),
        Validators.maxLength(this.vf.formRules.maxPhoneNumber),
      ]),
      personName: new FormControl('', [Validators.maxLength(this.vf.formRules.personName), Validators.required]),
    });
  }
  getUser() {
    this.getCurrentUser().then(() => {
      this.authService.getUserInDBByEmail(this.email).subscribe((response: any) => {
        this.currentUser = response[0].payload.doc.data();
        this.formUpdateAccount.controls['name'].setValue(this.currentUser.name ?? '');
        this.formUpdateAccount.controls['dips'].setValue(this.currentUser.dips ?? '');
        this.formUpdateAccount.controls['memberCode'].setValue(this.currentUser.memberCode ?? '');
        this.formUpdateAccount.controls['branch'].setValue(this.currentUser.brandName ?? '');
        this.formUpdateAccount.controls['address'].setValue(this.currentUser.address ?? '');
        this.formUpdateAccount.controls['phoneNumber'].setValue(this.currentUser.phoneNumber ?? '');
        this.formUpdateAccount.controls['personName'].setValue(this.currentUser.displayName ?? '');
      })
    }).catch((error) => {
      console.log(error);
    });
  }

  redirectToInstructorScreen() {
    this.router.navigate(['/account/instructor']);
  }

  redirectToAircraftUsedScreen() {
    this.router.navigate(['/account/aircraft-used']);
  }

  logout() {
    this.authService.logOut().then((res: any) => {
      this.analytics.logEvent(this.firebaseAnalyticService.EVENT_NAME.LOGOUT, {
        'content_type': this.firebaseAnalyticService.CONTENT_TYPE,
        'develop_type': this.firebaseAnalyticService.TYPE,
      });
      this.router.navigate(['login']);
    }).catch((error: any) => {
      console.log('Something is wrong:', error.message);
    });
  }

  async getCurrentUser() {
    const currentUser = await this.angularFireAuth.currentUser;
    if (currentUser) {
      if (currentUser.email != null) {
        this.email = currentUser?.email;
      }

      this.id = await this.userService.getDocId(this.email);
      await this.emailValidate(currentUser?.email);
    }
  }

  async emailValidate(email: any) {
    const user = await this.angularFireAuth.fetchSignInMethodsForEmail(email);
    if (user) {
      user.forEach((value) => {
        if (value == 'password') this.checkPass = true;
      });
    }
  }

  get attribute() {
    return this.formUpdateAccount.controls;
  }

  onValidate() {
    this.submitted = true;
    return this.formUpdateAccount.status === 'VALID';
  }

  updateAccount() {
    this.alertService.clear();
    if (this.onValidate()) {
      try {
        const userUpdate = {
          name: this.formUpdateAccount.value.name,
          dips: this.formUpdateAccount.value.dips,
          email: this.email,
          memberCode: this.formUpdateAccount.value.memberCode,
          branch: this.formUpdateAccount.value.branch,
          address: this.formUpdateAccount.value.address,
          phoneNumber: this.formUpdateAccount.value.phoneNumber,
          personName: this.formUpdateAccount.value.personName,
        }
        this.userService.updateAccount(userUpdate).then(res => {
          this.analytics.logEvent(this.firebaseAnalyticService.EVENT_NAME.SAVE_INFO_USER, {
            'content_type': this.firebaseAnalyticService.CONTENT_TYPE,
            'develop_type': this.firebaseAnalyticService.TYPE,
          });
          this.alertService.success(this.translateService.instant('alertMessages.updateAccount.success'));
        });
      } catch (e) {
        console.log(e);
        this.alertService.error(this.translateService.instant('alertMessages.updateAccount.fail'));
      }
    }
  }
}
