import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-modal-delete-common',
  templateUrl: './modal-delete-common.component.html',
  styleUrls: ['./modal-delete-common.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class ModalDeleteCommonComponent implements OnInit {
  @Input() deleteId: string = '';
  @Input() titleDelete: string = '';
  @Output() handleDeleteCommon = new EventEmitter<any>();
  protected title: string = '';

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.title = this.titleDelete;
    this.cdr.detectChanges();
  }

  ngOnInit(): void {}

  async handleDelete() {
    this.handleDeleteCommon.emit(true);
  }
}
