import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { ValidationFormsService, AlertService } from '@app/services';
import { convertMbToB, getFirstError } from '@app/helpers';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-input-file-image-upload',
  templateUrl: './input-file-image-upload.component.html',
  styleUrls: ['./input-file-image-upload.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class InputFileImageUploadComponent implements OnInit, OnChanges {
  getFirstError = getFirstError;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @Input('form') form: any = [];
  @Input('label') label: string = '';
  @Input('ngClass') ngClass: NgClass['ngClass'];
  @Input('formControlNameInput') formControlNameInput: string = '';
  @Input('placeHolderInput') placeHolderInput: string = '';
  @Input('maxLength') maxLength: string = '';
  @Input('submitted') submitted: boolean = false;
  @Input('requiredText') requiredText: boolean = false;
  @Input('accept') accept: string = '';
  @Input('image') image: string = '';
  @Output() fileEvent = new EventEmitter<string>();
  protected imageUrl: string = '';

  constructor(
    private validationFormService: ValidationFormsService,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes['image']) this.imageUrl = changes['image'].currentValue;
  }

  get errorMessages() {
    return this.validationFormService.errorMessages;
  }

  onFileSelected(event: any): void {
    let allImages: Array<string> = ['image/png', 'image/jpg', 'image/jpeg'];
    const file = event.target.files[0];
    if (file == undefined) return;

    if (!allImages.includes(file.type)) {
      this.alertService.error(this.translateService.instant('common.upload_image.error_type'));
      return;
    }

    if (file && file.size > convertMbToB(10)) {
      this.alertService.error(this.translateService.instant('common.upload_image.invalid_max_file'));
      return;
    }

    const reader = new FileReader();
    reader.onload = () => (this.imageUrl = reader.result as string);
    reader.readAsDataURL(file);
    this.fileEvent.emit(file);
  }

  showFileInput(): void {
    this.fileInput.nativeElement.click();
  }
}
