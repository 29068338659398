import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationFormsService {

  errorMessages: any;

  formRules = {
    nonEmpty: '^[a-zA-Z0-9]+([_ -]?[a-zA-Z0-9])*$',
    passwordMin: 6,
    textMax50: 50,
    textMax30: 30,
    passwordPattern: '^(?=.*[A-Z])(?=.*[a-z])(?=.*[\\d])([A-Za-z\\d]{8,32})$',
    emailPattern: '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    nameMax: 255,
    registrationNumberMax: 255,
    licenseNumberMax: 255,
    DIPSMax: 255,
    memberCode: 255,
    branch: 255,
    address: 255,
    phoneNumberMax: 255,
    personName: 255,
    numberMax: 17,
    onlyNumber: '^[0-9]+$',
    phoneNumber: '^0[0-9]{8,16}$',
    minPhoneNumber: 8,
    maxPhoneNumber: 16,
    regexJa: '[ぁ-ゔゞァ-・ヽヾ゛゜ー 　]+$',
    regexJaSearch: '[ぁ-んァ-ン]+$',
    regexCertificateNumber: '^[a-zA-Z0-9]*$',
  };

  formErrors = {
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    accept: false,
  };

  constructor() {
    this.errorMessages = {
      common: {
        required: '内容をご入力ください。',
        maxLength255: '1文字以上255文字以下でご入力ください。',
        maxLength17: '17桁以内の半角英数字でご入力ください。',
        onlyNumber: '半角英数字を入力してください。',
      },
      firstName: {
        required: 'First name is required',
      },
      lastName: {
        required: 'Last name is required',
      },
      email: {
        required: 'メールアドレスをご入力ください。',
        email: '正しい形式のメールアドレスをご入力ください。',
        inCorrect: '正しい形式のメールアドレスをご入力ください。',
      },
      password: {
        required: 'パスワードをご入力ください。',
        pattern: ' 半角大小英数字をそれぞれ1種類以上含む8文字以上32文字以下でご入力ください。',
        minLength: `Password must be at least ${this.formRules.passwordMin} characters`
      },
      confirmPassword: {
        required: '確認用のパスワードをご入力ください。',
        passwordMismatch: '入力されたパスワードが新しいパスワードと一致しません。もう一度ご入力ください。'
      },
      accept: {
        requiredTrue: 'You have to accept our Terms and Conditions'
      },
      cardNumber: {
        required: '内容をご入力ください。'
      },
      cardCVC: {
        required: '内容をご入力ください。'
      },
      exp_month: {
        required: '内容をご入力ください。'
      },
      exp_year: {
        required: '内容をご入力ください。'
      },
      name: {
        maxLength: `名前は${this.formRules.nameMax}文字以内でご入力ください。`,
        required: '内容をご入力ください。'
      },
      registrationNumber: {
        maxLength: `登録番号は${this.formRules.registrationNumberMax}数字以内でご入力ください。`,
        required: '機体登録番号をご入力ください。',
        maxlength: `${this.formRules.textMax30}文字以内でご入力してください`,
      },
      licenseNumber: {
        maxLength: `免許番号は${this.formRules.licenseNumberMax}半角英数字以内でご入力ください。`,
      },
      dips: {
        maxLength: `DIPS番号は${this.formRules.licenseNumberMax}半角英数字以内でご入力ください。`,
      },
      serialNo: {
        uniqued: "このシリアルNOはすでに保存されています。"
      },
      formCreate: {
        member: {
          max: '1以上で入力してください。',
          min: '{max}以下を入力してください。'
        }
      },
      itemName: {
        required: '項目名をご入力ください。',
        maxlength: `${this.formRules.textMax50}文字以内でご入力してください`,
      },
    };
  }
}
