// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAL0-wjpq9dFsIQHE4-gX23qHNp5h6hlbk",
    authDomain: "unlc-studentrecord.firebaseapp.com",
    projectId: "unlc-studentrecord",
    storageBucket: "unlc-studentrecord.appspot.com",
    messagingSenderId: "653804771461",
    appId: "1:653804771461:web:7108c902a561d4e58fb1ec",
    measurementId: "G-HGDQCXG7BJ"
  },
  register_url_stripe:"https://us-central1-unlc-studentrecord.cloudfunctions.net/api/stripe",
  url_stripe_subscription:"https://us-central1-unlc-studentrecord.cloudfunctions.net/api/stripe-subscription",
  url_notification:"https://fcm.googleapis.com/fcm/send",
  key_notification:"key=AAAAkkkyPC8:APA91bEBxTRwCj2wCXQYdT77VLhXFcDuvy8MzgUAUHYirBh-Xx5iw3sYX2zLaZTdl8xwYzoABRlEe6UqR1PfldfCiqQCilWs5LCT99ct080wjC6CbvfnUDiZ1RLPRZEj6qxyvUVlmj2Y",
  baseUrl:'https://us-central1-unlc-studentrecord.cloudfunctions.net/api',
  urlUpdateDisplayName:'https://us-central1-unlc-studentrecord.cloudfunctions.net/api/users/update-display-name',
  api_address: 'https://mreversegeocoder.gsi.go.jp/reverse-geocoder/LonLatToAddress',
  api_search_map:'https://msearch.gsi.go.jp/address-search/AddressSearch',
  DOMAIN_URL:'https://branchschool.unlc.jp/'
};
