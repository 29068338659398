import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
})
export class PaginationComponent implements OnInit {
  currentPage: number = 1;
  @Output() handleChangePage = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  changePage = (page: number) => {
    this.currentPage = page;
    this.handleChangePage.emit(page);
  };
}
