<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <div class="custom-container">
    <app-breadcrumb [items]="breadcrumbItems"></app-breadcrumb>

    <c-col class="justify-content-center mb-5">
      <div class="d-flex justify-content-between align-items-center box-title">
        <h3 *ngIf="id">{{ 'student.completionRecord.update.title' | translate }}</h3>
        <h3 *ngIf="!id">{{ 'student.completionRecord.create.title' | translate }}</h3>
      </div>
    </c-col>

    <c-card class="card-no-border account-card">
      <div class="create-completion-record">
        <form [formGroup]="formCreateCompletionRecord" novalidate>
          <div>
            <div class="form_item">
              <div class="form_item--title">{{ 'student.completionRecord.create.pass_judgment' | translate }}</div>
              <div class="form_item--content">
                <!-- examDate -->
                <app-input-date-picker
                  [label]="'student.completionRecord.form.examDate' | translate"
                  [formGroup]="formCreateCompletionRecord"
                  [form]="f"
                  [formControlNameInput]="'examDate'"
                  [submitted]="submitted"
                  [requiredText]="true"
                >
                </app-input-date-picker>

                <!-- examName -->
                <app-input-base-with-button
                  [label]="'student.completionRecord.form.examName' | translate"
                  class="input-group-container"
                  [formControlNameInput]="'examName'"
                  [placeHolderInput]="'student.completionRecord.form.examName' | translate"
                  [inputType]="'text'"
                  [submitted]="submitted"
                  [maxLength]="'254'"
                  [requiredText]="true"
                  [form]="f"
                  [inputGroupText]="'...'"
                  [idModal]="'leaderNameModal'"
                  [titleModal]="'student.completionRecord.form.examNameModal' | translate"
                  [items]="leaderNameItems"
                  (addItem)="handleAddItems($event, 'examName')"
                ></app-input-base-with-button>

                <!-- airframeTitle -->
                <app-input-base-with-button
                  [label]="'student.completionRecord.form.airframeTitle' | translate"
                  class="input-group-container"
                  [formControlNameInput]="'airframeTitle'"
                  [placeHolderInput]="'student.completionRecord.form.airframeTitle' | translate"
                  [inputType]="'text'"
                  [submitted]="submitted"
                  [maxLength]="'254'"
                  [requiredText]="false"
                  [form]="f"
                  [inputGroupText]="'...'"
                  [idModal]="'airframeTitleModal'"
                  [titleModal]="'student.completionRecord.form.airframeTitleModal' | translate"
                  [items]="airframeTitleItems"
                  (addItem)="handleAddairframeTitleItems($event)"
                  [splitColumnModal]="true"
                >
                </app-input-base-with-button>

                <!-- airframeNumber -->
                <app-input-base
                  [label]="'student.completionRecord.form.airframeNumber' | translate"
                  class="input-group-container"
                  [formControlNameInput]="'airframeNumber'"
                  [placeHolderInput]="'student.completionRecord.form.airframeNumber' | translate"
                  [inputType]="'text'"
                  [submitted]="submitted"
                  [maxLength]="'254'"
                  [requiredText]="false"
                  [form]="f">
                </app-input-base>

                <!-- subject1 -->
                <app-input-base
                  [label]="'student.completionRecord.form.subject1' | translate"
                  class="input-group-container"
                  [formControlNameInput]="'subject1'"
                  [placeHolderInput]="'student.completionRecord.form.subject1' | translate"
                  [inputType]="'number'"
                  [submitted]="submitted"
                  [max]="'0'"
                  [min]="'-100'"
                  [requiredText]="false"
                  [form]="f"
                  (onChange)="changeScore($event)"
                >
                </app-input-base>

                <!-- subject2 -->
                <app-input-base
                  [label]="'student.completionRecord.form.subject2' | translate"
                  class="input-group-container"
                  [formControlNameInput]="'subject2'"
                  [placeHolderInput]="'student.completionRecord.form.subject2' | translate"
                  [inputType]="'number'"
                  [submitted]="submitted"
                  [max]="'0'"
                  [min]="'-100'"
                  [requiredText]="false"
                  [form]="f"
                  (onChange)="changeScore($event)"
                >
                </app-input-base>

                <c-row>
                  <c-col [md]="6">
                    <!-- subject3_1_title -->
                    <app-input-base
                      [label]="'student.completionRecord.form.subject3_1_title' | translate"
                      class="input-group-container"
                      [formControlNameInput]="'subject3_1_title'"
                      [placeHolderInput]="'student.completionRecord.form.subject3_1_title' | translate"
                      [inputType]="'text'"
                      [submitted]="submitted"
                      [maxLength]="'127'"
                      [requiredText]="false"
                      [form]="f">
                    </app-input-base>
                  </c-col>
                  <c-col [md]="6">
                    <!-- subject3_1 -->
                    <app-input-base
                      [label]="'student.completionRecord.form.subject3_1' | translate"
                      class="input-group-container"
                      [formControlNameInput]="'subject3_1'"
                      [placeHolderInput]="'student.completionRecord.form.subject3_1' | translate"
                      [inputType]="'number'"
                      [submitted]="submitted"
                      [max]="'0'"
                      [min]="'-100'"
                      [requiredText]="false"
                      [form]="f"
                      (onChange)="changeScore($event)"
                    >
                    </app-input-base>
                  </c-col>
                </c-row>

                <c-row>
                  <c-col [md]="6">
                    <!-- subject3_2_title -->
                    <app-input-base
                      [label]="'student.completionRecord.form.subject3_2_title' | translate"
                      class="input-group-container"
                      [formControlNameInput]="'subject3_2_title'"
                      [placeHolderInput]="'student.completionRecord.form.subject3_2_title' | translate"
                      [inputType]="'text'"
                      [submitted]="submitted"
                      [maxLength]="'127'"
                      [requiredText]="false"
                      [form]="f">
                    </app-input-base>
                  </c-col>
                  <c-col [md]="6">
                    <!-- subject3_2 -->
                    <app-input-base
                      [label]="'student.completionRecord.form.subject3_2' | translate"
                      class="input-group-container"
                      [formControlNameInput]="'subject3_2'"
                      [placeHolderInput]="'student.completionRecord.form.subject3_2' | translate"
                      [inputType]="'number'"
                      [submitted]="submitted"
                      [requiredText]="false"
                      [max]="'0'"
                      [min]="'-100'"
                      [form]="f"
                      (onChange)="changeScore($event)"
                    >
                    </app-input-base>
                  </c-col>
                </c-row>

                <c-row>
                  <c-col [md]="6">
                    <!-- subject3_3_title -->
                    <app-input-base
                      [label]="'student.completionRecord.form.subject3_3_title' | translate"
                      class="input-group-container"
                      [formControlNameInput]="'subject3_3_title'"
                      [placeHolderInput]="'student.completionRecord.form.subject3_3_title' | translate"
                      [inputType]="'text'"
                      [submitted]="submitted"
                      [maxLength]="'127'"
                      [requiredText]="false"
                      [form]="f">
                    </app-input-base>
                  </c-col>
                  <c-col [md]="6">
                    <!-- subject3_3 -->
                    <app-input-base
                      [label]="'student.completionRecord.form.subject3_3' | translate"
                      class="input-group-container"
                      [formControlNameInput]="'subject3_3'"
                      [placeHolderInput]="'student.completionRecord.form.subject3_3' | translate"
                      [inputType]="'number'"
                      [submitted]="submitted"
                      [requiredText]="false"
                      [max]="'0'"
                      [min]="'-100'"
                      [form]="f"
                      (onChange)="changeScore($event)"
                    >
                    </app-input-base>
                  </c-col>
                </c-row>

                <!-- subject4 -->
                <app-input-base
                  [label]="'student.completionRecord.form.subject4' | translate"
                  class="input-group-container"
                  [formControlNameInput]="'subject4'"
                  [placeHolderInput]="'student.completionRecord.form.subject4' | translate"
                  [inputType]="'number'"
                  [submitted]="submitted"
                  [max]="'0'"
                  [min]="'-100'"
                  [requiredText]="false"
                  [form]="f"
                  (onChange)="changeScore($event)"
                >
                </app-input-base>

                <!-- subject5 -->
                <app-input-base
                  [label]="'student.completionRecord.form.subject5' | translate"
                  class="input-group-container"
                  [formControlNameInput]="'subject5'"
                  [placeHolderInput]="'student.completionRecord.form.subject5' | translate"
                  [inputType]="'number'"
                  [submitted]="submitted"
                  [max]="'0'"
                  [min]="'-100'"
                  [requiredText]="false"
                  [form]="f"
                  (onChange)="changeScore($event)"
                >
                </app-input-base>

                <!-- subject6_1 -->
                <app-input-base
                  [label]="'student.completionRecord.form.subject6_1' | translate"
                  class="input-group-container"
                  [formControlNameInput]="'subject6_1'"
                  [placeHolderInput]="'student.completionRecord.form.subject6_1' | translate"
                  [inputType]="'number'"
                  [submitted]="submitted"
                  [requiredText]="false"
                  [max]="'0'"
                  [min]="'-100'"
                  [form]="f"
                  (onChange)="changeScore($event)"
                >
                </app-input-base>

                <!-- subject6_2 -->
                <app-input-base
                  [label]="'student.completionRecord.form.subject6_2' | translate"
                  class="input-group-container"
                  [formControlNameInput]="'subject6_2'"
                  [placeHolderInput]="'student.completionRecord.form.subject6_2' | translate"
                  [inputType]="'number'"
                  [submitted]="submitted"
                  [requiredText]="false"
                  [max]="'0'"
                  [min]="'-100'"
                  [form]="f"
                  (onChange)="changeScore($event)"
                >
                </app-input-base>

                <!-- remark -->
                <app-text-area
                  [label]="'student.completionRecord.form.remark' | translate"
                  class="input-group-container"
                  [formControlNameInput]="'remark'"
                  [placeHolderInput]="'student.completionRecord.form.remark' | translate"
                  [inputType]="'text'"
                  [submitted]="submitted"
                  [requiredText]="false"
                  [maxLength]="'1023'"
                  [form]="f">

                </app-text-area>

              </div>
            </div>
            <div class="form_item">
              <div class="form_item--title">{{ 'student.completionRecord.create.pass_judgment' | translate }}</div>
              <div class="form_item--content">
                <!-- score -->
                <app-input-base
                  [label]="'student.completionRecord.form.score' | translate"
                  class="input-group-container"
                  [formControlNameInput]="'score'"
                  [placeHolderInput]="'student.completionRecord.form.score' | translate"
                  [inputType]="'number'"
                  [requiredText]="false"
                  [disabled]="true"
                  [form]="f">
                </app-input-base>

                <!-- examPass -->
                <app-input-radio
                  [label]="'student.completionRecord.form.examPass' | translate"
                  [formControlNameInput]="'examPass'"
                  [dataOption]="examPass"
                  [submitted]="submitted"
                  [form]="f">
                </app-input-radio>
              </div>
            </div>
          </div>
          <div class="mb-3 d-flex justify-content-end">
            <button class="back-btn btn" [class.mr-0]="currentUser?.uid !== userId" (click)="redirectBack()">
              {{ "form.backButton" | translate }}
            </button>
            <button *ngIf="currentUser?.uid === userId" cButton type="submit" class="btn-submit" (click)="submit()">
              {{ (id ? "form.inputLabel.save" : "form.inputLabel.submit") | translate }}
            </button>
          </div>
        </form>
      </div>
    </c-card>
  </div>
</c-container>
