import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  constructor(private firestore: AngularFirestore) {}

  async getLeftMenuSetting() {
    return this.firestore.collection('system_config').snapshotChanges();
  }

  async getTimeSetting() {
    return this.firestore.collection('time_settings').snapshotChanges();
  }
}
