import { Injectable } from '@angular/core';
import { INotification } from '@app/models';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private db: AngularFirestore) { }

  // Create notification
  async create(Notification: INotification) {
    await this.db.collection('messages').add({
      messageTitle: Notification.messageTitle,
      messageBody: Notification.messageBody,
      sendAll: Notification.sendAll,
      user_uid: Notification.user_uid,
    });
  }

  getAll(email: string, id: string) {
    return this.db
      .collection('users', (ref) => ref.where('email', '==', email))
      .doc(id)
      .collection('messages', (ref) => ref.where('isDelete', '==', false).orderBy('timePushMessage', 'desc'))
      .snapshotChanges();
  }

  getNotification(email: string, id: string) {
    return this.db
      .collection('users', (ref) => ref.where('email', '==', email))
      .doc(id)
      .collection('messages', (ref) =>
        ref.where('isDelete', '==', false)
          .orderBy('timePushMessage', 'desc')
          .limit(5)
      )
      .snapshotChanges();
  }
  getNotificationEvent(email: string, id: string) {
    return this.db.collection('users/' + id + '/messages').snapshotChanges();
  }

  delete(email: string, id: string, messageId: string | undefined) {
    return this.db.collection('users', (ref) => ref.where('email', '==', email))
      .doc(id)
      .collection('messages')
      .doc(messageId)
      .update({ isDelete: true });
  }

  readMark(email: string, id: string, messageId: string | undefined) {
    return this.db.collection('users', (ref) => ref.where('email', '==', email))
      .doc(id)
      .collection('messages')
      .doc(messageId)
      .update({ isRead: true });
  }

  nextPage(email: string, id: string, currentLastDocumentTime: any) {
    return this.db
      .collection('users', (ref) => ref.where('email', '==', email)).doc(id)
      .collection('messages', (ref) =>
        ref.where('isDelete', '==', false)
          .orderBy('timePushMessage', 'desc')
          .startAfter(currentLastDocumentTime)
          .limit(10)
      ).get();
  }

  timeSince(date: any): string {
    const time: any = new Date();
    const seconds = Math.floor((time - date) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + '年前';
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + 'ヶ月前';
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + '日前';
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + '時間前';
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + '分前';
    }
    return '今';
  }
}
