<c-container class="min-vh-90">
  <c-row class="justify-content-center">
    <c-col md="6">
      <span class="clearfix">
        <h1 class="float-start display-3 me-4">Privacy Policy</h1>
        <h4 class="pt-3">プライバシーポリシー</h4>
        <p class="text-medium-emphasis float-start">
          個人情報保護に関する方針（プライバシー・ポリシー）について
        </p>
        <p class="text-medium-emphasis float-start">
          　当社は、お取引に伴いお客様の個人情報をいただいております。以下の記述は、個人情報保護法の規定に従い、当社との取引等に伴い当社が入手する個人情報の入手目的、取り扱い等についてご説明するものです。
        </p>
        <p class="text-medium-emphasis float-start">
          本プライバシー·ポリシーの目的
        </p>
        <p class="text-medium-emphasis float-start">
          　本プライバシー・ポリシーの目的は、株式会社タクト（以下、「当社」と呼びます）のウェブサイトなどにおける個人情報の取り扱いに関する方針を説明し、当社によって収集された個人情報の取り扱い方法に関してお客様の選択肢について情報を提供することです。
        </p>
        <p class="text-medium-emphasis float-start">
          　なお、当社が他のビジネスパートナーとともにコンテンツを提供したウェブサイトなど、当社が独自で提供していないウェブサイトなどにおいては、追加的なプライバシー・ポリシーや別のプライバシー・ポリシーに準拠することにご注意ください。
        </p>
        <p class="text-medium-emphasis float-start">
          個人情報に関する当社の方針
        </p>
        <p class="text-medium-emphasis float-start">
          　当社にとって、お客様の個人情報の保護は重要な問題です。お客様の個人情報を保護するために、当社は、個人情報に関する当社の慣行とお客様の個人情報を収集し使用し開示する方法に関するお客様の選択肢を説明する本プライバシー・ポリシーを作成しました。お客様の目にとまるように、当社のホームページの他、個人情報を求められる場所には本プライバシー・ポリシーを用意します。
        </p>
        <p class="text-medium-emphasis float-start">
          当社が収集する情報
        </p>
        <p class="text-medium-emphasis float-start">

          　本プライバシー・ポリシーは、個人情報を始めとする当社が収集したすべての情報や当社に提供されたすべての情報に適用されます。「個人情報」とは、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含む）、及び、生存する個人に関する情報であって、個人識別符号を含むものをいいます。
        </p>
        <p class="text-medium-emphasis float-start">
          　当社では、以下の場合に個人情報を収集します。
        </p>
        <p class="text-medium-emphasis float-start">
          お客様が当社のウェブサイトや当社が提供する各種サービスのメンバーになるためにユーザー・アカウントを作成する場合
        </p>
        <p class="text-medium-emphasis float-start">
          お客様がオンラインで当社製品・サービスを購入する場合
        </p>
        <p class="text-medium-emphasis float-start">
          お客様がユーザー登録する場合
        </p>
        <p class="text-medium-emphasis float-start">
          お客様が当社製品・サービスのサポートを請求する場合
        </p>
        <p class="text-medium-emphasis float-start">
          お客様が当社の情報や資料（ホワイトペーパーなど）を請求する場合
        </p>
        <p class="text-medium-emphasis float-start">
          お客様が当社の調査と評価に参加する場合
        </p>
        <p class="text-medium-emphasis float-start">
          お客様が当社のプロモーションやコンテストや懸賞に参加する場合
        </p>
        <p class="text-medium-emphasis float-start">
          お客様が当社に対して問い合せやコメントをする場合
        </p>
        <p class="text-medium-emphasis float-start">
          お客様が当社に対して就業を希望して応募した場合
        </p>
        <p class="text-medium-emphasis float-start">
          　当社は、会議、トレードショー、展示会でも個人の同意を得た上で個人情報を収集することがあります。
        </p>
        <p class="text-medium-emphasis float-start">
          　収集する個人情報の種類には以下のものが含まれます（ただしこれらに限定されません）。
        </p>
        <p class="text-medium-emphasis float-start">
          お客様の氏名
        </p>
        <p class="text-medium-emphasis float-start">
          お客様の肩書とお客様の所属する組織の名称
        </p>
        <p class="text-medium-emphasis float-start">
          お客様の自宅の住所や請求書の送付先などの実際の住所（都道府県、市町村の名称など）
        </p>
        <p class="text-medium-emphasis float-start">
          お客様のメールアドレス
        </p>
        <p class="text-medium-emphasis float-start">
          お客様の電話番号
        </p>
        <p class="text-medium-emphasis float-start">
          当社がお客様と実際にまたはオンライン上で連絡を取る上でお客様の特定に用いるその他の事項
        </p>
        <p class="text-medium-emphasis float-start">
          以下の情報を始めとする、お客様のアカウントに関連して当社がオンライン上でお客様から収集し保持する情報
        </p>
        <p class="text-medium-emphasis float-start">
          　- お客様の当社のサービスにおけるユーザー名
        </p>
        <p class="text-medium-emphasis float-start">
          　- お客様の当社のサービスにおけるパスワード
        </p>
        <p class="text-medium-emphasis float-start">
          　- お客様のクレジットカードに関する情報
        </p>
        <p class="text-medium-emphasis float-start">
          お客様の個人情報の利用目的
        </p>
        <p class="text-medium-emphasis float-start">
          　当社は、以下の目的でお客様から提供された個人情報を利用することがあります。
        </p>
        <p class="text-medium-emphasis float-start">
          お客様のアカウントの作成や保持
        </p>
        <p class="text-medium-emphasis float-start">
          お客様の指示に従う処理や確認
        </p>
        <p class="text-medium-emphasis float-start">
          お客様の当社製品の登録
        </p>
        <p class="text-medium-emphasis float-start">
          お客様の質問に対する回答
        </p>
        <p class="text-medium-emphasis float-start">
          プログラムへのお客様の登録（お客様の要求によります）
        </p>
        <p class="text-medium-emphasis float-start">
          お客様に対する情報の送付
        </p>
        <p class="text-medium-emphasis float-start">
          お客様に対する調査書等の送付
        </p>
        <p class="text-medium-emphasis float-start">
          応募者の就業について検討する場合
        </p>
        <p class="text-medium-emphasis float-start">
          また当社は、お客様のアカウントやお客様が当社から購入した製品やサービスに関する情報をお客様に提供し、お客様のニーズや関心をよく理解し、当社の製品やサービスを改善し、通信物に氏名や住所等を記入するために、こうした個人情報を利用することがあります。
        </p>
        <p class="text-medium-emphasis float-start">
          お客様の個人情報の共有
        </p>
        <p class="text-medium-emphasis float-start">
          　お客様が承諾されない限り、当社は、以下の場合を除きお客様から当社に提供された個人情報を第三者と共有し、又は第三者に提供することはありません。当社は、以下の場合にお客様の個人情報を第三者に開示・提供することができます。
        </p>
        <p class="text-medium-emphasis float-start">
          注文の実行、請求／クレジットカードの処理、サービスの提供、電子メールハウジングのために必要な場合
        </p>
        <p class="text-medium-emphasis float-start">
          （本プライバシー・ポリシーの中で既に説明した利用の結果としてのもの）
        </p>
        <p class="text-medium-emphasis float-start">
          法律により求められる場合
        </p>
        <p class="text-medium-emphasis float-start">
          （有効な召喚状、令状、税務調査、行政機関の措置に応じる場合や不正行為を防止する場合など）
        </p>
        <p class="text-medium-emphasis float-start">
          統計報告の提出などの、調査活動を目的とする場合
        </p>
        <p class="text-medium-emphasis float-start">
          （収集された情報は現在と将来のパートナーやその他の第三者に当社のサービスを説明するために用いられ、報告の対象者との連絡には用いられません）
        </p>
        <p class="text-medium-emphasis float-start">
          合併、買収、統合、部門売却などの企業取引の場合
        </p>
        <p class="text-medium-emphasis float-start">
          （受領者が企業取引の実行以外のために情報を使用しないことに同意した機密保持の誓約に従うもの）
        </p>
        <p class="text-medium-emphasis float-start">
          その他個人情報の保護に関する法律により認められる場合
        </p>
        <p class="text-medium-emphasis float-start">
          当社と共催企業によるサービスを提供するために、本ステートメント規定の個人情報を、規定された利用目的の範囲内において共有利用することが必要となった場合
        </p>
        <p class="text-medium-emphasis float-start">
          （当社の共催企業から、電話、Eメール、郵便等により、製品・サービスに関する情報等が提供されることがあります。）
        </p>
        <p class="text-medium-emphasis float-start">
          データの安全保護に関する当社の方針
        </p>
        <p class="text-medium-emphasis float-start">
          　当社は、当社のプライバシー・ポリシーについての社員教育を行うだけでなく、当社のビジネスパートナーにも当社のプライバシー・ポリシーを開示しています。また、当社のウェブサイトはSecure Socket Layer（SSL）という技術を利用し、お客様が個人情報を当社のウェブサイトに送信される際には、その個人情報を暗号化しています。さらに、当社と当社のビジネスパートナーはお客様の個人情報の損失、悪用、開示を防ぐための注意と予防措置を求める秘密保持契約を締結しています。
        </p>
        <p class="text-medium-emphasis float-start">
          電子メールの受領に関するお客様の選択肢
        </p>
        <p class="text-medium-emphasis float-start">
          　当社は、当社や当社のパートナーからの電子メールの受領のオプト・インとオプト・アウトができるようにあらゆる努力を行います。
        </p>
        <p class="text-medium-emphasis float-start">
          　当社は、以下の場合、お客様に電子メールを送信します。
        </p>
        <p class="text-medium-emphasis float-start">
          お客様のアカウントについてお客様にお知らせするため
        </p>
        <p class="text-medium-emphasis float-start">
          お客様が購入した製品についてお客様にお知らせするため
        </p>
        <p class="text-medium-emphasis float-start">
          お客様が購入したサービスに関してお客様にお知らせするため
        </p>
        <p class="text-medium-emphasis float-start">
          お客様からの問い合わせに答えるため
        </p>
        <p class="text-medium-emphasis float-start">
          　また当社は、お客様が受領を希望しないことを伝えた場合を除き、お客様にとって興味がありそうな製品やサービスに関する情報や特別な情報を盛り込んだ電子メールをお客様にお送りします。加えて、お客様が承諾した場合、当社のビジネスパートナーがお客様にこれらの情報をお送りします。
        </p>
        <p class="text-medium-emphasis float-start">
          　お客様は、お客様が当社のウェブサイトにアカウントを作成するときや、お客様が各種サービスのための登録を行うときや、お客様がお客様の個人情報を当社に提供するときや、当社がお客様に電子メールを送るときに、当社やビジネスパートナーから情報や特別な情報の提供や販促資料の受領を希望されるか否かを当社に伝えることができます。お客様が宣伝用の電子メールを受領しないことを選択した場合、お客様にとって価値があるかもしれない特別な情報の提供を受けることができないことにご注意ください。
        </p>
        <p class="text-medium-emphasis float-start">
          　当社は、当社のダイレクト・マーケティング・コミュニケーションをオプト・アウトする機会を常にお客様に提供します。当社からお送りするすべての宣伝用電子メールには、その後の宣伝用電子メールの受領を拒否する機会をお客様に与えるための情報が含まれています。お客様は、以下のいずれかのアドレスで当社と連絡を取り配信停止を請求して、電子メールによるお知らせの配信停止を求めることができます。
        </p>
        <p class="text-medium-emphasis float-start">
          電子メール：ppinfo@tact-sccess.co.jp
        </p>
        <p class="text-medium-emphasis float-start">
          電話：0721-98-6081　　（月～金　９：００～１７：３０）
        </p>
        <p class="text-medium-emphasis float-start">
          　お客様を保護するために、お客様が購入した製品やサービスに関する問題で、お客様が直ちに注意することが必要な問題が確認された場合、当社はお客様に連絡します。
        </p>
        <p class="text-medium-emphasis float-start">
          クッキーやブラウザに関する情報
        </p>
        <p class="text-medium-emphasis float-start">
          　当社のウェブサイトは自動的にお客様のIPアドレスを掌握します。掌握したIPアドレスは、当社のサーバーに関する問題の解決、ウェブサイトの運営、お客様が当社ウェブサイトを使用する上での安全管理のために使用します。また、お客様本人とお客様のショッピングカートの確認のためにも使用します。
        </p>
        <p class="text-medium-emphasis float-start">
          　また、当社では、お客様個別の需要に合ったサービスを提供するために、お客様の情報を保存、または追跡するクッキーを使用しています。クッキーとは、ウェブサイトからお客様のブラウザに送られ、お客様のコンピューターのハードディスクに保存される少量のデータです。ログインが必要なウェブサイト、またはお客様への個別サービスが提供される当社のウェブサイトにおいては、お客様のブラウザにてクッキーを承諾する必要がある場合があります。
        </p>
        <p class="text-medium-emphasis float-start">
          　お客様の個人情報がクッキーにより保存されることを希望されない場合、お客様は、クッキーを常に拒否するようにあるいはクッキーを承諾するか否かをその都度お客様に質問するようにお客様のブラウザを設定することができます。ただしある種の製品やサービスを提供する上でクッキーの使用が必要になる場合があり、クッキーの拒否を選択するとウェブサイトのパフォーマンスや機能が低下することをご了解ください。お客様のブラウザの説明書には、クッキーの承諾を定める方法に関する正確な記載があります。
        </p>
        <p class="text-medium-emphasis float-start">
          オープンフォーラムについて
        </p>
        <p class="text-medium-emphasis float-start">
          　オープンフォーラムには通常、お客様用にチャットルーム、フォーラム、メッセージボード、ニュースグループなどが設けられています。このようなオンラインサービス上において開示された情報は、一般に公開されたものとなることを、ご了承ください。お客様の個人情報を開示する際にはお客様自身でご注意ください。当社は、お客様個々人のご意見を尊重し、言論の自由を啓発します。しかし、当社は、フォーラムを維持するために、公序良俗に反する掲示を取り除く等の必要な措置を講じる権利を留保します。
        </p>
        <p class="text-medium-emphasis float-start">
          子供の個人情報保護に関する当社の方針
        </p>
        <p class="text-medium-emphasis float-start">
          　子供のプライバシーに関する特別な懸念から、当社は、13歳未満であることを承知の上で13歳未満の子供から個人情報をオンライン上で受け付けることはありません。当社は、13歳未満であることを承知の上で13歳未満の子供が当社のウェブサイトの登録メンバーになることや、当社のウェブサイト上で製品やサービスを購入することを認めることはありません。当社は、13歳未満であることを承知の上で13歳未満の子供に関する個人情報を収集したり請求したりすることはありません。
        </p>
        <p class="text-medium-emphasis float-start">
          その他のウェブサイトへのリンクについて
        </p>
        <p class="text-medium-emphasis float-start">
          　当社のウェブサイトにはその他のウェブサイトへのリンクが含まれていますが、当社は、当社のウェブサイトからリンクを介して到達することができるウェブサイトの情報収集を全く関知していません。もし、そのようなウェブサイトでの情報収集にご質問のある場合には、そのウェブサイトの管理者に直接お尋ねください。
        </p>
        <p class="text-medium-emphasis float-start">
          お客様の情報のアクセス、変更、更新、削除の方法
        </p>
        <p class="text-medium-emphasis float-start">
          　お客様はいつでもご自分の個人情報の変更、更新、削除を依頼することができます。必要な場合は、変更等の詳細をご記入の上、電子メールにて ppinfo@tact-sccess.co.jp まで変更等の依頼をお送りください。
        </p>
        <p class="text-medium-emphasis float-start">
          お問い合わせ方法
        </p>
        <p class="text-medium-emphasis float-start">
          　本プライバシー・ポリシーや当社によるお客様の個人情報の使用についてご不明な点がありましたら、ppinfo@tact-sccess.co.jp までお問い合わせください。
        </p>
        <p class="text-medium-emphasis float-start">
          このプライバシー・ポリシーの変更
        </p>
        <p class="text-medium-emphasis float-start">
          　当社はプライバシーに関する方針を随時変更する権利を有します。変更された場合には変更日から30日間、変更の告知をこのウェブサイトに掲示します。
        </p>
        <p class="text-medium-emphasis float-start">
          ※このプライバシー・ポリシーの最終更新日は 2022年11月27日です。
        </p>
      </span>
    </c-col>
  </c-row>
</c-container>
