import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ICompletionRecord, ISearch as ISearchAttendances } from '@app/models';
import { ISearch } from '@app/models/student';
import firebase from 'firebase/compat';
import { Timestamp } from 'firebase/firestore';
import { ISyncCompletionRecord } from '@app/models/completion-record';
import { EXAM_PASS } from '@app/constants';

@Injectable({
  providedIn: 'root'
})
export class CompletionRecordService {
  readonly collectionName: string = 'completion_records';

  constructor(
    private db: AngularFirestore
  ) {}

  public async create(
    Notification: ICompletionRecord,
    userDocId: string = '',
    studentDocId: string = '',
    completionRecordDocId: string = ''
  ): Promise<void>{
    return await this.db.collection('users')
      .doc(userDocId).collection('students')
      .doc(studentDocId).collection(this.collectionName)
      .doc(completionRecordDocId).set(Notification);
  }

  public async createSyncCompletionRecord(
    Notification: ICompletionRecord,
    userDocId: string = '',
    studentDocId: string = '',
    completionRecordDocId: string = ''
  ): Promise<void> {
    let dataInsert: ISyncCompletionRecord = {
      completionRecord: Notification,
      studentDocId: studentDocId,
      userId: userDocId,
    };
    return await this.db.collection('sync_completion_records').doc(userDocId).collection('completion_records').doc(completionRecordDocId).set(dataInsert);
  }

  public async getCompletionRecord(
    userDocId: string = '',
    studentDocId: string = '',
    completionRecordDocId: string = ''
  ): Promise<any> {
    return new Promise<any>((resolve, error) => {
      this.db.collection('users')
        .doc(userDocId).collection('students')
        .doc(studentDocId).collection(this.collectionName)
        .doc(completionRecordDocId).valueChanges()
        .subscribe((completionRecord) => {
          if (completionRecord) completionRecord = { ...completionRecord, ...{ id: completionRecordDocId } };
          resolve(completionRecord);
        });
    })
  }

  async geCompletionRecordExamPass(userDocId: string = '', studentDocId: string = '') {
    return new Promise<any>((resolve, error) => {
      this.db.collection('users').doc(userDocId).collection('students').doc(studentDocId).collection(this.collectionName, ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.where('examPass', '==', EXAM_PASS)
        return query.orderBy('createAt', 'desc');
      }).valueChanges({ idField: 'id' })
      .subscribe((completionRecord) => resolve(completionRecord));
    })
  }

  public async update(
    Notification: ICompletionRecord,
    userDocId: string = '',
    studentDocId: string = '',
    completionRecordDocId: string = ''
  ): Promise<void> {
    //todo create student
    let dataInsert: ISyncCompletionRecord = {
      completionRecord: Notification,
      studentDocId: studentDocId,
      userId: userDocId,
    };
    return await this.db.collection('sync_completion_records').doc(userDocId).collection('completion_records').doc(completionRecordDocId).set(dataInsert);
  }


  public getCompletionRecords(
    param: ISearch,
    userDocId: string = '',
    studentDocId: string = '',
    course: string = ''
  ) {
    //todo search attendances in user
    return this.db.collection('users')
      .doc(userDocId).collection('students')
      .doc(studentDocId).collection(this.collectionName, ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.where('course', '==', course);

        if (param.status != '' && param.status != 'all') {
          query = query.where("examPass", "==", param.status);
        }

        return query.orderBy('createAt', 'desc');

      }).valueChanges({ idField: 'id' });
  }


  public async deleteCompletionRecord(userDocId: string = '', studentDocId: string = '', completionRecordDocId: string) {
    return await this.db.collection('sync_completion_records').doc(userDocId).collection('completion_records').doc(completionRecordDocId).delete();
  }

  private setDate(date: any): string | Date {
    return date ? new Date((date as Timestamp).toDate()) : '';
  }
}
