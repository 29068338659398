import { Component, OnInit, Input } from '@angular/core';
import { formatDatePicker } from '@app/constants';
import { formatDate } from '@app/helpers';
import { AlertService, AttendanceService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';
import { IStudent } from '@app/models/student';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-student-attendance-item',
  templateUrl: './student-attendance-item.component.html',
  styleUrls: ['./student-attendance-item.component.scss'],
})
export class StudentAttendanceItemComponent implements OnInit {
  @Input('itemInput') itemInput: any;
  @Input('studentId') studentId: string = '';
  @Input('student') student?: IStudent | undefined;
  @Input('currentUser') currentUser: any;
  protected readonly formatDate = formatDate;
  protected readonly formatDatePicker: string = formatDatePicker;
  protected course: string | null = '';

  constructor(
    private attendanceService: AttendanceService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.course = this.activatedRoute.snapshot.paramMap.get('course');
  }

  async handleDeleteAttendance(deleteStatus: boolean): Promise<void> {
    this.alertService.clear();
    if (deleteStatus) {
      await this.attendanceService.deleteSyncAttendance(this.currentUser.uid, this.studentId, this.itemInput.id)
        .then((res: any) => this.alertService.success(this.translateService.instant('student.attendances.delete.success')))
        .catch((error) => this.alertService.error(this.translateService.instant('student.attendances.delete.error')));
    }
  }

  public redirectEdit() {
    let params = {};

    if (this.student?.userId && this.student?.userId !== this.currentUser.uid) {
      params = { ...{ user_id: this.student?.userId ?? '' } };
    }

    this.route.navigate(
      [ `/student/${this.studentId}/attendances/${this.course}/edit/${this.itemInput.id}`],
      {
        queryParams: params
      }
    );
  }
}
