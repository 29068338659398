import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  experiencedTypeOption,
  TEXT_FALSE,
  TEXT_PASS,
  TEXT_TRUE,
  issueOption,
  privateSkillCertificateOption,
  x_rotorcraftMulti1,
  x_rotorcraftMulti2,
  y_plane1,
  y_plane2,
  z_rotorcraft1,
  z_rotorcraft2,
  IDataOption,
  IDataOptionLabelName,
  STUDENT
} from "@app/constants";
import { getFirstError, numberOnly, SlideInOutAnimation } from '@app/helpers';
import { IStudent } from '@app/models/student';

@Component({
  selector: 'app-student-confirmation',
  templateUrl: './student-confirmation.component.html',
  styleUrls: ['./student-confirmation.component.scss'],
  animations: [SlideInOutAnimation]
})
export class StudentConfirmationComponent implements OnInit {
  @Input('formGroup') formGroup: any;
  @Input('form') form: any = [];
  @Input('submitted') submitted: boolean = false;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @Output() fileEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() changeEtcTextCheckbox: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input('isEdit') isEdit: boolean = false;
  @Input('student') student!: IStudent;

  protected numberOnly = numberOnly;
  protected readonly experiencedType: IDataOption[] = experiencedTypeOption;
  protected readonly privateSkillCertificate: IDataOption[] = privateSkillCertificateOption;
  protected readonly issue: IDataOption[] = issueOption;
  protected xRotorcraftMulti1: IDataOptionLabelName[] = x_rotorcraftMulti1;
  protected xRotorcraftMulti2: IDataOptionLabelName[] = x_rotorcraftMulti2;
  protected yPlane1: IDataOptionLabelName[] = y_plane1;
  protected yPlane2: IDataOptionLabelName[] = y_plane2;
  protected zRotorcraft1: IDataOptionLabelName[] = z_rotorcraft1;
  protected zRotorcraft2: IDataOptionLabelName[] = z_rotorcraft2;
  protected readonly getFirstError = getFirstError;
  protected readonly TEXT_TRUE: string = TEXT_TRUE;
  protected readonly TEXT_PASS: string = TEXT_PASS;
  protected readonly TEXT_FALSE: string = TEXT_FALSE;
  protected readonly STUDENT = STUDENT;
  protected isDisabled: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  public changePrivateSkillCertificate(value: any): void {
    if (value === TEXT_TRUE) {
      this.form['issuingOrganizationName'].setValidators([Validators.minLength(1), Validators.maxLength(127), Validators.required]);
      this.form['issuingOrganizationName'].updateValueAndValidity();

      this.form['no_expireDate'].setValidators([Validators.required]);
      this.form['no_expireDate'].updateValueAndValidity();
    } else {
      this.form['issuingOrganizationName'].setValidators([])
      this.form['issuingOrganizationName'].updateValueAndValidity()

      this.form['no_expireDate'].setValidators([])
      this.form['no_expireDate'].updateValueAndValidity()
    }
  }

  public changeIssue(value: any): void {
    if (value === TEXT_TRUE) {
      this.form['dateOfIssue'].setValidators([Validators.required]);
      this.form['dateOfIssue'].updateValueAndValidity();

      this.form['dateOfExpiry'].setValidators([Validators.required]);
      this.form['dateOfExpiry'].updateValueAndValidity();

      this.form['licenseConditions'].setValidators([Validators.minLength(1), Validators.maxLength(15), Validators.required]);
      this.form['licenseConditions'].updateValueAndValidity();

      this.form['skillNumber'].setValidators([Validators.minLength(1), Validators.maxLength(15), Validators.required]);
      this.form['skillNumber'].updateValueAndValidity();

    } else {

      this.form['dateOfIssue'].setValidators([]);
      this.form['dateOfIssue'].updateValueAndValidity();

      this.form['dateOfExpiry'].setValidators([])
      this.form['dateOfExpiry'].updateValueAndValidity();

      this.form['licenseConditions'].setValidators([]);
      this.form['licenseConditions'].updateValueAndValidity();

      this.form['skillNumber'].setValidators([]);
      this.form['skillNumber'].updateValueAndValidity();
    }
  }

  public changeValueEtcCheckBox() {
    if (this.formGroup.get('etc').value == false) {
      this.changeEtcTextCheckbox.emit(true);
    }
    this.isDisabled = !this.formGroup.get('etc').value;
  }
}
