<div class="mb-3 image_container">
  <label for="{{ formControlNameInput }}" class="form-label mb-1">
    {{ label }}
    <c-badge *ngIf="requiredText" color="danger">{{ "form.inputLabel.must" | translate}}</c-badge>
  </label>
  <div class="image_content">
    <div class="image_avatar">
      <img src="{{ imageUrl || 'assets/img/avatar_default.png' }}" alt="avatar">
    </div>
    <div class="btn_upload">
      <button cButton color="light" id="fileInput" (click)="showFileInput()">顔写真</button>
      <input #fileInput
             cFormControl
             [formControlName]="formControlNameInput"
             type="file"
             autoComplete="true"
             id="{{ formControlNameInput }}"
             [style.display]="'none'"
             (change)="onFileSelected($event)"
             accept="image/x-png,image/jpeg,image/jpg"
      />
    </div>
  </div>
  <div>
    <c-form-feedback *ngIf="submitted && form[formControlNameInput].errors" class="error_text">
      <div *ngIf="form[formControlNameInput]?.errors[getFirstError(form[formControlNameInput]?.errors) || '']">
        <div *ngIf="getFirstError(form[formControlNameInput]?.errors) === 'required'">
          {{ "errorMessages.formValidate.avatar.required" | translate }}
        </div>
      </div>
    </c-form-feedback>
  </div>
</div>
