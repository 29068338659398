import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { getAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  AlertService,
  ValidationFormsService,
  FirebaseAnalyticService,
  AuthService,
} from '@app/services';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  protected form!: UntypedFormGroup;
  protected loading: boolean = false;
  protected loadingRedirect: boolean = false;
  protected submitted: boolean = false;

  constructor(
    private angularFireAuth: AngularFireAuth,
    private alertService: AlertService,
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private validationFormService: ValidationFormsService,
    private analytics: AngularFireAnalytics,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(this.validationFormService.formRules.emailPattern)]],
      password: ['', [Validators.required]]
    });
    this.getAccountUser();
  }

  get f() {
    return this.form.controls;
  }

  get errorMessages() {
    return this.validationFormService.errorMessages;
  }

  login() {
    const auth = getAuth();
    auth.languageCode = 'ja';
    this.submitted = true;
    this.loading = true;
    this.alertService.clear();
    if (this.form.invalid) {
      this.loading = false;
      return;
    }
    this.analytics.logEvent(this.firebaseAnalyticService.EVENT_NAME.LOGIN, {
      'content_type': this.firebaseAnalyticService.CONTENT_TYPE,
      'develop_type': this.firebaseAnalyticService.TYPE,
    });

    this.angularFireAuth.signInWithEmailAndPassword(this.f['email'].value, this.f['password'].value)
      .then((res: any) => {
        localStorage.setItem('email_user', res.user?.email);
        localStorage.setItem('uid_user', res.user?.uid);
        this.router.navigateByUrl('/account')
      })
      .then(() => this.alertService.success(this.translateService.instant('alertMessages.login.success')))
      .catch((error) => this.alertService.error(this.translateService.instant('alertMessages.login.fail')))
      .finally(() => (this.loading = false));
  }

  private async getAccountUser() {
    let user = await this.authService.userValue();
    if (user) {
      this.loadingRedirect = false;
      this.router.navigate(['account']);
    } else this.loadingRedirect = true;
  }
}
