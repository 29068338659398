import { Component, Input, OnInit } from '@angular/core';
import { IDataOption, statusCompletionOption } from '@app/constants';

@Component({
  selector: 'app-student-completion',
  templateUrl: './student-completion.component.html',
  styleUrls: ['./student-completion.component.scss'],
})
export class StudentCompletionComponent implements OnInit {
  @Input('formGroup') formGroup: any;
  @Input('form') form: any = [];
  @Input('submitted') submitted: boolean = false;
  protected readonly status: IDataOption[] = statusCompletionOption;

  constructor() {}

  ngOnInit(): void {}
}
