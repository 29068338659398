export const errorMessages = {
  email: {
    required: 'メールアドレスをご入力ください。',
  },
  password: {
    required: 'パスワードをご入力ください。',
  },
};

export const toastMessage = {
  login: {
    fail: 'ログインできませんでした',
  },
};
