import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAnalyticService {
  public readonly EVENT_NAME = {
    NAVIGATE_REGISTER: 'navigate_register',
    USING_NOT_LOGIN: 'using_not_login',
    LOGIN: 'login',
    LOGIN_WITH_GOOGLE: 'login_with_google',
    LOGIN_WITH_APPLE: 'login_with_apple',
    FORGOT_PASSWORD: 'forgot_password',
    REGISTER: 'register',
    TAB_RECORD: 'tab_record',
    TAB_AIRFRAMES: 'tab_airframes',
    TAB_ACCOUNT: 'tab_account',
    TAB_NOTIFICATION: 'tab_notification',
    NAVIGATE_CREATE_FLIGHT: 'navigate_create_flight',
    CREATE_FLIGHT: 'create_flight',
    RESET_START_TIME: 'reset_start_time',
    RESET_END_TIME: 'reset_end_time',
    CHOOSE_AIRFRAMES: 'choose_airframes',
    NAVIGATE_CREATE_AIRFRAMES: 'navigate_create_airframes',
    CREATE_AIRFRAMES: 'create_airframes',
    SAVE_INFO_USER: 'save_info_user',
    BUY_PLAN: 'buy_plan',
    LOGOUT: 'logout',
    DELETE_ACCOUNT: 'delete_account',
    ORDER_BASIC: 'order_basic',
    READ_PRIVACY_POLICY: 'read_privacy_policy',
    READ_TERM_OF_USE: 'read_term_of_use',
    RELOAD_NOTIFICATIONS: 'reload_notifications',
    MARK_ALL_READ: 'mark_all_read'
  }

  public readonly CONTENT_TYPE = 'web'

  public readonly TYPE = 'staging'
}