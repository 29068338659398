<div [formGroup]="formGroup">
  <div class="form_item">
    <div class="form_item--title">{{ 'student.createStudent.basic.personalInformation' | translate }}</div>
    <div class="form_item--content">
      <div class="image_input_content">
        <app-input-file-image-upload
          [label]="'student.createStudent.basic.avatar' | translate"
          [formControlNameInput]="'avatar'"
          [formGroup]="formGroup"
          [submitted]="submitted"
          [image]="imageUrl"
          (fileEvent)="onDataFileUpload($event)"
          [form]="form">
        </app-input-file-image-upload>

        <div class="w-100 mt-1">
          <c-row>
            <c-col [lg]="6">
              <!-- ledgerNumber -->
              <app-input-base
                [label]="'student.createStudent.basic.ledgerNumber' | translate"
                class="input-group-container"
                [formControlNameInput]="'ledgerNumber'"
                [placeHolderInput]="'student.createStudent.basic.ledgerNumber' | translate"
                [inputType]="'text'"
                [submitted]="submitted"
                [disabled]="true"
                [form]="form">
              </app-input-base>
            </c-col>
            <c-col [lg]="6">
              <!-- studentNumber -->
              <app-input-base
                [label]="'student.createStudent.basic.studentNumber' | translate"
                class="input-group-container"
                [formControlNameInput]="'studentNumber'"
                [placeHolderInput]="'student.createStudent.basic.studentNumber' | translate"
                [inputType]="'text'"
                [submitted]="submitted"
                [disabled]="true"
                [form]="form">
              </app-input-base>
            </c-col>
          </c-row>

          <c-row>
            <c-col [lg]="6">
              <!-- name -->
              <app-input-base
                [label]="'student.createStudent.basic.name' | translate"
                class="input-group-container"
                [formControlNameInput]="'name'"
                [placeHolderInput]="'student.createStudent.basic.name' | translate"
                [inputType]="'text'"
                [maxLength]="'31'"
                [submitted]="submitted"
                [form]="form">
              </app-input-base>
            </c-col>

            <c-col [lg]="6">
              <!-- hurigana -->
              <app-input-base
                [label]="'student.createStudent.basic.hurigana' | translate"
                class="input-group-container"
                [formControlNameInput]="'hurigana'"
                [placeHolderInput]="'student.createStudent.basic.hurigana' | translate"
                [inputType]="'text'"
                [maxLength]="'31'"
                [submitted]="submitted"
                [form]="form">
              </app-input-base>
            </c-col>

            <c-col [lg]="6">
              <!-- organization name -->
              <app-input-base
                [label]="'student.createStudent.basic.organizationName' | translate"
                class="input-group-container"
                [formControlNameInput]="'organizationName'"
                [placeHolderInput]="'student.createStudent.basic.organizationName' | translate"
                [inputType]="'text'"
                [submitted]="submitted"
                [maxLength]="'127'"
                [form]="form"
                [requiredText]="false">
              </app-input-base>
            </c-col>
          </c-row>
        </div>
      </div>
      <!-- certificateNumber -->
      <app-input-base
        [label]="'student.createStudent.basic.certificateNumber' | translate"
        class="input-group-container"
        [formControlNameInput]="'certificateNumber'"
        [placeHolderInput]="'student.createStudent.basic.certificateNumber' | translate"
        [inputType]="'text'"
        [submitted]="submitted"
        [requiredText]="true"
        [maxLength]="'10'"
        [form]="form">
      </app-input-base>

      <!-- birthday -->
      <app-input-date-picker
        [label]="'student.createStudent.basic.birthday' | translate"
        [formControlNameInput]="'birthday'"
        [formGroup]="formGroup"
        [submitted]="submitted"
        [requiredText]="true"
        [initialDate]="form['birthday'].value"
        [form]="form">
      </app-input-date-picker>

      <!-- sex -->
      <app-input-radio
        [label]="'student.createStudent.basic.sex' | translate"
        class="input-group-container"
        [formControlNameInput]="'sex'"
        [dataOption]="gender"
        [submitted]="submitted"
        [form]="form">
      </app-input-radio>

      <!-- address -->
      <app-input-base
        [label]="'student.createStudent.basic.address' | translate"
        class="input-group-container"
        [formControlNameInput]="'address'"
        [placeHolderInput]="'student.createStudent.basic.address' | translate"
        [inputType]="'text'"
        [submitted]="submitted"
        [requiredText]="true"
        [maxLength]="'127'"
        [form]="form">
      </app-input-base>

      <!-- tel -->
      <app-input-base
        [label]="'student.createStudent.basic.tel' | translate"
        class="input-group-container"
        [formControlNameInput]="'tel'"
        [placeHolderInput]="'student.createStudent.basic.tel' | translate"
        [inputType]="'text'"
        [submitted]="submitted"
        [maxLength]="'11'"
        [form]="form">
      </app-input-base>

      <!-- email -->
      <app-input-base
        [label]="'student.createStudent.basic.email' | translate"
        class="input-group-container"
        [formControlNameInput]="'email'"
        [placeHolderInput]="'student.createStudent.basic.email' | translate"
        [inputType]="'email'"
        [requiredText]="true"
        [submitted]="submitted"
        [form]="form">
      </app-input-base>

      <c-row>
        <c-col [md]="6">
          <!-- enrollmentDate -->
          <app-input-date-picker
            [label]="'student.createStudent.basic.enrollmentDate' | translate"
            [formControlNameInput]="'enrollmentDate'"
            [formGroup]="formGroup"
            [submitted]="submitted"
            [requiredText]="false"
            [initialDate]="form['enrollmentDate'].value"
            [form]="form">
          </app-input-date-picker>
        </c-col>
        <c-col [md]="6">
          <!-- practicalDate -->
          <app-input-date-picker
            [label]="'student.createStudent.basic.practicalDate' | translate"
            [formControlNameInput]="'practicalDate'"
            [formGroup]="formGroup"
            [submitted]="submitted"
            [requiredText]="false"
            [initialDate]="form['practicalDate'].value"
            [form]="form">
          </app-input-date-picker>
        </c-col>
      </c-row>

      <div class="search_color">
        <div class="search_color--first">
          <label class="form-label mb-1">
            {{ 'student.createStudent.confirmation.courseItems' | translate }}
            <c-badge color="danger">{{ "form.inputLabel.must" | translate}}</c-badge>
          </label>
          <div *ngFor="let item of courseItems; let i = index" class="color_item">
            <input [formControlName]="'courseItems'" [value]="item.itemNumber"  class="check-box-color d-none" id="courseItems{{ item.itemNumber }}" type="radio"/>
            <label
              cFormCheckLabel
              for="courseItems{{ item.itemNumber }}"
              class="color-icon"
              [style.--border-color]="'1px solid #667085'"
              [style.--color]="item.color"
              [style.--background-color]="item.color"
              [style.border]="'1px solid #667085'">
            </label>
            <label class="cursor-pointer" cFormCheckLabel for="color{{ item.itemName }}">{{ item.itemName }}</label>
          </div>
        </div>
      </div>

      <!--status-->
      <app-input-radio
        [label]="'student.createStudent.confirmation.status' | translate"
        [formControlNameInput]="'status'"
        [dataOption]="status"
        [submitted]="submitted"
        [form]="form">
      </app-input-radio>

      <!-- academicExamStatus -->
      <app-input-radio
        [label]="'student.createStudent.confirmation.academicExamStatus' | translate"
        class="input-group-container"
        [formControlNameInput]="'academicExamStatus'"
        [dataOption]="academicExamStatus"
        [submitted]="submitted"
        (changeValue)="changeAcademicExamStatus($event)"
        [form]="form">
      </app-input-radio>

      <c-row>
        <c-col [md]="6">
          <!-- academicExamPassDate -->
          <app-input-date-picker
            [label]="'student.createStudent.confirmation.academicExamPassDate' | translate"
            [formControlNameInput]="'academicExamPassDate'"
            [formGroup]="formGroup"
            [submitted]="submitted"
            [initialDate]="form['academicExamPassDate'].value"
            [requiredText]="formGroup.get(STUDENT.ACADEMIC_EXAM_STATUS)?.value === TEXT_PASS"
            [form]="form">
          </app-input-date-picker>
        </c-col>
        <c-col [md]="6">
          <!-- academicExamPassCertificateNumber -->
          <app-input-base
            [label]="'student.createStudent.confirmation.academicExamPassCertificateNumber' | translate"
            class="input-group-container"
            [formControlNameInput]="'academicExamPassCertificateNumber'"
            [placeHolderInput]="'student.createStudent.confirmation.academicExamPassCertificateNumber' | translate"
            [inputType]="'text'"
            [submitted]="submitted"
            [requiredText]="formGroup.get(STUDENT.ACADEMIC_EXAM_STATUS)?.value === TEXT_PASS"
            [maxLength]="'15'"
            [minLength]="'1'"
            [form]="form">
          </app-input-base>
        </c-col>
      </c-row>

      <!--useElearning-->
      <app-input-radio
        [label]="'student.createStudent.confirmation.useElearning' | translate"
        [formControlNameInput]="'useElearning'"
        [dataOption]="useElearning"
        [submitted]="submitted"
        [form]="form"
        (changeValue)="changeUseLearning($event)">
      </app-input-radio>

      <!--eLearning-->
      <app-input-radio
      [label]="'student.createStudent.confirmation.eLearning' | translate"
      [formControlNameInput]="'statusElearning'"
      [dataOption]="statusElearning"
      [submitted]="submitted"
      [form]="form"
      [requiredText]="!eLearnings ? false : true"
      [isDisable]="!eLearnings"
      (changeValue)="changeAccountLearning($event)"
      >
      </app-input-radio>
    </div>
  </div>

  <div class="btn-submit-learning">
    <button cButton type="submit" class="btn-submit" (click)="submitLearning()">
      {{ "form.inputLabel.save" | translate }}
    </button>
  </div>

  <div class="form_item eLearning">
    <div class="form_item--title">{{'student.createStudent.confirmation.etc' | translate}}</div>
    <div class="form_item--content">
      <app-text-area
        [label]="'student.createStudent.confirmation.remark' | translate"
        class="input-group-container"
        [formControlNameInput]="'remark'"
        [placeHolderInput]="'student.createStudent.confirmation.remark' | translate"
        [inputType]="'text'"
        [submitted]="submitted"
        [requiredText]="false"
        [maxLength]="'1023'"
        [form]="form">

      </app-text-area>
    </div>
  </div>
  <app-loading [isLoading]="isLoading"></app-loading>
</div>
