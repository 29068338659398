import { Component, Input, OnInit } from '@angular/core';
import {
  changeCourseTypes,
  checkTypeOrderCourse,
  SlideInOutAnimation,
} from '@app/helpers';
import { courseTypeOrders, courseTypes } from '@app/constants';
import * as moment from 'moment';

@Component({
  selector: 'app-student-course-multi',
  templateUrl: './student-course-multi.component.html',
  styleUrls: ['./student-course-multi.component.scss'],
  animations: [SlideInOutAnimation],
})
export class StudentCourseMultiComponent implements OnInit {
  @Input('formGroup') formGroup: any;
  @Input('form') form: any = [];
  @Input('submitted') submitted = false;
  @Input('isEdit') isEdit = false;
  protected readonly checkTypeOrderCourse = checkTypeOrderCourse;
  protected readonly changeCourseTypes = changeCourseTypes;

  constructor() {}

  ngOnInit(): void {}

  public changeCourse(): void {
    if (!this.form['a_rotorcraftMulti'].value) {
      courseTypeOrders.forEach((courseTypeOrder) => {
        courseTypes.forEach((courseType) => {
          let key: string = `a_${courseTypeOrder}${courseType}`;
          this.form[key].setValue(false);
          changeCourseTypes(key, this.form);
        });
      });
    }
  }

  public changeDate(event: any): void {
    if (event.data) {
        const deliveryDate = moment(event.data, 'YYYY/MM/DD');
        const expirationDate = deliveryDate.add(1, 'year').subtract(1, 'day');

        const controlMappings: any = {
            'a_firstClassBasicDeliveryDate': 'a_firstClassBasicExpirationDate',
            'a_firstClassDaytimeOnlyDeliveryDate': 'a_firstClassDaytimeOnlyExpirationDate',
            'a_firstClassVisualLineOfSightDeliveryDate': 'a_firstClassVisualLineOfSightExpirationDate',
            'a_firstClassWeightLimitedDeliveryDate': 'a_firstClassWeightLimitedExpirationDate',
            'a_secondClassBasicDeliveryDate': 'a_secondClassBasicExpirationDate',
            'a_secondClassDaytimeOnlyDeliveryDate': 'a_secondClassDaytimeOnlyExpirationDate',
            'a_secondClassVisualLineOfSightDeliveryDate': 'a_secondClassVisualLineOfSightExpirationDate',
            'a_secondClassWeightLimitedDeliveryDate': 'a_secondClassWeightLimitedExpirationDate',
        };
        const expirationControlName = controlMappings[event.value];
        if (expirationControlName) {
            this.form[expirationControlName].setValue(expirationDate.toDate());
        }
    }
  }
}
