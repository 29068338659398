import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { ValidationFormsService } from '@app/services';
import { getFirstError } from '@app/helpers';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-input-upload',
  templateUrl: './input-upload.component.html',
  styleUrls: ['./input-upload.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class InputUploadComponent implements OnInit {
  getFirstError = getFirstError;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @Input('form') form: any = [];
  @Input('label') label: string = '';
  @Input('ngClass') ngClass: NgClass['ngClass'];
  @Input('formControlNameInput') formControlNameInput: string = '';
  @Input('placeHolderInput') placeHolderInput: string = '';
  @Input('inputType') inputType: string = '';
  @Input('maxLength') maxLength: string = '';
  @Input('submitted') submitted: boolean = false;
  @Input('requiredText') requiredText: boolean = true;
  @Input('accept') accept: string = '';
  @Output() fileEvent = new EventEmitter<string>();
  constructor(private validationFormService: ValidationFormsService) {}

  ngOnInit(): void {}

  get errorMessages() {
    return this.validationFormService.errorMessages;
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    this.fileEvent.emit(file);
  }

  showFileInput(): void {
    this.fileInput.nativeElement.click();
  }
}
