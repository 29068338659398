import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { endTimeOfDay, formatDatePicker, startTimeOfDay } from '@app/constants';
import { formatDate } from '@app/helpers';
import { ISearchQuotation } from '@app/models/student';
import firebase from "firebase/compat";
import { Api } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class QuotationsService {

  constructor(
    private db: AngularFirestore,
    private api: Api,
  ) { }

  async getAllQuotationItem() {
    return new Promise<any>((resolve, error) => {
      this.db.collection('quotation_item', ref => ref.orderBy('sort', 'desc'))
      .valueChanges({ idField: 'id' })
      .subscribe((quotationItem) => resolve(quotationItem));
    })
  }

  async createQuotation(param: {
    user_id?: any,
    recipient_name?: any,
    honorific_title?: any,
    total_amount?: any,
    tax?: any,
    description?: any,
    dataQuotationArr?: any,
    createdAt?: any,
  }) {
    const quotationRef =  await this.db.collection('quotations').add({
      user_id: param.user_id,
      recipient_name: param.recipient_name,
      honorific_title: param.honorific_title,
      total_amount: param.total_amount,
      tax: param.tax,
      description: param.description,
      createdAt: param.createdAt,
    })

    const quotationId = quotationRef.id;
    for (const dataQuotationItem of param.dataQuotationArr) {
      await this.db.collection('quotations').doc(quotationId).collection('quotation_item_link').add({
        quotation_item_id: dataQuotationItem.quotationItemId,
        quantity: dataQuotationItem.quantity,
        total: dataQuotationItem.total,
        sort: dataQuotationItem.sort,
      });
    }
    return quotationId;
  }

  async updateQuotation(param: {
    user_id?: any,
    recipient_name?: any,
    honorific_title?: any,
    quotationId: any,
    total_amount?: any,
    description?: any,
    dataQuotationArr?: any,
  }) {
    const quotationDocRef = this.db.collection('quotations').doc(param.quotationId);

    await quotationDocRef.update({
      user_id: param.user_id,
      recipient_name: param.recipient_name,
      honorific_title: param.honorific_title,
      total_amount: param.total_amount,
      description: param.description,
    });

    await this.db.collection('quotations').doc(param.quotationId).collection('quotation_item_link').get().toPromise()
    .then((querySnapshot: any) => {
      querySnapshot.forEach((doc: any) => {
        doc.ref.delete();
      });
    })


    if (param.dataQuotationArr && param.dataQuotationArr.length > 0) {
      for (const dataQuotationItem of param.dataQuotationArr) {

        await this.db.collection('quotations').doc(param.quotationId).collection('quotation_item_link').add({
          quotation_item_id: dataQuotationItem.quotation_item_id,
          quantity: dataQuotationItem.quantity,
          total: dataQuotationItem.total,
          sort: dataQuotationItem.sort,
        });
      }
    }
  }


  async getValueTax() {
    return new Promise<any>((resolve, error) => {
      this.db.collection('settings').valueChanges().subscribe((data) => resolve(data))
    })
  }

  public getListQuotationItemLink(param: ISearchQuotation) {
    const searchQuotation = this.db.collectionGroup('quotation_item_link', ref => ref.where('quotation_item_id', '==', param.quotationItem))
    .snapshotChanges();
    return searchQuotation;
  }

  public getListQuotationStudent(param: ISearchQuotation) {
    const searchQuotation = this.db.collection('quotations', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = this.searchQuery(param, query);
      return query.orderBy('createdAt', 'desc');
    }).valueChanges({ idField: 'id' });
    return searchQuotation;
  }

  searchQuery(param: ISearchQuotation, query: any) {
    if (param?.startDate instanceof Date) {
      query = query.where('createdAt', '>=', new Date(formatDate(param.startDate, formatDatePicker) + startTimeOfDay));
    }

    if (param?.endDate instanceof Date) {
      query = query.where('createdAt', '<=', new Date(formatDate(param.endDate, formatDatePicker) + endTimeOfDay));
    }
    return query;
  }

  async deleteQuotationStudent(id: any) {
    this.db.collection('quotations').doc(id).collection('quotation_item_link').get().toPromise()
    .then((querySnapshot: any) => {
      querySnapshot.forEach((doc: any) => {
        doc.ref.delete();
      });
    })
    this.db.collection('quotations').doc(id).delete();
  }

  public getQuotationStudentById(docId: string = '') {
    return new Promise<any>((resolve, reject) => {
      this.db.collection('quotations').doc(docId).valueChanges().subscribe((quotation: any) => {
        if (quotation) {
          this.db.collection('quotations').doc(docId).collection('quotation_item_link', ref => ref.orderBy('sort', 'desc')).valueChanges({ idField: 'id' }).subscribe((quotationItemLinks: any) => {
            const quotationWithItemLinks = { ...quotation, quotation_item_link: quotationItemLinks };
            resolve(quotationWithItemLinks);
          }, error => {
            reject(error);
          });
        }
      });
    });
  }

  exportPDF(userId: any, quotationId: any, formQuotation: any, timeCreatedAt: any) {
    return this.api.get(`/user/${userId}/quotation/${quotationId}`, { responseType: 'blob' })
      .then((res: any) => {
      const url = window["URL"].createObjectURL(new Blob([res]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `見積書_${formQuotation.value.recipientName}_${timeCreatedAt}.pdf`);
      document.body.appendChild(link);
      link.click();
    })
  }
}
