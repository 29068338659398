import { NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { getFirstError } from '@app/helpers';

@Component({
  selector: 'app-input-select-option',
  templateUrl: './input-select-option.component.html',
  styleUrls: ['./input-select-option.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class InputSelectOptionComponent implements OnInit {
  getFirstError = getFirstError;
  @Input('form') form: any = [];
  @Input('dataOption') dataOption: any = [];
  @Input('label') label: string = '';
  @Input('ngClass') ngClass: NgClass['ngClass'];
  @Input('formControlNameInput') formControlNameInput: string = '';
  @Input('placeHolderInput') placeHolderInput: string = '';
  @Input('inputType') inputType: string = '';
  @Input('submitted') submitted = false;
  @Input('requiredText') requiredText = true;

  constructor() {}

  ngOnInit(): void {}
}
