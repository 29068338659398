<div class="mb-3">
  <label for="{{ formControlNameInput }}" class="form-label mb-1">
    {{ label }}
    <c-badge *ngIf="requiredText" color="danger">{{ "form.inputLabel.must" | translate}}</c-badge>
  </label>
  <button cButton color="light" id="fileInput" (click)="showFileInput()">顔写真</button>
  <input #fileInput
    cFormControl
    [formControlName]="formControlNameInput"
    [type]="inputType"
    [accept] ="accept"
    autoComplete="true"
    id="{{ formControlNameInput }}"
    [style.display]="'none'"
    (change) ="onFileSelected($event)"
  />
  <c-form-feedback *ngIf="(submitted || form[formControlNameInput]?.touched) && form[formControlNameInput].errors">
    <div *ngIf="form[formControlNameInput]?.errors[getFirstError(form[formControlNameInput]?.errors) || '']">
      <div *ngIf="getFirstError(form[formControlNameInput]?.errors) === 'required'">
        {{ "errorMessages.formValidate.input.required" | translate }}
      </div>
    </div>
  </c-form-feedback>
</div>
