<button [cModalToggle]="deleteCommon.id" class="btn-trash"></button>

<c-modal #deleteCommon alignment="center" id="deleteCommon{{ deleteId }}">
  <c-modal-header>
    <h5 cModalTitle>{{ title }}</h5>
    <button [cModalToggle]="deleteCommon.id" cButtonClose></button>
  </c-modal-header>
  <c-modal-footer>
    <button [cModalToggle]="deleteCommon.id" cButton class="btn_cancel">いいえ</button>
    <button cButton [cModalToggle]="deleteCommon.id" class="btn_delete" (click)="handleDelete()">はい</button>
  </c-modal-footer>
</c-modal>
