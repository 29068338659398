import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { getFirstError } from '@app/helpers';
import { ValidationFormsService } from '@app/services';

@Component({
  selector: 'app-input-base',
  templateUrl: './input-base.component.html',
  styleUrls: ['./input-base.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class InputBaseComponent implements OnInit {
  getFirstError = getFirstError;
  @Input('form') form: any = [];
  @Input('label') label: string = '';
  @Input('ngClass') ngClass: NgClass['ngClass'];
  @Input('formControlNameInput') formControlNameInput: string = '';
  @Input('placeHolderInput') placeHolderInput: string = '';
  @Input('inputType') inputType: string = '';
  @Input('maxLength') maxLength: string = '';
  @Input('minLength') minLength: string = '';
  @Input('max') max: string = '';
  @Input('min') min: string = '';
  @Input('submitted') submitted: boolean = false;
  @Input('requiredText') requiredText: boolean = true;
  @Input('disabled') disabled: boolean = false;

  @Output('onChange') onChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private validationFormService: ValidationFormsService) {}

  ngOnInit(): void {}

  get errorMessages() {
    return this.validationFormService.errorMessages;
  }

  inputChange(event: any) {
    this.onChange.emit(event.target.value);
  }
}
