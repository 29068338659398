export const startTimeOfDay = ' 00:00:00';
export const endTimeOfDay = ' 23:59:59';
export const formatSearchTime = 'HH:mm:ss';
export const formatDatePicker = 'YYYY/MM/DD';
export const formatDatePickerUs = 'MM/DD/YYYY';
export const formatDateY_M_D = 'YYYY-MM-DD';

export const formatDataDate: { en: string; ja: string } = {
  en: 'MM/DD/YYYY',
  ja: 'YYYY/MM/DD',
};
export const formatEntryNo = 'YYYYMMDDHHmmssSSS';
export const formatFileName = 'YYYYMMDDHHmmss';
export const formatTargetDateJapan = 'YYYY年MM月DD日';
export const formatTargetDateEnglish = 'MMMM DD, YYYY';
export const formatHoursAndSecond = 'HH:mm';
export const formatYYYYMMDD = 'YYYYMMDD';
