import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NO, NOT_USE, Processing, Released, YES, formatDatePicker } from '@app/constants';
import { formatDate } from '@app/helpers';

@Component({
  selector: 'app-student-sub-item',
  templateUrl: './student-sub-item.component.html',
  styleUrls: ['./student-sub-item.component.scss'],
})
export class StudentSubItemComponent implements OnInit {
  @Input('studentCourse') studentCourse: any;
  @Input('itemInput') itemInput: any;
  @Input('currentUser') currentUser: any;
  @Input('studentApproval') studentApproval: any;
  @Output('updateStatusRequest') updateStatusRequest: EventEmitter<any> = new EventEmitter<any>();
  protected readonly formatDate = formatDate;
  protected readonly formatDatePicker: string = formatDatePicker;
  protected ELearning : boolean = false
  protected YES: string = YES;
  protected NO: string = NO;
  protected Processing: string = Processing;
  protected Released: string = Released;
  constructor(
    private route: Router,
  ) {}

  ngOnInit(): void {
    this.ELearning = this.getELearning();
  }

  public getELearning() : boolean {
    if(!this.itemInput.studentClassification.useElearning) {
      return true;
    } else if(this.itemInput.studentClassification.useElearning && this.itemInput.studentClassification.statusElearning == NOT_USE) {
      return true;
    }
    return false;
  }

  get titleCourseType() {
    return `student.createStudent.${this.studentCourse.type}.${this.studentCourse.masterKey}`;
  }

  get titleCourseKey() {
    return `student.createStudent.${this.studentCourse.type}.${this.studentCourse.key}`;
  }

  public redirectAttendances(): void {
    let params = {};

    if (this.itemInput.userId && this.itemInput.userId !== this.currentUser.uid) {
      params = { ...{ user_id: this.itemInput.userId ?? '' }, ...params }
    }

    this.route.navigate(
      [`/student/${this.itemInput.id}/attendances/${this.studentCourse.key}`],
      {
        queryParams: params
      }).then(()=> localStorage.removeItem('queryString.attendances'));
  }

  async handleUpdateStatus() {
    this.updateStatusRequest.emit({uid: this.currentUser.uid, studentID: this.itemInput.id, studentCourse: this.studentCourse});
  }

  public getStatusKey(): string {
    return this.studentCourse.key + 'Status';
  }

  public redirectCompletionRecord(): void {
    let params = {};

    if (this.itemInput.userId && this.itemInput.userId !== this.currentUser.uid) {
      params = { ...{ user_id: this.itemInput.userId ?? '' }, ...params  }
    }

    this.route.navigate(
      [`/student/${this.itemInput.id}/completion-records/${this.studentCourse.key}`],
      {
        queryParams: params
      }).then(()=> localStorage.removeItem('queryString.completion'));
  }
}
