import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { getFirstError } from '@app/helpers';
import { ValidationFormsService } from '@app/services';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class InputCheckboxComponent implements OnInit {
  getFirstError = getFirstError;
  @Input('form') form: any = [];
  @Input('formGroup') formGroup: any = [];
  @Input('label') label: string = '';
  @Input('ngClass') ngClass: NgClass['ngClass'];
  @Input('formControlNameInput') formControlNameInput: string = '';
  @Input('submitted') submitted: boolean = false;
  @Input('requiredText') requiredText: boolean = true;
  @Input('disabled') disabled: boolean = false;
  @Output('changeValue') changValue: EventEmitter<any> = new EventEmitter<any>();

  constructor(private validationFormService: ValidationFormsService) {}

  ngOnInit(): void {}

  public changeCheckbox(value: any) {
    this.changValue.emit(value.target.value);
  }
}
