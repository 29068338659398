import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { getFirstError } from '@app/helpers';
import { AircraftUsedService, AlertService, ValidationFormsService } from '@app/services';

@Component({
  selector: 'app-aircraft-used',
  templateUrl: './aircraft-used.component.html',
  styleUrls: ['./aircraft-used.component.scss']
})
export class AircraftUsedComponent implements OnInit {
  formAircrafts = this.formBuilder.group({
    itemName: ['', [Validators.required, Validators.maxLength(50)]],
    registrationNumber: ['', [Validators.required, Validators.maxLength(30)]],
  });
  aircrafts: any = [];

  protected submitted = false;
  protected maxSort: any = 0;
  protected minSort: any = 0;
  protected currentUser: any;
  protected readonly getFirstError = getFirstError;

  constructor(
    private formBuilder: FormBuilder,
    private validationFormService: ValidationFormsService,
    private alertService: AlertService,
    private auth: AngularFireAuth,
    private aircraftUsedService: AircraftUsedService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.getUser().then(async () => {
      await this.getItems();
    });
  }

  private async getUser(): Promise<void> {
    const currentUser = await this.auth.currentUser;
    if (currentUser) this.currentUser = currentUser;
  }

  get formAircraftControls() {
    return this.formAircrafts.controls;
  }

  get errorMessages() {
    return this.validationFormService.errorMessages;
  }

  resetFormValues(): void {
    this.formAircrafts.reset();
  }
  
  getValidationClass(formControlItem: FormControl) {
    if (this.submitted && formControlItem.invalid) {
      return 'is-invalid';
    }
    if (formControlItem.valid) {
      return 'is-valid';
    }
    return '';
  }

  isInvalid(formControlItem: FormControl) {
    return this.submitted && formControlItem.errors;
  }

  async getItems() {
    (await this.aircraftUsedService.getItems(this.currentUser.uid)).subscribe((items) => {
      let dataResponse = items.map((e) => {
        const data: any = e.payload.doc.data();
        data.id = e.payload.doc.id;
        return data;
      });
      this.aircrafts = dataResponse;
      this.maxSort = dataResponse[0]?.sort ?? 0;
      this.minSort = dataResponse[dataResponse.length - 1]?.sort ?? 0;
      this.cdr.detectChanges();
    });
  }

  async handleDeleteItem(event: any) {
    await this.aircraftUsedService.deleteItem(event.id, this.currentUser.uid).then((res) => {
      this.alertService.success('項目を削除しました。');
    });;
    await this.getItems();
    this.cdr.detectChanges();
  }

  async handleUpItem(event: any) {
    const targetIndexNowItem = this.aircrafts.findIndex((item: any) => item.id === event.id);
    if (targetIndexNowItem > 0) {
      const sortOfPreviousElement = this.aircrafts[targetIndexNowItem - 1]?.sort;
      const idOfPreviousElement = this.aircrafts[targetIndexNowItem - 1].id;
      await this.aircraftUsedService.updateItem(event.id, sortOfPreviousElement, this.currentUser.uid);
      await this.aircraftUsedService.updateItem(idOfPreviousElement, event?.sort, this.currentUser.uid);
      await this.getItems();
      this.cdr.detectChanges();
    }
  }

  async handleDownItem(event: any) {
    const targetIndexNowItem = this.aircrafts.findIndex((item: any) => item.id === event.id);
    if (targetIndexNowItem < this.aircrafts.length - 1) {
      const sortOfAfterElement = this.aircrafts[targetIndexNowItem + 1]?.sort;
      const idOfAfterElement = this.aircrafts[targetIndexNowItem + 1].id;
      await this.aircraftUsedService.updateItem(event.id, sortOfAfterElement, this.currentUser.uid);
      await this.aircraftUsedService.updateItem(idOfAfterElement, event?.sort, this.currentUser.uid);
      await this.getItems();
      this.cdr.detectChanges();
    }
  }

  private onValidate() {
    this.submitted = true;
    return this.formAircrafts.valid;
  }

  onSubmit() {
    this.alertService.clear();

    if (this.onValidate()) {
      try {
        this.submitted = true;
        let data = {
          ...this.formAircrafts.value,
          sort: this.maxSort ? this.maxSort + 1 : 1,
        };
        this.aircraftUsedService.createItem(data, this.currentUser.uid).then((_) => {
          this.alertService.success('項目を追加しました。');
          this.submitted = false;
          this.resetFormValues();
        })
      } catch (error) {
        this.alertService.error('保存に失敗しました。');
      }
    }
  }
}
