import { ColorTypes } from "./colors";
import { HonorificTitleOption, IDataOption } from "./interface";

export const TEXT_TRUE: string = '有';
export const TEXT_FALSE: string = '無';
export const TEXT_PASS: string = '合格済';
export const UNTESTED: string = '未試験';
export const BEGINNER: string = '初学者';
export const EXPERIENCED: string = '経験者';
export const ALL_TEXT: string = '全て';
export const ATTENDING_NAME: string = '受講中';
export const GRADUATION_NAME: string = '修了';
export const UNABLE_GRADUATE_NAME: string = '未修了';
export const RESERVATION_GENERAL: string = '予約(一般)';
export const RESERVATION_RIZAEN: string = '予約(特例申請)';
export const NOT_ISSUED: string = 'アカウント未発行';
export const PUBLISHED: string = 'アカウント発行済み';
export const NOT_USE: string = '学習完了';
export const YES: string = 'あり';
export const NO: string = 'なし';
export const Processing: string = '申請中';
export const Released: string = '発行済';
export const LEVEl_ONE: string = 'レベル1';
export const EXAM_PASS: string = '合格';
export const LEVEl_TWO: string = 'レベル2';
export const HONORIFIC_TITLE: HonorificTitleOption[] = [
  { id: '1' , value: '様'},
  { id: '2' , value: '御中'},
]
export const GENDER_OPTION: IDataOption[] = [
  { value: '男性', label: '男性' },
  { value: '女性', label: '女性' },
  { value: '未選択', label: '未選択' }
];

export const STATUS_EXAM: IDataOption[] = [
  { value: '合格', label: '合格' },
  { value: '不合格', label: '不合格' }
];

export const statusCompletionOption: IDataOption[] = [
  { value: ATTENDING_NAME, label: ATTENDING_NAME },
  { value: GRADUATION_NAME, label: GRADUATION_NAME },
  { value: UNABLE_GRADUATE_NAME, label: UNABLE_GRADUATE_NAME },
  { value: RESERVATION_GENERAL, label: RESERVATION_GENERAL },
  { value: RESERVATION_RIZAEN, label: RESERVATION_RIZAEN },
];

export const statusElearning: IDataOption[] = [
  { value: NOT_ISSUED, label: NOT_ISSUED },
  { value: PUBLISHED, label: PUBLISHED },
  { value: NOT_USE, label: NOT_USE },
];

export const useElearning: IDataOption[] = [
  { value: YES, label: YES },
  { value: NO, label: NO },
]

export const experiencedTypeOption: IDataOption[] = [
  { value: EXPERIENCED, label: EXPERIENCED },
  { value: BEGINNER, label: BEGINNER },
]

export const privateSkillCertificateOption: IDataOption[] = [
  { value: TEXT_TRUE, label: TEXT_TRUE },
  { value: TEXT_FALSE, label: TEXT_FALSE },
]

export const issueOption: IDataOption[] = [
  { value: TEXT_TRUE, label: TEXT_TRUE },
  { value: TEXT_FALSE, label: TEXT_FALSE },
]


export const academicExamStatus: IDataOption[] = [
  { value: TEXT_PASS, label: TEXT_PASS },
  { value: UNTESTED, label: UNTESTED },
]

export const STUDENT = {
  ISSUE: 'issue',
  ACADEMIC_EXAM_STATUS: 'academicExamStatus',
  PRIVATE_SKILL_CERTIFICATE: "privateSkillCertificate"
}

export const experiencedType = {
  experienced: '経験者',
  beginner: "初学者"
}

export const experiencedTypeListColor = [
  {
    key: 'beginner_first',
    color: ColorTypes['white']
  },
  {
    key: 'beginner_second',
    color: ColorTypes['bright_blue']
  },
  {
    key: 'experienced_first',
    color: ColorTypes['moderate_green']
  },
  {
    key: 'experienced_second',
    color: ColorTypes['soft_pink']
  }
]
