<c-row class="mb-3">
  <c-col
    [xs]="haveRegistrationNumber ? '4' : '7'"
    [sm]="haveRegistrationNumber ? '4' : '8'"
    [md]="haveRegistrationNumber ? '6' : '9'"
    [lg]="haveRegistrationNumber ? '8' : '10'"
  >
    <input disabled cFormControl type="text" value="{{ itemInput.itemName }}"/>
  </c-col>
  <c-col xs="3" sm="4" md="3" lg="2" *ngIf="haveRegistrationNumber">
    <input disabled cFormControl type="text" value="{{ itemInput.registrationNumber}}"/>
  </c-col>
  <c-col xs="5" sm="4" md="3" lg="2">
    <div class="action">
      <div>
        <button class="btn-common button-clear" type="submit" (click)="clickUpItem()" [ngClass]="{'custom-style': itemInput?.sort == maxSort}">
          <svg [cIcon]="icons.cilArrowThickTop" size="lg" title="List Icon">
          </svg>
        </button>
      </div>
      <div *ngIf="itemInput?.sort != minSort">
        <button class="btn-common button-clear" type="submit" (click)="clickDownItem()">
          <svg [cIcon]="icons.cilArrowThickBottom" size="lg" title="List Icon"></svg>
        </button>
      </div>
      <div>
        <button [cModalToggle]="verticallyCenteredModal.id" class="btn-common button-clear" type="submit">削除</button>
        <c-modal #verticallyCenteredModal alignment="center" id="verticallyCenteredModal{{ itemInput?.id }}">
          <c-modal-header>
            <h5 cModalTitle>項目を削除しますか？</h5>
            <button [cModalToggle]="verticallyCenteredModal.id" cButtonClose></button>
          </c-modal-header>
          <c-modal-footer>
            <button [cModalToggle]="verticallyCenteredModal.id" cButton class="btn_cancel">
              いいえ
            </button>
            <button cButton [cModalToggle]="verticallyCenteredModal.id" class="btn_delete" (click)="handleDeleteItem()">はい</button>
          </c-modal-footer>
        </c-modal>
      </div>
    </div>
  </c-col>
</c-row>
