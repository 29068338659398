export const Images = <any>{
  "application/vnd.ms-powerpoint": "/assets/svg/common/ppt-file.svg",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": "/assets/svg/common/ppt-file.svg",
  "text/csv": "/assets/svg/common/excel-file.svg",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "/assets/svg/common/excel-file.svg",
  "application/vnd.ms-excel": "/assets/svg/common/excel-file.svg",
  "application/pdf": "/assets/svg/common/pdf-file.svg",
  "application/msword": "/assets/svg/common/word-file.svg",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "/assets/svg/common/word-file.svg",
  "application/x-7z-compressed": "/assets/svg/common/zip-file.svg",
  "application/vnd.rar": "/assets/svg/common/zip-file.svg",
  "application/x-bzip": "/assets/svg/common/zip-file.svg",
  "application/x-bzip2": "/assets/svg/common/zip-file.svg",
  "application/gzip": "/assets/svg/common/zip-file.svg",
  "application/zip": "/assets/svg/common/zip-file.svg",
  "file": "/assets/svg/common/file.svg"
}
