<nav *ngIf="leftMenu" class="sidebar" [ngClass]="{ 'close' : !active }">
  <header>
    <div class="text image-text">
      <span>{{ sidebarTitle }}</span>
    </div>
    <img (click)="toggleNav()" class="toggle" ngSrc="assets/svg/chevron-right.svg" alt="" height="24" width="24">
  </header>

  <div class="menu-bar">
    <div class="menu">
      <ul class="menu-links">
        <li *ngFor="let navItem of navItems" class="nav-link">
          <a [routerLink]="['iframe']" [queryParams]="{ url: navItem?.link }"  >
            <div class="icon">
              <img src="{{ navItem?.icon }}" alt="Menu icon">
            </div>
            <span class="text">{{ navItem?.name }}</span>
          </a>
        </li>
      </ul>
<!--        <router-outlet></router-outlet>-->
    </div>
  </div>
</nav>
