<div class="mb-3">
  <label for="{{ formControlNameInput }}" class="form-label mb-1">
    {{ label }}
    <c-badge *ngIf="requiredText" color="danger">{{ "form.inputLabel.must" | translate}}</c-badge>
  </label>
  <select cSelect [formControlName]="formControlNameInput">
    <option [selected]="true" [value]="null" hidden>
      {{ "form.placeHolder.select" | translate }}
    </option>
    <option *ngFor="let item of dataOption" [value]="item?.id">
      {{ item?.title }}
    </option>
  </select>
  <c-form-feedback *ngIf="(submitted || form[formControlNameInput]?.touched) && form[formControlNameInput].errors">
    <div *ngIf="form[formControlNameInput]?.errors[getFirstError(form[formControlNameInput]?.errors) || '']">
      <div *ngIf="getFirstError(form[formControlNameInput]?.errors) === 'required'">
        {{ "errorMessages.formValidate.input.required" | translate }}
      </div>
      <div *ngIf="getFirstError(form[formControlNameInput]?.errors) === 'maxlength'">
        {{ "errorMessages.formValidate.input.maxlength" | translate }}
      </div>
      <div *ngIf="getFirstError(form[formControlNameInput]?.errors) === 'pattern'">
        {{
          "errorMessages.formValidate." + formControlNameInput + '.' + getFirstError(form[formControlNameInput]?.errors) | translate
        }}
      </div>
    </div>
  </c-form-feedback>
</div>
