<div [formGroup]="formGroup">

  <div class="form_item">
    <div class="form_item--title">修了などの記録</div>
    <div class="form_item--content">

      <app-input-date-picker
        [label]="'修了審査合格年月日'"
        [formControlNameInput]="'completionDate'"
        [formGroup]="formGroup"
        [submitted]="submitted"
        [requiredText]="false"
        [form]="form">
      </app-input-date-picker>

      <app-input-base
        [label]="'修了審査実施会場'"
        class="input-group-container"
        [formControlNameInput]="'completionPlace'"
        [placeHolderInput]="'修了審査実施会場'"
        [inputType]="'text'"
        [submitted]="submitted"
        [requiredText]="false"
        [maxLength]="'15'"
        [form]="form">
      </app-input-base>

      <app-input-base
        [label]="'担当修了審査員'"
        class="input-group-container"
        [formControlNameInput]="'completionResponsible'"
        [placeHolderInput]="'担当修了審査員'"
        [inputType]="'text'"
        [submitted]="submitted"
        [requiredText]="false"
        [maxLength]="'15'"
        [form]="form">
      </app-input-base>

      <app-input-base
        [label]="'修了番号'"
        class="input-group-container"
        [formControlNameInput]="'completionNumber'"
        [placeHolderInput]="'修了番号'"
        [inputType]="'text'"
        [submitted]="submitted"
        [requiredText]="false"
        [maxLength]="'15'"
        [form]="form">
      </app-input-base>

      <!-- status -->
      <app-input-radio
        [label]="'student.createStudent.confirmation.status' | translate"
        [formControlNameInput]="'status'"
        [dataOption]="status"
        [submitted]="submitted"
        [form]="form">
      </app-input-radio>
    </div>

    <div class="form_item">
      <div class="form_item--title">その他</div>
      <div class="form_item--content">
        <app-text-area
          [label]="'備考'"
          class="input-group-container"
          [formControlNameInput]="'remark'"
          [placeHolderInput]="'備考'"
          [inputType]="'text'"
          [submitted]="submitted"
          [requiredText]="false"
          [maxLength]="'1023'"
          [form]="form">

        </app-text-area>
      </div>
    </div>
  </div>
</div>
