import { AccountType, AccountTypeName, UserRole } from "../enums";
import { Timestamp } from 'firebase/firestore';

export interface IUser {
  uid: string;
  email: string;
  name: string;
  displayName: string;
  photoURL: string;
  active: boolean;
  purchaseDate: Timestamp;
  currentLessonID: number|string;
  dips: string;
  userRole: UserRole,
  accountType: AccountType,
  accountTypeName: AccountTypeName,
  createAt: Timestamp;
  updateAt: Timestamp;
}
