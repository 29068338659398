import { Timestamp } from "firebase/firestore";
import { ICompletionRecord } from "@app/models/completion-record";

export interface IStudent {
  id?: string
  // student [個人情報]
  studentInfo?: IStudentInfo,
  //Student Enrollment
  studentEnrollment?: IStudentEnrollment,
  //Student Category [受講者区分]
  studentClassification?: IStudentClassification,
  //identity verification[本人確認の書類]
  studentIdentityVerification?: IStudentIdentityVerification,
  // [無人航空機操縦士資格の有無と種類]
  studentPilotLicense?: IStudentPilotLicense,
  //student course
  studentCourseMulti?: IStudentCourseMulti,
  studentCourseAirplane?: IStudentCourseAirplane,
  studentCourseHelicopter?: IStudentCourseHelicopter,
  //Maneuver Mode
  studentManeuverMode?: IStudentManeuverMode,
  completionRecords?: ICompletionRecord,
  level?: string,
  userId?: string
}

export interface IStudentInfo {
  ledgerNumber: number,
  studentNumber: number,
  hurigana: string
  name: string,
  certificateNumber: string,
  birthday: Date | Timestamp,
  sex: string,
  address: string,
  tel: string,
  email: string,
  remark: string,
  picture?: string,
  academicExamStatus: string,
  academicExamPassDate: Date | Timestamp,
  academicExamPassCertificateNumber: string,
  courseItems: number,
  organizationName: string,
  createAt?: Date,
}

export interface IStudentEnrollment {
  enrollmentDate: Date | Timestamp,
  practicalDate: Date | Timestamp
}

export interface IStudentClassification {
  experiencedType: string,
  privateSkillCertificate: string,
  totalFlightTime: number,
  issuingOrganizationName: string,
  no: number,
  no_expireDate: Date | Timestamp,
  number: number,
  number_expireDate: Date | Timestamp,
  status: string,
  statusElearning: string,
  useElearning: boolean,
}

export interface IStudentIdentityVerification {
  driverLicense: string
  myNumberCard: string,
  etc: string,
  etcText: string,
  noEtc: string
}

export interface IStudentPilotLicense {
  issue: boolean,
  dateOfIssue: Date | Timestamp,
  dateOfExpiry: Date | Timestamp,
  skillNumber: string,
  licenseConditions: string,

  x_firstClassBasic: boolean,
  x_firstClassDaytimeOnly: boolean,
  x_firstClassVisualLineOfSight: boolean,
  x_firstClassWeightLimited: boolean,
  x_secondClassBasic: boolean,
  x_secondClassDaytimeOnly: boolean,
  x_secondClassVisualLineOfSight: boolean,
  x_secondClassWeightLimited: boolean,
  y_firstClassDaytimeOnly: boolean,
  y_firstClassVisualLineOfSight: boolean,
  y_firstClassWeightLimited: boolean,
  y_firstClassBasic: boolean,
  y_secondClassBasic: boolean,
  y_secondClassDaytimeOnly: boolean,
  y_secondClassVisualLineOfSight: boolean,
  y_secondClassWeightLimited: boolean,

  z_firstClassBasic: boolean,
  z_firstClassDaytimeOnly: boolean,
  z_firstClassVisualLineOfSight: boolean,
  z_firstClassWeightLimited: boolean,
  z_secondClassBasic: boolean,
  z_secondClassDaytimeOnly: boolean,
  z_secondClassVisualLineOfSight: boolean,
  z_secondClassWeightLimited: boolean
}

export interface IStudentCourseMulti {
  a_rotorcraftMulti: boolean,

  a_firstClassBasic: boolean,
  a_firstClassBasicDate: Date | Timestamp,
  a_firstClassBasicNo: string,
  a_firstClassBasicPlace: string,
  a_firstClassBasicResponsible: string,
  a_firstClassBasicSendingDate: Date | Timestamp,
  a_firstClassBasicDeliveryDate: Date | Timestamp,
  a_firstClassBasicExpirationDate: Date | Timestamp,
  a_firstClassBasicStatus: string,
  a_firstClassBasicApplicationDate: Date | Timestamp,

  a_firstClassDaytimeOnly: boolean,
  a_firstClassDaytimeOnlyDate: Date | Timestamp,
  a_firstClassDaytimeOnlyNo: string,
  a_firstClassDaytimeOnlyPlace: string,
  a_firstClassDaytimeOnlyResponsible: string,
  a_firstClassDaytimeOnlySendingDate: Date | Timestamp,
  a_firstClassDaytimeOnlyDeliveryDate: Date | Timestamp,
  a_firstClassDaytimeOnlyExpirationDate: Date | Timestamp,
  a_firstClassDaytimeOnlyStatus: string,
  a_firstClassDaytimeOnlyApplicationDate: Date | Timestamp,

  a_firstClassVisualLineOfSight: boolean,
  a_firstClassVisualLineOfSightDate: Date | Timestamp,
  a_firstClassVisualLineOfSightNo: string,
  a_firstClassVisualLineOfSightPlace: string,
  a_firstClassVisualLineOfSightResponsible: string,
  a_firstClassVisualLineOfSightSendingDate: Date | Timestamp,
  a_firstClassVisualLineOfSightDeliveryDate: Date | Timestamp,
  a_firstClassVisualLineOfSightExpirationDate: Date | Timestamp,
  a_firstClassVisualLineOfSightStatus: string,
  a_firstClassVisualLineOfSightApplicationDate: Date | Timestamp,

  a_firstClassWeightLimited: boolean,
  a_firstClassWeightLimitedDate: Date | Timestamp,
  a_firstClassWeightLimitedNo: string,
  a_firstClassWeightLimitedPlace: string,
  a_firstClassWeightLimitedResponsible: string,
  a_firstClassWeightLimitedSendingDate: Date | Timestamp,
  a_firstClassWeightLimitedDeliveryDate: Date | Timestamp,
  a_firstClassWeightLimitedExpirationDate: Date | Timestamp,
  a_firstClassWeightLimitedStatus: string,
  a_firstClassWeightLimitedApplicationDate: Date | Timestamp,

  a_secondClassBasic: boolean,
  a_secondClassBasicDate: Date | Timestamp,
  a_secondClassBasicNo: string,
  a_secondClassBasicPlace: string,
  a_secondClassBasicResponsible: string,
  a_secondClassBasicSendingDate: Date | Timestamp,
  a_secondClassBasicDeliveryDate: Date | Timestamp,
  a_secondClassBasicExpirationDate: Date | Timestamp,
  a_secondClassBasicStatus: string,
  a_secondClassBasicApplicationDate: Date | Timestamp,

  a_secondClassDaytimeOnly: boolean,
  a_secondClassDaytimeOnlyDate: Date | Timestamp,
  a_secondClassDaytimeOnlyNo: string,
  a_secondClassDaytimeOnlyPlace: string,
  a_secondClassDaytimeOnlyResponsible: string,
  a_secondClassDaytimeOnlySendingDate: Date | Timestamp,
  a_secondClassDaytimeOnlyDeliveryDate: Date | Timestamp,
  a_secondClassDaytimeOnlyExpirationDate: Date | Timestamp,
  a_secondClassDaytimeOnlyStatus: string,
  a_secondClassDaytimeOnlyApplicationDate: Date | Timestamp,

  a_secondClassVisualLineOfSight: boolean,
  a_secondClassVisualLineOfSightDate: Date | Timestamp,
  a_secondClassVisualLineOfSightNo: string,
  a_secondClassVisualLineOfSightPlace: string,
  a_secondClassVisualLineOfSightResponsible: string,
  a_secondClassVisualLineOfSightSendingDate: Date | Timestamp,
  a_secondClassVisualLineOfSightDeliveryDate: Date | Timestamp,
  a_secondClassVisualLineOfSightExpirationDate: Date | Timestamp,
  a_secondClassVisualLineOfSightStatus: string,
  a_secondClassVisualLineOfSightApplicationDate: Date | Timestamp,

  a_secondClassWeightLimited: boolean,
  a_secondClassWeightLimitedDate: Date | Timestamp,
  a_secondClassWeightLimitedNo: number,
  a_secondClassWeightLimitedPlace: string,
  a_secondClassWeightLimitedResponsible: string,
  a_secondClassWeightLimitedSendingDate: Date | Timestamp,
  a_secondClassWeightLimitedDeliveryDate: Date | Timestamp,
  a_secondClassWeightLimitedExpirationDate: Date | Timestamp,
  a_secondClassWeightLimitedStatus: string,
  a_secondClassWeightLimitedApplicationDate: Date | Timestamp,
}

export interface IStudentCourseAirplane {
  b_plane: boolean,

  b_firstClassBasic: boolean,
  b_firstClassBasicDate: Date | Timestamp,
  b_firstClassBasicNo: string,
  b_firstClassBasicPlace: string,
  b_firstClassBasicResponsible: string,
  b_firstClassBasicSendingDate: Date | Timestamp,
  b_firstClassBasicDeliveryDate: Date | Timestamp,
  b_firstClassBasicExpirationDate: Date | Timestamp,
  b_firstClassBasicStatus: string,
  b_firstClassBasicApplicationDate: Date | Timestamp,

  b_firstClassDaytimeOnly: boolean,
  b_firstClassDaytimeOnlyDate: Date | Timestamp,
  b_firstClassDaytimeOnlyNo: string,
  b_firstClassDaytimeOnlyPlace: string,
  b_firstClassDaytimeOnlyResponsible: string,
  b_firstClassDaytimeOnlySendingDate: Date | Timestamp,
  b_firstClassDaytimeOnlyDeliveryDate: Date | Timestamp,
  b_firstClassDaytimeOnlyExpirationDate: Date | Timestamp,
  b_firstClassDaytimeOnlyStatus: string,
  b_firstClassDaytimeOnlyApplicationDate: Date | Timestamp,

  b_firstClassVisualLineOfSight: boolean,
  b_firstClassVisualLineOfSightDate: Date | Timestamp,
  b_firstClassVisualLineOfSightNo: string,
  b_firstClassVisualLineOfSightPlace: string,
  b_firstClassVisualLineOfSightResponsible: string,
  b_firstClassVisualLineOfSightSendingDate: Date | Timestamp,
  b_firstClassVisualLineOfSightDeliveryDate: Date | Timestamp,
  b_firstClassVisualLineOfSightExpirationDate: Date | Timestamp,
  b_firstClassVisualLineOfSightStatus: string,
  b_firstClassVisualLineOfSightApplicationDate: Date | Timestamp,

  b_firstClassWeightLimited: boolean,
  b_firstClassWeightLimitedDate: Date | Timestamp,
  b_firstClassWeightLimitedNo: string,
  b_firstClassWeightLimitedPlace: string,
  b_firstClassWeightLimitedResponsible: string,
  b_firstClassWeightLimitedSendingDate: Date | Timestamp,
  b_firstClassWeightLimitedDeliveryDate: Date | Timestamp,
  b_firstClassWeightLimitedExpirationDate: Date | Timestamp,
  b_firstClassWeightLimitedStatus: string,
  b_firstClassWeightLimitedApplicationDate: Date | Timestamp,

  b_secondClassBasic: boolean,
  b_secondClassBasicDate: Date | Timestamp,
  b_secondClassBasicNo: string,
  b_secondClassBasicPlace: string,
  b_secondClassBasicResponsible: string,
  b_secondClassBasicSendingDate: Date | Timestamp,
  b_secondClassBasicDeliveryDate: Date | Timestamp,
  b_secondClassBasicExpirationDate: Date | Timestamp,
  b_secondClassBasicStatus: string,
  b_secondClassBasicApplicationDate: Date | Timestamp,

  b_secondClassDaytimeOnly: boolean,
  b_secondClassDaytimeOnlyDate: Date | Timestamp,
  b_secondClassDaytimeOnlyNo: string,
  b_secondClassDaytimeOnlyPlace: string,
  b_secondClassDaytimeOnlyResponsible: string,
  b_secondClassDaytimeOnlySendingDate:Date | Timestamp,
  b_secondClassDaytimeOnlyDeliveryDate: Date | Timestamp,
  b_secondClassDaytimeOnlyExpirationDate: Date | Timestamp,
  b_secondClassDaytimeOnlyStatus: string,
  b_secondClassDaytimeOnlyApplicationDate: Date | Timestamp,

  b_secondClassVisualLineOfSight: boolean,
  b_secondClassVisualLineOfSightDate: Date | Timestamp,
  b_secondClassVisualLineOfSightNo: string,
  b_secondClassVisualLineOfSightPlace: string,
  b_secondClassVisualLineOfSightResponsible: string,
  b_secondClassVisualLineOfSightSendingDate: Date | Timestamp,
  b_secondClassVisualLineOfSightDeliveryDate: Date | Timestamp,
  b_secondClassVisualLineOfSightExpirationDate: Date | Timestamp,
  b_secondClassVisualLineOfSightStatus: string,
  b_secondClassVisualLineOfSightApplicationDate: Date | Timestamp,

  b_secondClassWeightLimited: boolean,
  b_secondClassWeightLimitedDate: Date | Timestamp,
  b_secondClassWeightLimitedNo: number,
  b_secondClassWeightLimitedPlace: string,
  b_secondClassWeightLimitedResponsible: string,
  b_secondClassWeightLimitedSendingDate: Date | Timestamp,
  b_secondClassWeightLimitedDeliveryDate: Date | Timestamp,
  b_secondClassWeightLimitedExpirationDate: Date | Timestamp,
  b_secondClassWeightLimitedStatus: string,
  b_secondClassWeightLimitedApplicationDate: Date | Timestamp,
}

export interface IStudentCourseHelicopter {
  c_rotorcraft: boolean,

  c_firstClassBasic: boolean,
  c_firstClassBasicDate: Date | Timestamp,
  c_firstClassBasicNo: string,
  c_firstClassBasicPlace: string,
  c_firstClassBasicResponsible: string,
  c_firstClassBasicSendingDate: Date | Timestamp,
  c_firstClassBasicDeliveryDate: Date | Timestamp,
  c_firstClassBasicExpirationDate: Date | Timestamp,
  c_firstClassBasicStatus: string,
  c_firstClassBasicApplicationDate: Date | Timestamp,

  c_firstClassDaytimeOnly: boolean,
  c_firstClassDaytimeOnlyDate: Date | Timestamp,
  c_firstClassDaytimeOnlyNo: string,
  c_firstClassDaytimeOnlyPlace: string,
  c_firstClassDaytimeOnlyResponsible: string,
  c_firstClassDaytimeOnlySendingDate: Date | Timestamp,
  c_firstClassDaytimeOnlyDeliveryDate: Date | Timestamp,
  c_firstClassDaytimeOnlyExpirationDate: Date | Timestamp,
  c_firstClassDaytimeOnlyStatus: string,
  c_firstClassDaytimeOnlyApplicationDate: Date | Timestamp,

  c_firstClassVisualLineOfSight: boolean,
  c_firstClassVisualLineOfSightDate: Date | Timestamp,
  c_firstClassVisualLineOfSightNo: string,
  c_firstClassVisualLineOfSightPlace: string,
  c_firstClassVisualLineOfSightResponsible: string,
  c_firstClassVisualLineOfSightSendingDate: Date | Timestamp,
  c_firstClassVisualLineOfSightDeliveryDate: Date | Timestamp,
  c_firstClassVisualLineOfSightExpirationDate: Date | Timestamp,
  c_firstClassVisualLineOfSightStatus: string,
  c_firstClassVisualLineOfSightApplicationDate: Date | Timestamp,

  c_firstClassWeightLimited: boolean,
  c_firstClassWeightLimitedDate: Date | Timestamp,
  c_firstClassWeightLimitedNo: string,
  c_firstClassWeightLimitedPlace: string,
  c_firstClassWeightLimitedResponsible: string,
  c_firstClassWeightLimitedSendingDate: Date | Timestamp,
  c_firstClassWeightLimitedDeliveryDate: Date | Timestamp,
  c_firstClassWeightLimitedExpirationDate: Date | Timestamp,
  c_firstClassWeightLimitedStatus: string,
  c_firstClassWeightLimitedApplicationDate: Date | Timestamp,

  c_secondClassBasic: boolean,
  c_secondClassBasicDate: Date | Timestamp,
  c_secondClassBasicNo: string,
  c_secondClassBasicPlace: string,
  c_secondClassBasicResponsible: string,
  c_secondClassBasicSendingDate: Date | Timestamp,
  c_secondClassBasicDeliveryDate: Date | Timestamp,
  c_secondClassBasicExpirationDate: Date | Timestamp,
  c_secondClassBasicStatus: string,
  c_secondClassBasicApplicationDate: Date | Timestamp,

  c_secondClassDaytimeOnly: boolean,
  c_secondClassDaytimeOnlyDate: Date | Timestamp,
  c_secondClassDaytimeOnlyNo: string,
  c_secondClassDaytimeOnlyPlace: string,
  c_secondClassDaytimeOnlyResponsible: string,
  c_secondClassDaytimeOnlySendingDate: Date | Timestamp,
  c_secondClassDaytimeOnlyDeliveryDate: Date | Timestamp,
  c_secondClassDaytimeOnlyExpirationDate: Date | Timestamp,
  c_secondClassDaytimeOnlyStatus: string,
  c_secondClassDaytimeOnlyApplicationDate: Date | Timestamp,

  c_secondClassVisualLineOfSight: boolean,
  c_secondClassVisualLineOfSightDate: Date | Timestamp,
  c_secondClassVisualLineOfSightNo: string,
  c_secondClassVisualLineOfSightPlace: string,
  c_secondClassVisualLineOfSightResponsible: string,
  c_secondClassVisualLineOfSightSendingDate: Date | Timestamp,
  c_secondClassVisualLineOfSightDeliveryDate: Date | Timestamp,
  c_secondClassVisualLineOfSightExpirationDate: Date | Timestamp,
  c_secondClassVisualLineOfSightStatus: string,
  c_secondClassVisualLineOfSightApplicationDate: Date | Timestamp,

  c_secondClassWeightLimited: boolean,
  c_secondClassWeightLimitedDate: Date | Timestamp,
  c_secondClassWeightLimitedNo: string,
  c_secondClassWeightLimitedPlace: string,
  c_secondClassWeightLimitedResponsible: string,
  c_secondClassWeightLimitedSendingDate: Date | Timestamp,
  c_secondClassWeightLimitedDeliveryDate: Date | Timestamp,
  c_secondClassWeightLimitedExpirationDate: Date | Timestamp,
  c_secondClassWeightLimitedStatus: string,
  c_secondClassWeightLimitedApplicationDate: Date | Timestamp,
}

export interface IStudentManeuverMode {
  mode1: boolean,
  mode2: boolean,
  mode3: boolean,
}

export interface ISearch {
  querySearch: string,
  startDate: Date,
  endDate: Date,
  level: string,
  status: string,
  statusElearning: string,
  courseItems: number,
}

export interface ISearchQuotation {
  querySearch: string,
  startDate: Date,
  endDate: Date,
  quotationItem: string
}

export const Student: IStudent[] = [];
