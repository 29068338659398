import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class StateService {
    private appState: any = {};

    set(key: string, value: any): void {
        this.appState[key] = value;
    }

    get(key: string): any {
        return this.appState[key];
    }
}