<c-row class="mb-3 d-none d-lg-flex">
  <c-col [lg]="5">
    <input disabled cFormControl type="text" value="{{ itemInput?.itemName }}"/>
  </c-col>
  <c-col [lg]="5" class="mb-2">
    <c-row>
      <c-col [lg]="4">
        <input disabled class="inp-quotation" cFormControl type="text" value="{{ itemInput?.unitPrice | number:'1.0-3' }}"/>

      </c-col>
      <c-col [lg]="4">
        <input disabled class="inp-quotation" cFormControl type="text" value="{{ itemInput?.quantity }}"/>

      </c-col>
      <c-col [lg]="4">
        <input disabled class="inp-quotation" cFormControl type="text" value="{{ itemInput?.total | number:'1.0-3' }}"/>

      </c-col>
    </c-row>
  </c-col>
  <c-col [lg]="2" class="mb-2">
    <c-col xs="12" sm="2">
      <div class="action">
        <div>
          <a class="form-create-data btn-common btn-title btn-action" [ngClass]="{'custom-style': itemIndex == 0}" (click)="handleMoveItemUp(itemIndex)">
            <svg [cIcon]="icons.cilArrowThickTop" size="lg" title="List Icon">
            </svg>
          </a>
        </div>
        <div *ngIf="itemIndex != lengthDataQuotationArr - 1">
          <a class="form-create-data btn-common btn-title btn-action" (click)="handleMoveItemDown(itemIndex)">
            <svg [cIcon]="icons.cilArrowThickBottom" size="lg" title="List Icon"></svg>
          </a>
        </div>
        <div>
          <a class="form-create-data btn-common btn-title btn-action" (click)="handleDeleteItemQuotation(itemIndex)">削除</a>
        </div>
      </div>
    </c-col>
  </c-col>
</c-row>

<c-card class="mb-3 card-border d-flex d-lg-none px-1 py-2">
  <c-card-body class="d-flex align-items-center px-3 py-2">
    <c-col class="col-4">項目名</c-col>
    <c-col class="col-8">
      <input
        disabled
        cFormControl
        type="text"
        value="{{ itemInput?.itemName }}"
        cTooltip="{{ itemInput?.itemName }}"
        cTooltipPlacement="top"
      />
    </c-col>
  </c-card-body>
  <c-card-body class="d-flex align-items-center px-3 py-2">
    <c-col class="col-4">単価</c-col>
    <c-col class="col-8">
      <input disabled class="inp-quotation float-end" cFormControl type="text" value="{{ itemInput?.unitPrice | number:'1.0-3' }}"/>
    </c-col>
  </c-card-body>
  <c-card-body class="d-flex align-items-center px-3 py-2">
    <c-col class="col-4">数量</c-col>
    <c-col class="col-8">
      <input disabled class="inp-quotation float-end" cFormControl type="text" value="{{ itemInput?.quantity }}"/>
    </c-col>
  </c-card-body>
  <c-card-body class="d-flex align-items-center px-3 py-2">
    <c-col class="col-4">金額</c-col>
    <c-col class="col-8">
      <input disabled class="inp-quotation float-end" cFormControl type="text" value="{{ itemInput?.total | number:'1.0-3' }}"/>
    </c-col>
  </c-card-body>
  <c-card-body class="d-flex align-items-center px-3 py-2">
    <c-col>
      <div class="action float-end">
        <div>
          <a class="form-create-data btn-common btn-title btn-action" [ngClass]="{'custom-style': itemIndex == 0}" (click)="handleMoveItemUp(itemIndex)">
            <svg [cIcon]="icons.cilArrowThickTop" size="lg" title="List Icon">
            </svg>
          </a>
        </div>
        <div *ngIf="itemIndex != lengthDataQuotationArr - 1">
          <a class="form-create-data btn-common btn-title btn-action" (click)="handleMoveItemDown(itemIndex)">
            <svg [cIcon]="icons.cilArrowThickBottom" size="lg" title="List Icon"></svg>
          </a>
        </div>
        <div>
          <a class="form-create-data btn-common btn-title btn-action" (click)="handleDeleteItemQuotation(itemIndex)">削除</a>
        </div>
      </div>
    </c-col>
  </c-card-body>
</c-card>
