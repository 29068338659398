<div [formGroup]="formGroup">
  <div class="form_item">
    <div class="form_item--title">
      {{ "student.createStudent.confirmation.studentClassification" | translate }}
    </div>
    <div class="form_item--content">
      <!-- experiencedType -->
      <app-input-radio
        [requiredText]="false"
        [formControlNameInput]="'experiencedType'"
        [dataOption]="experiencedType"
        [submitted]="submitted"
        [form]="form">
      </app-input-radio>

      <!-- privateSkillCertificate -->
      <app-input-radio
        [label]="'student.createStudent.confirmation.privateSkillCertificate' | translate"
        [formControlNameInput]="'privateSkillCertificate'"
        [dataOption]="privateSkillCertificate"
        [submitted]="submitted"
        [form]="form"
        (changeValue)="changePrivateSkillCertificate($event)"
      >
      </app-input-radio>

      <div>


        <!-- issuingOrganizationName -->
        <app-input-base
          [label]="'student.createStudent.confirmation.issuingOrganizationName' | translate"
          [formControlNameInput]="'issuingOrganizationName'"
          [placeHolderInput]="'student.createStudent.confirmation.issuingOrganizationName' | translate"
          [inputType]="'text'"
          [submitted]="submitted"
          [requiredText]="formGroup.get(STUDENT.PRIVATE_SKILL_CERTIFICATE)?.value === TEXT_TRUE"
          [maxLength]="'127'"
          [minLength]="'1'"
          [form]="form">
        </app-input-base>

        <c-row>
          <c-col [md]="6">
            <!-- no -->
            <app-input-base
              [label]="'student.createStudent.confirmation.no' | translate"
              [formControlNameInput]="'no'"
              [placeHolderInput]="'student.createStudent.confirmation.no' | translate"
              [inputType]="'text'"
              [maxLength]="'15'"
              [minLength]="'1'"
              [submitted]="submitted"
              [requiredText]="false"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- enrollmentDate -->
            <app-input-date-picker
              [label]="'student.createStudent.confirmation.no_expireDate' | translate"
              [formControlNameInput]="'no_expireDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [initialDate]="form['no_expireDate'].value"
              [requiredText]="formGroup.get(STUDENT.PRIVATE_SKILL_CERTIFICATE)?.value === TEXT_TRUE"
              [form]="form">
            </app-input-date-picker>
          </c-col>
        </c-row>

        <c-row>
          <c-col [md]="6">
            <!-- number -->
            <app-input-base
              [label]="'student.createStudent.confirmation.number' | translate"
              [formControlNameInput]="'number'"
              [placeHolderInput]="'student.createStudent.confirmation.number' | translate"
              [inputType]="'text'"
              [maxLength]="'16'"
              [minLength]="'1'"
              [requiredText]="false"
              [submitted]="submitted"
              [form]="form">
            </app-input-base>
          </c-col>
          <c-col [md]="6">
            <!-- number_expireDate -->
            <app-input-date-picker
              [label]="'student.createStudent.confirmation.number_expireDate' | translate"
              [formControlNameInput]="'number_expireDate'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [initialDate]="form['number_expireDate'].value"
              [requiredText]="false"
              [form]="form">
            </app-input-date-picker>
          </c-col>
        </c-row>

        <!-- totalFlightTime -->
        <app-input-base
          [label]="'student.createStudent.confirmation.totalFlightTime' | translate"
          [formControlNameInput]="'totalFlightTime'"
          [placeHolderInput]="'student.createStudent.confirmation.totalFlightTime' | translate"
          [inputType]="'number'"
          [max]="'9999'"
          [min]="'0'"
          [submitted]="submitted"
          [requiredText]="formGroup.get(STUDENT.PRIVATE_SKILL_CERTIFICATE)?.value === TEXT_FALSE"
          (keypress)="numberOnly($event)"
          [form]="form">
        </app-input-base>
      </div>
    </div>
  </div>

  <div class="form_item">
    <div class="form_item--title">
      {{ "student.createStudent.confirmation.documentsVerification" | translate }}
    </div>
    <div class="form_item--content">
      <!-- driverLicense -->
      <div style="margin-bottom: 20px;">
        <app-input-checkbox
          [label]="'student.createStudent.confirmation.driverLicense' | translate"
          [formControlNameInput]="'driverLicense'"
          [submitted]="submitted"
          [formGroup]="formGroup"
          [form]="form">
        </app-input-checkbox>
      </div>

      <!-- myNumberCard -->
      <app-input-checkbox
        [label]="'student.createStudent.confirmation.myNumberCard' | translate"
        [formControlNameInput]="'myNumberCard'"
        [submitted]="submitted"
        [formGroup]="formGroup"
        [form]="form">
      </app-input-checkbox>

      <!-- etc -->
      <c-row>
        <div class="etc_checkbox">
          <app-input-checkbox
            [label]="'student.createStudent.confirmation.etc' | translate"
            [formControlNameInput]="'etc'"
            [submitted]="submitted"
            [formGroup]="formGroup"
            (changeValue)="changeValueEtcCheckBox()"
            [form]="form">
          </app-input-checkbox>
        </div>

        <c-col [md]="6">
          <input
            class="etc_text"
            cFormControl
            type="text"
            [class]="{
              'is-invalid': (submitted || form['etcText']?.touched) && form['etcText']?.invalid && isDisabled == false,
              'is-valid': form['etcText']?.touched && form['etcText']?.valid && isDisabled == false
            }"
            placeholder="{{ 'student.createStudent.confirmation.etc' | translate }}"
            formControlName="etcText"
            [attr.disabled]="formGroup.get('etc').value === true ? null : true"
          />
        </c-col>
      </c-row>

      <!-- noEtc -->
      <app-input-base
        [label]="'student.createStudent.confirmation.noEtc' | translate"
        [formControlNameInput]="'noEtc'"
        [placeHolderInput]="'student.createStudent.confirmation.noEtc' | translate"
        [inputType]="'text'"
        [maxLength]="'15'"
        [minLength]="'1'"
        [submitted]="submitted"
        [requiredText]="false"
        [form]="form">
      </app-input-base>
    </div>
  </div>

  <div class="form_item">
    <div class="form_item--title">{{ 'student.createStudent.basic.pilotingMode' | translate }}</div>
    <div class="form_item--content">
      <div class="form_item--checkbox_group">
        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.basic.model1' | translate"
          [formControlNameInput]="'mode1'"
          [submitted]="submitted"
          [formGroup]="formGroup"
          [form]="form">
        </app-input-checkbox>

        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.basic.model2' | translate"
          [formControlNameInput]="'mode2'"
          [submitted]="submitted"
          [formGroup]="formGroup"
          [form]="form">
        </app-input-checkbox>

        <app-input-checkbox
          [ngClass]="'checkbox_group--inline'"
          [label]="'student.createStudent.basic.model3' | translate"
          [formControlNameInput]="'mode3'"
          [submitted]="submitted"
          [formGroup]="formGroup"
          [form]="form">
        </app-input-checkbox>
      </div>
    </div>
  </div>

  <div class="form_item">
    <div class="form_item--title">{{ "student.createStudent.confirmation.presenceAndTypeOfUnmanned" | translate}}</div>
    <div class="form_item--content">
      <!-- issue-->
      <app-input-radio
        [label]="'student.createStudent.confirmation.issue' | translate"
        class="input-group-container"
        [formControlNameInput]="'issue'"
        [dataOption]="issue"
        [submitted]="submitted"
        (changeValue)="changeIssue($event)"
        [form]="form">
      </app-input-radio>

      <div>
        <c-row>
          <c-col [md]="6">
            <!-- dateOfIssue -->
            <app-input-date-picker
              [label]="'student.createStudent.confirmation.dateOfIssue' | translate"
              [formControlNameInput]="'dateOfIssue'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [initialDate]="form['dateOfIssue'].value"
              [requiredText]="formGroup.get(STUDENT.ISSUE)?.value === TEXT_TRUE"
              [form]="form">
            </app-input-date-picker>
          </c-col>
          <c-col [md]="6">
            <!-- dateOfExpiry -->
            <app-input-date-picker
              [label]="'student.createStudent.confirmation.dateOfExpiry' | translate"
              [formControlNameInput]="'dateOfExpiry'"
              [formGroup]="formGroup"
              [submitted]="submitted"
              [initialDate]="form['dateOfExpiry'].value"
              [requiredText]="formGroup.get(STUDENT.ISSUE)?.value === TEXT_TRUE"
              [form]="form">
            </app-input-date-picker>
          </c-col>
        </c-row>

        <!-- skillNumber -->
        <app-input-base
          [label]="'student.createStudent.confirmation.skillNumber' | translate"
          class="input-group-container"
          [formControlNameInput]="'skillNumber'"
          [placeHolderInput]="'student.createStudent.confirmation.skillNumber' | translate"
          [inputType]="'text'"
          [submitted]="submitted"
          [requiredText]="formGroup.get(STUDENT.ISSUE)?.value === TEXT_TRUE"
          [maxLength]="'15'"
          [minLength]="'1'"
          [form]="form">
        </app-input-base>

        <!-- licenseConditions -->
        <app-input-base
          [label]="'student.createStudent.confirmation.licenseConditions' | translate"
          class="input-group-container"
          [formControlNameInput]="'licenseConditions'"
          [placeHolderInput]="'student.createStudent.confirmation.licenseConditionsPlaceholder' | translate"
          [maxLength]="'127'"
          [minLength]="'1'"
          [inputType]="'text'"
          [submitted]="submitted"
          [requiredText]="formGroup.get(STUDENT.ISSUE)?.value === TEXT_TRUE"
          [form]="form">
        </app-input-base>

        <div class="form_item--subtitle">{{ "student.createStudent.confirmation.rotorcraft" | translate }}</div>
        <div class="form_item--checkbox_group">
          <app-input-checkbox
            *ngFor="let zRotorcraft of zRotorcraft1"
            [ngClass]="'checkbox_group--inline'"
            [label]="zRotorcraft.label"
            [formControlNameInput]="zRotorcraft.name"
            [submitted]="submitted"
            [formGroup]="formGroup"
            [disabled]="isEdit"
            [form]="form">
          </app-input-checkbox>
        </div>
        <div class="form_item--checkbox_group">
          <app-input-checkbox
            *ngFor="let zRotorcraft of zRotorcraft2"
            [ngClass]="'checkbox_group--inline'"
            [label]="zRotorcraft.label"
            [formControlNameInput]="zRotorcraft.name"
            [submitted]="submitted"
            [formGroup]="formGroup"
            [disabled]="isEdit"
            [form]="form">
          </app-input-checkbox>
        </div>

        <div class="form_item--subtitle">{{ "student.createStudent.confirmation.multi" | translate }}</div>
        <div class="form_item--checkbox_group">
          <app-input-checkbox
            *ngFor="let xRotorcraftMulti of xRotorcraftMulti1"
            [ngClass]="'checkbox_group--inline'"
            [label]="xRotorcraftMulti.label"
            [formControlNameInput]="xRotorcraftMulti.name"
            [formGroup]="formGroup"
            [submitted]="submitted"
            [disabled]="isEdit"
            [form]="form">
          </app-input-checkbox>
        </div>
        <div class="form_item--checkbox_group">
          <app-input-checkbox
            *ngFor="let xRotorcraftMulti of xRotorcraftMulti2"
            [ngClass]="'checkbox_group--inline'"
            [label]="xRotorcraftMulti.label"
            [formControlNameInput]="xRotorcraftMulti.name"
            [submitted]="submitted"
            [formGroup]="formGroup"
            [disabled]="isEdit"
            [form]="form">
          </app-input-checkbox>
        </div>

        <!-- <div class="form_item--subtitle">{{ "student.createStudent.confirmation.plane" | translate }}</div>
        <div class="form_item--checkbox_group">
          <app-input-checkbox
            *ngFor="let yPlane of yPlane1"
            [ngClass]="'checkbox_group--inline'"
            [label]="yPlane.label"
            [formControlNameInput]="yPlane.name"
            [submitted]="submitted"
            [formGroup]="formGroup"
            [disabled]="isEdit"
            [form]="form">
          </app-input-checkbox>
        </div>
        <div class="form_item--checkbox_group">
          <app-input-checkbox
            *ngFor="let  yPlane of yPlane2"
            [ngClass]="'checkbox_group--inline'"
            [label]=" yPlane.label"
            [formControlNameInput]=" yPlane.name"
            [submitted]="submitted"
            [formGroup]="formGroup"
            [disabled]="isEdit"
            [form]="form">
          </app-input-checkbox>
        </div> -->
      </div>
    </div>
  </div>
</div>
