<div class="mb-3 form-select-date" [formGroup]="formGroup">
  <label class="form-label mb-1" for="{{ formControlNameInput }}">
    {{ label }}
    <c-badge *ngIf="requiredText" color="danger">{{ "form.inputLabel.must" | translate}}</c-badge>
  </label>
  <c-col lg="12">
    <c-date-picker
      cFormControl
      class="input-date"
      (dateChange)="handleChangeDate($event)"
      id="{{ formControlNameInput }}"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [locale]="currentDatepickerLocale"
      [dayFormat]="dayFormat"
      [date]="initialDate"
      (keypress)="checkInputEnter($event)"
      [formControlName]="formControlNameInput"
      [disabled]="disabled"
      [placeholder]="formatDatePicker">
    </c-date-picker>
  </c-col>
  <c-form-feedback *ngIf="(submitted || form[formControlNameInput]?.touched) && form[formControlNameInput].errors">
    <div *ngIf="form[formControlNameInput]?.errors[getFirstError(form[formControlNameInput]?.errors) || '']">
      <div *ngIf="getFirstError(form[formControlNameInput]?.errors) === 'required'">
        {{ "errorMessages.formValidate.select.required" | translate }}
      </div>
      <div *ngIf="getFirstError(form[formControlNameInput]?.errors) === 'maxlength'">
        {{ "errorMessages.formValidate.input.maxlength" | translate }}
      </div>
      <div *ngIf="getFirstError(form[formControlNameInput]?.errors) === 'pattern'">
        {{
          "errorMessages.formValidate." + formControlNameInput + '.' + getFirstError(form[formControlNameInput]?.errors) | translate
        }}
      </div>
    </div>
  </c-form-feedback>
</div>
