import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HONORIFIC_TITLE, HonorificTitleOption, MAX_QUOTATION, formatDatePicker } from '@app/constants';
import { formatDate } from '@app/helpers';
import { AlertService, QuotationsService, StudentService } from '@app/services';

@Component({
  selector: 'app-edit-quotation',
  templateUrl: './edit-quotation.component.html',
  styleUrls: ['./edit-quotation.component.scss']
})
export class EditQuotationComponent implements OnInit {
  protected formEditQuotation!: FormGroup;
  protected id: string | null = '';
  protected quotationStudent: any;
  protected inputValueRecipientName: string = '';
  protected currentUser: any;
  protected listQuotationItem: any;
  protected unitPriceQuotationSelect: any;
  protected sumQuotation: any;
  protected inputValueQuantity: any;
  protected dataQuotationArr: any = [];
  protected lengthDataQuotationArr: any;
  protected totalMoney: any;
  protected totalTax: any;
  protected valueTax: any;
  protected objQuotation: any = {};
  protected submitted: boolean = false;
  protected isLoading: boolean = false;
  protected maxSort: number = 0;
  protected listHonorificTitle: HonorificTitleOption[] = HONORIFIC_TITLE;
  protected dataHonorificTitle: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private quotationsService: QuotationsService,
    private alertService: AlertService,
    private studentService: StudentService,
    private auth: AngularFireAuth,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  public async ngOnInit() {
    this.getDataQuotation();
  }

  public async getDataQuotation() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    await this.initForm();
    await this.getListQuotationItem().then();
    await this.getUser().then();
    if (this.id) {
      this.quotationStudent = await this.quotationsService.getQuotationStudentById(this.id)
      this.setQuotationInfo(this.quotationStudent);

      if (!this.quotationStudent) {
        this.quotationStudent.navigateByUrl('quotation');
      }
    }
  }

  public async setQuotationInfo(dataQuotation: any) {
    if (dataQuotation) {
      this.inputValueRecipientName = dataQuotation.recipient_name;
      this.dataQuotationArr = dataQuotation.quotation_item_link;
      this.maxSort = dataQuotation.quotation_item_link[0].sort;
      this.lengthDataQuotationArr = dataQuotation.quotation_item_link.length;
      this.valueTax = dataQuotation.tax;
      this.totalMoney = this.calculateTotal();
      this.totalTax = Math.floor(this.totalMoney * (this.valueTax / 100));
      this.dataHonorificTitle = this.listHonorificTitle.find((item: HonorificTitleOption) => item.value == dataQuotation.honorific_title);

      this.f['remark'].setValue(dataQuotation.description);
      this.f['recipientName'].setValue(this.inputValueRecipientName);
      this.f['selectHonorificTitle'].setValue(this.dataHonorificTitle.id);
      const result = this.combineArrays(this.dataQuotationArr, this.listQuotationItem);
      this.dataQuotationArr = result;

    }
  }

  public combineArrays(quotation: any, quotationItems: any) {
    return quotation.map((item: any) => {
        const quotationItem = quotationItems.find((qItem: any) => qItem.id === item.quotation_item_id);
        return {
            ...item,
            unitPrice: quotationItem?.unitPrice,
            itemName: quotationItem?.itemName,
        };
    });
  }

  async changeSelectQuotation(event: any) {
    const selectedQuotationItem = this.listQuotationItem.find((item: any) => item.id == event.target.value);
    this.objQuotation = selectedQuotationItem;
    if (selectedQuotationItem) {
      this.unitPriceQuotationSelect = selectedQuotationItem.unitPrice;
      this.sumQuotation = this.inputValueQuantity ? selectedQuotationItem.unitPrice * this.inputValueQuantity : ''
    }
  }

  calculateTotal(): number {
    return this.dataQuotationArr.reduce((acc:any, item: any) => acc + item.total, 0);
  }

  private async getUser(): Promise<void> {
    const currentUser = await this.auth.currentUser;
    if (currentUser) this.currentUser = currentUser;
  }

  private async getListQuotationItem(): Promise<void> {
    const data = await this.quotationsService.getAllQuotationItem();
    if (data.length) this.listQuotationItem = data;
  }

  async fetchDataQuantity() {
    this.sumQuotation = this.inputValueQuantity * this.unitPriceQuotationSelect;
  }

  get f() {
    return this.formEditQuotation.controls;
  }

  async editQuotation() {
    this.alertService.clear();
    if (this.onValidate()) {
      const dataUpdate = {
        user_id: this.currentUser.uid,
        quotationId: this.activatedRoute.snapshot.paramMap.get('id'),
        recipient_name: this.formEditQuotation.value.recipientName,
        honorific_title: this.dataHonorificTitle.value,
        total_amount: this.totalMoney,
        description: this.formEditQuotation.value.remark,
        dataQuotationArr: this.dataQuotationArr,
      }
      await this.quotationsService.updateQuotation(dataUpdate).then(() => this.router.navigateByUrl('quotation'))
      .then(() => this.alertService.success('項目を追加しました。'))
    }
  }

  async editQuotationAndExport() {
    this.alertService.clear();
    if (this.onValidate()) {
      const dataUpdate = {
        user_id: this.currentUser.uid,
        quotationId: this.activatedRoute.snapshot.paramMap.get('id'),
        recipient_name: this.formEditQuotation.value.recipientName,
        honorific_title: this.dataHonorificTitle.value,
        total_amount: this.totalMoney,
        description: this.formEditQuotation.value.remark,
        dataQuotationArr: this.dataQuotationArr,
      }

      await this.quotationsService.updateQuotation(dataUpdate)
      .then(() => this.alertService.success('項目を追加しました。'))
    }
  }

  onValidate() {
    this.submitted = true;
    return this.formEditQuotation.status === 'VALID';
  }

  get attribute() {
    return this.formEditQuotation.controls;
  }


  async onSubmit() {
    if (this.onValidate() && this.dataQuotationArr.length < MAX_QUOTATION) {
      const existingIndex = this.dataQuotationArr.findIndex((item: any) => item.quotation_item_id === this.objQuotation.id);
      const newQuotation = {
        quotation_item_id: this.objQuotation.id,
        itemName: this.objQuotation.itemName,
        unitPrice: this.objQuotation.unitPrice,
        quantity: this.inputValueQuantity,
        sort: ++this.maxSort,
        total: this.sumQuotation,
      };

      if (existingIndex !== -1) {
        this.dataQuotationArr[existingIndex] = newQuotation;
      } else {
        this.dataQuotationArr.unshift(newQuotation);
      }

      this.lengthDataQuotationArr = this.dataQuotationArr.length;
      this.totalMoney = this.calculateTotal();
      this.totalTax = Math.floor(this.totalMoney * (this.valueTax / 100));
    }
  }

  private initForm(): void {
    this.formEditQuotation = this.fb.group({
      recipientName: ['', [
        Validators.required,
      ]],
      selectHonorificTitle: new FormControl(null, [
      ]),
      valueQuantity: ['', [
        Validators.pattern(/^\d+$/),
      ]],
      remark: ['', [
        Validators.maxLength(1024),
      ]],
      selectQuotationItem: new FormControl(null, [
      ])
    });
  }

  async changeSelectHonorificTitle(event: any) {
    this.dataHonorificTitle = this.listHonorificTitle.find((item: HonorificTitleOption) => item.id == event.target.value);
  }

  public handleDeleteQuotationItem(event: any) {
    if (event.index >= 0 && event.index < this.dataQuotationArr.length) {
      this.dataQuotationArr.splice(event.index, 1);
      this.lengthDataQuotationArr = this.dataQuotationArr.length;
      this.totalMoney = this.calculateTotal();
      this.totalTax = Math.floor(this.totalMoney * (this.valueTax / 100));
    }
  }

  async handleMoveItemUp(event: any) {
    if (event.index > 0 && event.index < this.dataQuotationArr.length) {
      const currentSort = this.dataQuotationArr[event.index].sort;
      const previousSort = this.dataQuotationArr[event.index - 1].sort;

      // Swap the positions of the items
      const temp = this.dataQuotationArr[event.index];
      this.dataQuotationArr[event.index] = this.dataQuotationArr[event.index - 1];
      this.dataQuotationArr[event.index - 1] = temp;

      // Update the 'sort' values
      this.dataQuotationArr[event.index].sort = currentSort;
      this.dataQuotationArr[event.index - 1].sort = previousSort;
    }
  }

  public handleMoveItemDown(event: any) {
    if (event.index >= 0 && event.index < this.dataQuotationArr.length - 1) {

      const currentSort = this.dataQuotationArr[event.index].sort;
      const previousSort = this.dataQuotationArr[event.index + 1].sort;

      const temp = this.dataQuotationArr[event.index];
      this.dataQuotationArr[event.index] = this.dataQuotationArr[event.index + 1];
      this.dataQuotationArr[event.index + 1] = temp;

      this.dataQuotationArr[event.index].sort = currentSort;
      this.dataQuotationArr[event.index + 1].sort = previousSort;
    }
  }

  protected exportPDFQuotation(): void {
    this.editQuotationAndExport().then(() => {
      const timeCreatedAt = formatDate(this.quotationStudent.createdAt.toDate(), formatDatePicker)
      this.isLoading = true;
      this.quotationsService.exportPDF(this.currentUser.uid as any, this.id as any, this.formEditQuotation as any, timeCreatedAt as any)
        .finally(() => {
          this.isLoading = false;
          this.router.navigateByUrl('quotation');
        });
    })
  }
}
