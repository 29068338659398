import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthComponent } from './auth/auth.component';
import { RouterModule } from '@angular/router';
import { DropdownModule, GridModule, HeaderModule, ImgModule, SidebarModule } from '@coreui/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HeaderGuestComponent } from '@app/components/header-guest/header-guest.component';
import { HeaderUserComponent } from '@app/components/header-user/header-user.component';
import { NotifyPopupComponent } from '@app/components/notify-popup/notify-popup.component';
import { IconModule } from "@coreui/icons-angular";

@NgModule({
  declarations: [
    AuthComponent,
    HeaderGuestComponent,
    HeaderUserComponent,
    NotifyPopupComponent,
  ],
    imports: [
        DropdownModule,
        CommonModule,
        RouterModule,
        GridModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json'),
                deps: [HttpClient],
            },
        }),
        HeaderModule,
        IconModule,
        SidebarModule,
        ImgModule,
    ],
  exports: [
    AuthComponent,
    HeaderGuestComponent,
    HeaderUserComponent,
    NotifyPopupComponent,
  ],
})
export class LayoutsModule {}
