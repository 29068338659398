import { Component, OnInit, ElementRef, Inject, } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService, AttendanceService, StudentService, UploadService, UserService, ValidationFormsService } from "@app/services";
import {
  IStudent,
  IStudentClassification,
  IStudentCourseAirplane,
  IStudentCourseHelicopter,
  IStudentCourseMulti,
  IStudentEnrollment,
  IStudentIdentityVerification,
  IStudentInfo,
  IStudentManeuverMode,
  IStudentPilotLicense,
} from "@app/models/student";
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ulid } from "ulid";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ATTENDING_NAME, LEVEl_ONE, LEVEl_TWO, NO, NOT_ISSUED, YES, formatYYYYMMDD } from '@app/constants';
import { IBreadcrumbItem } from '@coreui/angular';
import { checkboxSecondClassChecked, formatDate } from '@app/helpers';


@Component({
  selector: 'app-create-student',
  templateUrl: './create-student.component.html',
  styleUrls: ['./create-student.component.scss']
})
export class CreateStudentComponent implements OnInit {
  protected submitted: boolean = false;
  protected isRequest: boolean = false;
  protected formCreateStudent!: FormGroup;
  protected fileUpload: any;
  protected currentUser: any;
  protected learning: boolean = true;
  protected dataTab: any;
  protected id: string = '';
  protected elementRef: ElementRef;
  protected inValid: boolean = false;
  protected breadcrumbItems: IBreadcrumbItem[] = [];
  protected isLoading: boolean = false;
  protected courseItems: any = [];

  constructor(
    private attendanceService: AttendanceService,
    private studentService: StudentService,
    private uploadService: UploadService,
    private fb: FormBuilder,
    private auth: AngularFireAuth,
    private userService: UserService,
    private alertService: AlertService,
    private validationFormService: ValidationFormsService,
    private router: Router,
    private translateService: TranslateService,
    @Inject(ElementRef) elementRef: ElementRef
  ) {
    this.elementRef = elementRef;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.initForm();
    this.getCourseItems();
    this.getUser().then(() => {
      this.setDataReadOnly();
      this.isLoading = false;
    });
    this.translateService.get([
      'student.listStudent.title',
      'student.createStudent.title'
    ]).subscribe(translations => {
      this.breadcrumbItems = [
        { label: translations['student.listStudent.title'], url: '/student' },
        { label: translations['student.createStudent.title'], url: `` },
      ];
    });
  }

  private async getCourseItems(): Promise<void> {
    const data = await this.attendanceService.getItems('course_items', 'itemNumber', false);
    if (data.length) {
      this.courseItems = data;
      this.formCreateStudent.controls['courseItems'].setValue(this.courseItems[0].itemNumber);
    }
  }

  get f() {
    return this.formCreateStudent.controls;
  }

  initForm() {
    this.formCreateStudent = this.fb.group({
      ledgerNumber: new FormControl('', { validators: [Validators.required] }),
      studentNumber: new FormControl('', { validators: [Validators.required] }),
      hurigana: new FormControl('', { validators: [Validators.required, Validators.maxLength(31), Validators.pattern(this.validationFormService.formRules.regexJa)] }),
      name: new FormControl('', { validators: [Validators.required, Validators.maxLength(31)] }),
      certificateNumber: new FormControl('', { validators: [
        Validators.maxLength(31),
        Validators.required,
        Validators.pattern(this.validationFormService.formRules.regexCertificateNumber),
      ] }),
      birthday: new FormControl(new Date(), { validators: [Validators.required] }),
      email: new FormControl('', { validators: [Validators.pattern(this.validationFormService.formRules.emailPattern), Validators.required] }),
      sex: new FormControl('男性', { validators: [Validators.required] }),
      address: new FormControl('', { validators: [Validators.required, Validators.maxLength(127)] }),
      tel: new FormControl('', { validators: [Validators.required, Validators.pattern(this.validationFormService.formRules.phoneNumber)] }),
      avatar: new FormControl(null, { validators: [] }),
      remark: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(1023)] }),
      academicExamStatus: new FormControl('合格済', { validators: [] }),
      academicExamPassDate: new FormControl(new Date(), { validators: [Validators.required] }),
      academicExamPassCertificateNumber: new FormControl('', { validators: [Validators.required, Validators.minLength(1), Validators.maxLength(15)] }),
      courseItems: new FormControl('', { validators: [] }),
      organizationName: new FormControl('', { validators: [] }),

      practicalDate: new FormControl(new Date(), { validators: [] }),
      enrollmentDate: new FormControl(new Date(), { validators: [] }),

      experiencedType: new FormControl("経験者", { validators: [] }),
      privateSkillCertificate: new FormControl("有", { validators: [] }),
      totalFlightTime: new FormControl(0, { validators: [Validators.max(9998), Validators.min(0), Validators.pattern(/^\d+$/)] }),
      issuingOrganizationName: new FormControl("", { validators: [Validators.minLength(1), Validators.maxLength(127), Validators.required] }),
      no: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      no_expireDate: new FormControl('', { validators: [Validators.required] }),
      number: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      number_expireDate: new FormControl('', { validators: [] }),
      status: new FormControl(ATTENDING_NAME, { validators: [] }),
      statusElearning: new FormControl(NOT_ISSUED, { validators: [] }),
      useElearning: new FormControl(YES, { validators: [] }),

      driverLicense: new FormControl(false, { validators: [] }),
      myNumberCard: new FormControl(false, { validators: [] }),
      etc: new FormControl(false, { validators: [] }),
      etcText: new FormControl('', { validators: [] }),
      noEtc: new FormControl("", { validators: [Validators.minLength(1), Validators.maxLength(15)] }),

      issue: new FormControl('有', { validators: [] }),
      dateOfIssue: new FormControl('', { validators: [Validators.required] }),
      dateOfExpiry: new FormControl('', { validators: [Validators.required] }),
      skillNumber: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15), Validators.required] }),
      licenseConditions: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(127), Validators.required] }),

      x_firstClassBasic: new FormControl(false, { validators: [] }),
      x_firstClassDaytimeOnly: new FormControl(false, { validators: [] }),
      x_firstClassVisualLineOfSight: new FormControl(false, { validators: [] }),
      x_firstClassWeightLimited: new FormControl(false, { validators: [] }),
      x_secondClassBasic: new FormControl(false, { validators: [] }),
      x_secondClassDaytimeOnly: new FormControl(false, { validators: [] }),
      x_secondClassVisualLineOfSight: new FormControl(false, { validators: [] }),
      x_secondClassWeightLimited: new FormControl(false, { validators: [] }),

      y_firstClassBasic: new FormControl(false, { validators: [] }),
      y_firstClassDaytimeOnly: new FormControl(false, { validators: [] }),
      y_firstClassVisualLineOfSight: new FormControl(false, { validators: [] }),
      y_firstClassWeightLimited: new FormControl(false, { validators: [] }),
      y_secondClassBasic: new FormControl(false, { validators: [] }),
      y_secondClassDaytimeOnly: new FormControl(false, { validators: [] }),
      y_secondClassVisualLineOfSight: new FormControl(false, { validators: [] }),
      y_secondClassWeightLimited: new FormControl(false, { validators: [] }),

      z_firstClassBasic: new FormControl(false, { validators: [] }),
      z_firstClassDaytimeOnly: new FormControl(false, { validators: [] }),
      z_firstClassVisualLineOfSight: new FormControl(false, { validators: [] }),
      z_firstClassWeightLimited: new FormControl(false, { validators: [] }),
      z_secondClassBasic: new FormControl(false, { validators: [] }),
      z_secondClassDaytimeOnly: new FormControl(false, { validators: [] }),
      z_secondClassVisualLineOfSight: new FormControl(false, { validators: [] }),
      z_secondClassWeightLimited: new FormControl(false, { validators: [] }),

      a_rotorcraftMulti: new FormControl(false, { validators: [] }),

      a_firstClassBasic: new FormControl(false, { validators: [] }),
      a_firstClassBasicDate: new FormControl('', { validators: [] }),
      a_firstClassBasicNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_firstClassBasicPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_firstClassBasicResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_firstClassBasicSendingDate: new FormControl('', { validators: [] }),
      a_firstClassBasicDeliveryDate: new FormControl('', { validators: [] }),
      a_firstClassBasicExpirationDate: new FormControl('', { validators: [] }),
      a_firstClassBasicStatus: new FormControl('', { validators: [] }),
      a_firstClassBasicApplicationDate: new FormControl('', { validators: [] }),

      a_firstClassDaytimeOnly: new FormControl(false, { validators: [] }),
      a_firstClassDaytimeOnlyDate: new FormControl('', { validators: [] }),
      a_firstClassDaytimeOnlyNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_firstClassDaytimeOnlyPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_firstClassDaytimeOnlyResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_firstClassDaytimeOnlySendingDate: new FormControl('', { validators: [] }),
      a_firstClassDaytimeOnlyDeliveryDate: new FormControl('', { validators: [] }),
      a_firstClassDaytimeOnlyExpirationDate: new FormControl('', { validators: [] }),
      a_firstClassDaytimeOnlyStatus: new FormControl('', { validators: [] }),
      a_firstClassDaytimeOnlyApplicationDate: new FormControl('', { validators: [] }),

      a_firstClassVisualLineOfSight: new FormControl(false, { validators: [] }),
      a_firstClassVisualLineOfSightDate: new FormControl('', { validators: [] }),
      a_firstClassVisualLineOfSightNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_firstClassVisualLineOfSightPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_firstClassVisualLineOfSightResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_firstClassVisualLineOfSightSendingDate: new FormControl('', { validators: [] }),
      a_firstClassVisualLineOfSightDeliveryDate: new FormControl('', { validators: [] }),
      a_firstClassVisualLineOfSightExpirationDate: new FormControl('', { validators: [] }),
      a_firstClassVisualLineOfSightStatus: new FormControl('', { validators: [] }),
      a_firstClassVisualLineOfSightApplicationDate: new FormControl('', { validators: [] }),

      a_firstClassWeightLimited: new FormControl(false, { validators: [] }),
      a_firstClassWeightLimitedDate: new FormControl('', { validators: [] }),
      a_firstClassWeightLimitedNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_firstClassWeightLimitedPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_firstClassWeightLimitedResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_firstClassWeightLimitedSendingDate: new FormControl('', { validators: [] }),
      a_firstClassWeightLimitedDeliveryDate: new FormControl('', { validators: [] }),
      a_firstClassWeightLimitedExpirationDate: new FormControl('', { validators: [] }),
      a_firstClassWeightLimitedStatus: new FormControl('', { validators: [] }),
      a_firstClassWeightLimitedApplicationDate: new FormControl('', { validators: [] }),

      a_secondClassBasic: new FormControl(false, { validators: [] }),
      a_secondClassBasicDate: new FormControl('', { validators: [] }),
      a_secondClassBasicNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_secondClassBasicPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_secondClassBasicResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_secondClassBasicSendingDate: new FormControl('', { validators: [] }),
      a_secondClassBasicDeliveryDate: new FormControl('', { validators: [] }),
      a_secondClassBasicExpirationDate: new FormControl('', { validators: [] }),
      a_secondClassBasicStatus: new FormControl('', { validators: [] }),
      a_secondClassBasicApplicationDate: new FormControl('', { validators: [] }),

      a_secondClassDaytimeOnly: new FormControl(false, { validators: [] }),
      a_secondClassDaytimeOnlyDate: new FormControl('', { validators: [] }),
      a_secondClassDaytimeOnlyNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_secondClassDaytimeOnlyPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_secondClassDaytimeOnlyResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_secondClassDaytimeOnlySendingDate: new FormControl('', { validators: [] }),
      a_secondClassDaytimeOnlyDeliveryDate: new FormControl('', { validators: [] }),
      a_secondClassDaytimeOnlyExpirationDate: new FormControl('', { validators: [] }),
      a_secondClassDaytimeOnlyStatus: new FormControl('', { validators: [] }),
      a_secondClassDaytimeOnlyApplicationDate: new FormControl('', { validators: [] }),

      a_secondClassVisualLineOfSight: new FormControl(false, { validators: [] }),
      a_secondClassVisualLineOfSightDate: new FormControl('', { validators: [] }),
      a_secondClassVisualLineOfSightNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_secondClassVisualLineOfSightPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_secondClassVisualLineOfSightResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_secondClassVisualLineOfSightSendingDate: new FormControl('', { validators: [] }),
      a_secondClassVisualLineOfSightDeliveryDate: new FormControl('', { validators: [] }),
      a_secondClassVisualLineOfSightExpirationDate: new FormControl('', { validators: [] }),
      a_secondClassVisualLineOfSightStatus: new FormControl('', { validators: [] }),
      a_secondClassVisualLineOfSightApplicationDate: new FormControl('', { validators: [] }),

      a_secondClassWeightLimited: new FormControl(false, { validators: [] }),
      a_secondClassWeightLimitedDate: new FormControl('', { validators: [] }),
      a_secondClassWeightLimitedNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_secondClassWeightLimitedPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_secondClassWeightLimitedResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_secondClassWeightLimitedSendingDate: new FormControl('', { validators: [] }),
      a_secondClassWeightLimitedDeliveryDate: new FormControl('', { validators: [] }),
      a_secondClassWeightLimitedExpirationDate: new FormControl('', { validators: [] }),
      a_secondClassWeightLimitedStatus: new FormControl('', { validators: [] }),
      a_secondClassWeightLimitedApplicationDate: new FormControl('', { validators: [] }),

      b_plane: new FormControl(false, { validators: [] }),

      b_firstClassBasic: new FormControl(false, { validators: [] }),
      b_firstClassBasicDate: new FormControl('', { validators: [] }),
      b_firstClassBasicNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_firstClassBasicPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_firstClassBasicResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_firstClassBasicSendingDate: new FormControl('', { validators: [] }),
      b_firstClassBasicDeliveryDate: new FormControl('', { validators: [] }),
      b_firstClassBasicExpirationDate: new FormControl('', { validators: [] }),
      b_firstClassBasicStatus: new FormControl('', { validators: [] }),
      b_firstClassBasicApplicationDate: new FormControl('', { validators: [] }),

      b_firstClassDaytimeOnly: new FormControl(false, { validators: [] }),
      b_firstClassDaytimeOnlyDate: new FormControl('', { validators: [] }),
      b_firstClassDaytimeOnlyNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_firstClassDaytimeOnlyPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_firstClassDaytimeOnlyResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_firstClassDaytimeOnlySendingDate: new FormControl('', { validators: [] }),
      b_firstClassDaytimeOnlyDeliveryDate: new FormControl('', { validators: [] }),
      b_firstClassDaytimeOnlyExpirationDate: new FormControl('', { validators: [] }),
      b_firstClassDaytimeOnlyStatus: new FormControl('', { validators: [] }),
      b_firstClassDaytimeOnlyApplicationDate: new FormControl('', { validators: [] }),

      b_firstClassVisualLineOfSight: new FormControl(false, { validators: [] }),
      b_firstClassVisualLineOfSightDate: new FormControl('', { validators: [] }),
      b_firstClassVisualLineOfSightNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_firstClassVisualLineOfSightPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_firstClassVisualLineOfSightResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_firstClassVisualLineOfSightSendingDate: new FormControl('', { validators: [] }),
      b_firstClassVisualLineOfSightDeliveryDate: new FormControl('', { validators: [] }),
      b_firstClassVisualLineOfSightExpirationDate: new FormControl('', { validators: [] }),
      b_firstClassVisualLineOfSightStatus: new FormControl('', { validators: [] }),
      b_firstClassVisualLineOfSightApplicationDate: new FormControl('', { validators: [] }),

      b_firstClassWeightLimited: new FormControl(false, { validators: [] }),
      b_firstClassWeightLimitedDate: new FormControl('', { validators: [] }),
      b_firstClassWeightLimitedNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_firstClassWeightLimitedPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_firstClassWeightLimitedResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_firstClassWeightLimitedSendingDate: new FormControl('', { validators: [] }),
      b_firstClassWeightLimitedDeliveryDate: new FormControl('', { validators: [] }),
      b_firstClassWeightLimitedExpirationDate: new FormControl('', { validators: [] }),
      b_firstClassWeightLimitedStatus: new FormControl('', { validators: [] }),
      b_firstClassWeightLimitedApplicationDate: new FormControl('', { validators: [] }),

      b_secondClassBasic: new FormControl(false, { validators: [] }),
      b_secondClassBasicDate: new FormControl('', { validators: [] }),
      b_secondClassBasicNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_secondClassBasicPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_secondClassBasicResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_secondClassBasicSendingDate: new FormControl('', { validators: [] }),
      b_secondClassBasicDeliveryDate: new FormControl('', { validators: [] }),
      b_secondClassBasicExpirationDate: new FormControl('', { validators: [] }),
      b_secondClassBasicStatus: new FormControl('', { validators: [] }),
      b_secondClassBasicApplicationDate: new FormControl('', { validators: [] }),

      b_secondClassDaytimeOnly: new FormControl(false, { validators: [] }),
      b_secondClassDaytimeOnlyDate: new FormControl('', { validators: [] }),
      b_secondClassDaytimeOnlyNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_secondClassDaytimeOnlyPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_secondClassDaytimeOnlyResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_secondClassDaytimeOnlySendingDate: new FormControl('', { validators: [] }),
      b_secondClassDaytimeOnlyDeliveryDate: new FormControl('', { validators: [] }),
      b_secondClassDaytimeOnlyExpirationDate: new FormControl('', { validators: [] }),
      b_secondClassDaytimeOnlyStatus: new FormControl('', { validators: [] }),
      b_secondClassDaytimeOnlyApplicationDate: new FormControl('', { validators: [] }),

      b_secondClassVisualLineOfSight: new FormControl(false, { validators: [] }),
      b_secondClassVisualLineOfSightDate: new FormControl('', { validators: [] }),
      b_secondClassVisualLineOfSightNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_secondClassVisualLineOfSightPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_secondClassVisualLineOfSightResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_secondClassVisualLineOfSightSendingDate: new FormControl('', { validators: [] }),
      b_secondClassVisualLineOfSightDeliveryDate: new FormControl('', { validators: [] }),
      b_secondClassVisualLineOfSightExpirationDate: new FormControl('', { validators: [] }),
      b_secondClassVisualLineOfSightStatus: new FormControl('', { validators: [] }),
      b_secondClassVisualLineOfSightApplicationDate: new FormControl('', { validators: [] }),

      b_secondClassWeightLimited: new FormControl(false, { validators: [] }),
      b_secondClassWeightLimitedDate: new FormControl('', { validators: [] }),
      b_secondClassWeightLimitedNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_secondClassWeightLimitedPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_secondClassWeightLimitedResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_secondClassWeightLimitedSendingDate: new FormControl('', { validators: [] }),
      b_secondClassWeightLimitedDeliveryDate: new FormControl('', { validators: [] }),
      b_secondClassWeightLimitedExpirationDate: new FormControl('', { validators: [] }),
      b_secondClassWeightLimitedStatus: new FormControl('', { validators: [] }),
      b_secondClassWeightLimitedApplicationDate: new FormControl('', { validators: [] }),

      c_rotorcraft: new FormControl(false, { validators: [] }),

      c_firstClassBasic: new FormControl(false, { validators: [] }),
      c_firstClassBasicDate: new FormControl('', { validators: [] }),
      c_firstClassBasicNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_firstClassBasicPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_firstClassBasicResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_firstClassBasicSendingDate: new FormControl('', { validators: [] }),
      c_firstClassBasicDeliveryDate: new FormControl('', { validators: [] }),
      c_firstClassBasicExpirationDate: new FormControl('', { validators: [] }),
      c_firstClassBasicStatus: new FormControl('', { validators: [] }),
      c_firstClassBasicApplicationDate: new FormControl('', { validators: [] }),

      c_firstClassDaytimeOnly: new FormControl(false, { validators: [] }),
      c_firstClassDaytimeOnlyDate: new FormControl('', { validators: [] }),
      c_firstClassDaytimeOnlyNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_firstClassDaytimeOnlyPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_firstClassDaytimeOnlyResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_firstClassDaytimeOnlySendingDate: new FormControl('', { validators: [] }),
      c_firstClassDaytimeOnlyDeliveryDate: new FormControl('', { validators: [] }),
      c_firstClassDaytimeOnlyExpirationDate: new FormControl('', { validators: [] }),
      c_firstClassDaytimeOnlyStatus: new FormControl('', { validators: [] }),
      c_firstClassDaytimeOnlyApplicationDate: new FormControl('', { validators: [] }),

      c_firstClassVisualLineOfSight: new FormControl(false, { validators: [] }),
      c_firstClassVisualLineOfSightDate: new FormControl('', { validators: [] }),
      c_firstClassVisualLineOfSightNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_firstClassVisualLineOfSightPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_firstClassVisualLineOfSightResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_firstClassVisualLineOfSightSendingDate: new FormControl('', { validators: [] }),
      c_firstClassVisualLineOfSightDeliveryDate: new FormControl('', { validators: [] }),
      c_firstClassVisualLineOfSightExpirationDate: new FormControl('', { validators: [] }),
      c_firstClassVisualLineOfSightStatus: new FormControl('', { validators: [] }),
      c_firstClassVisualLineOfSightApplicationDate: new FormControl('', { validators: [] }),

      c_firstClassWeightLimited: new FormControl(false, { validators: [] }),
      c_firstClassWeightLimitedDate: new FormControl('', { validators: [] }),
      c_firstClassWeightLimitedNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_firstClassWeightLimitedPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_firstClassWeightLimitedResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_firstClassWeightLimitedSendingDate: new FormControl('', { validators: [] }),
      c_firstClassWeightLimitedDeliveryDate: new FormControl('', { validators: [] }),
      c_firstClassWeightLimitedExpirationDate: new FormControl('', { validators: [] }),
      c_firstClassWeightLimitedStatus: new FormControl('', { validators: [] }),
      c_firstClassWeightLimitedApplicationDate: new FormControl('', { validators: [] }),

      c_secondClassBasic: new FormControl(false, { validators: [] }),
      c_secondClassBasicDate: new FormControl('', { validators: [] }),
      c_secondClassBasicNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_secondClassBasicPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_secondClassBasicResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_secondClassBasicSendingDate: new FormControl('', { validators: [] }),
      c_secondClassBasicDeliveryDate: new FormControl('', { validators: [] }),
      c_secondClassBasicExpirationDate: new FormControl('', { validators: [] }),
      c_secondClassBasicStatus: new FormControl('', { validators: [] }),
      c_secondClassBasicApplicationDate: new FormControl('', { validators: [] }),

      c_secondClassDaytimeOnly: new FormControl(false, { validators: [] }),
      c_secondClassDaytimeOnlyDate: new FormControl('', { validators: [] }),
      c_secondClassDaytimeOnlyNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_secondClassDaytimeOnlyPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_secondClassDaytimeOnlyResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_secondClassDaytimeOnlySendingDate: new FormControl('', { validators: [] }),
      c_secondClassDaytimeOnlyDeliveryDate: new FormControl('', { validators: [] }),
      c_secondClassDaytimeOnlyExpirationDate: new FormControl('', { validators: [] }),
      c_secondClassDaytimeOnlyStatus: new FormControl('', { validators: [] }),
      c_secondClassDaytimeOnlyApplicationDate: new FormControl('', { validators: [] }),

      c_secondClassVisualLineOfSight: new FormControl(false, { validators: [] }),
      c_secondClassVisualLineOfSightDate: new FormControl('', { validators: [] }),
      c_secondClassVisualLineOfSightNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_secondClassVisualLineOfSightPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_secondClassVisualLineOfSightResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_secondClassVisualLineOfSightSendingDate: new FormControl('', { validators: [] }),
      c_secondClassVisualLineOfSightDeliveryDate: new FormControl('', { validators: [] }),
      c_secondClassVisualLineOfSightExpirationDate: new FormControl('', { validators: [] }),
      c_secondClassVisualLineOfSightStatus: new FormControl('', { validators: [] }),
      c_secondClassVisualLineOfSightApplicationDate: new FormControl('', { validators: [] }),

      c_secondClassWeightLimited: new FormControl(false, { validators: [] }),
      c_secondClassWeightLimitedDate: new FormControl('', { validators: [] }),
      c_secondClassWeightLimitedNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_secondClassWeightLimitedPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_secondClassWeightLimitedResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_secondClassWeightLimitedSendingDate: new FormControl('', { validators: [] }),
      c_secondClassWeightLimitedDeliveryDate: new FormControl('', { validators: [] }),
      c_secondClassWeightLimitedExpirationDate: new FormControl('', { validators: [] }),
      c_secondClassWeightLimitedStatus: new FormControl('', { validators: [] }),
      c_secondClassWeightLimitedApplicationDate: new FormControl('', { validators: [] }),

      mode1: new FormControl(false, { validators: [] }),
      mode2: new FormControl(false, { validators: [] }),
      mode3: new FormControl(false, { validators: [] }),

    });
  }

  public checkValidTabBasic(): boolean {
    return (
      this.f['hurigana'].invalid
      || this.f['name'].invalid
      || this.f['certificateNumber'].invalid
      || this.f['birthday'].invalid
      || this.f['address'].invalid
      || this.f['email'].invalid
      || this.f['remark'].invalid
      || this.f['tel'].invalid
      || this.f['academicExamPassDate'].invalid
      || this.f['academicExamPassCertificateNumber'].invalid
    ) && this.submitted;
  }

  public checkValidConfirmation(): boolean {
    return (
      this.f['no_expireDate'].invalid
      || this.f['issuingOrganizationName'].invalid
      || this.f['no'].invalid
      || this.f['noEtc'].invalid
      || this.f['totalFlightTime'].invalid
      || this.f['dateOfIssue'].invalid
      || this.f['dateOfExpiry'].invalid
      || this.f['skillNumber'].invalid
      || this.f['licenseConditions'].invalid
      || this.inValid
    ) && this.submitted;
  }

  public checkValidAirplane(): boolean {
    return (
      this.f['b_firstClassBasicNo'].invalid ||
      this.f['b_firstClassBasicPlace'].invalid ||
      this.f['b_firstClassBasicResponsible'].invalid ||

      this.f['b_firstClassDaytimeOnlyNo'].invalid ||
      this.f['b_firstClassDaytimeOnlyPlace'].invalid ||
      this.f['b_firstClassDaytimeOnlyResponsible'].invalid ||

      this.f['b_firstClassVisualLineOfSightNo'].invalid ||
      this.f['b_firstClassVisualLineOfSightPlace'].invalid ||
      this.f['b_firstClassVisualLineOfSightResponsible'].invalid ||

      this.f['b_firstClassWeightLimitedNo'].invalid ||
      this.f['b_firstClassWeightLimitedPlace'].invalid ||
      this.f['b_firstClassWeightLimitedResponsible'].invalid ||

      this.f['b_secondClassBasicNo'].invalid ||
      this.f['b_secondClassBasicPlace'].invalid ||
      this.f['b_secondClassBasicResponsible'].invalid ||

      this.f['b_secondClassDaytimeOnlyNo'].invalid ||
      this.f['b_secondClassDaytimeOnlyPlace'].invalid ||
      this.f['b_secondClassDaytimeOnlyResponsible'].invalid ||

      this.f['b_secondClassVisualLineOfSightNo'].invalid ||
      this.f['b_secondClassVisualLineOfSightPlace'].invalid ||
      this.f['b_secondClassVisualLineOfSightResponsible'].invalid ||

      this.f['b_secondClassWeightLimitedNo'].invalid ||
      this.f['b_secondClassWeightLimitedPlace'].invalid ||
      this.f['b_secondClassWeightLimitedResponsible'].invalid
    ) && this.submitted;
  }

  public checkValidHelicopter(): boolean {
    return (
      this.f['c_firstClassBasicNo'].invalid ||
      this.f['c_firstClassBasicPlace'].invalid ||
      this.f['c_firstClassBasicResponsible'].invalid ||

      this.f['c_firstClassDaytimeOnlyNo'].invalid ||
      this.f['c_firstClassDaytimeOnlyPlace'].invalid ||
      this.f['c_firstClassDaytimeOnlyResponsible'].invalid ||

      this.f['c_firstClassVisualLineOfSightNo'].invalid ||
      this.f['c_firstClassVisualLineOfSightPlace'].invalid ||
      this.f['c_firstClassVisualLineOfSightResponsible'].invalid ||

      this.f['c_firstClassWeightLimitedNo'].invalid ||
      this.f['c_firstClassWeightLimitedPlace'].invalid ||
      this.f['c_firstClassWeightLimitedResponsible'].invalid ||

      this.f['c_secondClassBasicNo'].invalid ||
      this.f['c_secondClassBasicPlace'].invalid ||
      this.f['c_secondClassBasicResponsible'].invalid ||

      this.f['c_secondClassDaytimeOnlyNo'].invalid ||
      this.f['c_secondClassDaytimeOnlyPlace'].invalid ||
      this.f['c_secondClassDaytimeOnlyResponsible'].invalid ||

      this.f['c_secondClassVisualLineOfSightNo'].invalid ||
      this.f['c_secondClassVisualLineOfSightPlace'].invalid ||
      this.f['c_secondClassVisualLineOfSightResponsible'].invalid ||

      this.f['c_secondClassWeightLimitedNo'].invalid ||
      this.f['c_secondClassWeightLimitedPlace'].invalid ||
      this.f['c_secondClassWeightLimitedResponsible'].invalid
    ) && this.submitted
  }

  public checkValidMulti(): boolean {
    return (
      this.f['a_firstClassBasicNo'].invalid ||
      this.f['a_firstClassBasicPlace'].invalid ||
      this.f['a_firstClassBasicResponsible'].invalid ||

      this.f['a_firstClassDaytimeOnlyNo'].invalid ||
      this.f['a_firstClassDaytimeOnlyPlace'].invalid ||
      this.f['a_firstClassDaytimeOnlyResponsible'].invalid ||

      this.f['a_firstClassVisualLineOfSightNo'].invalid ||
      this.f['a_firstClassVisualLineOfSightPlace'].invalid ||
      this.f['a_firstClassVisualLineOfSightResponsible'].invalid ||

      this.f['a_firstClassWeightLimitedNo'].invalid ||
      this.f['a_firstClassWeightLimitedPlace'].invalid ||
      this.f['a_firstClassWeightLimitedResponsible'].invalid ||

      this.f['a_secondClassBasicNo'].invalid ||
      this.f['a_secondClassBasicPlace'].invalid ||
      this.f['a_secondClassBasicResponsible'].invalid ||

      this.f['a_secondClassDaytimeOnlyNo'].invalid ||
      this.f['a_secondClassDaytimeOnlyPlace'].invalid ||
      this.f['a_secondClassDaytimeOnlyResponsible'].invalid ||

      this.f['a_secondClassVisualLineOfSightNo'].invalid ||
      this.f['a_secondClassVisualLineOfSightPlace'].invalid ||
      this.f['a_secondClassVisualLineOfSightResponsible'].invalid ||

      this.f['a_secondClassWeightLimitedNo'].invalid ||
      this.f['a_secondClassWeightLimitedPlace'].invalid ||
      this.f['a_secondClassWeightLimitedResponsible'].invalid
    ) && this.submitted;
  }

  async submit() {
    this.formCreateStudent.updateValueAndValidity();

    for (const key of Object.keys(this.formCreateStudent.controls)) {
      if (this.formCreateStudent.controls[key].invalid) {
        const invalidControl = this.elementRef.nativeElement.querySelector('#' + key);
        invalidControl.focus();
        break;
      }
    }

    this.submitted = true;
    if (!this.isRequest && this.formCreateStudent.valid && !this.inValid && this.submitted) {
      this.isLoading = true;
      this.isRequest = true;
      this.id = ulid();
      let dataForm = this.formCreateStudent.getRawValue();
      let studentInfo = this.getStudentInfo(dataForm);
      let studentEnrollment = this.getStudentEnrollment(dataForm);
      let studentClassification = this.getStudentClassification(dataForm);
      let studentIdentityVerification = this.getStudentIdentityVerification(dataForm);
      let studentPilotLicense = this.getStudentStudentPilotLicense(dataForm);
      let studentCourseMulti = this.getStudentCourseMulti(dataForm);
      let studentCourseAirplane = this.getStudentCourseAirplane(dataForm);
      let studentCourseHelicopter = this.getStudentCourseHelicopter(dataForm);
      let StudentManeuverMode = this.getStudentManeuverMode(dataForm);
      let level = LEVEl_ONE;

      if (!!this.fileUpload) {
        studentInfo.picture = await this.uploadService.uploadFile(this.id, this.fileUpload);
      }

      if (
        checkboxSecondClassChecked(this.f, 'a_second') ||
        checkboxSecondClassChecked(this.f, 'b_second') ||
        checkboxSecondClassChecked(this.f, 'c_second')
      ) level = LEVEl_TWO;

      const student: IStudent = {
        studentInfo: studentInfo,
        studentEnrollment: studentEnrollment,
        studentClassification: studentClassification,
        studentIdentityVerification: studentIdentityVerification,
        studentPilotLicense: studentPilotLicense,
        studentCourseMulti: studentCourseMulti,
        studentCourseHelicopter: studentCourseHelicopter,
        studentCourseAirplane: studentCourseAirplane,
        studentManeuverMode: StudentManeuverMode,
        level: level,
      };

      this.studentService.create(student, this.currentUser.uid)
      .then(() => this.router.navigateByUrl('student'))
      .then(() => this.alertService.success(this.translateService.instant('student.createStudent.success')))
      .catch(() => this.alertService.error(this.translateService.instant('student.createStudent.error')))
      .finally(() => {
        this.isRequest = false;
        this.isLoading = false;
      });
    }
  }

  getStudentManeuverMode(dataForm: any): IStudentManeuverMode {
    return {
      mode1: dataForm.mode1,
      mode2: dataForm.mode2,
      mode3: dataForm.mode3,
    };
  }

  getStudentCourseHelicopter(dataForm: any): IStudentCourseHelicopter {
    return {
      c_rotorcraft: dataForm.c_rotorcraft,

      c_firstClassBasic: dataForm.c_firstClassBasic,
      c_firstClassBasicDate: dataForm.c_firstClassBasicDate,
      c_firstClassBasicNo: dataForm.c_firstClassBasicNo,
      c_firstClassBasicPlace: dataForm.c_firstClassBasicPlace,
      c_firstClassBasicResponsible: dataForm.c_firstClassBasicResponsible,
      c_firstClassBasicSendingDate: dataForm.c_firstClassBasicSendingDate,
      c_firstClassBasicDeliveryDate: dataForm.c_firstClassBasicDeliveryDate,
      c_firstClassBasicExpirationDate: dataForm.c_firstClassBasicExpirationDate,
      c_firstClassBasicStatus: dataForm.c_firstClassBasicStatus,
      c_firstClassBasicApplicationDate: dataForm.c_firstClassBasicApplicationDate,

      c_firstClassDaytimeOnly: dataForm.c_firstClassDaytimeOnly,
      c_firstClassDaytimeOnlyDate: dataForm.c_firstClassDaytimeOnlyDate,
      c_firstClassDaytimeOnlyNo: dataForm.c_firstClassDaytimeOnlyNo,
      c_firstClassDaytimeOnlyPlace: dataForm.c_firstClassDaytimeOnlyPlace,
      c_firstClassDaytimeOnlyResponsible: dataForm.c_firstClassDaytimeOnlyResponsible,
      c_firstClassDaytimeOnlySendingDate: dataForm.c_firstClassDaytimeOnlySendingDate,
      c_firstClassDaytimeOnlyDeliveryDate: dataForm.c_firstClassDaytimeOnlyDeliveryDate,
      c_firstClassDaytimeOnlyExpirationDate: dataForm.c_firstClassDaytimeOnlyExpirationDate,
      c_firstClassDaytimeOnlyStatus: dataForm.c_firstClassDaytimeOnlyStatus,
      c_firstClassDaytimeOnlyApplicationDate: dataForm.c_firstClassDaytimeOnlyApplicationDate,

      c_firstClassVisualLineOfSight: dataForm.c_firstClassVisualLineOfSight,
      c_firstClassVisualLineOfSightDate: dataForm.c_firstClassVisualLineOfSightDate,
      c_firstClassVisualLineOfSightNo: dataForm.c_firstClassVisualLineOfSightNo,
      c_firstClassVisualLineOfSightPlace: dataForm.c_firstClassVisualLineOfSightPlace,
      c_firstClassVisualLineOfSightResponsible: dataForm.c_firstClassVisualLineOfSightResponsible,
      c_firstClassVisualLineOfSightSendingDate: dataForm.c_firstClassVisualLineOfSightSendingDate,
      c_firstClassVisualLineOfSightDeliveryDate: dataForm.c_firstClassVisualLineOfSightDeliveryDate,
      c_firstClassVisualLineOfSightExpirationDate: dataForm.c_firstClassVisualLineOfSightExpirationDate,
      c_firstClassVisualLineOfSightStatus: dataForm.c_firstClassVisualLineOfSightStatus,
      c_firstClassVisualLineOfSightApplicationDate: dataForm.c_firstClassVisualLineOfSightApplicationDate,

      c_firstClassWeightLimited: dataForm.c_firstClassWeightLimited,
      c_firstClassWeightLimitedDate: dataForm.c_firstClassWeightLimitedDate,
      c_firstClassWeightLimitedNo: dataForm.c_firstClassWeightLimitedNo,
      c_firstClassWeightLimitedPlace: dataForm.c_firstClassWeightLimitedPlace,
      c_firstClassWeightLimitedResponsible: dataForm.c_firstClassWeightLimitedResponsible,
      c_firstClassWeightLimitedSendingDate: dataForm.c_firstClassWeightLimitedSendingDate,
      c_firstClassWeightLimitedDeliveryDate: dataForm.c_firstClassWeightLimitedDeliveryDate,
      c_firstClassWeightLimitedExpirationDate: dataForm.c_firstClassWeightLimitedExpirationDate,
      c_firstClassWeightLimitedStatus: dataForm.c_firstClassWeightLimitedStatus,
      c_firstClassWeightLimitedApplicationDate: dataForm.c_firstClassWeightLimitedApplicationDate,

      c_secondClassBasic: dataForm.c_secondClassBasic,
      c_secondClassBasicDate: dataForm.c_secondClassBasicDate,
      c_secondClassBasicNo: dataForm.c_secondClassBasicNo,
      c_secondClassBasicPlace: dataForm.c_secondClassBasicPlace,
      c_secondClassBasicResponsible: dataForm.c_secondClassBasicResponsible,
      c_secondClassBasicSendingDate: dataForm.c_secondClassBasicSendingDate,
      c_secondClassBasicDeliveryDate: dataForm.c_secondClassBasicDeliveryDate,
      c_secondClassBasicExpirationDate: dataForm.c_secondClassBasicExpirationDate,
      c_secondClassBasicStatus: dataForm.c_secondClassBasicStatus,
      c_secondClassBasicApplicationDate: dataForm.c_secondClassBasicApplicationDate,

      c_secondClassDaytimeOnly: dataForm.c_secondClassDaytimeOnly,
      c_secondClassDaytimeOnlyDate: dataForm.c_secondClassDaytimeOnlyDate,
      c_secondClassDaytimeOnlyNo: dataForm.c_secondClassDaytimeOnlyNo,
      c_secondClassDaytimeOnlyPlace: dataForm.c_secondClassDaytimeOnlyPlace,
      c_secondClassDaytimeOnlyResponsible: dataForm.c_secondClassDaytimeOnlyResponsible,
      c_secondClassDaytimeOnlySendingDate: dataForm.c_secondClassDaytimeOnlySendingDate,
      c_secondClassDaytimeOnlyDeliveryDate: dataForm.c_secondClassDaytimeOnlyDeliveryDate,
      c_secondClassDaytimeOnlyExpirationDate: dataForm.c_secondClassDaytimeOnlyExpirationDate,
      c_secondClassDaytimeOnlyStatus: dataForm.c_secondClassDaytimeOnlyStatus,
      c_secondClassDaytimeOnlyApplicationDate: dataForm.c_secondClassDaytimeOnlyApplicationDate,

      c_secondClassVisualLineOfSight: dataForm.c_secondClassVisualLineOfSight,
      c_secondClassVisualLineOfSightDate: dataForm.c_secondClassVisualLineOfSightDate,
      c_secondClassVisualLineOfSightNo: dataForm.c_secondClassVisualLineOfSightNo,
      c_secondClassVisualLineOfSightPlace: dataForm.c_secondClassVisualLineOfSightPlace,
      c_secondClassVisualLineOfSightResponsible: dataForm.c_secondClassVisualLineOfSightResponsible,
      c_secondClassVisualLineOfSightSendingDate: dataForm.c_secondClassVisualLineOfSightSendingDate,
      c_secondClassVisualLineOfSightDeliveryDate: dataForm.c_secondClassVisualLineOfSightDeliveryDate,
      c_secondClassVisualLineOfSightExpirationDate: dataForm.c_secondClassVisualLineOfSightExpirationDate,
      c_secondClassVisualLineOfSightStatus: dataForm.c_secondClassVisualLineOfSightStatus,
      c_secondClassVisualLineOfSightApplicationDate: dataForm.c_secondClassVisualLineOfSightApplicationDate,

      c_secondClassWeightLimited: dataForm.c_secondClassWeightLimited,
      c_secondClassWeightLimitedDate: dataForm.c_secondClassWeightLimitedDate,
      c_secondClassWeightLimitedNo: dataForm.c_secondClassWeightLimitedNo,
      c_secondClassWeightLimitedPlace: dataForm.c_secondClassWeightLimitedPlace,
      c_secondClassWeightLimitedResponsible: dataForm.c_secondClassWeightLimitedResponsible,
      c_secondClassWeightLimitedSendingDate: dataForm.c_secondClassWeightLimitedSendingDate,
      c_secondClassWeightLimitedDeliveryDate: dataForm.c_secondClassWeightLimitedDeliveryDate,
      c_secondClassWeightLimitedExpirationDate: dataForm.c_secondClassWeightLimitedExpirationDate,
      c_secondClassWeightLimitedStatus: dataForm.c_secondClassWeightLimitedStatus,
      c_secondClassWeightLimitedApplicationDate: dataForm.c_secondClassWeightLimitedApplicationDate,
    };
  }

  getStudentCourseAirplane(dataForm: any): IStudentCourseAirplane {
    return {
      b_plane: dataForm.b_plane,

      b_firstClassBasic: dataForm.b_firstClassBasic,
      b_firstClassBasicDate: dataForm.b_firstClassBasicDate,
      b_firstClassBasicNo: dataForm.b_firstClassBasicNo,
      b_firstClassBasicPlace: dataForm.b_firstClassBasicPlace,
      b_firstClassBasicResponsible: dataForm.b_firstClassBasicResponsible,
      b_firstClassBasicSendingDate: dataForm.b_firstClassBasicSendingDate,
      b_firstClassBasicDeliveryDate: dataForm.b_firstClassBasicDeliveryDate,
      b_firstClassBasicExpirationDate: dataForm.b_firstClassBasicExpirationDate,
      b_firstClassBasicStatus: dataForm.b_firstClassBasicStatus,
      b_firstClassBasicApplicationDate: dataForm.b_firstClassBasicApplicationDate,

      b_firstClassDaytimeOnly: dataForm.b_firstClassDaytimeOnly,
      b_firstClassDaytimeOnlyDate: dataForm.b_firstClassDaytimeOnlyDate,
      b_firstClassDaytimeOnlyNo: dataForm.b_firstClassDaytimeOnlyNo,
      b_firstClassDaytimeOnlyPlace: dataForm.b_firstClassDaytimeOnlyPlace,
      b_firstClassDaytimeOnlyResponsible: dataForm.b_firstClassDaytimeOnlyResponsible,
      b_firstClassDaytimeOnlySendingDate: dataForm.b_firstClassDaytimeOnlySendingDate,
      b_firstClassDaytimeOnlyDeliveryDate: dataForm.b_firstClassDaytimeOnlyDeliveryDate,
      b_firstClassDaytimeOnlyExpirationDate: dataForm.b_firstClassDaytimeOnlyExpirationDate,
      b_firstClassDaytimeOnlyStatus: dataForm.b_firstClassDaytimeOnlyStatus,
      b_firstClassDaytimeOnlyApplicationDate: dataForm.b_firstClassDaytimeOnlyApplicationDate,

      b_firstClassVisualLineOfSight: dataForm.b_firstClassVisualLineOfSight,
      b_firstClassVisualLineOfSightDate: dataForm.b_firstClassVisualLineOfSightDate,
      b_firstClassVisualLineOfSightNo: dataForm.b_firstClassVisualLineOfSightNo,
      b_firstClassVisualLineOfSightPlace: dataForm.b_firstClassVisualLineOfSightPlace,
      b_firstClassVisualLineOfSightResponsible: dataForm.b_firstClassVisualLineOfSightResponsible,
      b_firstClassVisualLineOfSightSendingDate: dataForm.b_firstClassVisualLineOfSightSendingDate,
      b_firstClassVisualLineOfSightDeliveryDate: dataForm.b_firstClassVisualLineOfSightDeliveryDate,
      b_firstClassVisualLineOfSightExpirationDate: dataForm.b_firstClassVisualLineOfSightExpirationDate,
      b_firstClassVisualLineOfSightStatus: dataForm.b_firstClassVisualLineOfSightStatus,
      b_firstClassVisualLineOfSightApplicationDate: dataForm.b_firstClassVisualLineOfSightApplicationDate,

      b_firstClassWeightLimited: dataForm.b_firstClassWeightLimited,
      b_firstClassWeightLimitedDate: dataForm.b_firstClassWeightLimitedDate,
      b_firstClassWeightLimitedNo: dataForm.b_firstClassWeightLimitedNo,
      b_firstClassWeightLimitedPlace: dataForm.b_firstClassWeightLimitedPlace,
      b_firstClassWeightLimitedResponsible: dataForm.b_firstClassWeightLimitedResponsible,
      b_firstClassWeightLimitedSendingDate: dataForm.b_firstClassWeightLimitedSendingDate,
      b_firstClassWeightLimitedDeliveryDate: dataForm.b_firstClassWeightLimitedDeliveryDate,
      b_firstClassWeightLimitedExpirationDate: dataForm.b_firstClassWeightLimitedExpirationDate,
      b_firstClassWeightLimitedStatus: dataForm.b_firstClassWeightLimitedStatus,
      b_firstClassWeightLimitedApplicationDate: dataForm.b_firstClassWeightLimitedApplicationDate,

      b_secondClassBasic: dataForm.b_secondClassBasic,
      b_secondClassBasicDate: dataForm.b_secondClassBasicDate,
      b_secondClassBasicNo: dataForm.b_secondClassBasicNo,
      b_secondClassBasicPlace: dataForm.b_secondClassBasicPlace,
      b_secondClassBasicResponsible: dataForm.b_secondClassBasicResponsible,
      b_secondClassBasicSendingDate: dataForm.b_secondClassBasicSendingDate,
      b_secondClassBasicDeliveryDate: dataForm.b_secondClassBasicDeliveryDate,
      b_secondClassBasicExpirationDate: dataForm.b_secondClassBasicExpirationDate,
      b_secondClassBasicStatus: dataForm.b_secondClassBasicStatus,
      b_secondClassBasicApplicationDate: dataForm.b_secondClassBasicApplicationDate,

      b_secondClassDaytimeOnly: dataForm.b_secondClassDaytimeOnly,
      b_secondClassDaytimeOnlyDate: dataForm.b_secondClassDaytimeOnlyDate,
      b_secondClassDaytimeOnlyNo: dataForm.b_secondClassDaytimeOnlyNo,
      b_secondClassDaytimeOnlyPlace: dataForm.b_secondClassDaytimeOnlyPlace,
      b_secondClassDaytimeOnlyResponsible: dataForm.b_secondClassDaytimeOnlyResponsible,
      b_secondClassDaytimeOnlySendingDate: dataForm.b_secondClassDaytimeOnlySendingDate,
      b_secondClassDaytimeOnlyDeliveryDate: dataForm.b_secondClassDaytimeOnlyDeliveryDate,
      b_secondClassDaytimeOnlyExpirationDate: dataForm.b_secondClassDaytimeOnlyExpirationDate,
      b_secondClassDaytimeOnlyStatus: dataForm.b_secondClassDaytimeOnlyStatus,
      b_secondClassDaytimeOnlyApplicationDate: dataForm.b_secondClassDaytimeOnlyApplicationDate,

      b_secondClassVisualLineOfSight: dataForm.b_secondClassVisualLineOfSight,
      b_secondClassVisualLineOfSightDate: dataForm.b_secondClassVisualLineOfSightDate,
      b_secondClassVisualLineOfSightNo: dataForm.b_secondClassVisualLineOfSightNo,
      b_secondClassVisualLineOfSightPlace: dataForm.b_secondClassVisualLineOfSightPlace,
      b_secondClassVisualLineOfSightResponsible: dataForm.b_secondClassVisualLineOfSightResponsible,
      b_secondClassVisualLineOfSightSendingDate: dataForm.b_secondClassVisualLineOfSightSendingDate,
      b_secondClassVisualLineOfSightDeliveryDate: dataForm.b_secondClassVisualLineOfSightDeliveryDate,
      b_secondClassVisualLineOfSightExpirationDate: dataForm.b_secondClassVisualLineOfSightExpirationDate,
      b_secondClassVisualLineOfSightStatus: dataForm.b_secondClassVisualLineOfSightStatus,
      b_secondClassVisualLineOfSightApplicationDate: dataForm.b_secondClassVisualLineOfSightApplicationDate,

      b_secondClassWeightLimited: dataForm.b_secondClassWeightLimited,
      b_secondClassWeightLimitedDate: dataForm.b_secondClassWeightLimitedDate,
      b_secondClassWeightLimitedNo: dataForm.b_secondClassWeightLimitedNo,
      b_secondClassWeightLimitedPlace: dataForm.b_secondClassWeightLimitedPlace,
      b_secondClassWeightLimitedResponsible: dataForm.b_secondClassWeightLimitedResponsible,
      b_secondClassWeightLimitedSendingDate: dataForm.b_secondClassWeightLimitedSendingDate,
      b_secondClassWeightLimitedDeliveryDate: dataForm.b_secondClassWeightLimitedDeliveryDate,
      b_secondClassWeightLimitedExpirationDate: dataForm.b_secondClassWeightLimitedExpirationDate,
      b_secondClassWeightLimitedStatus: dataForm.b_secondClassWeightLimitedStatus,
      b_secondClassWeightLimitedApplicationDate: dataForm.b_secondClassWeightLimitedApplicationDate,
    };
  }

  getStudentCourseMulti(dataForm: any): IStudentCourseMulti {
    return {
      a_rotorcraftMulti: dataForm.a_rotorcraftMulti,

      a_firstClassBasic: dataForm.a_firstClassBasic,
      a_firstClassBasicDate: dataForm.a_firstClassBasicDate,
      a_firstClassBasicNo: dataForm.a_firstClassBasicNo,
      a_firstClassBasicPlace: dataForm.a_firstClassBasicPlace,
      a_firstClassBasicResponsible: dataForm.a_firstClassBasicResponsible,
      a_firstClassBasicSendingDate: dataForm.a_firstClassBasicSendingDate,
      a_firstClassBasicDeliveryDate: dataForm.a_firstClassBasicDeliveryDate,
      a_firstClassBasicExpirationDate: dataForm.a_firstClassBasicExpirationDate,
      a_firstClassBasicStatus: dataForm.a_firstClassBasicStatus,
      a_firstClassBasicApplicationDate: dataForm.a_firstClassBasicApplicationDate,

      a_firstClassDaytimeOnly: dataForm.a_firstClassDaytimeOnly,
      a_firstClassDaytimeOnlyDate: dataForm.a_firstClassDaytimeOnlyDate,
      a_firstClassDaytimeOnlyNo: dataForm.a_firstClassDaytimeOnlyNo,
      a_firstClassDaytimeOnlyPlace: dataForm.a_firstClassDaytimeOnlyPlace,
      a_firstClassDaytimeOnlyResponsible: dataForm.a_firstClassDaytimeOnlyResponsible,
      a_firstClassDaytimeOnlySendingDate: dataForm.a_firstClassDaytimeOnlySendingDate,
      a_firstClassDaytimeOnlyDeliveryDate: dataForm.a_firstClassDaytimeOnlyDeliveryDate,
      a_firstClassDaytimeOnlyExpirationDate: dataForm.a_firstClassDaytimeOnlyExpirationDate,
      a_firstClassDaytimeOnlyStatus: dataForm.a_firstClassDaytimeOnlyStatus,
      a_firstClassDaytimeOnlyApplicationDate: dataForm.a_firstClassDaytimeOnlyApplicationDate,

      a_firstClassVisualLineOfSight: dataForm.a_firstClassVisualLineOfSight,
      a_firstClassVisualLineOfSightDate: dataForm.a_firstClassVisualLineOfSightDate,
      a_firstClassVisualLineOfSightNo: dataForm.a_firstClassVisualLineOfSightNo,
      a_firstClassVisualLineOfSightPlace: dataForm.a_firstClassVisualLineOfSightPlace,
      a_firstClassVisualLineOfSightResponsible: dataForm.a_firstClassVisualLineOfSightResponsible,
      a_firstClassVisualLineOfSightSendingDate: dataForm.a_firstClassVisualLineOfSightSendingDate,
      a_firstClassVisualLineOfSightDeliveryDate: dataForm.a_firstClassVisualLineOfSightDeliveryDate,
      a_firstClassVisualLineOfSightExpirationDate: dataForm.a_firstClassVisualLineOfSightExpirationDate,
      a_firstClassVisualLineOfSightStatus: dataForm.a_firstClassVisualLineOfSightStatus,
      a_firstClassVisualLineOfSightApplicationDate: dataForm.a_firstClassVisualLineOfSightApplicationDate,

      a_firstClassWeightLimited: dataForm.a_firstClassWeightLimited,
      a_firstClassWeightLimitedDate: dataForm.a_firstClassWeightLimitedDate,
      a_firstClassWeightLimitedNo: dataForm.a_firstClassWeightLimitedNo,
      a_firstClassWeightLimitedPlace: dataForm.a_firstClassWeightLimitedPlace,
      a_firstClassWeightLimitedResponsible: dataForm.a_firstClassWeightLimitedResponsible,
      a_firstClassWeightLimitedSendingDate: dataForm.a_firstClassWeightLimitedSendingDate,
      a_firstClassWeightLimitedDeliveryDate: dataForm.a_firstClassWeightLimitedDeliveryDate,
      a_firstClassWeightLimitedExpirationDate: dataForm.a_firstClassWeightLimitedExpirationDate,
      a_firstClassWeightLimitedStatus: dataForm.a_firstClassWeightLimitedStatus,
      a_firstClassWeightLimitedApplicationDate: dataForm.a_firstClassWeightLimitedApplicationDate,

      a_secondClassBasic: dataForm.a_secondClassBasic,
      a_secondClassBasicDate: dataForm.a_secondClassBasicDate,
      a_secondClassBasicNo: dataForm.a_secondClassBasicNo,
      a_secondClassBasicPlace: dataForm.a_secondClassBasicPlace,
      a_secondClassBasicResponsible: dataForm.a_secondClassBasicResponsible,
      a_secondClassBasicSendingDate: dataForm.a_secondClassBasicSendingDate,
      a_secondClassBasicDeliveryDate: dataForm.a_secondClassBasicDeliveryDate,
      a_secondClassBasicExpirationDate: dataForm.a_secondClassBasicExpirationDate,
      a_secondClassBasicStatus: dataForm.a_secondClassBasicStatus,
      a_secondClassBasicApplicationDate: dataForm.a_secondClassBasicApplicationDate,

      a_secondClassDaytimeOnly: dataForm.a_secondClassDaytimeOnly,
      a_secondClassDaytimeOnlyDate: dataForm.a_secondClassDaytimeOnlyDate,
      a_secondClassDaytimeOnlyNo: dataForm.a_secondClassDaytimeOnlyNo,
      a_secondClassDaytimeOnlyPlace: dataForm.a_secondClassDaytimeOnlyPlace,
      a_secondClassDaytimeOnlyResponsible: dataForm.a_secondClassDaytimeOnlyResponsible,
      a_secondClassDaytimeOnlySendingDate: dataForm.a_secondClassDaytimeOnlySendingDate,
      a_secondClassDaytimeOnlyDeliveryDate: dataForm.a_secondClassDaytimeOnlyDeliveryDate,
      a_secondClassDaytimeOnlyExpirationDate: dataForm.a_secondClassDaytimeOnlyExpirationDate,
      a_secondClassDaytimeOnlyStatus: dataForm.a_secondClassDaytimeOnlyStatus,
      a_secondClassDaytimeOnlyApplicationDate: dataForm.a_secondClassDaytimeOnlyApplicationDate,

      a_secondClassVisualLineOfSight: dataForm.a_secondClassVisualLineOfSight,
      a_secondClassVisualLineOfSightDate: dataForm.a_secondClassVisualLineOfSightDate,
      a_secondClassVisualLineOfSightNo: dataForm.a_secondClassVisualLineOfSightNo,
      a_secondClassVisualLineOfSightPlace: dataForm.a_secondClassVisualLineOfSightPlace,
      a_secondClassVisualLineOfSightResponsible: dataForm.a_secondClassVisualLineOfSightResponsible,
      a_secondClassVisualLineOfSightSendingDate: dataForm.a_secondClassVisualLineOfSightSendingDate,
      a_secondClassVisualLineOfSightDeliveryDate: dataForm.a_secondClassVisualLineOfSightDeliveryDate,
      a_secondClassVisualLineOfSightExpirationDate: dataForm.a_secondClassVisualLineOfSightExpirationDate,
      a_secondClassVisualLineOfSightStatus: dataForm.a_secondClassVisualLineOfSightStatus,
      a_secondClassVisualLineOfSightApplicationDate: dataForm.a_secondClassVisualLineOfSightApplicationDate,

      a_secondClassWeightLimited: dataForm.a_secondClassWeightLimited,
      a_secondClassWeightLimitedDate: dataForm.a_secondClassWeightLimitedDate,
      a_secondClassWeightLimitedNo: dataForm.a_secondClassWeightLimitedNo,
      a_secondClassWeightLimitedPlace: dataForm.a_secondClassWeightLimitedPlace,
      a_secondClassWeightLimitedResponsible: dataForm.a_secondClassWeightLimitedResponsible,
      a_secondClassWeightLimitedSendingDate: dataForm.a_secondClassWeightLimitedSendingDate,
      a_secondClassWeightLimitedDeliveryDate: dataForm.a_secondClassWeightLimitedDeliveryDate,
      a_secondClassWeightLimitedExpirationDate: dataForm.a_secondClassWeightLimitedExpirationDate,
      a_secondClassWeightLimitedStatus: dataForm.a_secondClassWeightLimitedStatus,
      a_secondClassWeightLimitedApplicationDate: dataForm.a_secondClassWeightLimitedApplicationDate,
    };
  }

  getStudentStudentPilotLicense(dataForm: any): IStudentPilotLicense {
    return {
      issue: dataForm.issue,
      dateOfIssue: dataForm.dateOfIssue,
      dateOfExpiry: dataForm.dateOfExpiry,
      skillNumber: dataForm.skillNumber,
      licenseConditions: dataForm.licenseConditions,

      x_firstClassBasic: dataForm.x_firstClassBasic,
      x_firstClassDaytimeOnly: dataForm.x_firstClassDaytimeOnly,
      x_firstClassVisualLineOfSight: dataForm.x_firstClassVisualLineOfSight,
      x_firstClassWeightLimited: dataForm.x_firstClassWeightLimited,

      x_secondClassBasic: dataForm.x_secondClassBasic,
      x_secondClassDaytimeOnly: dataForm.x_secondClassDaytimeOnly,
      x_secondClassVisualLineOfSight: dataForm.x_secondClassVisualLineOfSight,
      x_secondClassWeightLimited: dataForm.x_secondClassWeightLimited,

      y_firstClassBasic: dataForm.y_firstClassBasic,
      y_firstClassDaytimeOnly: dataForm.y_firstClassDaytimeOnly,
      y_firstClassVisualLineOfSight: dataForm.y_firstClassVisualLineOfSight,
      y_firstClassWeightLimited: dataForm.y_firstClassWeightLimited,

      y_secondClassBasic: dataForm.y_secondClassBasic,
      y_secondClassDaytimeOnly: dataForm.y_secondClassDaytimeOnly,
      y_secondClassVisualLineOfSight: dataForm.y_secondClassVisualLineOfSight,
      y_secondClassWeightLimited: dataForm.y_secondClassWeightLimited,

      z_firstClassBasic: dataForm.z_firstClassBasic,
      z_firstClassDaytimeOnly: dataForm.z_firstClassDaytimeOnly,
      z_firstClassVisualLineOfSight: dataForm.z_firstClassVisualLineOfSight,
      z_firstClassWeightLimited: dataForm.z_firstClassWeightLimited,

      z_secondClassBasic: dataForm.z_secondClassBasic,
      z_secondClassDaytimeOnly: dataForm.z_secondClassDaytimeOnly,
      z_secondClassVisualLineOfSight: dataForm.z_secondClassVisualLineOfSight,
      z_secondClassWeightLimited: dataForm.z_secondClassWeightLimited
    };
  }

  getStudentIdentityVerification(dataForm: any): IStudentIdentityVerification {
    return {
      driverLicense: dataForm.driverLicense,
      myNumberCard: dataForm.myNumberCard,
      etc: dataForm.etc,
      etcText: dataForm.etcText,
      noEtc: dataForm.noEtc
    };
  }

  getStudentClassification(dataForm: any): IStudentClassification {
    return {
      experiencedType: dataForm.experiencedType,
      privateSkillCertificate: dataForm.privateSkillCertificate,
      totalFlightTime: dataForm.totalFlightTime,
      issuingOrganizationName: dataForm.issuingOrganizationName,
      no: dataForm.no,
      no_expireDate: dataForm.no_expireDate,
      number: dataForm.number,
      number_expireDate: dataForm.number_expireDate,
      status: dataForm.status,
      statusElearning: dataForm.statusElearning,
      useElearning: (dataForm.useElearning == YES) ? true : false,
    };
  }

  getStudentEnrollment(dataForm: any): IStudentEnrollment {
    return {
      enrollmentDate: dataForm.enrollmentDate,
      practicalDate: dataForm.practicalDate
    };
  }

  getStudentInfo(dataForm: any): IStudentInfo {
    return {
      ledgerNumber: dataForm.ledgerNumber,
      studentNumber: dataForm.studentNumber,
      hurigana: dataForm.hurigana,
      name: dataForm.name,
      certificateNumber: dataForm.certificateNumber,
      birthday: dataForm.birthday,
      sex: dataForm.sex,
      address: dataForm.address,
      tel: dataForm.tel,
      email: dataForm.email,
      createAt: new Date(),
      remark: dataForm.remark,
      academicExamStatus: dataForm.academicExamStatus,
      academicExamPassDate: dataForm.academicExamPassDate,
      academicExamPassCertificateNumber: dataForm.academicExamPassCertificateNumber,
      courseItems: dataForm.courseItems,
      organizationName: dataForm.organizationName,
    };
  }

  onDataReceived(data: string): void {
    this.fileUpload = data;
  }

  async getUser(): Promise<void> {
    const currentUser = await this.auth.currentUser;
    if (currentUser) this.currentUser = currentUser;
    const [dataUser] = await this.userService.getUserByMail(currentUser?.email);
    this.f['statusElearning'].setValue(dataUser.statusElearning);
    this.f['useElearning'].setValue(dataUser.useElearning ? YES : NO);
    this.learning = dataUser.useElearning;
  }

  async setDataReadOnly(): Promise<void> {
    const date = new Date();
    const time = formatDate(date, formatYYYYMMDD);
    await this.studentService.getAllStudentByUser(this.currentUser.uid).subscribe((students) => {
      let numberUnix = students.length + 1;
      const ledgerNumber = `${time}${numberUnix}`;
      this.f['ledgerNumber'].setValue(ledgerNumber);
      this.f['studentNumber'].setValue(numberUnix);
    })
  }

  public backToListStudent(): void {
    this.router.navigateByUrl('student').then();
  }

  public changeEtcTextCheckbox(): void {
    this.formCreateStudent.controls['etcText'].setValue('');
  }
}
