import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingService } from '@app/services';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  protected menuData: any;
  protected jsonFileUrl: string = '/assets/json/menu.json';
  protected sidebarTitle: string = '';
  protected navItems: any;
  protected active: boolean = true;
  protected leftMenu: boolean = false;

  constructor(
    private http: HttpClient,
    private settingService: SettingService,
) {}

  ngOnInit(): void {
    this.getSettings();
    this.http.get(this.jsonFileUrl).subscribe((res) => {
      this.menuData = res;
      this.sidebarTitle = this.menuData?.meta?.name;
      this.navItems = this.menuData?.objects;
    });
  }

  private async getSettings(): Promise<void> {
    (await this.settingService.getLeftMenuSetting()).subscribe((items) => {
			if (items.length > 0) {
				let data: any = items[0].payload.doc.data();
				this.leftMenu = data.left_menu ?? true;
			}
		});
  }

  toggleNav() {
    this.active = !this.active;
    const signInStatus = document.getElementById('signInStatusId');
    signInStatus?.classList.toggle('active-sidebar-toggle');
  }
}
