import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import {
  DEFAULT_ITEMS_PER_PAGE,
  formatDatePicker,
  Processing,
  Released,
  STRING_REGEX,
  WHITE_SPACE_REGEX,
} from '@app/constants';
import { formatDate, getLocaleDatePicker } from '@app/helpers';
import { TranslateService } from '@ngx-translate/core';
import { ILevel, LevelStudent } from '@app/models/levelStudent';
import { IStatus, StatusElearningStudent, StatusStudent } from '@app/models/statusStudent';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Student, IStudent } from '@app/models/student';
import { AlertService, AttendanceService, StudentService, UserService } from '@app/services';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.scss'],
})
export class StudentComponent implements OnInit {
  protected visibleCollapse: boolean = false;
  protected date = new Date();
  protected formSearch!: FormGroup;
  protected visible: boolean = false;
  protected id: string = '';
  protected currentDatepickerLocale: string = '';
  protected currentLanguage = this.translate.currentLang;
  protected formatDatePicker: string = formatDatePicker;
  protected queryParams: any;
  protected readonly levels: Array<ILevel> = LevelStudent;
  protected readonly status: Array<IStatus> = StatusStudent;
  protected readonly statusElearning: Array<IStatus> = StatusElearningStudent;
  protected readonly studentcr: Array<IStudent> = Student;
  protected students: Array<IStudent> = [];
  protected currentPage: number = 1;
  readonly itemsPerPage: number = DEFAULT_ITEMS_PER_PAGE;
  protected currentUser: any;
  protected dataCurrentUser: any;
  protected numberStudentFilter: number = 0;
  protected isLoading: boolean = false;
  protected studentVisibleArr: string[] = [];
  @Output() pageOwner = new EventEmitter<number>();
  protected siteBar: string = "true";
  protected Processing: string = Processing;
  protected Released: string = Released;
  protected courseItems: any = [];

  constructor(
    private router: Router,
    private translate: TranslateService,
    private attendanceService: AttendanceService,
    private formBuilder: FormBuilder,
    private studentService: StudentService,
    private auth: AngularFireAuth,
    private userService: UserService,
    private alertService: AlertService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.formSearch = this.formBuilder.group({
      querySearch: [''],
      startDate: [new Date(new Date().getFullYear(), 0, 1)],
      endDate: [new Date(new Date().getFullYear(), 11, 31)],
      cerf_code: [false],
      level: [''],
      courseItems: [null],
      status: [''],
      statusElearning: [''],
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) this.navigateParams();
    });
    this.visibleCollapse = false;
    this.currentDatepickerLocale = getLocaleDatePicker(this.currentLanguage);
    this.getCourseItems();
    this.getUser().then(() => this.handleQueryParams());
  }

  private async getCourseItems(): Promise<void> {
    const data = await this.attendanceService.getItems('course_items', 'itemNumber', false);
    if (data.length) this.courseItems = data;
  }

  protected handleChangeCollapseStudent(event: any) {
    this.studentVisibleArr = this.studentVisibleArr.filter((item) => item !== event.id);
    if (event.visible) this.studentVisibleArr.push(event.id);
  }

  private handleQueryParams(): void {
    let params: any = localStorage.getItem('queryString.students');
    localStorage.removeItem('queryString.students');
    if (params) {
      params = JSON.parse(params);
      this.f['querySearch'].setValue(params['querySearch']);
      this.f['cerf_code'].setValue(params['cerf_code']);
      this.f['level'].setValue(params['level']);
      this.f['status'].setValue(params['status']);
      this.f['statusElearning'].setValue(params['statusElearning']);

      if (!!params['startDate']) this.f['startDate'].setValue(new Date(params['startDate']));
      if (!!params['endDate']) this.f['endDate'].setValue(new Date(params['endDate']));

      this.visibleCollapse = params['visibleCollapse'];
      this.studentVisibleArr = params['studentVisibleArr'];
      this.search().then(() => this.changePage(params['page']));
    } else this.search();
  }

  protected handleUpdateStatusRequestReleased(event: any) {
    this.studentService.updateStatusRequestReleased(event.data.uid, event.data.studentID, this.Processing, event.data.studentCourse)
      .finally(() => {
        this.alertService.success(this.translateService.instant('student.attendances.update.success'));
      });
  }

  protected toggleCollapse() {
    this.visibleCollapse = !this.visibleCollapse;
  }

  protected toggleModal(id: string) {
    this.id = id;
    this.visible = !this.visible;
  }

  protected dayFormat = (date: Date) => date.getDate();

  protected get f() {
    return this.formSearch.controls;
  }

  protected onUncheckOptions(option: string): void {
    if (option == 'courseItems') {
      this.f[option].setValue(null);
    } else {
      this.f[option].setValue('');
    }
  }

  protected checkOptionAll(option: string): boolean {
    if (option == 'courseItems') {
      return this.formSearch.get(option)?.value == null;
    }
    return !this.formSearch.get(option)?.value?.length;
  }

  protected async search(): Promise<void> {
    this.currentPage = 1;
    await this.studentService.getList(this.formSearch.value, this.currentUser.uid, this.f['cerf_code'].value)
      .subscribe(student => {
        let unique = [...new Map(student.map(item => [item.id, item])).values()];
        let students: any[] = [];

        students = unique.filter((data: any) => {
          const querySearch = this.f['querySearch'].value.trim();
          const advancedSearch = this.f['cerf_code'].value;
          data.studentInfo.organizationName = data.studentInfo.organizationName ?? '';

          if (querySearch != '') {
            if (advancedSearch) {
              let querySearchArray: string[] = querySearch.split(STRING_REGEX).filter(Boolean);
              if (querySearchArray.length > 1) {
                let stringHurigana: string = querySearchArray[0].trim() ?? '';
                querySearchArray.splice(0, 1);
                let certificateNumber: string = querySearchArray.join('').trim().replace(WHITE_SPACE_REGEX, '');
                if (!!stringHurigana && !!certificateNumber) {
                  return data.studentInfo.hurigana.indexOf(stringHurigana) > -1 && data.studentInfo.certificateNumber.indexOf(certificateNumber) > -1;
                }
              }
              return false;
            }
            return data.studentInfo.name.indexOf(querySearch) > -1
              || data.studentInfo.certificateNumber.indexOf(querySearch) > -1
              || data.studentInfo.hurigana.indexOf(querySearch) > -1
              || data.studentInfo.organizationName.indexOf(querySearch) > -1;
          }
          return true;
        });

        this.students = students;
        this.numberStudentFilter = this.students.length;
      });

  }

  private async getUser(): Promise<void> {
    const currentUser = await this.auth.currentUser;
    const user = await this.userService.getUserByMail(currentUser?.email);
    if (user && user.length > 0) {
      const [dataUser] = user;
      this.dataCurrentUser = dataUser;
    }
    if (currentUser) this.currentUser = currentUser;
  }

  protected changePage = (page: number): void => {
    this.currentPage = page;
    this.pageOwner.emit(page);
  }

  protected exportCsvStudent(): void {
    this.isLoading = true;
    this.studentService.exportCsv(this.currentUser.uid, this.formSearch.value)
      .finally(() => (this.isLoading = false));
  }

  protected onFileChange(event: any): void {
    const file = event.target.files[0];
    this.isLoading = true;

    if (file.type !== 'text/csv') {
      this.isLoading = false;
      this.alertService.error('csvファイルを入力してください。');
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const csvData = e.target?.result as string;
      this.studentService.importCsv(csvData, this.currentUser.uid)
        .finally(() => {
          this.isLoading = false;
          event.target.value = '';
        });
    };

    reader.readAsText(file);
  }

  private navigateParams(): void {
    let params = this.formSearch.getRawValue();
    params.startDate = formatDate(params.startDate, formatDatePicker);
    params.endDate = formatDate(params.endDate, formatDatePicker);
    params.visibleCollapse = this.visibleCollapse;
    params.page = this.currentPage;
    params.studentVisibleArr = this.studentVisibleArr;
    localStorage.setItem('queryString.students', JSON.stringify(params));
  }
}
