<div class="mb-3">
  <label class="form-label mb-1">
    {{ label }}
    <c-badge *ngIf="requiredText" color="danger">{{ "form.inputLabel.must" | translate}}</c-badge>
  </label>
  <div class="flex-lg-row d-flex">
    <div class="checkbox_item">
      <label class="checkbox_item--label" *ngFor="let item of dataOption">
        <input
          cFormCheckInput
          type="radio"
          [value]="item.value"
          [formControlName]="formControlNameInput"
          [name]="formControlNameInput"
          id="{{ formControlNameInput + item.id }}"
        />
        <div class="checkmark_radio"></div>
        <div>{{ item?.label }}</div>
      </label>
    </div>
  </div>
  <c-form-feedback *ngIf="(submitted || form[formControlNameInput]?.touched) && form[formControlNameInput].errors">
    <div *ngIf="form[formControlNameInput]?.errors[getFirstError(form[formControlNameInput]?.errors) || '']">
      <div *ngIf="getFirstError(form[formControlNameInput]?.errors) === 'required'">
        {{ "errorMessages.formValidate.input.required" | translate }}
      </div>
    </div>
  </c-form-feedback>
</div>
