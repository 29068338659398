import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { formatDatePicker } from '@app/constants';
import {
  checkInputEnter,
  getFirstError,
  getLocaleDatePicker,
} from '@app/helpers';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-input-date-picker',
  templateUrl: './input-date-picker.component.html',
  styleUrls: ['./input-date-picker.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class InputDatePickerComponent implements OnInit {
  getFirstError = getFirstError;
  @Input('formGroup') formGroup: any;
  @Input('form') form: any = [];
  @Input('label') label: string = '';
  @Input('ngClass') ngClass: NgClass['ngClass'];
  @Input('formControlNameInput') formControlNameInput: string = '';
  @Input('placeHolderInput') placeHolderInput: string = '';
  @Input('submitted') submitted: boolean = false;
  @Input('initialDate') initialDate: Date = new Date();
  @Input('requiredText') requiredText: boolean = true;
  @Input('disabled') disabled: boolean = false;
  @Output('changeValueDate') changeValueDate: EventEmitter<any> = new EventEmitter<any>();

  protected minDate: Date = new Date(this.getMinDate());
  protected maxDate: Date = new Date(this.getMaxDate());
  protected currentDatepickerLocale: string = '';
  protected checkInputEnter = checkInputEnter;
  protected formatDatePicker: string = formatDatePicker;
  protected currentLanguage = this.translate.currentLang;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.currentDatepickerLocale = getLocaleDatePicker(this.currentLanguage);
    if (this.placeHolderInput) this.formatDatePicker = this.placeHolderInput;
  }

  private getMinDate(): Date {
    const today = new Date();
    return new Date(today.getFullYear() - 200, 0, 0);
  }

  private getMaxDate(): Date {
    const today = new Date();
    return new Date(today.getFullYear() + 200, 0, 0);
  }

  public handleChangeDate(event: any) {
    const validControlNames = [
        'a_firstClassBasicDeliveryDate',
        'a_firstClassDaytimeOnlyDeliveryDate',
        'a_firstClassVisualLineOfSightDeliveryDate',
        'a_firstClassWeightLimitedDeliveryDate',
        'a_secondClassBasicDeliveryDate',
        'a_secondClassDaytimeOnlyDeliveryDate',
        'a_secondClassVisualLineOfSightDeliveryDate',
        'a_secondClassWeightLimitedDeliveryDate',

        'b_firstClassBasicDeliveryDate',
        'b_firstClassDaytimeOnlyDeliveryDate',
        'b_firstClassVisualLineOfSightDeliveryDate',
        'b_firstClassWeightLimitedDeliveryDate',
        'b_secondClassBasicDeliveryDate',
        'b_secondClassDaytimeOnlyDeliveryDate',
        'b_secondClassVisualLineOfSightDeliveryDate',
        'b_secondClassWeightLimitedDeliveryDate',

        'c_firstClassBasicDeliveryDate',
        'c_firstClassDaytimeOnlyDeliveryDate',
        'c_firstClassVisualLineOfSightDeliveryDate',
        'c_firstClassWeightLimitedDeliveryDate',
        'c_secondClassBasicDeliveryDate',
        'c_secondClassDaytimeOnlyDeliveryDate',
        'c_secondClassVisualLineOfSightDeliveryDate',
        'c_secondClassWeightLimitedDeliveryDate',
    ];

    if (validControlNames.includes(this.formControlNameInput)) {
        this.changeValueDate.emit({ data: event, value: this.formControlNameInput });
    }
  }

  dayFormat = (date: Date) => date.getDate();
}
