import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ILevel, LevelStudent } from "@app/models/levelStudent";
import { Router } from "@angular/router";
import {
  ColorTypes,
  courseTypeAttributes,
  courseTypeOrders,
  courseTypes,
  experiencedType, experiencedTypeListColor,
  formatDatePicker, LEVEl_ONE, LEVEl_TWO,
  NO,
  studentCourses,
  YES
} from "@app/constants";
import { formatDate } from "@app/helpers";
import { AttendanceService } from '@app/services';
import { CompletionRecordService } from '@app/services/completion-record.service';

@Component({
  selector: 'app-student-item',
  templateUrl: './student-item.component.html',
  styleUrls: ['./student-item.component.scss']
})
export class StudentItemComponent implements OnInit {
  @Input('itemInput') itemInput: any;
  @Input('dataCurrentUser') dataCurrentUser: any;
  @Input('currentUser') currentUser: any;
  @Input('studentApproval') studentApproval: any;
  @Input('studentVisibleArr') studentVisibleArr: string[] = [];

  @Output('changeVisible') changeVisible: EventEmitter<any> = new EventEmitter<any>();
  @Output('updateStatusRequestReleased') updateStatusRequestReleased: EventEmitter<any> = new EventEmitter<any>();
  @Output('deleteItem') deleteItem: EventEmitter<any> = new EventEmitter<any>();

  protected studentCourseProcessed: any[] = [];
  readonly LevelStudent: ILevel[] = LevelStudent;
  protected color: string = '';
  protected border: string = '1px solid #667085';
  protected readonly formatDatePicker: string = formatDatePicker;
  protected readonly formatDate = formatDate;
  protected visible: boolean = false;
  protected urlElearning:string = 'https://tact-elearning.web.app/#/login?certificate=';
  protected dataStudentCourse: any = '';
  protected YES: string = YES;
  protected NO: string = NO;
  protected courseItem: any;
  protected courseItems: any = [];

  constructor(
    private route: Router,
    private attendanceService: AttendanceService,
    private completionRecordService: CompletionRecordService
  ) {}

  public getColor(): void {
    let experiencedStr: string = '';
    let levelStr: string = '';
    if (this.itemInput.studentClassification.experiencedType === experiencedType.experienced) experiencedStr = 'experienced';
    if (this.itemInput.studentClassification.experiencedType === experiencedType.beginner) experiencedStr = 'beginner';
    if (this.itemInput.level === LEVEl_ONE) levelStr = 'first';
    if (this.itemInput.level === LEVEl_TWO) levelStr = 'second';

    let level = experiencedTypeListColor.find(item => item.key === `${experiencedStr}_${levelStr}`);
    if (!!level) this.color = level?.color;
    if (this.color !== ColorTypes['white']) this.border = '';
  }

  ngOnInit(): void {
    if (this.studentVisibleArr.includes(this.itemInput.id)) this.visible = true;
    this.getCourseItems();
    this.getColor();
    this.processCourse();
  }

  public toggleCollapse(): void {
    this.visible = !this.visible;
    this.changeVisible.emit({id: this.itemInput.id, visible: this.visible});
  }

  protected handleUpdateStatusRequest(event: any) {
    this.updateStatusRequestReleased.emit({data: event});
  }

  private async getCourseItems(): Promise<void> {
    const data = await this.attendanceService.getItems('course_items', 'itemNumber', false);
    if (data.length) this.courseItems = data;

    if (this.itemInput.studentInfo.courseItems && this.courseItems.length > 0) {
      this.courseItem = this.courseItems.filter((e: any) => e.itemNumber == this.itemInput.studentInfo.courseItems)[0];
    }
  }

  private async processCourse() {
    const [dataAttendancesExamPass, dataCompletionExamPass] = await Promise.all([
      this.attendanceService.geAttendancesExamPass(this.currentUser.uid, this.itemInput.id),
      this.completionRecordService.geCompletionRecordExamPass(this.currentUser.uid, this.itemInput.id)
    ]);
    studentCourses.forEach(studentCourse => {
      const course = this.itemInput[studentCourse.key];
      courseTypeOrders.forEach(typeOrder => {
        courseTypes.forEach(type => {
          const key: string = `${studentCourse.firstKey}_${typeOrder}${type}`;
          if (course[key]) {
            const studentCourseObj: any = {};
            studentCourseObj['type'] = studentCourse.key;
            studentCourseObj['masterKey'] = studentCourse.masterKey;
            studentCourseObj['key'] = key;
            studentCourseObj[key] = course[key];
            if (!studentCourseObj['courseGraduated']) {
              studentCourseObj['courseGraduated'] = {};
            }
            studentCourseObj['courseGraduated']['useElearning'] = this.itemInput['studentClassification']['useElearning'];
            studentCourseObj['courseGraduated']['statusElearning'] = this.itemInput['studentClassification']['statusElearning'];
            courseTypeAttributes.forEach(attribute => {
              const keyAttribute: string = key + attribute;
              studentCourseObj[keyAttribute] = course[keyAttribute];
              studentCourseObj['courseGraduated'][attribute] = course[keyAttribute];
              studentCourseObj['id'] = this.itemInput.id;
            });
            this.studentCourseProcessed.push(studentCourseObj);
          }
        });
      });
    })
    this.studentCourseProcessed.forEach(item => {
      const matchingAttendances = dataAttendancesExamPass.filter((attendances: any) => item.key === attendances.course);
      if (matchingAttendances.length > 0) {
        item['attendances'] = matchingAttendances;
      }

      const matchingCompletionRecords = dataCompletionExamPass.filter((completionRecord: any) => item.key === completionRecord.course);
      if (matchingCompletionRecords.length > 0) {
        item['completionRecord'] = matchingCompletionRecords;
      }
    });

    const dataStudentCourse = this.studentCourseProcessed.sort((a: any, b: any) => b.courseGraduated.Date - a.courseGraduated.Date)[0];
    if (dataStudentCourse?.courseGraduated.SendingDate == '') {
      this.handleDeleteFromChild(dataStudentCourse.id);
    } else {
      this.dataStudentCourse = dataStudentCourse;
    }
  }

  handleDeleteFromChild(id: string) {
    this.deleteItem.emit({id: id});
  }
}
