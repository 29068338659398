import { Injectable } from '@angular/core';
import {
  getAuth,
  onAuthStateChanged,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from '@firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { initializeApp } from '@angular/fire/app';
import { environment } from '@environments/environment';
import { sendPasswordResetEmail } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { serverTimestamp } from 'firebase/firestore';
import { UserService } from './user.service';
import { AccountType, AccountTypeName, UserRole } from '@app/enums';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userId: any;

  constructor(
    public angularFireAuth: AngularFireAuth,
    private router: Router,
    private db: AngularFirestore,
    private userService: UserService
  ) {}

  async userValue() {
    const app = await initializeApp(environment.firebaseConfig);
    const auth = await getAuth(app);
    return new Promise((resolve, reject) => {
      try {
        onAuthStateChanged(auth, (user) => resolve(user));
      } catch {
        reject('failed');
      }
    });
  }

  async register(email: string, password: string) {
    await this.angularFireAuth.createUserWithEmailAndPassword(email, password);
    await this.storeUser();
    await this.sendMailRegisterAccount(email);
  }

  async logOut() {
    await this.angularFireAuth.signOut();
  }

  async storeUser() {
    const currentUser = await this.angularFireAuth.currentUser;
    if (currentUser) {
      const user = await this.userService.getUserByMail(currentUser.email);
      if (user && !user[0]) {
        await this.db.collection('users').doc(currentUser.uid).set({
          uid: currentUser.uid,
          email: currentUser.email,
          displayName: currentUser.displayName,
          name: currentUser.displayName,
          photoURL: currentUser.photoURL,
          active: true,
          currentLessonID: '',
          dips: '',
          userRole: UserRole.BEGINNER,
          accountType: AccountType.FREE,
          accountTypeName: AccountTypeName.FREE_ACCOUNT,
          purchaseDate: serverTimestamp(),
          createAt: serverTimestamp(),
          updateAt: serverTimestamp(),
        });
      } else await this.db.collection('users').doc(currentUser.uid).update({ lastLoginAt: serverTimestamp() });
    }
  }

  async forgotPassword(email: string) {
    await sendPasswordResetEmail(getAuth(), email);
  }

  async updatePassword(oldPassword: string, newPassword: string) {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const credential = await EmailAuthProvider.credential(
        user.email ? user.email : '',
        oldPassword
      );
      await reauthenticateWithCredential(auth.currentUser, credential);
      await updatePassword(user, newPassword);
    }
  }

  async sendMailRegisterAccount(email: string) {
    await this.db.collection('mail').add({
      'to': email,
      'message': {
        'subject': 'UNLC 無人航空機講習 管理システム: サインアップありがとうございました',
        'html': `
          <p>UNLC 無人航空機講習 管理サービスにサインアップをありがとうございます。</p>
          <p>ログインは <a href="https://tact-studentrecord.web.app/" target="_blank"> こちら </a> のアドレスからご利用ください。</p>
          <p>UNLC 無人航空機講習 管理 サポートチーム</p>
        `,
      },
    });
  }

  getUserInDBByEmail(email: string) {
    return this.db.collection('users', (ref) => ref.where('email', '==', email)).snapshotChanges();
  }
}
