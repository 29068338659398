import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder, FormGroup } from '@angular/forms';
import { formatDatePicker } from '@app/constants';
import { getLocaleDatePicker } from '@app/helpers';
import { AlertService, QuotationsService, StudentService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.scss']
})
export class QuotationComponent implements OnInit {
  protected visibleCollapse: boolean = false;
  protected formSearch!: FormGroup;
  protected currentDatepickerLocale: string = '';
  protected currentLanguage = this.translate.currentLang;
  protected formatDatePicker: string = formatDatePicker;
  protected dataListQuotationItem: any = [];
  protected currentUser: any;
  protected listQuotationStudent: any;
  protected listQuotationStudentLength: number = 0;
  protected arrIdQuotationItem: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private quotationService: QuotationsService,
    private studentService: StudentService,
    private auth: AngularFireAuth,
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.visibleCollapse = false;
    this.formSearch = this.formBuilder.group({
      querySearch: [''],
      startDate: [new Date(new Date().getFullYear(), 0, 1)],
      endDate: [new Date(new Date().getFullYear(), 11, 31)],
      quotationItem: [''],
    });

    this.currentDatepickerLocale = getLocaleDatePicker(this.currentLanguage);
    this.getAllQuotationItem().then();
    this.getUser().then(() => {
      this.search();
    })
  }

  async getAllQuotationItem() {
   const data = await this.quotationService.getAllQuotationItem();
   this.dataListQuotationItem = data;
  }

  protected dayFormat = (date: Date) => date.getDate();

  protected toggleCollapse() {
    this.visibleCollapse = !this.visibleCollapse;
  }

  private async getUser(): Promise<void> {
    const currentUser = await this.auth.currentUser;
    if (currentUser) this.currentUser = currentUser;
  }

  protected async search() {
    await this.quotationService.getListQuotationStudent(this.formSearch.value).subscribe((quotationStudent) => {
      const quotationsByUserArr = quotationStudent.filter((item: any) => item.user_id == this.currentUser.uid);

      const quotationItem = this.f['quotationItem'].value;
      const querySearch = this.f['querySearch'].value;

      let quotations: any[] = [];

      if (querySearch && !quotationItem) {
          quotations = quotationsByUserArr.filter((data: any) => {
            if (querySearch != '') {
              return data.recipient_name.indexOf(querySearch) > -1;
            }
          return true;
        })
        this.listQuotationStudent = quotations
        this.listQuotationStudentLength = quotations.length
      }else if (quotationItem && !querySearch) {
        this.quotationService.getListQuotationItemLink(this.formSearch.value).subscribe((data) => {
          const quotationsWithStudent = quotationsByUserArr.filter((item: any) => {
             return data.some((ref) => {
               return item.id == ref.payload.doc.ref.parent.parent?.id;
             });
           });
           this.listQuotationStudent = quotationsWithStudent
           this.listQuotationStudentLength = quotationsWithStudent.length
         });
      } else if (querySearch && quotationItem) {
        quotations = quotationsByUserArr.filter((data: any) => {
            if (querySearch != '') {
              return data.recipient_name.indexOf(querySearch) > -1;
            }
          return true;
        })

        this.quotationService.getListQuotationItemLink(this.formSearch.value).subscribe((data) => {
          const quotationsWithStudent = quotations.filter((item: any) => {
             return data.some((ref) => {
               return item.id == ref.payload.doc.ref.parent.parent?.id;
             });
           });
           this.listQuotationStudent = quotationsWithStudent
           this.listQuotationStudentLength = quotationsWithStudent.length
         });

      } else {
        this.listQuotationStudent = quotationsByUserArr
        this.listQuotationStudentLength = quotationsByUserArr.length
      }

    })
  }

  protected get f() {
    return this.formSearch.controls;
  }

  protected onUncheckOptions(option: string): void {
    this.f[option].setValue('');
  }

  protected checkOptionAll(option: string): boolean {
    return !this.formSearch.get(option)?.value?.length;
  }

  async handleDeleteQuotationByStudent(event: any) {
    this.alertService.clear();
    await this.quotationService.deleteQuotationStudent(event.id).then((res) => {
      this.alertService.success('項目を削除しました。');
    }).then(() => {
      this.search();
    });
  }
}
