import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { AlertService, AttendanceService } from '@app/services';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { TranslateService } from '@ngx-translate/core';
import { IAttendances } from '@app/models';
import { numberOnly, getTime } from '@app/helpers';
import { IBreadcrumbItem } from '@coreui/angular';
import { IDataOption, STATUS_EXAM } from '@app/constants';

@Component({
  selector: 'app-create-attendances',
  templateUrl: './create-attendances.component.html',
  styleUrls: ['./create-attendances.component.scss'],
})
export class CreateAttendancesComponent implements OnInit {
  protected formCreateAttendances!: FormGroup;
  protected submitted = false;
  protected id: string | null = '';
  protected course: string | null = '';
  protected currentUser: any;
  protected numberOnly = numberOnly;
  protected elementRef: ElementRef;
  protected courseContentItems: any;
  protected leaderNameItems: any;
  protected isRequest: boolean = false;
  protected breadcrumbItems: IBreadcrumbItem[] = [];
  protected readonly statusExam: IDataOption[] = STATUS_EXAM;

  constructor(
    private attendanceService: AttendanceService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private auth: AngularFireAuth,
    private alertService: AlertService,
    private translateService: TranslateService,
    private router: Router,
    @Inject(ElementRef) elementRef: ElementRef
  ) {
    this.elementRef = elementRef;
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.course = this.activatedRoute.snapshot.paramMap.get('course');
    this.initForm();
    this.getCourseContentItems();
    this.getUser().then(() => {
      this.checkStudent(this.currentUser.uid, this.id).then((data) => {
        if (!data) {
          this.router.navigate(['student']);
          this.alertService.error(this.translateService.instant('student.not_found'));
        }
      });
      this.getLeaderNameItems();
    });
    this.translateService.get([
      'student.listStudent.title',
      'student.attendances.list.title',
      'student.attendances.create.title'
    ]).subscribe(translations => {
      this.breadcrumbItems = [
        {
          label: translations['student.listStudent.title'],
          url: '/student'
        },
        {
          label: translations['student.attendances.list.title'],
          url: `/student/${this.id}/attendances/${this.course}`
        },
        {
          label: translations['student.attendances.create.title'],
          url: ''
        },
      ];
    });
  }

  private async getCourseContentItems(): Promise<void> {
    const data = await this.attendanceService.getItems('course_content_items');
    if (data.length) this.courseContentItems = data;
  }

  private async getLeaderNameItems(): Promise<void> {
    const data = await this.attendanceService.getItemsWithUserId('instructors', this.currentUser.uid);
    if (data.length) this.leaderNameItems = data;
  }

  private changeTime(stringTime: string): number {
    const timeArr: string[] = stringTime.split(':');
    const time = parseInt(timeArr[0]);
    const minute = parseInt(timeArr[1]);
    return time * 60 + minute;
  }

  protected checkValidCourseTime() {
    const courseStartTime = this.changeTime(getTime(this.f['courseStartTime'].value));
    const courseEndTime = this.changeTime(getTime(this.f['courseEndTime'].value));
    if (courseStartTime > courseEndTime) {
      this.alertService.error('開始時間は終了時間より前である必要があります。')
      return false;
    }
    return true;
  }

  protected submit(): void {
    this.focusInput(this.formCreateAttendances.controls);
    this.submitted = true;

    if (!this.isRequest && this.submitted && this.formCreateAttendances.valid && this.checkValidCourseTime()) {
      this.isRequest = true;
      let dataForm = this.formCreateAttendances.getRawValue();
      const dataInsert: IAttendances = {
        courseContentCurriculum: dataForm.courseContentCurriculum,
        courseDate: dataForm.courseDate,
        courseStartTime: getTime(dataForm.courseStartTime),
        courseEndTime: getTime(dataForm.courseEndTime),
        actualFlightTime: dataForm.actualFlightTime,
        comment: dataForm.comment,
        leaderName: dataForm.leaderName,
        trainingLocation: dataForm.trainingLocation,
        remark: dataForm.remark,
        exam: dataForm.exam,
        examPass: dataForm.examPass,
        score: dataForm.score,
        course: this.course,
        examComment: dataForm.examComment,
        createAt: new Date()
      };
      const id = this.id != null ? this.id : '';
      this.attendanceService
        .createSyncAttendances(dataInsert, this.currentUser.uid, id)
        .then(() => this.router.navigateByUrl(`student/${id}/attendances/${this.course}`))
        .then(() => this.alertService.success(this.translateService.instant('student.attendances.create.success')))
        .finally(() => this.isRequest = false);
    }
  }

  protected onPaste(e: any): void {
    e.preventDefault();
  }

  private initForm(): void {
    this.formCreateAttendances = this.fb.group({
      courseContentCurriculum: new FormControl('', { validators: [Validators.required, Validators.minLength(1), Validators.maxLength(127)] }),
      courseDate: new FormControl('', { validators: [Validators.required] }),
      courseStartTime: new FormControl('', { validators: [Validators.required] }),
      courseEndTime: new FormControl('', { validators: [Validators.required] }),
      actualFlightTime: new FormControl('', { validators: [Validators.min(0), Validators.max(9999)] }),
      comment: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(1023)] }),
      leaderName: new FormControl('', { validators: [Validators.required, Validators.minLength(1), Validators.maxLength(127)] }),
      trainingLocation: new FormControl('', {validators: [Validators.required, Validators.minLength(1), Validators.maxLength(127)]}),
      remark: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(1023)] }),
      exam: new FormControl(false, { validators: [Validators.minLength(0), Validators.maxLength(127)] }),
      examPass: new FormControl("合格", { validators: [] }),
      score: new FormControl('', { validators: [Validators.min(0), Validators.max(100)] }),
      examComment: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(1023)] }),
    });
  }

  private async getUser(): Promise<void> {
    const currentUser = await this.auth.currentUser;
    if (currentUser) this.currentUser = currentUser;
  }

  protected get f() {
    return this.formCreateAttendances.controls;
  }

  protected async checkStudent(userId: string, studentId: string | null): Promise<any> {
    studentId = studentId == null ? '' : studentId;
    return await this.attendanceService.checkStudent(userId, studentId);
  }

  private focusInput(form: any): void {
    for (const key of Object.keys(form)) {
      if (form[key].invalid) {
        const invalidControl = this.elementRef.nativeElement.querySelector('#' + key);
        invalidControl.focus();
        break;
      }
    }
  }

  protected back(): void {
    this.router.navigateByUrl(`student/${this.id}/attendances/${this.course}`);
  }

  protected examChange(event: Event): void {
    if (this.f['exam'].value) {
      this.f['score'].setValidators([Validators.required, Validators.min(0), Validators.max(100)]);
      this.f['score'].updateValueAndValidity();
    } else {
      this.f['score'].setValidators([Validators.min(0), Validators.max(100)]);
      this.f['score'].updateValueAndValidity();
    }
  }

  handleAddItems(event: any, fieldName: string) {
    if (event.items.length > 0) {
      let value = '';
      event.items.forEach((item: any) => {
        if (value == '') {
          value = item.itemName;
        } else {
          value += '、' + item.itemName;
        }
      });
      this.f[fieldName].setValue(this.f[fieldName].value == ''
        ? value
        : this.f[fieldName].value + '、' + value);
    }
  }
}
