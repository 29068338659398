import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HONORIFIC_TITLE, HonorificTitleOption, MAX_QUOTATION, formatDatePicker } from '@app/constants';
import { formatDate } from '@app/helpers';
import { AlertService, QuotationsService, StudentService } from '@app/services';

@Component({
  selector: 'app-quotation',
  templateUrl: './create-quotation.component.html',
  styleUrls: ['./create-quotation.component.scss']
})
export class CreateQuotationComponent implements OnInit {
  protected formQuotation!: FormGroup;
  protected currentUser: any;
  protected nameStudent: any;
  protected idStudent: any;
  protected listQuotationItem: any;
  protected listHonorificTitle: HonorificTitleOption[] = HONORIFIC_TITLE;
  protected unitPriceQuotationSelect: any;
  protected inputValueQuantity: any;
  protected sumQuotation: any;
  protected submitted: boolean = false;
  protected dataQuotationArr: any = [];
  protected objQuotation: any = {};
  protected lengthDataQuotationArr: any;
  protected totalMoney: any;
  protected totalTax: any;
  protected valueTax: any;
  protected idQuotation: any;
  protected isLoading: boolean = false;
  protected dataInfoStudent: any;
  protected numberSort:number = 0;
  protected dataHonorificTitle: any;

  constructor(
    private formBuilder: FormBuilder,
    private auth: AngularFireAuth,
    private quotationsService: QuotationsService,
    private alertService: AlertService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.formQuotation = this.formBuilder.group({
      recipientName: ['', [
        Validators.required,
      ]],
      selectHonorificTitle: new FormControl(null, [
        Validators.required,
      ]),
      valueQuantity: ['', [
        Validators.required,
        Validators.pattern(/^\d+$/),
      ]],
      remark: ['', [
        Validators.maxLength(1024),
      ]],
      selectQuotationItem: new FormControl(null, [
        Validators.required,
      ])
    });

    this.getUser();
    this.getListQuotationItem();
    this.getValueTaxAmount();
  }

  get attribute() {
    return this.formQuotation.controls;
  }

  onValidate() {
    this.submitted = true;
    return this.formQuotation.status === 'VALID';
  }

  async fetchDataQuantity() {
    this.sumQuotation = this.inputValueQuantity * this.unitPriceQuotationSelect;
  }

  async changeSelectQuotation(event: any) {
    const selectedQuotationItem = this.listQuotationItem.find((item: any) => item.id == event.target.value);
    this.objQuotation = selectedQuotationItem;
    if (selectedQuotationItem) {
      this.unitPriceQuotationSelect = selectedQuotationItem.unitPrice;
      this.sumQuotation = this.inputValueQuantity ? selectedQuotationItem.unitPrice * this.inputValueQuantity : ''
    }
  }

  async changeSelectHonorificTitle(event: any) {
    this.dataHonorificTitle = this.listHonorificTitle.find((item: HonorificTitleOption) => item.id == event.target.value);
  }

  private async getUser(): Promise<void> {
    const currentUser = await this.auth.currentUser;
    if (currentUser) this.currentUser = currentUser;
  }

  private async getListQuotationItem(): Promise<void> {
    const data = await this.quotationsService.getAllQuotationItem();
    if (data.length) this.listQuotationItem = data;
  }

  private async getValueTaxAmount(): Promise<void> {
    const [data, ...res] = await this.quotationsService.getValueTax();
    if (data) this.valueTax = data.tax;
  }

  async createQuotation() {
    this.alertService.clear();
    if (this.onValidate()) {
      const dataCreate = {
        user_id: this.currentUser.uid,
        recipient_name: this.formQuotation.value.recipientName,
        honorific_title: this.dataHonorificTitle.value,
        total_amount: this.totalMoney,
        tax: this.valueTax,
        description: this.formQuotation.value.remark,
        dataQuotationArr: this.dataQuotationArr,
        createdAt: new Date(),
      }
      await this.quotationsService.createQuotation(dataCreate).then(() => this.router.navigateByUrl('quotation'))
      .then(() => this.alertService.success('項目を追加しました。'))
    }
  }

  async createQuotationAndExport() {
    const dataCreate = {
      user_id: this.currentUser.uid,
      recipient_name: this.formQuotation.value.recipientName,
      honorific_title: this.dataHonorificTitle.value,
      total_amount: this.totalMoney,
      tax: this.valueTax,
      description: this.formQuotation.value.remark,
      dataQuotationArr: this.dataQuotationArr,
      createdAt: new Date(),
    }

    const id = await this.quotationsService.createQuotation(dataCreate);
    if (id){
      this.idQuotation = id;
      this.alertService.success('項目を追加しました。');
    }
  }

  async onSubmit() {
    if (this.onValidate() && this.dataQuotationArr.length < MAX_QUOTATION) {
      const existingIndex = this.dataQuotationArr.findIndex((item: any) => item.quotationItemId === this.objQuotation.id);

      const newQuotation = {
        quotationItemId: this.objQuotation.id,
        itemName: this.objQuotation.itemName,
        unitPrice: this.objQuotation.unitPrice,
        quantity: this.inputValueQuantity,
        total: this.sumQuotation,
        sort: ++this.numberSort,
      };

      if (existingIndex !== -1) {
        this.dataQuotationArr[existingIndex] = newQuotation;
      } else {
        this.dataQuotationArr.unshift(newQuotation);
      }

      this.lengthDataQuotationArr = this.dataQuotationArr.length;
      this.totalMoney = this.calculateTotal();
      this.totalTax = Math.floor(this.totalMoney * (this.valueTax / 100));
    }
  }

  public handleDeleteQuotationItem(event: any) {
    if (event.index >= 0 && event.index < this.dataQuotationArr.length) {
      this.dataQuotationArr.splice(event.index, 1);
      this.lengthDataQuotationArr = this.dataQuotationArr.length;
      this.totalMoney = this.calculateTotal();
      this.totalTax = Math.floor(this.totalMoney * (this.valueTax / 100));
    }
  }

  public handleMoveItemUp(event: any) {
    if (event.index > 0 && event.index < this.dataQuotationArr.length) {
      const currentSort = this.dataQuotationArr[event.index].sort;
      const previousSort = this.dataQuotationArr[event.index - 1].sort;

      const temp = this.dataQuotationArr[event.index];
      this.dataQuotationArr[event.index] = this.dataQuotationArr[event.index - 1];
      this.dataQuotationArr[event.index - 1] = temp;

      this.dataQuotationArr[event.index].sort = currentSort;
      this.dataQuotationArr[event.index - 1].sort = previousSort;
    }
  }

  public handleMoveItemDown(event: any) {
    if (event.index >= 0 && event.index < this.dataQuotationArr.length - 1) {

      const currentSort = this.dataQuotationArr[event.index].sort;
      const previousSort = this.dataQuotationArr[event.index + 1].sort;

      const temp = this.dataQuotationArr[event.index];
      this.dataQuotationArr[event.index] = this.dataQuotationArr[event.index + 1];
      this.dataQuotationArr[event.index + 1] = temp;

      this.dataQuotationArr[event.index].sort = currentSort;
      this.dataQuotationArr[event.index + 1].sort = previousSort;
    }
  }

  calculateTotal(): number {
    return this.dataQuotationArr.reduce((acc:any, item: any) => acc + item.total, 0);
  }

  protected exportPDFQuotations(): void {
    this.alertService.clear();
    if (this.onValidate()) {
      this.createQuotationAndExport().then(() => {
        const timeCreatedAt = formatDate(new Date(), formatDatePicker)
        this.isLoading = true;
        this.quotationsService.exportPDF(this.currentUser.uid as any, this.idQuotation as any, this.formQuotation as any, timeCreatedAt as any)
          .finally(() => {
            this.isLoading = false;
            this.router.navigateByUrl('quotation');
          });
      })
    }
  }
}
