import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import {
  getAuth,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from '@firebase/auth';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private baseUrl = environment.baseUrl;

  constructor(
    private router: Router,
    private db: AngularFirestore,
  ) {}

  async getUserByMail(email: any) {
    return new Promise<any>((resolve, error) => {
      this.db.collection('users', (ref) => ref.where('email', '==', email))
        .valueChanges({ idField: 'id' })
        .subscribe((user) => resolve(user));
    });
  }

  async updateAccount(param: {
    name?: string,
    dips?: string,
    email?: string,
    memberCode?: string,
    branch?: string,
    address?: string,
    phoneNumber?: string,
    personName?: string,
  }) {
    const userId = await this.getDocId(param.email);
    await this.db.collection('users').doc(userId).update({
      displayName: param?.personName,
      name: param?.name,
      dips: param?.dips,
      memberCode: param?.memberCode,
      brandName: param?.branch,
      address: param?.address,
      phoneNumber: param?.phoneNumber,
    });
  }

  async updatePassword(oldPassword: string, newPassword: string) {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const credential = await EmailAuthProvider.credential(
        user.email ? user.email : '',
        oldPassword
      );
      await reauthenticateWithCredential(auth.currentUser, credential);
      await updatePassword(user, newPassword);
    }
  }

  async deleteAccount(id: string) {
    await this.db.collection('users').doc(id).update({
      active: false,
      tokens: [],
    });
  }

  async update(customer: string, email: string) {
    const userId = await this.getDocId(email);
    await this.db.collection('users').doc(userId).update({
      payment_method: 'stripe',
      stripe_cus: customer,
    });
  }

  async updateUserLearning(userId: string, dataLearning: any) {
    await this.db.collection('users').doc(userId).set({
      statusElearning: dataLearning.statusElearning,
      useElearning: dataLearning.useElearning,
    }, { merge: true });
  }

  async getDocId(email: any) {
    return new Promise<any>((resolve, reject) => {
      try {
        const auth = getAuth();
        this.db.collection('users', (ref: any) => ref.where('email', '==', email))
          .snapshotChanges()
          .subscribe((res: any) => {
            if (!!res.length) resolve(res[0].payload.doc.id);
            resolve(auth?.currentUser?.uid);
          });
      } catch (e) {
        reject('failed');
      }
    });
  }
}
