<div class="mb-3">
  <label for="{{ formControlNameInput }}" class="form-label mb-1">
    {{ label }}
    <c-badge *ngIf="requiredText" color="danger">{{ "form.inputLabel.must" | translate}}</c-badge>
  </label>
  <c-input-group>
    <input
      cFormControl
      [ngClass]="ngClass"
      [ngClass]="{
        'is-invalid': (submitted || form[formControlNameInput]?.touched) && form[formControlNameInput]?.invalid,
        'is-valid': form[formControlNameInput]?.touched && form[formControlNameInput]?.valid
      }"
      [formControlName]="formControlNameInput"
      [placeholder]="placeHolderInput"
      [type]="inputType"
      autoComplete="true"
      [maxLength]="maxLength || 255" 
      [minlength]="minLength"
      [max]="max"
      [min]="min"
      id="{{ formControlNameInput }}"
      [attr.disabled]="disabled ? true : null"
    />
    <button *ngIf="inputGroupText" [cModalToggle]="listItem.id" cInputGroupText type="button">
      {{ inputGroupText }}
    </button>
  </c-input-group>

  <c-modal #listItem alignment="center" [id]="idModal" (visibleChange)="clearList()">
    <c-modal-header>
      <h5 cModalTitle>{{ titleModal }}</h5>
    </c-modal-header>
    <c-modal-body>
      <div>
        <div class="row item-name">
          <div [ngClass]="splitColumnModal ? 'col-6' : 'col-12'">
            項目名
          </div>
          <div *ngIf="splitColumnModal" class="col-6">
            機体番号
          </div>
        </div>
        <c-form-check *ngFor="let item of items">
          <input
            cFormCheckInput
            [checked]="listCheckbox.includes(item.id)"
            [id]="item.id"
            type="checkbox"
            (change)="changeInput($event)"
            [value]="item.id"
          />
          <label
            cFormCheckLabel
            [for]="item.id"
            (click)="changeLabel($event, item.id)"
            class="row"
          >
            <div [ngClass]="splitColumnModal ? 'col-6' : 'col-12'">
              {{ item.itemName }}
            </div>
            <div *ngIf="splitColumnModal" class="col-6 p-0">
              {{ item.registrationNumber ?? '' }}
            </div>
          </label>
        </c-form-check>
      </div>
    </c-modal-body>
    <c-modal-footer class="justify-content-between">
      <button cButton [cModalToggle]="listItem.id" class="btn_delete" (click)="handleAddItem()">選択</button>
      <button cButton [cModalToggle]="listItem.id" class="btn_cancel">閉じる</button>
    </c-modal-footer>
  </c-modal>
  <c-form-feedback *ngIf="(submitted || form[formControlNameInput]?.touched) && form[formControlNameInput].errors">
    <div *ngIf="form[formControlNameInput]?.errors[getFirstError(form[formControlNameInput]?.errors) || '']">
      <div *ngIf="getFirstError(form[formControlNameInput]?.errors) === 'required'">
        {{ "errorMessages.formValidate.input.required" | translate }}
      </div>
      <div *ngIf="getFirstError(form[formControlNameInput]?.errors) === 'maxlength'">
        {{ "errorMessages.formValidate.input.maxlength" | translate : {maxlength: maxLength || 255} }}
      </div>
      <div *ngIf="minLength && getFirstError(form[formControlNameInput]?.errors) === 'minlength'">
        {{ "errorMessages.formValidate.input.minlength" | translate : {minlength: minLength || 4} }}
      </div>
      <div *ngIf="max && getFirstError(form[formControlNameInput]?.errors) === 'max'">
        {{ "errorMessages.formValidate.input.max" | translate : { max: max } }}
      </div>
      <div *ngIf="min && getFirstError(form[formControlNameInput]?.errors) === 'min'">
        {{ "errorMessages.formValidate.input.min" | translate : { min: min } }}
      </div>
      <div *ngIf="getFirstError(form[formControlNameInput]?.errors) === 'minlength'">
        {{ "errorMessages.formValidate.input.maxlength" | translate }}
      </div>
      <div *ngIf="getFirstError(form[formControlNameInput]?.errors) === 'pattern'">
        {{ "errorMessages.formValidate." + formControlNameInput + '.' + getFirstError(form[formControlNameInput]?.errors) | translate }}
      </div>
    </div>
  </c-form-feedback>
</div>
