<c-card class="mb-3 pa">
  <c-card-body>
    <div class="student-sub-item">
      <div class="student_item--header">
        <div class="header-title">
          <div class="title">{{ titleCourseType | translate }}</div>
          <div>{{ titleCourseKey | translate }}</div>
        </div>
        <div class="row courseGraduated">
          <div class="col-lg">
            <div class="item">修了年月日 {{ studentCourse?.courseGraduated?.Date ? formatDate(studentCourse?.courseGraduated?.Date.toDate(), formatDatePicker) : '' }}</div>
            <div class="item">修了審査実施会場 {{studentCourse?.courseGraduated?.Place}}</div>
            <div class="item">交付日 {{ studentCourse?.courseGraduated?.DeliveryDate ? formatDate(studentCourse?.courseGraduated?.DeliveryDate.toDate(), formatDatePicker) : '' }}</div>
            <div>有効期限 {{ studentCourse?.courseGraduated?.ExpirationDate ? formatDate(studentCourse?.courseGraduated?.ExpirationDate.toDate(), formatDatePicker) : '' }}</div>
          </div>
          <div class="col-lg">
            <div class="item">講習修了書番号 {{studentCourse?.courseGraduated?.No}}</div>
            <div class="item">担当修了審査員 {{studentCourse?.courseGraduated?.Responsible}}</div>
          </div>
          <div class="col-lg">
            <div>送付日 {{ studentCourse?.courseGraduated?.SendingDate ? formatDate(studentCourse?.courseGraduated?.SendingDate.toDate(), formatDatePicker) : '' }}</div>
            <div class="item">
              {{ 'student.createStudent.confirmation.useElearning' | translate }} {{ (studentCourse.courseGraduated.useElearning) ? YES : NO }}
            </div>
            <div *ngIf="studentCourse.courseGraduated.useElearning" class="item">
              {{ 'student.createStudent.confirmation.eLearning' | translate }} {{ studentCourse.courseGraduated.statusElearning }}
            </div>
          </div>
        </div>
        <div class="status-item text-center" [class]="{'align-self-end': studentCourse[getStatusKey()] == Processing}">
          <div *ngIf="!studentApproval && ELearning && studentCourse.attendances && studentCourse.completionRecord">
            <a *ngIf="!studentCourse[getStatusKey()]" class="form-create-data btn-common btn-title btn-access" (click)="handleUpdateStatus()">
              <span>発行申請</span>
            </a>
            <div class="d-flex flex-column" *ngIf="studentCourse[getStatusKey()] == Processing">
              <a class="text_status_processing">申請中</a>
              <a target="_blank" class="link_to_form" href="https://forms.gle/EtCu4nUdYRCkwf3V8">発行依頼申請</a>
            </div>
            <a class="text_status_released" *ngIf="studentCourse[getStatusKey()] === Released">発行済</a>
          </div>
        </div>
        <div class="student-sub-item--action">
          <span class="btn-attendance-student" (click)="redirectAttendances()" title="{{ 'student.attendances.list.title' | translate }}"></span>
          <span class="btn-completion-student cursor-pointer" (click)="redirectCompletionRecord()"
                title="{{ 'student.completionRecord.list.title' | translate }}"></span>
        </div>
      </div>
    </div>
  </c-card-body>
</c-card>
