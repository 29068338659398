<div class="mb-3 form-select-date" [formGroup]="formGroup">
  <label class="form-label mb-1" for="{{ formControlNameInput }}">
    {{ label }}
    <c-badge *ngIf="requiredText" color="danger">{{ "form.inputLabel.must" | translate}}</c-badge>
  </label>
  <c-col lg="12">
    <c-time-picker
      class="c-picker"
      (paste)="onPaste($event)"
      [ngClass]="ngClass"
      [ngClass]="{
      'is-invalid': (submitted || form[formControlNameInput]?.touched) && form[formControlNameInput]?.invalid,
      'is-valid': form[formControlNameInput]?.touched && form[formControlNameInput]?.valid
    }"
      id="{{ formControlNameInput }}"
      [locale]="currentDatepickerLocale"
      (keypress)="checkInputEnter($event)"
      [formControlName]="formControlNameInput"
      [placeholder]="placeHolderInput"
      [disabled] ="disabled"
      [locale]="'zh-CN'"

    ></c-time-picker>
  </c-col>
  <c-form-feedback *ngIf="(submitted || form[formControlNameInput]?.touched) && form[formControlNameInput].errors">
    <div *ngIf="form[formControlNameInput]?.errors[getFirstError(form[formControlNameInput]?.errors) || '']">
      <div *ngIf="getFirstError(form[formControlNameInput]?.errors) === 'required'">
        {{ "errorMessages.formValidate.select.required" | translate }}
      </div>
      <div *ngIf="getFirstError(form[formControlNameInput]?.errors) === 'maxlength'">
        {{ "errorMessages.formValidate.input.maxlength" | translate }}
      </div>
      <div *ngIf="getFirstError(form[formControlNameInput]?.errors) === 'pattern'">
        {{
        "errorMessages.formValidate." + formControlNameInput + '.' + getFirstError(form[formControlNameInput]?.errors) | translate
        }}
      </div>
    </div>
  </c-form-feedback>
</div>
