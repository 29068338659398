<c-input-group [ngClass]="ngClass" class="input-group__mail">
  <input
    cFormControl
    [ngClass]="ngClass"
    [formControlName]="formControlNameInput"
    [placeholder]="placeHolderInput"
    formControlName="email"
    id="email"
    type="email"
    autoComplete="email"
  />
</c-input-group>
<c-form-feedback *ngIf="(submitted || form['email'].touched) && form['email'].errors">
  <div *ngIf="form['email'].errors['required']">
    {{ 'errorMessages.email.required'|translate }}
  </div>
  <div *ngIf="form['email'].errors['pattern']">
    {{ 'errorMessages.email.email'|translate }}
  </div>
</c-form-feedback>
