export interface IAttendances {
  courseContentCurriculum: string,
  courseDate: Date,
  courseStartTime: string,
  courseEndTime: string,
  actualFlightTime: number,
  comment: string,
  leaderName: string,
  trainingLocation: string,
  remark: string,
  exam: string,
  examPass: string,
  score: number,
  examComment: string,
  course?: string | null,
  createAt?: Date,
}

export interface ISearch {
  querySearch: string,
  startDate: Date,
  endDate: Date,
  level: string,
  status: string
}
export interface ISyncAttendances {
  attendances: IAttendances,
  userId: string,
  studentDocId: string,
}
