import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { getFirstError } from '@app/helpers';
import { ValidationFormsService } from '@app/services';

@Component({
  selector: 'app-input-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: ['./input-radio.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class InputRadioComponent implements OnInit, OnChanges {
  getFirstError = getFirstError;
  @Input('form') form: any = [];
  @Input('dataOption') dataOption: any = [];
  @Input('label') label: string = '';
  @Input('ngClass') ngClass: NgClass['ngClass'];
  @Input('formControlNameInput') formControlNameInput: string = '';
  @Input('submitted') submitted: boolean = false;
  @Input('requiredText') requiredText: boolean = true;
  @Input('isDisable') isDisable: any;
  @Output('changeValue') changValue: EventEmitter<any> = new EventEmitter<any>();

  constructor(private validationFormService: ValidationFormsService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes['isDisable']) {
      if(changes['isDisable'].currentValue == true) {
        this.isDisable = true;
      } else if(changes['isDisable'].currentValue == false) {
        this.isDisable = false;
      }
    };
  }

  get errorMessages() {
    return this.validationFormService.errorMessages;
  }

  public changeRadio(value: any): void {
    this.changValue.emit(value);
  }
}
