import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { AlertService, AttendanceService, StudentService, UploadService, ValidationFormsService } from "@app/services";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import firebase from "firebase/compat/app";
import {
  IStudent, IStudentClassification, IStudentCourseAirplane,
  IStudentCourseHelicopter, IStudentCourseMulti,
  IStudentEnrollment, IStudentIdentityVerification,
  IStudentInfo,
  IStudentManeuverMode, IStudentPilotLicense,
} from "@app/models/student";
import { ActivatedRoute, Router } from "@angular/router";
import { Timestamp } from "firebase/firestore";
import { TranslateService } from '@ngx-translate/core';
import {
  ATTENDING_NAME,
  LEVEl_ONE,
  LEVEl_TWO,
  NO,
  NOT_ISSUED,
  TEXT_PASS,
  TEXT_TRUE,
  YES
} from "@app/constants";
import { IBreadcrumbItem } from '@coreui/angular';
import { checkboxSecondClassChecked } from '@app/helpers';

@Component({
  selector: 'app-edit-student',
  templateUrl: './edit-student.component.html',
  styleUrls: ['./edit-student.component.scss']
})
export class EditStudentComponent implements OnInit {
  protected submitted = false;
  protected formEditStudent!: FormGroup;
  protected fileUpload: any;
  protected currentUser: any;
  protected id: string | null = '';
  protected imageUrl: string = '';
  protected learning: boolean = true;
  protected learningStatus: string = '';
  protected student!: IStudent
  protected inValid: boolean = false;
  protected isRequest: boolean = false;
  protected breadcrumbItems: IBreadcrumbItem[] = [];
  protected level: string = '';
  protected isLoading: boolean = false;
  protected courseItems: any = [];

  constructor(
    private attendanceService: AttendanceService,
    private studentService: StudentService,
    private uploadService: UploadService,
    private fb: FormBuilder,
    private auth: AngularFireAuth,
    private alertService: AlertService,
    private validationFormService: ValidationFormsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
  ) {}

  public async ngOnInit() {
    this.isLoading = true;
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.initForm();
    this.getCourseItems();
    await this.getUser().then();
    this.translateService.get([
      'student.listStudent.title',
      'student.editStudent.title'
    ]).subscribe(translations => {
      this.breadcrumbItems = [
        { label: translations['student.listStudent.title'], url: '/student' },
        { label: translations['student.editStudent.title'], url: `` },
      ];
    });
    if (this.id) {
      this.student = await this.studentService.getStudent(this.currentUser.uid, this.id);
      if (!this.student) {
        this.router.navigateByUrl('student');
      }
      this.setStudentInfo(this.student.studentInfo);
      this.setStudentEnrollment(this.student.studentEnrollment);
      this.setStudentClassification(this.student.studentClassification);
      this.setStudentIdentityVerification(this.student.studentIdentityVerification);
      this.setStudentPilotLicense(this.student.studentPilotLicense);
      this.setStudentCourseMulti(this.student.studentCourseMulti);
      this.setStudentCourseAirplane(this.student.studentCourseAirplane);
      this.setStudentCourseHelicopter(this.student.studentCourseHelicopter);
      this.setStudentManeuverMode(this.student.studentManeuverMode);
      this.level = this?.student?.level || '';
    }
    this.isLoading = false;
  }

  private async getCourseItems(): Promise<void> {
    const data = await this.attendanceService.getItems('course_items', 'itemNumber', false);
    if (data.length) {
      this.courseItems = data;
      this.formEditStudent.controls['courseItems'].setValue(this.courseItems[0].itemNumber);
    }
    this
  }

  get f() {
    return this.formEditStudent.controls;
  }

  private initForm(): void {
    this.formEditStudent = this.fb.group({
      ledgerNumber: new FormControl('', { validators: [Validators.required] }),
      studentNumber: new FormControl('', { validators: [Validators.required] }),
      hurigana: new FormControl('', { validators: [Validators.required, Validators.maxLength(31), Validators.pattern(this.validationFormService.formRules.regexJa)] }),
      name: new FormControl('', { validators: [Validators.required, Validators.maxLength(31)] }),
      certificateNumber: new FormControl('', { validators: [
        Validators.maxLength(10),
        Validators.required,
        Validators.pattern(this.validationFormService.formRules.regexCertificateNumber),
      ] }),
      birthday: new FormControl(new Date(), { validators: [Validators.required] }),
      email: new FormControl('', { validators: [Validators.pattern(this.validationFormService.formRules.emailPattern), Validators.required] }),
      sex: new FormControl('男性', { validators: [Validators.required] }),
      address: new FormControl('', { validators: [Validators.required, Validators.maxLength(127)] }),
      tel: new FormControl('', { validators: [Validators.required, Validators.pattern(this.validationFormService.formRules.phoneNumber)] }),
      avatar: new FormControl(null, { validators: [] }),
      academicExamStatus: new FormControl('合格済', { validators: [] }),
      academicExamPassDate: new FormControl(new Date(), { validators: [Validators.required] }),
      academicExamPassCertificateNumber: new FormControl('', { validators: [Validators.required, Validators.minLength(1), Validators.maxLength(15)] }),
      courseItems: new FormControl('', { validators: [] }),
      organizationName: new FormControl('', { validators: [] }),

      practicalDate: new FormControl(new Date(), { validators: [] }),
      enrollmentDate: new FormControl(new Date(), { validators: [] }),

      experiencedType: new FormControl("経験者", { validators: [] }),
      privateSkillCertificate: new FormControl("有", { validators: [] }),
      totalFlightTime: new FormControl(0, { validators: [Validators.max(9998), Validators.min(0), Validators.pattern(/^\d+$/)]}),
      issuingOrganizationName: new FormControl("", { validators: [Validators.minLength(1), Validators.maxLength(127), Validators.required] }),
      no: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      no_expireDate: new FormControl('', { validators: [Validators.required] }),
      number: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      number_expireDate: new FormControl('', { validators: [] }),
      status: new FormControl(ATTENDING_NAME, { validators: [] }),
      statusElearning: new FormControl(NOT_ISSUED, { validators: [] }),
      useElearning: new FormControl(YES, { validators: [] }),

      driverLicense: new FormControl(false, { validators: [] }),
      myNumberCard: new FormControl(false, { validators: [] }),
      etc: new FormControl(false, { validators: [] }),
      etcText: new FormControl('', { validators: [] }),
      noEtc: new FormControl("", { validators: [Validators.minLength(1), Validators.maxLength(15)] }),

      issue: new FormControl(TEXT_TRUE, { validators: [] }),
      dateOfIssue: new FormControl('', { validators: [Validators.required] }),
      dateOfExpiry: new FormControl('', { validators: [Validators.required] }),
      skillNumber: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15), Validators.required] }),
      licenseConditions: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(127), Validators.required] }),

      x_firstClassBasic: new FormControl(false, { validators: [] }),
      x_firstClassDaytimeOnly: new FormControl(false, { validators: [] }),
      x_firstClassVisualLineOfSight: new FormControl(false, { validators: [] }),
      x_firstClassWeightLimited: new FormControl(false, { validators: [] }),
      x_secondClassBasic: new FormControl(false, { validators: [] }),
      x_secondClassDaytimeOnly: new FormControl(false, { validators: [] }),
      x_secondClassVisualLineOfSight: new FormControl(false, { validators: [] }),
      x_secondClassWeightLimited: new FormControl(false, { validators: [] }),

      y_firstClassBasic: new FormControl(false, { validators: [] }),
      y_firstClassDaytimeOnly: new FormControl(false, { validators: [] }),
      y_firstClassVisualLineOfSight: new FormControl(false, { validators: [] }),
      y_firstClassWeightLimited: new FormControl(false, { validators: [] }),
      y_secondClassBasic: new FormControl(false, { validators: [] }),
      y_secondClassDaytimeOnly: new FormControl(false, { validators: [] }),
      y_secondClassVisualLineOfSight: new FormControl(false, { validators: [] }),
      y_secondClassWeightLimited: new FormControl(false, { validators: [] }),

      z_firstClassBasic: new FormControl(false, { validators: [] }),
      z_firstClassDaytimeOnly: new FormControl(false, { validators: [] }),
      z_firstClassVisualLineOfSight: new FormControl(false, { validators: [] }),
      z_firstClassWeightLimited: new FormControl(false, { validators: [] }),
      z_secondClassBasic: new FormControl(false, { validators: [] }),
      z_secondClassDaytimeOnly: new FormControl(false, { validators: [] }),
      z_secondClassVisualLineOfSight: new FormControl(false, { validators: [] }),
      z_secondClassWeightLimited: new FormControl(false, { validators: [] }),

      a_rotorcraftMulti: new FormControl(false, { validators: [] }),

      a_firstClassBasic: new FormControl(false, { validators: [] }),
      a_firstClassBasicDate: new FormControl('', { validators: [] }),
      a_firstClassBasicNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_firstClassBasicPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_firstClassBasicResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_firstClassBasicSendingDate: new FormControl('', { validators: [] }),
      a_firstClassBasicDeliveryDate: new FormControl('', { validators: [] }),
      a_firstClassBasicExpirationDate: new FormControl('', { validators: [] }),
      a_firstClassBasicStatus: new FormControl('', { validators: [] }),
      a_firstClassBasicApplicationDate: new FormControl('', { validators: [] }),

      a_firstClassDaytimeOnly: new FormControl(false, { validators: [] }),
      a_firstClassDaytimeOnlyDate: new FormControl('', { validators: [] }),
      a_firstClassDaytimeOnlyNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_firstClassDaytimeOnlyPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_firstClassDaytimeOnlyResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_firstClassDaytimeOnlySendingDate: new FormControl('', { validators: [] }),
      a_firstClassDaytimeOnlyDeliveryDate: new FormControl('', { validators: [] }),
      a_firstClassDaytimeOnlyExpirationDate: new FormControl('', { validators: [] }),
      a_firstClassDaytimeOnlyStatus: new FormControl('', { validators: [] }),
      a_firstClassDaytimeOnlyApplicationDate: new FormControl('', { validators: [] }),

      a_firstClassVisualLineOfSight: new FormControl(false, { validators: [] }),
      a_firstClassVisualLineOfSightDate: new FormControl('', { validators: [] }),
      a_firstClassVisualLineOfSightNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_firstClassVisualLineOfSightPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_firstClassVisualLineOfSightResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_firstClassVisualLineOfSightSendingDate: new FormControl('', { validators: [] }),
      a_firstClassVisualLineOfSightDeliveryDate: new FormControl('', { validators: [] }),
      a_firstClassVisualLineOfSightExpirationDate: new FormControl('', { validators: [] }),
      a_firstClassVisualLineOfSightStatus: new FormControl('', { validators: [] }),
      a_firstClassVisualLineOfSightApplicationDate: new FormControl('', { validators: [] }),

      a_firstClassWeightLimited: new FormControl(false, { validators: [] }),
      a_firstClassWeightLimitedDate: new FormControl('', { validators: [] }),
      a_firstClassWeightLimitedNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_firstClassWeightLimitedPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_firstClassWeightLimitedResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_firstClassWeightLimitedSendingDate: new FormControl('', { validators: [] }),
      a_firstClassWeightLimitedDeliveryDate: new FormControl('', { validators: [] }),
      a_firstClassWeightLimitedExpirationDate: new FormControl('', { validators: [] }),
      a_firstClassWeightLimitedStatus: new FormControl('', { validators: [] }),
      a_firstClassWeightLimitedApplicationDate: new FormControl('', { validators: [] }),

      a_secondClassBasic: new FormControl(false, { validators: [] }),
      a_secondClassBasicDate: new FormControl('', { validators: [] }),
      a_secondClassBasicNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_secondClassBasicPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_secondClassBasicResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_secondClassBasicSendingDate: new FormControl('', { validators: [] }),
      a_secondClassBasicDeliveryDate: new FormControl('', { validators: [] }),
      a_secondClassBasicExpirationDate: new FormControl('', { validators: [] }),
      a_secondClassBasicStatus: new FormControl('', { validators: [] }),
      a_secondClassBasicApplicationDate: new FormControl('', { validators: [] }),

      a_secondClassDaytimeOnly: new FormControl(false, { validators: [] }),
      a_secondClassDaytimeOnlyDate: new FormControl('', { validators: [] }),
      a_secondClassDaytimeOnlyNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_secondClassDaytimeOnlyPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_secondClassDaytimeOnlyResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_secondClassDaytimeOnlySendingDate: new FormControl('', { validators: [] }),
      a_secondClassDaytimeOnlyDeliveryDate: new FormControl('', { validators: [] }),
      a_secondClassDaytimeOnlyExpirationDate: new FormControl('', { validators: [] }),
      a_secondClassDaytimeOnlyStatus: new FormControl('', { validators: [] }),
      a_secondClassDaytimeOnlyApplicationDate: new FormControl('', { validators: [] }),

      a_secondClassVisualLineOfSight: new FormControl(false, { validators: [] }),
      a_secondClassVisualLineOfSightDate: new FormControl('', { validators: [] }),
      a_secondClassVisualLineOfSightNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_secondClassVisualLineOfSightPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_secondClassVisualLineOfSightResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_secondClassVisualLineOfSightSendingDate: new FormControl('', { validators: [] }),
      a_secondClassVisualLineOfSightDeliveryDate: new FormControl('', { validators: [] }),
      a_secondClassVisualLineOfSightExpirationDate: new FormControl('', { validators: [] }),
      a_secondClassVisualLineOfSightStatus: new FormControl('', { validators: [] }),
      a_secondClassVisualLineOfSightApplicationDate: new FormControl('', { validators: [] }),

      a_secondClassWeightLimited: new FormControl(false, { validators: [] }),
      a_secondClassWeightLimitedDate: new FormControl('', { validators: [] }),
      a_secondClassWeightLimitedNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_secondClassWeightLimitedPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_secondClassWeightLimitedResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      a_secondClassWeightLimitedSendingDate: new FormControl('', { validators: [] }),
      a_secondClassWeightLimitedDeliveryDate: new FormControl('', { validators: [] }),
      a_secondClassWeightLimitedExpirationDate: new FormControl('', { validators: [] }),
      a_secondClassWeightLimitedStatus: new FormControl('', { validators: [] }),
      a_secondClassWeightLimitedApplicationDate: new FormControl('', { validators: [] }),

      b_plane: new FormControl(false, { validators: [] }),

      b_firstClassBasic: new FormControl(false, { validators: [] }),
      b_firstClassBasicDate: new FormControl('', { validators: [] }),
      b_firstClassBasicNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_firstClassBasicPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_firstClassBasicResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_firstClassBasicSendingDate: new FormControl('', { validators: [] }),
      b_firstClassBasicDeliveryDate: new FormControl('', { validators: [] }),
      b_firstClassBasicExpirationDate: new FormControl('', { validators: [] }),
      b_firstClassBasicStatus: new FormControl('', { validators: [] }),
      b_firstClassBasicApplicationDate: new FormControl('', { validators: [] }),

      b_firstClassDaytimeOnly: new FormControl(false, { validators: [] }),
      b_firstClassDaytimeOnlyDate: new FormControl('', { validators: [] }),
      b_firstClassDaytimeOnlyNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_firstClassDaytimeOnlyPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_firstClassDaytimeOnlyResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_firstClassDaytimeOnlySendingDate: new FormControl('', { validators: [] }),
      b_firstClassDaytimeOnlyDeliveryDate: new FormControl('', { validators: [] }),
      b_firstClassDaytimeOnlyExpirationDate: new FormControl('', { validators: [] }),
      b_firstClassDaytimeOnlyStatus: new FormControl('', { validators: [] }),
      b_firstClassDaytimeOnlyApplicationDate: new FormControl('', { validators: [] }),

      b_firstClassVisualLineOfSight: new FormControl(false, { validators: [] }),
      b_firstClassVisualLineOfSightDate: new FormControl('', { validators: [] }),
      b_firstClassVisualLineOfSightNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_firstClassVisualLineOfSightPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_firstClassVisualLineOfSightResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_firstClassVisualLineOfSightSendingDate: new FormControl('', { validators: [] }),
      b_firstClassVisualLineOfSightDeliveryDate: new FormControl('', { validators: [] }),
      b_firstClassVisualLineOfSightExpirationDate: new FormControl('', { validators: [] }),
      b_firstClassVisualLineOfSightStatus: new FormControl('', { validators: [] }),
      b_firstClassVisualLineOfSightApplicationDate: new FormControl('', { validators: [] }),

      b_firstClassWeightLimited: new FormControl(false, { validators: [] }),
      b_firstClassWeightLimitedDate: new FormControl('', { validators: [] }),
      b_firstClassWeightLimitedNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_firstClassWeightLimitedPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_firstClassWeightLimitedResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_firstClassWeightLimitedSendingDate: new FormControl('', { validators: [] }),
      b_firstClassWeightLimitedDeliveryDate: new FormControl('', { validators: [] }),
      b_firstClassWeightLimitedExpirationDate: new FormControl('', { validators: [] }),
      b_firstClassWeightLimitedStatus: new FormControl('', { validators: [] }),
      b_firstClassWeightLimitedApplicationDate: new FormControl('', { validators: [] }),

      b_secondClassBasic: new FormControl(false, { validators: [] }),
      b_secondClassBasicDate: new FormControl('', { validators: [] }),
      b_secondClassBasicNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_secondClassBasicPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_secondClassBasicResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_secondClassBasicSendingDate: new FormControl('', { validators: [] }),
      b_secondClassBasicDeliveryDate: new FormControl('', { validators: [] }),
      b_secondClassBasicExpirationDate: new FormControl('', { validators: [] }),
      b_secondClassBasicStatus: new FormControl('', { validators: [] }),
      b_secondClassBasicApplicationDate: new FormControl('', { validators: [] }),

      b_secondClassDaytimeOnly: new FormControl(false, { validators: [] }),
      b_secondClassDaytimeOnlyDate: new FormControl('', { validators: [] }),
      b_secondClassDaytimeOnlyNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_secondClassDaytimeOnlyPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_secondClassDaytimeOnlyResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_secondClassDaytimeOnlySendingDate: new FormControl('', { validators: [] }),
      b_secondClassDaytimeOnlyDeliveryDate: new FormControl('', { validators: [] }),
      b_secondClassDaytimeOnlyExpirationDate: new FormControl('', { validators: [] }),
      b_secondClassDaytimeOnlyStatus: new FormControl('', { validators: [] }),
      b_secondClassDaytimeOnlyApplicationDate: new FormControl('', { validators: [] }),

      b_secondClassVisualLineOfSight: new FormControl(false, { validators: [] }),
      b_secondClassVisualLineOfSightDate: new FormControl('', { validators: [] }),
      b_secondClassVisualLineOfSightNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_secondClassVisualLineOfSightPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_secondClassVisualLineOfSightResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_secondClassVisualLineOfSightSendingDate: new FormControl('', { validators: [] }),
      b_secondClassVisualLineOfSightDeliveryDate: new FormControl('', { validators: [] }),
      b_secondClassVisualLineOfSightExpirationDate: new FormControl('', { validators: [] }),
      b_secondClassVisualLineOfSightStatus: new FormControl('', { validators: [] }),
      b_secondClassVisualLineOfSightApplicationDate: new FormControl('', { validators: [] }),

      b_secondClassWeightLimited: new FormControl(false, { validators: [] }),
      b_secondClassWeightLimitedDate: new FormControl('', { validators: [] }),
      b_secondClassWeightLimitedNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_secondClassWeightLimitedPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_secondClassWeightLimitedResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      b_secondClassWeightLimitedSendingDate: new FormControl('', { validators: [] }),
      b_secondClassWeightLimitedDeliveryDate: new FormControl('', { validators: [] }),
      b_secondClassWeightLimitedExpirationDate: new FormControl('', { validators: [] }),
      b_secondClassWeightLimitedStatus: new FormControl('', { validators: [] }),
      b_secondClassWeightLimitedApplicationDate: new FormControl('', { validators: [] }),

      c_rotorcraft: new FormControl(false, { validators: [] }),

      c_firstClassBasic: new FormControl(false, { validators: [] }),
      c_firstClassBasicDate: new FormControl('', { validators: [] }),
      c_firstClassBasicNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_firstClassBasicPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_firstClassBasicResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_firstClassBasicSendingDate: new FormControl('', { validators: [] }),
      c_firstClassBasicDeliveryDate: new FormControl('', { validators: [] }),
      c_firstClassBasicExpirationDate: new FormControl('', { validators: [] }),
      c_firstClassBasicStatus: new FormControl('', { validators: [] }),
      c_firstClassBasicApplicationDate: new FormControl('', { validators: [] }),

      c_firstClassDaytimeOnly: new FormControl(false, { validators: [] }),
      c_firstClassDaytimeOnlyDate: new FormControl('', { validators: [] }),
      c_firstClassDaytimeOnlyNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_firstClassDaytimeOnlyPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_firstClassDaytimeOnlyResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_firstClassDaytimeOnlySendingDate: new FormControl('', { validators: [] }),
      c_firstClassDaytimeOnlyDeliveryDate: new FormControl('', { validators: [] }),
      c_firstClassDaytimeOnlyExpirationDate: new FormControl('', { validators: [] }),
      c_firstClassDaytimeOnlyStatus: new FormControl('', { validators: [] }),
      c_firstClassDaytimeOnlyApplicationDate: new FormControl('', { validators: [] }),

      c_firstClassVisualLineOfSight: new FormControl(false, { validators: [] }),
      c_firstClassVisualLineOfSightDate: new FormControl('', { validators: [] }),
      c_firstClassVisualLineOfSightNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_firstClassVisualLineOfSightPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_firstClassVisualLineOfSightResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_firstClassVisualLineOfSightSendingDate: new FormControl('', { validators: [] }),
      c_firstClassVisualLineOfSightDeliveryDate: new FormControl('', { validators: [] }),
      c_firstClassVisualLineOfSightExpirationDate: new FormControl('', { validators: [] }),
      c_firstClassVisualLineOfSightStatus: new FormControl('', { validators: [] }),
      c_firstClassVisualLineOfSightApplicationDate: new FormControl('', { validators: [] }),

      c_firstClassWeightLimited: new FormControl(false, { validators: [] }),
      c_firstClassWeightLimitedDate: new FormControl('', { validators: [] }),
      c_firstClassWeightLimitedNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_firstClassWeightLimitedPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_firstClassWeightLimitedResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_firstClassWeightLimitedSendingDate: new FormControl('', { validators: [] }),
      c_firstClassWeightLimitedDeliveryDate: new FormControl('', { validators: [] }),
      c_firstClassWeightLimitedExpirationDate: new FormControl('', { validators: [] }),
      c_firstClassWeightLimitedStatus: new FormControl('', { validators: [] }),
      c_firstClassWeightLimitedApplicationDate: new FormControl('', { validators: [] }),

      c_secondClassBasic: new FormControl(false, { validators: [] }),
      c_secondClassBasicDate: new FormControl('', { validators: [] }),
      c_secondClassBasicNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_secondClassBasicPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_secondClassBasicResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_secondClassBasicSendingDate: new FormControl('', { validators: [] }),
      c_secondClassBasicDeliveryDate: new FormControl('', { validators: [] }),
      c_secondClassBasicExpirationDate: new FormControl('', { validators: [] }),
      c_secondClassBasicStatus: new FormControl('', { validators: [] }),
      c_secondClassBasicApplicationDate: new FormControl('', { validators: [] }),

      c_secondClassDaytimeOnly: new FormControl(false, { validators: [] }),
      c_secondClassDaytimeOnlyDate: new FormControl('', { validators: [] }),
      c_secondClassDaytimeOnlyNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_secondClassDaytimeOnlyPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_secondClassDaytimeOnlyResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_secondClassDaytimeOnlySendingDate: new FormControl('', { validators: [] }),
      c_secondClassDaytimeOnlyDeliveryDate: new FormControl('', { validators: [] }),
      c_secondClassDaytimeOnlyExpirationDate: new FormControl('', { validators: [] }),
      c_secondClassDaytimeOnlyStatus: new FormControl('', { validators: [] }),
      c_secondClassDaytimeOnlyApplicationDate: new FormControl('', { validators: [] }),

      c_secondClassVisualLineOfSight: new FormControl(false, { validators: [] }),
      c_secondClassVisualLineOfSightDate: new FormControl('', { validators: [] }),
      c_secondClassVisualLineOfSightNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_secondClassVisualLineOfSightPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_secondClassVisualLineOfSightResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_secondClassVisualLineOfSightSendingDate: new FormControl('', { validators: [] }),
      c_secondClassVisualLineOfSightDeliveryDate: new FormControl('', { validators: [] }),
      c_secondClassVisualLineOfSightExpirationDate: new FormControl('', { validators: [] }),
      c_secondClassVisualLineOfSightStatus: new FormControl('', { validators: [] }),
      c_secondClassVisualLineOfSightApplicationDate: new FormControl('', { validators: [] }),

      c_secondClassWeightLimited: new FormControl(false, { validators: [] }),
      c_secondClassWeightLimitedDate: new FormControl(new Date(), { validators: [] }),
      c_secondClassWeightLimitedNo: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_secondClassWeightLimitedPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_secondClassWeightLimitedResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      c_secondClassWeightLimitedSendingDate: new FormControl('', { validators: [] }),
      c_secondClassWeightLimitedDeliveryDate: new FormControl('', { validators: [] }),
      c_secondClassWeightLimitedExpirationDate: new FormControl('', { validators: [] }),
      c_secondClassWeightLimitedStatus: new FormControl('', { validators: [] }),
      c_secondClassWeightLimitedApplicationDate: new FormControl('', { validators: [] }),

      mode1: new FormControl(false, { validators: [] }),
      mode2: new FormControl(false, { validators: [] }),
      mode3: new FormControl(false, { validators: [] }),

      completionDate: new FormControl('', { validators: [] }),
      completionPlace: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      completionResponsible: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),
      completionNumber: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(15)] }),

      remark: new FormControl('', { validators: [Validators.minLength(1), Validators.maxLength(1023)] })
    });
  }

  public onDataReceived(data: string): void {
    this.fileUpload = data;
  }

  public async getUser(): Promise<void> {
    const currentUser: firebase.User | null = await this.auth.currentUser;
    if (currentUser) this.currentUser = currentUser;
  }

  public async submit(): Promise<void> {
    this.submitted = true;

    if (!this.isRequest && this.submitted && this.formEditStudent.valid && !this.inValid) {
      this.isLoading = true;
      this.isRequest = true;
      let dataForm = this.formEditStudent.getRawValue();
      let studentInfo: IStudentInfo = this.getStudentInfo(dataForm);
      let studentEnrollment: IStudentEnrollment = this.getStudentEnrollment(dataForm);
      let studentClassification: IStudentClassification = this.getStudentClassification(dataForm);
      let studentIdentityVerification: IStudentIdentityVerification = this.getStudentIdentityVerification(dataForm);
      let studentPilotLicense: IStudentPilotLicense = this.getStudentPilotLicense(dataForm);
      let studentCourseMulti: IStudentCourseMulti = this.getStudentCourseMulti(dataForm);
      let studentCourseAirplane: IStudentCourseAirplane = this.getStudentCourseAirplane(dataForm);
      let studentCourseHelicopter: IStudentCourseHelicopter = this.getStudentCourseHelicopter(dataForm);
      let studentManeuverMode: IStudentManeuverMode = this.getStudentManeuverMode(dataForm)
      studentInfo.picture = this.student.studentInfo?.picture ?? '';

      if (!!this.fileUpload) {
        studentInfo.picture = await this.uploadService.uploadFile(this.id as string, this.fileUpload);
      }

      if (
        checkboxSecondClassChecked(this.f, 'a_second') ||
        checkboxSecondClassChecked(this.f, 'b_second') ||
        checkboxSecondClassChecked(this.f, 'c_second')
      ) this.level = LEVEl_TWO;
      else this.level = LEVEl_ONE;

      const student: IStudent = {
        studentInfo: studentInfo,
        studentEnrollment: studentEnrollment,
        studentClassification: studentClassification,
        studentIdentityVerification: studentIdentityVerification,
        studentPilotLicense: studentPilotLicense,
        studentCourseMulti: studentCourseMulti,
        studentCourseHelicopter: studentCourseHelicopter,
        studentCourseAirplane: studentCourseAirplane,
        studentManeuverMode: studentManeuverMode,
        level: this.level,
      };

      this.studentService.update(student, this.currentUser.uid, this.id as string)
        .then(() => this.router.navigateByUrl('student'))
        .then(() => this.alertService.success(this.translateService.instant('student.editStudent.success')))
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.isRequest = false;
          this.isLoading = false;
        });
    }
  }

  public backToListStudent(): void {
    this.router.navigateByUrl('/student');
  }

  private getStudentManeuverMode(dataForm: any): IStudentManeuverMode {
    return {
      mode1: dataForm.mode1,
      mode2: dataForm.mode2,
      mode3: dataForm.mode3,
    };
  }

  private getStudentCourseHelicopter(dataForm: any): IStudentCourseHelicopter {
    return {
      c_rotorcraft: dataForm.c_rotorcraft,

      c_firstClassBasic: dataForm.c_firstClassBasic,
      c_firstClassBasicDate: dataForm.c_firstClassBasicDate,
      c_firstClassBasicNo: dataForm.c_firstClassBasicNo,
      c_firstClassBasicPlace: dataForm.c_firstClassBasicPlace,
      c_firstClassBasicResponsible: dataForm.c_firstClassBasicResponsible,
      c_firstClassBasicSendingDate: dataForm.c_firstClassBasicSendingDate,
      c_firstClassBasicDeliveryDate: dataForm.c_firstClassBasicDeliveryDate,
      c_firstClassBasicExpirationDate: dataForm.c_firstClassBasicExpirationDate,
      c_firstClassBasicStatus: dataForm.c_firstClassBasicStatus,
      c_firstClassBasicApplicationDate: dataForm.c_firstClassBasicApplicationDate,

      c_firstClassDaytimeOnly: dataForm.c_firstClassDaytimeOnly,
      c_firstClassDaytimeOnlyDate: dataForm.c_firstClassDaytimeOnlyDate,
      c_firstClassDaytimeOnlyNo: dataForm.c_firstClassDaytimeOnlyNo,
      c_firstClassDaytimeOnlyPlace: dataForm.c_firstClassDaytimeOnlyPlace,
      c_firstClassDaytimeOnlyResponsible: dataForm.c_firstClassDaytimeOnlyResponsible,
      c_firstClassDaytimeOnlySendingDate: dataForm.c_firstClassDaytimeOnlySendingDate,
      c_firstClassDaytimeOnlyDeliveryDate: dataForm.c_firstClassDaytimeOnlyDeliveryDate,
      c_firstClassDaytimeOnlyExpirationDate: dataForm.c_firstClassDaytimeOnlyExpirationDate,
      c_firstClassDaytimeOnlyStatus: dataForm.c_firstClassDaytimeOnlyStatus,
      c_firstClassDaytimeOnlyApplicationDate: dataForm.c_firstClassDaytimeOnlyApplicationDate,

      c_firstClassVisualLineOfSight: dataForm.c_firstClassVisualLineOfSight,
      c_firstClassVisualLineOfSightDate: dataForm.c_firstClassVisualLineOfSightDate,
      c_firstClassVisualLineOfSightNo: dataForm.c_firstClassVisualLineOfSightNo,
      c_firstClassVisualLineOfSightPlace: dataForm.c_firstClassVisualLineOfSightPlace,
      c_firstClassVisualLineOfSightResponsible: dataForm.c_firstClassVisualLineOfSightResponsible,
      c_firstClassVisualLineOfSightSendingDate: dataForm.c_firstClassVisualLineOfSightSendingDate,
      c_firstClassVisualLineOfSightDeliveryDate: dataForm.c_firstClassVisualLineOfSightDeliveryDate,
      c_firstClassVisualLineOfSightExpirationDate: dataForm.c_firstClassVisualLineOfSightExpirationDate,
      c_firstClassVisualLineOfSightStatus: dataForm.c_firstClassVisualLineOfSightStatus,
      c_firstClassVisualLineOfSightApplicationDate: dataForm.c_firstClassVisualLineOfSightApplicationDate,

      c_firstClassWeightLimited: dataForm.c_firstClassWeightLimited,
      c_firstClassWeightLimitedDate: dataForm.c_firstClassWeightLimitedDate,
      c_firstClassWeightLimitedNo: dataForm.c_firstClassWeightLimitedNo,
      c_firstClassWeightLimitedPlace: dataForm.c_firstClassWeightLimitedPlace,
      c_firstClassWeightLimitedResponsible: dataForm.c_firstClassWeightLimitedResponsible,
      c_firstClassWeightLimitedSendingDate: dataForm.c_firstClassWeightLimitedSendingDate,
      c_firstClassWeightLimitedDeliveryDate: dataForm.c_firstClassWeightLimitedDeliveryDate,
      c_firstClassWeightLimitedExpirationDate: dataForm.c_firstClassWeightLimitedExpirationDate,
      c_firstClassWeightLimitedStatus: dataForm.c_firstClassWeightLimitedStatus,
      c_firstClassWeightLimitedApplicationDate: dataForm.c_firstClassWeightLimitedApplicationDate,

      c_secondClassBasic: dataForm.c_secondClassBasic,
      c_secondClassBasicDate: dataForm.c_secondClassBasicDate,
      c_secondClassBasicNo: dataForm.c_secondClassBasicNo,
      c_secondClassBasicPlace: dataForm.c_secondClassBasicPlace,
      c_secondClassBasicResponsible: dataForm.c_secondClassBasicResponsible,
      c_secondClassBasicSendingDate: dataForm.c_secondClassBasicSendingDate,
      c_secondClassBasicDeliveryDate: dataForm.c_secondClassBasicDeliveryDate,
      c_secondClassBasicExpirationDate: dataForm.c_secondClassBasicExpirationDate,
      c_secondClassBasicStatus: dataForm.c_secondClassBasicStatus,
      c_secondClassBasicApplicationDate: dataForm.c_secondClassBasicApplicationDate,

      c_secondClassDaytimeOnly: dataForm.c_secondClassDaytimeOnly,
      c_secondClassDaytimeOnlyDate: dataForm.c_secondClassDaytimeOnlyDate,
      c_secondClassDaytimeOnlyNo: dataForm.c_secondClassDaytimeOnlyNo,
      c_secondClassDaytimeOnlyPlace: dataForm.c_secondClassDaytimeOnlyPlace,
      c_secondClassDaytimeOnlyResponsible: dataForm.c_secondClassDaytimeOnlyResponsible,
      c_secondClassDaytimeOnlySendingDate: dataForm.c_secondClassDaytimeOnlySendingDate,
      c_secondClassDaytimeOnlyDeliveryDate: dataForm.c_secondClassDaytimeOnlyDeliveryDate,
      c_secondClassDaytimeOnlyExpirationDate: dataForm.c_secondClassDaytimeOnlyExpirationDate,
      c_secondClassDaytimeOnlyStatus: dataForm.c_secondClassDaytimeOnlyStatus,
      c_secondClassDaytimeOnlyApplicationDate: dataForm.c_secondClassDaytimeOnlyApplicationDate,

      c_secondClassVisualLineOfSight: dataForm.c_secondClassVisualLineOfSight,
      c_secondClassVisualLineOfSightDate: dataForm.c_secondClassVisualLineOfSightDate,
      c_secondClassVisualLineOfSightNo: dataForm.c_secondClassVisualLineOfSightNo,
      c_secondClassVisualLineOfSightPlace: dataForm.c_secondClassVisualLineOfSightPlace,
      c_secondClassVisualLineOfSightResponsible: dataForm.c_secondClassVisualLineOfSightResponsible,
      c_secondClassVisualLineOfSightSendingDate: dataForm.c_secondClassVisualLineOfSightSendingDate,
      c_secondClassVisualLineOfSightDeliveryDate: dataForm.c_secondClassVisualLineOfSightDeliveryDate,
      c_secondClassVisualLineOfSightExpirationDate: dataForm.c_secondClassVisualLineOfSightExpirationDate,
      c_secondClassVisualLineOfSightStatus: dataForm.c_secondClassVisualLineOfSightStatus,
      c_secondClassVisualLineOfSightApplicationDate: dataForm.c_secondClassVisualLineOfSightApplicationDate,

      c_secondClassWeightLimited: dataForm.c_secondClassWeightLimited,
      c_secondClassWeightLimitedDate: dataForm.c_secondClassWeightLimitedDate,
      c_secondClassWeightLimitedNo: dataForm.c_secondClassWeightLimitedNo,
      c_secondClassWeightLimitedPlace: dataForm.c_secondClassWeightLimitedPlace,
      c_secondClassWeightLimitedResponsible: dataForm.c_secondClassWeightLimitedResponsible,
      c_secondClassWeightLimitedSendingDate: dataForm.c_secondClassWeightLimitedSendingDate,
      c_secondClassWeightLimitedDeliveryDate: dataForm.c_secondClassWeightLimitedDeliveryDate,
      c_secondClassWeightLimitedExpirationDate: dataForm.c_secondClassWeightLimitedExpirationDate,
      c_secondClassWeightLimitedStatus: dataForm.c_secondClassWeightLimitedStatus,
      c_secondClassWeightLimitedApplicationDate: dataForm.c_secondClassWeightLimitedApplicationDate,
    };
  }

  private getStudentCourseAirplane(dataForm: any): IStudentCourseAirplane {
    return {
      b_plane: dataForm.b_plane,

      b_firstClassBasic: dataForm.b_firstClassBasic,
      b_firstClassBasicDate: dataForm.b_firstClassBasicDate,
      b_firstClassBasicNo: dataForm.b_firstClassBasicNo,
      b_firstClassBasicPlace: dataForm.b_firstClassBasicPlace,
      b_firstClassBasicResponsible: dataForm.b_firstClassBasicResponsible,
      b_firstClassBasicSendingDate: dataForm.b_firstClassBasicSendingDate,
      b_firstClassBasicDeliveryDate: dataForm.b_firstClassBasicDeliveryDate,
      b_firstClassBasicExpirationDate: dataForm.b_firstClassBasicExpirationDate,
      b_firstClassBasicStatus: dataForm.b_firstClassBasicStatus,
      b_firstClassBasicApplicationDate: dataForm.b_firstClassBasicApplicationDate,

      b_firstClassDaytimeOnly: dataForm.b_firstClassDaytimeOnly,
      b_firstClassDaytimeOnlyDate: dataForm.b_firstClassDaytimeOnlyDate,
      b_firstClassDaytimeOnlyNo: dataForm.b_firstClassDaytimeOnlyNo,
      b_firstClassDaytimeOnlyPlace: dataForm.b_firstClassDaytimeOnlyPlace,
      b_firstClassDaytimeOnlyResponsible: dataForm.b_firstClassDaytimeOnlyResponsible,
      b_firstClassDaytimeOnlySendingDate: dataForm.b_firstClassDaytimeOnlySendingDate,
      b_firstClassDaytimeOnlyDeliveryDate: dataForm.b_firstClassDaytimeOnlyDeliveryDate,
      b_firstClassDaytimeOnlyExpirationDate: dataForm.b_firstClassDaytimeOnlyExpirationDate,
      b_firstClassDaytimeOnlyStatus: dataForm.b_firstClassDaytimeOnlyStatus,
      b_firstClassDaytimeOnlyApplicationDate: dataForm.b_firstClassDaytimeOnlyApplicationDate,

      b_firstClassVisualLineOfSight: dataForm.b_firstClassVisualLineOfSight,
      b_firstClassVisualLineOfSightDate: dataForm.b_firstClassVisualLineOfSightDate,
      b_firstClassVisualLineOfSightNo: dataForm.b_firstClassVisualLineOfSightNo,
      b_firstClassVisualLineOfSightPlace: dataForm.b_firstClassVisualLineOfSightPlace,
      b_firstClassVisualLineOfSightResponsible: dataForm.b_firstClassVisualLineOfSightResponsible,
      b_firstClassVisualLineOfSightSendingDate: dataForm.b_firstClassVisualLineOfSightSendingDate,
      b_firstClassVisualLineOfSightDeliveryDate: dataForm.b_firstClassVisualLineOfSightDeliveryDate,
      b_firstClassVisualLineOfSightExpirationDate: dataForm.b_firstClassVisualLineOfSightExpirationDate,
      b_firstClassVisualLineOfSightStatus: dataForm.b_firstClassVisualLineOfSightStatus,
      b_firstClassVisualLineOfSightApplicationDate: dataForm.b_firstClassVisualLineOfSightApplicationDate,

      b_firstClassWeightLimited: dataForm.b_firstClassWeightLimited,
      b_firstClassWeightLimitedDate: dataForm.b_firstClassWeightLimitedDate,
      b_firstClassWeightLimitedNo: dataForm.b_firstClassWeightLimitedNo,
      b_firstClassWeightLimitedPlace: dataForm.b_firstClassWeightLimitedPlace,
      b_firstClassWeightLimitedResponsible: dataForm.b_firstClassWeightLimitedResponsible,
      b_firstClassWeightLimitedSendingDate: dataForm.b_firstClassWeightLimitedSendingDate,
      b_firstClassWeightLimitedDeliveryDate: dataForm.b_firstClassWeightLimitedDeliveryDate,
      b_firstClassWeightLimitedExpirationDate: dataForm.b_firstClassWeightLimitedExpirationDate,
      b_firstClassWeightLimitedStatus: dataForm.b_firstClassWeightLimitedStatus,
      b_firstClassWeightLimitedApplicationDate: dataForm.b_firstClassWeightLimitedApplicationDate,

      b_secondClassBasic: dataForm.b_secondClassBasic,
      b_secondClassBasicDate: dataForm.b_secondClassBasicDate,
      b_secondClassBasicNo: dataForm.b_secondClassBasicNo,
      b_secondClassBasicPlace: dataForm.b_secondClassBasicPlace,
      b_secondClassBasicResponsible: dataForm.b_secondClassBasicResponsible,
      b_secondClassBasicSendingDate: dataForm.b_secondClassBasicSendingDate,
      b_secondClassBasicDeliveryDate: dataForm.b_secondClassBasicDeliveryDate,
      b_secondClassBasicExpirationDate: dataForm.b_secondClassBasicExpirationDate,
      b_secondClassBasicStatus: dataForm.b_secondClassBasicStatus,
      b_secondClassBasicApplicationDate: dataForm.b_secondClassBasicApplicationDate,

      b_secondClassDaytimeOnly: dataForm.b_secondClassDaytimeOnly,
      b_secondClassDaytimeOnlyDate: dataForm.b_secondClassDaytimeOnlyDate,
      b_secondClassDaytimeOnlyNo: dataForm.b_secondClassDaytimeOnlyNo,
      b_secondClassDaytimeOnlyPlace: dataForm.b_secondClassDaytimeOnlyPlace,
      b_secondClassDaytimeOnlyResponsible: dataForm.b_secondClassDaytimeOnlyResponsible,
      b_secondClassDaytimeOnlySendingDate: dataForm.b_secondClassDaytimeOnlySendingDate,
      b_secondClassDaytimeOnlyDeliveryDate: dataForm.b_secondClassDaytimeOnlyDeliveryDate,
      b_secondClassDaytimeOnlyExpirationDate: dataForm.b_secondClassDaytimeOnlyExpirationDate,
      b_secondClassDaytimeOnlyStatus: dataForm.b_secondClassDaytimeOnlyStatus,
      b_secondClassDaytimeOnlyApplicationDate: dataForm.b_secondClassDaytimeOnlyApplicationDate,

      b_secondClassVisualLineOfSight: dataForm.b_secondClassVisualLineOfSight,
      b_secondClassVisualLineOfSightDate: dataForm.b_secondClassVisualLineOfSightDate,
      b_secondClassVisualLineOfSightNo: dataForm.b_secondClassVisualLineOfSightNo,
      b_secondClassVisualLineOfSightPlace: dataForm.b_secondClassVisualLineOfSightPlace,
      b_secondClassVisualLineOfSightResponsible: dataForm.b_secondClassVisualLineOfSightResponsible,
      b_secondClassVisualLineOfSightSendingDate: dataForm.b_secondClassVisualLineOfSightSendingDate,
      b_secondClassVisualLineOfSightDeliveryDate: dataForm.b_secondClassVisualLineOfSightDeliveryDate,
      b_secondClassVisualLineOfSightExpirationDate: dataForm.b_secondClassVisualLineOfSightExpirationDate,
      b_secondClassVisualLineOfSightStatus: dataForm.b_secondClassVisualLineOfSightStatus,
      b_secondClassVisualLineOfSightApplicationDate: dataForm.b_secondClassVisualLineOfSightApplicationDate,

      b_secondClassWeightLimited: dataForm.b_secondClassWeightLimited,
      b_secondClassWeightLimitedDate: dataForm.b_secondClassWeightLimitedDate,
      b_secondClassWeightLimitedNo: dataForm.b_secondClassWeightLimitedNo,
      b_secondClassWeightLimitedPlace: dataForm.b_secondClassWeightLimitedPlace,
      b_secondClassWeightLimitedResponsible: dataForm.b_secondClassWeightLimitedResponsible,
      b_secondClassWeightLimitedSendingDate: dataForm.b_secondClassWeightLimitedSendingDate,
      b_secondClassWeightLimitedDeliveryDate: dataForm.b_secondClassWeightLimitedDeliveryDate,
      b_secondClassWeightLimitedExpirationDate: dataForm.b_secondClassWeightLimitedExpirationDate,
      b_secondClassWeightLimitedStatus: dataForm.b_secondClassWeightLimitedStatus,
      b_secondClassWeightLimitedApplicationDate: dataForm.b_secondClassWeightLimitedApplicationDate,
    };
  }

  private getStudentCourseMulti(dataForm: any): IStudentCourseMulti {
    return {
      a_rotorcraftMulti: dataForm.a_rotorcraftMulti,

      a_firstClassBasic: dataForm.a_firstClassBasic,
      a_firstClassBasicDate: dataForm.a_firstClassBasicDate,
      a_firstClassBasicNo: dataForm.a_firstClassBasicNo,
      a_firstClassBasicPlace: dataForm.a_firstClassBasicPlace,
      a_firstClassBasicResponsible: dataForm.a_firstClassBasicResponsible,
      a_firstClassBasicSendingDate: dataForm.a_firstClassBasicSendingDate,
      a_firstClassBasicDeliveryDate: dataForm.a_firstClassBasicDeliveryDate,
      a_firstClassBasicExpirationDate: dataForm.a_firstClassBasicExpirationDate,
      a_firstClassBasicStatus: dataForm.a_firstClassBasicStatus,
      a_firstClassBasicApplicationDate: dataForm.a_firstClassBasicApplicationDate,

      a_firstClassDaytimeOnly: dataForm.a_firstClassDaytimeOnly,
      a_firstClassDaytimeOnlyDate: dataForm.a_firstClassDaytimeOnlyDate,
      a_firstClassDaytimeOnlyNo: dataForm.a_firstClassDaytimeOnlyNo,
      a_firstClassDaytimeOnlyPlace: dataForm.a_firstClassDaytimeOnlyPlace,
      a_firstClassDaytimeOnlyResponsible: dataForm.a_firstClassDaytimeOnlyResponsible,
      a_firstClassDaytimeOnlySendingDate: dataForm.a_firstClassDaytimeOnlySendingDate,
      a_firstClassDaytimeOnlyDeliveryDate: dataForm.a_firstClassDaytimeOnlyDeliveryDate,
      a_firstClassDaytimeOnlyExpirationDate: dataForm.a_firstClassDaytimeOnlyExpirationDate,
      a_firstClassDaytimeOnlyStatus: dataForm.a_firstClassDaytimeOnlyStatus,
      a_firstClassDaytimeOnlyApplicationDate: dataForm.a_firstClassDaytimeOnlyApplicationDate,

      a_firstClassVisualLineOfSight: dataForm.a_firstClassVisualLineOfSight,
      a_firstClassVisualLineOfSightDate: dataForm.a_firstClassVisualLineOfSightDate,
      a_firstClassVisualLineOfSightNo: dataForm.a_firstClassVisualLineOfSightNo,
      a_firstClassVisualLineOfSightPlace: dataForm.a_firstClassVisualLineOfSightPlace,
      a_firstClassVisualLineOfSightResponsible: dataForm.a_firstClassVisualLineOfSightResponsible,
      a_firstClassVisualLineOfSightSendingDate: dataForm.a_firstClassVisualLineOfSightSendingDate,
      a_firstClassVisualLineOfSightDeliveryDate: dataForm.a_firstClassVisualLineOfSightDeliveryDate,
      a_firstClassVisualLineOfSightExpirationDate: dataForm.a_firstClassVisualLineOfSightExpirationDate,
      a_firstClassVisualLineOfSightStatus: dataForm.a_firstClassVisualLineOfSightStatus,
      a_firstClassVisualLineOfSightApplicationDate: dataForm.a_firstClassVisualLineOfSightApplicationDate,

      a_firstClassWeightLimited: dataForm.a_firstClassWeightLimited,
      a_firstClassWeightLimitedDate: dataForm.a_firstClassWeightLimitedDate,
      a_firstClassWeightLimitedNo: dataForm.a_firstClassWeightLimitedNo,
      a_firstClassWeightLimitedPlace: dataForm.a_firstClassWeightLimitedPlace,
      a_firstClassWeightLimitedResponsible: dataForm.a_firstClassWeightLimitedResponsible,
      a_firstClassWeightLimitedSendingDate: dataForm.a_firstClassWeightLimitedSendingDate,
      a_firstClassWeightLimitedDeliveryDate: dataForm.a_firstClassWeightLimitedDeliveryDate,
      a_firstClassWeightLimitedExpirationDate: dataForm.a_firstClassWeightLimitedExpirationDate,
      a_firstClassWeightLimitedStatus: dataForm.a_firstClassWeightLimitedStatus,
      a_firstClassWeightLimitedApplicationDate: dataForm.a_firstClassWeightLimitedApplicationDate,

      a_secondClassBasic: dataForm.a_secondClassBasic,
      a_secondClassBasicDate: dataForm.a_secondClassBasicDate,
      a_secondClassBasicNo: dataForm.a_secondClassBasicNo,
      a_secondClassBasicPlace: dataForm.a_secondClassBasicPlace,
      a_secondClassBasicResponsible: dataForm.a_secondClassBasicResponsible,
      a_secondClassBasicSendingDate: dataForm.a_secondClassBasicSendingDate,
      a_secondClassBasicDeliveryDate: dataForm.a_secondClassBasicDeliveryDate,
      a_secondClassBasicExpirationDate: dataForm.a_secondClassBasicExpirationDate,
      a_secondClassBasicStatus: dataForm.a_secondClassBasicStatus,
      a_secondClassBasicApplicationDate: dataForm.a_secondClassBasicApplicationDate,

      a_secondClassDaytimeOnly: dataForm.a_secondClassDaytimeOnly,
      a_secondClassDaytimeOnlyDate: dataForm.a_secondClassDaytimeOnlyDate,
      a_secondClassDaytimeOnlyNo: dataForm.a_secondClassDaytimeOnlyNo,
      a_secondClassDaytimeOnlyPlace: dataForm.a_secondClassDaytimeOnlyPlace,
      a_secondClassDaytimeOnlyResponsible: dataForm.a_secondClassDaytimeOnlyResponsible,
      a_secondClassDaytimeOnlySendingDate: dataForm.a_secondClassDaytimeOnlySendingDate,
      a_secondClassDaytimeOnlyDeliveryDate: dataForm.a_secondClassDaytimeOnlyDeliveryDate,
      a_secondClassDaytimeOnlyExpirationDate: dataForm.a_secondClassDaytimeOnlyExpirationDate,
      a_secondClassDaytimeOnlyStatus: dataForm.a_secondClassDaytimeOnlyStatus,
      a_secondClassDaytimeOnlyApplicationDate: dataForm.a_secondClassDaytimeOnlyApplicationDate,

      a_secondClassVisualLineOfSight: dataForm.a_secondClassVisualLineOfSight,
      a_secondClassVisualLineOfSightDate: dataForm.a_secondClassVisualLineOfSightDate,
      a_secondClassVisualLineOfSightNo: dataForm.a_secondClassVisualLineOfSightNo,
      a_secondClassVisualLineOfSightPlace: dataForm.a_secondClassVisualLineOfSightPlace,
      a_secondClassVisualLineOfSightResponsible: dataForm.a_secondClassVisualLineOfSightResponsible,
      a_secondClassVisualLineOfSightSendingDate: dataForm.a_secondClassVisualLineOfSightSendingDate,
      a_secondClassVisualLineOfSightDeliveryDate: dataForm.a_secondClassVisualLineOfSightDeliveryDate,
      a_secondClassVisualLineOfSightExpirationDate: dataForm.a_secondClassVisualLineOfSightExpirationDate,
      a_secondClassVisualLineOfSightStatus: dataForm.a_secondClassVisualLineOfSightStatus,
      a_secondClassVisualLineOfSightApplicationDate: dataForm.a_secondClassVisualLineOfSightApplicationDate,

      a_secondClassWeightLimited: dataForm.a_secondClassWeightLimited,
      a_secondClassWeightLimitedDate: dataForm.a_secondClassWeightLimitedDate,
      a_secondClassWeightLimitedNo: dataForm.a_secondClassWeightLimitedNo,
      a_secondClassWeightLimitedPlace: dataForm.a_secondClassWeightLimitedPlace,
      a_secondClassWeightLimitedResponsible: dataForm.a_secondClassWeightLimitedResponsible,
      a_secondClassWeightLimitedSendingDate: dataForm.a_secondClassWeightLimitedSendingDate,
      a_secondClassWeightLimitedDeliveryDate: dataForm.a_secondClassWeightLimitedDeliveryDate,
      a_secondClassWeightLimitedExpirationDate: dataForm.a_secondClassWeightLimitedExpirationDate,
      a_secondClassWeightLimitedStatus: dataForm.a_secondClassWeightLimitedStatus,
      a_secondClassWeightLimitedApplicationDate: dataForm.a_secondClassWeightLimitedApplicationDate,
    };
  }

  private getStudentPilotLicense(dataForm: any): IStudentPilotLicense {
    return {
      issue: dataForm.issue,
      dateOfIssue: dataForm.dateOfIssue,
      dateOfExpiry: dataForm.dateOfExpiry,
      skillNumber: dataForm.skillNumber,
      licenseConditions: dataForm.licenseConditions,

      x_firstClassBasic: dataForm.x_firstClassBasic,
      x_firstClassDaytimeOnly: dataForm.x_firstClassDaytimeOnly,
      x_firstClassVisualLineOfSight: dataForm.x_firstClassVisualLineOfSight,
      x_firstClassWeightLimited: dataForm.x_firstClassWeightLimited,
      x_secondClassBasic: dataForm.x_secondClassBasic,
      x_secondClassDaytimeOnly: dataForm.x_secondClassDaytimeOnly,
      x_secondClassVisualLineOfSight: dataForm.x_secondClassVisualLineOfSight,
      x_secondClassWeightLimited: dataForm.x_secondClassWeightLimited,
      y_firstClassBasic: dataForm.y_firstClassBasic,
      y_firstClassDaytimeOnly: dataForm.y_firstClassDaytimeOnly,
      y_firstClassVisualLineOfSight: dataForm.y_firstClassVisualLineOfSight,
      y_firstClassWeightLimited: dataForm.y_firstClassWeightLimited,
      y_secondClassBasic: dataForm.y_secondClassBasic,
      y_secondClassDaytimeOnly: dataForm.y_secondClassDaytimeOnly,
      y_secondClassVisualLineOfSight: dataForm.y_secondClassVisualLineOfSight,
      y_secondClassWeightLimited: dataForm.y_secondClassWeightLimited,
      z_firstClassBasic: dataForm.z_firstClassBasic,
      z_firstClassDaytimeOnly: dataForm.z_firstClassDaytimeOnly,
      z_firstClassVisualLineOfSight: dataForm.z_firstClassVisualLineOfSight,
      z_firstClassWeightLimited: dataForm.z_firstClassWeightLimited,
      z_secondClassBasic: dataForm.z_secondClassBasic,
      z_secondClassDaytimeOnly: dataForm.z_secondClassDaytimeOnly,
      z_secondClassVisualLineOfSight: dataForm.z_secondClassVisualLineOfSight,
      z_secondClassWeightLimited: dataForm.z_secondClassWeightLimited
    };
  }

  public checkValidTabBasic(): boolean {
    return (
      this.f['hurigana'].invalid
      || this.f['name'].invalid
      || this.f['certificateNumber'].invalid
      || this.f['birthday'].invalid
      || this.f['address'].invalid
      || this.f['email'].invalid
      || this.f['remark'].invalid
      || this.f['academicExamPassDate'].invalid
      || this.f['academicExamPassCertificateNumber'].invalid
      || this.f['tel'].invalid
    ) && this.submitted;
  }

  public checkValidConfirmation(): boolean {
    return (
      this.f['no_expireDate'].invalid
      || this.f['issuingOrganizationName'].invalid
      || this.f['no'].invalid
      || this.f['noEtc'].invalid
      || this.f['etcText'].invalid
      || this.f['totalFlightTime'].invalid
      || this.f['dateOfIssue'].invalid
      || this.f['dateOfExpiry'].invalid
      || this.f['skillNumber'].invalid
      || this.f['licenseConditions'].invalid
      || this.inValid
    ) && this.submitted;
  }

  public checkValidAirplane(): boolean {
    return (
      this.f['b_firstClassBasicNo'].invalid ||
      this.f['b_firstClassBasicPlace'].invalid ||
      this.f['b_firstClassBasicResponsible'].invalid ||

      this.f['b_firstClassDaytimeOnlyNo'].invalid ||
      this.f['b_firstClassDaytimeOnlyPlace'].invalid ||
      this.f['b_firstClassDaytimeOnlyResponsible'].invalid ||

      this.f['b_firstClassVisualLineOfSightNo'].invalid ||
      this.f['b_firstClassVisualLineOfSightPlace'].invalid ||
      this.f['b_firstClassVisualLineOfSightResponsible'].invalid ||

      this.f['b_firstClassWeightLimitedNo'].invalid ||
      this.f['b_firstClassWeightLimitedPlace'].invalid ||
      this.f['b_firstClassWeightLimitedResponsible'].invalid ||

      this.f['b_secondClassBasicNo'].invalid ||
      this.f['b_secondClassBasicPlace'].invalid ||
      this.f['b_secondClassBasicResponsible'].invalid ||

      this.f['b_secondClassDaytimeOnlyNo'].invalid ||
      this.f['b_secondClassDaytimeOnlyPlace'].invalid ||
      this.f['b_secondClassDaytimeOnlyResponsible'].invalid ||

      this.f['b_secondClassVisualLineOfSightNo'].invalid ||
      this.f['b_secondClassVisualLineOfSightPlace'].invalid ||
      this.f['b_secondClassVisualLineOfSightResponsible'].invalid ||

      this.f['b_secondClassWeightLimitedNo'].invalid ||
      this.f['b_secondClassWeightLimitedPlace'].invalid ||
      this.f['b_secondClassWeightLimitedResponsible'].invalid
    ) && this.submitted;
  }

  public checkValidHelicopter(): boolean {
    return (
      this.f['c_firstClassBasicNo'].invalid ||
      this.f['c_firstClassBasicPlace'].invalid ||
      this.f['c_firstClassBasicResponsible'].invalid ||

      this.f['c_firstClassDaytimeOnlyNo'].invalid ||
      this.f['c_firstClassDaytimeOnlyPlace'].invalid ||
      this.f['c_firstClassDaytimeOnlyResponsible'].invalid ||

      this.f['c_firstClassVisualLineOfSightNo'].invalid ||
      this.f['c_firstClassVisualLineOfSightPlace'].invalid ||
      this.f['c_firstClassVisualLineOfSightResponsible'].invalid ||

      this.f['c_firstClassWeightLimitedNo'].invalid ||
      this.f['c_firstClassWeightLimitedPlace'].invalid ||
      this.f['c_firstClassWeightLimitedResponsible'].invalid ||

      this.f['c_secondClassBasicNo'].invalid ||
      this.f['c_secondClassBasicPlace'].invalid ||
      this.f['c_secondClassBasicResponsible'].invalid ||

      this.f['c_secondClassDaytimeOnlyNo'].invalid ||
      this.f['c_secondClassDaytimeOnlyPlace'].invalid ||
      this.f['c_secondClassDaytimeOnlyResponsible'].invalid ||

      this.f['c_secondClassVisualLineOfSightNo'].invalid ||
      this.f['c_secondClassVisualLineOfSightPlace'].invalid ||
      this.f['c_secondClassVisualLineOfSightResponsible'].invalid ||

      this.f['c_secondClassWeightLimitedNo'].invalid ||
      this.f['c_secondClassWeightLimitedPlace'].invalid ||
      this.f['c_secondClassWeightLimitedResponsible'].invalid
    ) && this.submitted;
  }

  public checkValidMulti(): boolean {
    return (
      this.f['a_firstClassBasicNo'].invalid ||
      this.f['a_firstClassBasicPlace'].invalid ||
      this.f['a_firstClassBasicResponsible'].invalid ||

      this.f['a_firstClassDaytimeOnlyNo'].invalid ||
      this.f['a_firstClassDaytimeOnlyPlace'].invalid ||
      this.f['a_firstClassDaytimeOnlyResponsible'].invalid ||

      this.f['a_firstClassVisualLineOfSightNo'].invalid ||
      this.f['a_firstClassVisualLineOfSightPlace'].invalid ||
      this.f['a_firstClassVisualLineOfSightResponsible'].invalid ||

      this.f['a_firstClassWeightLimitedNo'].invalid ||
      this.f['a_firstClassWeightLimitedPlace'].invalid ||
      this.f['a_firstClassWeightLimitedResponsible'].invalid ||

      this.f['a_secondClassBasicNo'].invalid ||
      this.f['a_secondClassBasicPlace'].invalid ||
      this.f['a_secondClassBasicResponsible'].invalid ||

      this.f['a_secondClassDaytimeOnlyNo'].invalid ||
      this.f['a_secondClassDaytimeOnlyPlace'].invalid ||
      this.f['a_secondClassDaytimeOnlyResponsible'].invalid ||

      this.f['a_secondClassVisualLineOfSightNo'].invalid ||
      this.f['a_secondClassVisualLineOfSightPlace'].invalid ||
      this.f['a_secondClassVisualLineOfSightResponsible'].invalid ||

      this.f['a_secondClassWeightLimitedNo'].invalid ||
      this.f['a_secondClassWeightLimitedPlace'].invalid ||
      this.f['a_secondClassWeightLimitedResponsible'].invalid
    )
      && this.submitted;
  }

  private getStudentIdentityVerification(dataForm: any): IStudentIdentityVerification {
    return {
      driverLicense: dataForm.driverLicense,
      myNumberCard: dataForm.myNumberCard,
      etc: dataForm.etc,
      etcText: dataForm.etcText ?? '',
      noEtc: dataForm.noEtc
    };
  }

  private getStudentClassification(dataForm: any): IStudentClassification {
    return {
      experiencedType: dataForm.experiencedType,
      privateSkillCertificate: dataForm.privateSkillCertificate,
      totalFlightTime: dataForm.totalFlightTime,
      issuingOrganizationName: dataForm.issuingOrganizationName,
      no: dataForm.no,
      no_expireDate: dataForm.no_expireDate,
      number: dataForm.number,
      number_expireDate: dataForm.number_expireDate,
      status: dataForm.status,
      statusElearning: dataForm.statusElearning,
      useElearning: (dataForm.useElearning == YES) ? true : false,
    };
  }

  private getStudentEnrollment(dataForm: any): IStudentEnrollment {
    return {
      enrollmentDate: dataForm.enrollmentDate,
      practicalDate: dataForm.practicalDate
    };
  }

  private getStudentInfo(dataForm: any): IStudentInfo {
    return {
      ledgerNumber: dataForm.ledgerNumber,
      studentNumber: dataForm.studentNumber,
      hurigana: dataForm.hurigana,
      name: dataForm.name,
      certificateNumber: dataForm.certificateNumber,
      birthday: dataForm.birthday,
      sex: dataForm.sex,
      address: dataForm.address,
      tel: dataForm.tel,
      email: dataForm.email,
      remark: dataForm.remark,
      academicExamStatus: dataForm.academicExamStatus,
      academicExamPassDate: dataForm.academicExamPassDate,
      academicExamPassCertificateNumber: dataForm.academicExamPassCertificateNumber,
      courseItems: dataForm.courseItems,
      createAt: new Date(),
      organizationName: dataForm.organizationName ?? '',
    };
  }

  private setStudentInfo(studentInfo: IStudentInfo | undefined): void {
    if (studentInfo) {
      let sex = studentInfo.sex == '男' ? '男性' : studentInfo.sex == '女' ? '女性' : studentInfo.sex;
      this.f['ledgerNumber'].setValue(studentInfo.ledgerNumber);
      this.f['studentNumber'].setValue(studentInfo.studentNumber);
      this.f['hurigana'].setValue(studentInfo.hurigana);
      this.f['name'].setValue(studentInfo.name);
      this.f['certificateNumber'].setValue(studentInfo.certificateNumber);
      this.f['birthday'].setValue(this.setDate(studentInfo.birthday));
      this.f['sex'].setValue(sex);
      this.f['address'].setValue(studentInfo.address);
      this.f['tel'].setValue(studentInfo.tel);
      this.f['email'].setValue(studentInfo.email);
      this.f['remark'].setValue(studentInfo.remark);
      this.f['academicExamPassCertificateNumber'].setValue(studentInfo.academicExamPassCertificateNumber);
      this.f['academicExamPassDate'].setValue(this.setDate(studentInfo.academicExamPassDate));
      this.f['academicExamStatus'].setValue(studentInfo.academicExamStatus == '合格' ? '合格済' : studentInfo.academicExamStatus);
      if (this.courseItems.length > 0) {
        if (this.courseItems.filter((e: any) => e.itemNumber == studentInfo.courseItems).length > 0) {
          this.f['courseItems'].setValue(studentInfo.courseItems);
        } else {
          this.f['courseItems'].setValue(this.courseItems[0].itemNumber);
        }
      }
      this.f['organizationName'].setValue(studentInfo.organizationName);
      this.imageUrl = studentInfo.picture ?? '';
    }
  }

  private setStudentEnrollment(studentEnrollment: IStudentEnrollment | undefined): void {
    if (studentEnrollment) {
      this.f['enrollmentDate'].setValue(this.setDate(studentEnrollment.enrollmentDate));
      this.f['practicalDate'].setValue(this.setDate(studentEnrollment.practicalDate));
    }
  }

  private setStudentClassification(studentClassification: IStudentClassification | undefined): void {
    if (studentClassification) {
      this.f['experiencedType'].setValue(studentClassification.experiencedType);
      this.f['privateSkillCertificate'].setValue(studentClassification.privateSkillCertificate);
      this.f['totalFlightTime'].setValue(studentClassification.totalFlightTime);
      this.f['issuingOrganizationName'].setValue(studentClassification.issuingOrganizationName);
      this.f['no'].setValue(studentClassification.no);
      this.f['no_expireDate'].setValue(this.setDate(studentClassification.no_expireDate));
      this.f['number'].setValue(studentClassification.number);
      this.f['number_expireDate'].setValue(this.setDate(studentClassification.number_expireDate));
      this.f['status'].setValue(studentClassification.status);
      this.f['statusElearning'].setValue(studentClassification.statusElearning);
      this.f['useElearning'].setValue(studentClassification.useElearning ? YES : NO);
      this.learning = studentClassification.useElearning;
      this.learningStatus = studentClassification.statusElearning;
    }
  }

  private setStudentIdentityVerification(studentIdentityVerification: IStudentIdentityVerification | undefined): void {
    if (studentIdentityVerification) {
      this.f['driverLicense'].setValue(studentIdentityVerification.driverLicense);
      this.f['myNumberCard'].setValue(studentIdentityVerification.myNumberCard);
      this.f['etc'].setValue(studentIdentityVerification.etc);
      this.f['etcText'].setValue(studentIdentityVerification.etcText);
      this.f['noEtc'].setValue(studentIdentityVerification.noEtc);
    }
  }

  private setStudentPilotLicense(studentPilotLicense: IStudentPilotLicense | undefined): void {
    if (studentPilotLicense) {
      this.f['issue'].setValue(studentPilotLicense.issue);
      this.f['licenseConditions'].setValue(studentPilotLicense.licenseConditions);
      this.f['dateOfIssue'].setValue(this.setDate(studentPilotLicense.dateOfIssue));
      this.f['dateOfExpiry'].setValue(this.setDate(studentPilotLicense.dateOfExpiry));
      this.f['skillNumber'].setValue(studentPilotLicense.skillNumber);

      this.f['x_firstClassBasic'].setValue(studentPilotLicense.x_firstClassBasic);
      this.f['x_firstClassVisualLineOfSight'].setValue(studentPilotLicense.x_firstClassVisualLineOfSight);
      this.f['x_firstClassDaytimeOnly'].setValue(studentPilotLicense.x_firstClassDaytimeOnly);
      this.f['x_firstClassWeightLimited'].setValue(studentPilotLicense.x_firstClassWeightLimited);
      this.f['x_secondClassBasic'].setValue(studentPilotLicense.x_secondClassBasic);
      this.f['x_secondClassDaytimeOnly'].setValue(studentPilotLicense.x_secondClassDaytimeOnly);
      this.f['x_secondClassWeightLimited'].setValue(studentPilotLicense.x_secondClassWeightLimited);
      this.f['x_secondClassVisualLineOfSight'].setValue(studentPilotLicense.x_secondClassVisualLineOfSight);

      this.f['y_firstClassBasic'].setValue(studentPilotLicense.y_firstClassBasic);
      this.f['y_firstClassVisualLineOfSight'].setValue(studentPilotLicense.y_firstClassVisualLineOfSight);
      this.f['y_firstClassDaytimeOnly'].setValue(studentPilotLicense.y_firstClassDaytimeOnly);
      this.f['y_firstClassWeightLimited'].setValue(studentPilotLicense.y_firstClassWeightLimited);
      this.f['y_secondClassBasic'].setValue(studentPilotLicense.y_secondClassBasic);
      this.f['y_secondClassDaytimeOnly'].setValue(studentPilotLicense.y_secondClassDaytimeOnly);
      this.f['y_secondClassWeightLimited'].setValue(studentPilotLicense.y_secondClassWeightLimited);
      this.f['y_secondClassVisualLineOfSight'].setValue(studentPilotLicense.y_secondClassVisualLineOfSight);

      this.f['z_firstClassBasic'].setValue(studentPilotLicense.z_firstClassBasic);
      this.f['z_firstClassVisualLineOfSight'].setValue(studentPilotLicense.z_firstClassVisualLineOfSight);
      this.f['z_firstClassDaytimeOnly'].setValue(studentPilotLicense.z_firstClassDaytimeOnly);
      this.f['z_firstClassWeightLimited'].setValue(studentPilotLicense.z_firstClassWeightLimited);
      this.f['z_secondClassBasic'].setValue(studentPilotLicense.z_secondClassBasic);
      this.f['z_secondClassDaytimeOnly'].setValue(studentPilotLicense.z_secondClassDaytimeOnly);
      this.f['z_secondClassWeightLimited'].setValue(studentPilotLicense.z_secondClassWeightLimited);
      this.f['z_secondClassVisualLineOfSight'].setValue(studentPilotLicense.z_secondClassVisualLineOfSight);

      this.changeValidatorControl();
    }
  }

  public changeValidatorControl(): void {
    if (this.formEditStudent.get('privateSkillCertificate')?.value === TEXT_TRUE) {
      this.f['issuingOrganizationName'].setValidators([Validators.minLength(1), Validators.maxLength(127), Validators.required]);
      this.f['issuingOrganizationName'].updateValueAndValidity();

      this.f['no_expireDate'].setValidators([Validators.required]);
      this.f['no_expireDate'].updateValueAndValidity();
    } else {
      this.f['issuingOrganizationName'].setValidators([]);
      this.f['issuingOrganizationName'].updateValueAndValidity();

      this.f['no_expireDate'].setValidators([]);
      this.f['no_expireDate'].updateValueAndValidity();
    }

    if (this.formEditStudent.get('issue')?.value === TEXT_TRUE) {
      this.f['dateOfIssue'].setValidators([Validators.required]);
      this.f['dateOfIssue'].updateValueAndValidity();

      this.f['dateOfExpiry'].setValidators([Validators.required]);
      this.f['dateOfExpiry'].updateValueAndValidity();

      this.f['licenseConditions'].setValidators([Validators.minLength(1), Validators.maxLength(15), Validators.required]);
      this.f['licenseConditions'].updateValueAndValidity();

      this.f['skillNumber'].setValidators([Validators.minLength(1), Validators.maxLength(15), Validators.required]);
      this.f['skillNumber'].updateValueAndValidity();

    } else {

      this.f['dateOfIssue'].setValidators([]);
      this.f['dateOfIssue'].updateValueAndValidity();

      this.f['dateOfExpiry'].setValidators([]);
      this.f['dateOfExpiry'].updateValueAndValidity();

      this.f['licenseConditions'].setValidators([]);
      this.f['licenseConditions'].updateValueAndValidity();

      this.f['skillNumber'].setValidators([]);
      this.f['skillNumber'].updateValueAndValidity();

    }

    if (this.formEditStudent.get('academicExamStatus')?.value === TEXT_PASS) {
      this.f['academicExamPassDate'].setValidators([Validators.required]);
      this.f['academicExamPassDate'].updateValueAndValidity();

      this.f['academicExamPassCertificateNumber'].setValidators([Validators.required]);
      this.f['academicExamPassCertificateNumber'].updateValueAndValidity();

    } else {
      this.f['academicExamPassDate'].clearValidators();
      this.f['academicExamPassDate'].updateValueAndValidity();

      this.f['academicExamPassCertificateNumber'].clearValidators();
      this.f['academicExamPassCertificateNumber'].updateValueAndValidity();

    }
  }

  private setStudentCourseMulti(studentCourseMulti: IStudentCourseMulti | undefined): void {
    if (studentCourseMulti) {
      this.f['a_rotorcraftMulti'].setValue(studentCourseMulti.a_rotorcraftMulti);

      this.f['a_firstClassBasic'].setValue(studentCourseMulti.a_firstClassBasic);
      this.f['a_firstClassBasicNo'].setValue(studentCourseMulti.a_firstClassBasicNo);
      this.f['a_firstClassBasicDate'].setValue(this.setDate(studentCourseMulti.a_firstClassBasicDate));
      this.f['a_firstClassBasicPlace'].setValue(studentCourseMulti.a_firstClassBasicPlace ?? "");
      this.f['a_firstClassBasicResponsible'].setValue(studentCourseMulti.a_firstClassBasicResponsible ?? "");
      this.f['a_firstClassBasicSendingDate'].setValue(this.setDate(studentCourseMulti.a_firstClassBasicSendingDate));
      this.f['a_firstClassBasicDeliveryDate'].setValue(this.setDate(studentCourseMulti.a_firstClassBasicDeliveryDate));
      this.f['a_firstClassBasicExpirationDate'].setValue(this.setDate(studentCourseMulti.a_firstClassBasicExpirationDate));
      this.f['a_firstClassBasicStatus'].setValue(studentCourseMulti.a_firstClassBasicStatus ?? "");
      this.f['a_firstClassBasicApplicationDate'].setValue(this.setDate(studentCourseMulti.a_firstClassBasicApplicationDate) ?? "");

      this.f['a_firstClassDaytimeOnly'].setValue(studentCourseMulti.a_firstClassDaytimeOnly);
      this.f['a_firstClassDaytimeOnlyNo'].setValue(studentCourseMulti.a_firstClassDaytimeOnlyNo);
      this.f['a_firstClassDaytimeOnlyDate'].setValue(this.setDate(studentCourseMulti.a_firstClassDaytimeOnlyDate));
      this.f['a_firstClassDaytimeOnlyPlace'].setValue(studentCourseMulti.a_firstClassDaytimeOnlyPlace ?? "");
      this.f['a_firstClassDaytimeOnlyResponsible'].setValue(studentCourseMulti.a_firstClassDaytimeOnlyResponsible ?? "");
      this.f['a_firstClassDaytimeOnlySendingDate'].setValue(this.setDate(studentCourseMulti.a_firstClassDaytimeOnlySendingDate));
      this.f['a_firstClassDaytimeOnlyDeliveryDate'].setValue(this.setDate(studentCourseMulti.a_firstClassDaytimeOnlyDeliveryDate));
      this.f['a_firstClassDaytimeOnlyExpirationDate'].setValue(this.setDate(studentCourseMulti.a_firstClassDaytimeOnlyExpirationDate));
      this.f['a_firstClassDaytimeOnlyStatus'].setValue(studentCourseMulti.a_firstClassDaytimeOnlyStatus ?? "");
      this.f['a_firstClassDaytimeOnlyApplicationDate'].setValue(this.setDate(studentCourseMulti.a_firstClassDaytimeOnlyApplicationDate) ?? "");

      this.f['a_firstClassVisualLineOfSight'].setValue(studentCourseMulti.a_firstClassVisualLineOfSight);
      this.f['a_firstClassVisualLineOfSightNo'].setValue(studentCourseMulti.a_firstClassVisualLineOfSightNo);
      this.f['a_firstClassVisualLineOfSightDate'].setValue(this.setDate(studentCourseMulti.a_firstClassVisualLineOfSightDate));
      this.f['a_firstClassVisualLineOfSightPlace'].setValue(studentCourseMulti.a_firstClassVisualLineOfSightPlace ?? "");
      this.f['a_firstClassVisualLineOfSightResponsible'].setValue(studentCourseMulti.a_firstClassVisualLineOfSightResponsible ?? "");
      this.f['a_firstClassVisualLineOfSightSendingDate'].setValue(this.setDate(studentCourseMulti.a_firstClassVisualLineOfSightSendingDate));
      this.f['a_firstClassVisualLineOfSightDeliveryDate'].setValue(this.setDate(studentCourseMulti.a_firstClassVisualLineOfSightDeliveryDate));
      this.f['a_firstClassVisualLineOfSightExpirationDate'].setValue(this.setDate(studentCourseMulti.a_firstClassVisualLineOfSightExpirationDate));
      this.f['a_firstClassVisualLineOfSightStatus'].setValue(studentCourseMulti.a_firstClassVisualLineOfSightStatus ?? "");
      this.f['a_firstClassVisualLineOfSightApplicationDate'].setValue(this.setDate(studentCourseMulti.a_firstClassVisualLineOfSightApplicationDate) ?? "");

      this.f['a_firstClassWeightLimited'].setValue(studentCourseMulti.a_firstClassWeightLimited);
      this.f['a_firstClassWeightLimitedNo'].setValue(studentCourseMulti.a_firstClassWeightLimitedNo);
      this.f['a_firstClassWeightLimitedDate'].setValue(this.setDate(studentCourseMulti.a_firstClassWeightLimitedDate));
      this.f['a_firstClassWeightLimitedPlace'].setValue(studentCourseMulti.a_firstClassWeightLimitedPlace ?? "");
      this.f['a_firstClassWeightLimitedResponsible'].setValue(studentCourseMulti.a_firstClassWeightLimitedResponsible ?? "");
      this.f['a_firstClassWeightLimitedSendingDate'].setValue(this.setDate(studentCourseMulti.a_firstClassWeightLimitedSendingDate));
      this.f['a_firstClassWeightLimitedDeliveryDate'].setValue(this.setDate(studentCourseMulti.a_firstClassWeightLimitedDeliveryDate));
      this.f['a_firstClassWeightLimitedExpirationDate'].setValue(this.setDate(studentCourseMulti.a_firstClassWeightLimitedExpirationDate));
      this.f['a_firstClassWeightLimitedStatus'].setValue(studentCourseMulti.a_firstClassWeightLimitedStatus ?? "");
      this.f['a_firstClassWeightLimitedApplicationDate'].setValue(this.setDate(studentCourseMulti.a_firstClassWeightLimitedApplicationDate) ?? "");

      this.f['a_secondClassBasic'].setValue(studentCourseMulti.a_secondClassBasic);
      this.f['a_secondClassBasicNo'].setValue(studentCourseMulti.a_secondClassBasicNo);
      this.f['a_secondClassBasicDate'].setValue(this.setDate(studentCourseMulti.a_secondClassBasicDate));
      this.f['a_secondClassBasicPlace'].setValue(studentCourseMulti.a_secondClassBasicPlace ?? "");
      this.f['a_secondClassBasicResponsible'].setValue(studentCourseMulti.a_secondClassBasicResponsible ?? "");
      this.f['a_secondClassBasicSendingDate'].setValue(this.setDate(studentCourseMulti.a_secondClassBasicSendingDate));
      this.f['a_secondClassBasicDeliveryDate'].setValue(this.setDate(studentCourseMulti.a_secondClassBasicDeliveryDate));
      this.f['a_secondClassBasicExpirationDate'].setValue(this.setDate(studentCourseMulti.a_secondClassBasicExpirationDate));
      this.f['a_secondClassBasicStatus'].setValue(studentCourseMulti.a_secondClassBasicStatus ?? "");
      this.f['a_secondClassBasicApplicationDate'].setValue(this.setDate(studentCourseMulti.a_secondClassBasicApplicationDate) ?? "");

      this.f['a_secondClassDaytimeOnly'].setValue(studentCourseMulti.a_secondClassDaytimeOnly);
      this.f['a_secondClassDaytimeOnlyNo'].setValue(studentCourseMulti.a_secondClassDaytimeOnlyNo);
      this.f['a_secondClassDaytimeOnlyDate'].setValue(this.setDate(studentCourseMulti.a_secondClassDaytimeOnlyDate));
      this.f['a_secondClassDaytimeOnlyPlace'].setValue(studentCourseMulti.a_secondClassDaytimeOnlyPlace ?? "");
      this.f['a_secondClassDaytimeOnlyResponsible'].setValue(studentCourseMulti.a_secondClassDaytimeOnlyResponsible ?? "");
      this.f['a_secondClassDaytimeOnlySendingDate'].setValue(this.setDate(studentCourseMulti.a_secondClassDaytimeOnlySendingDate));
      this.f['a_secondClassDaytimeOnlyDeliveryDate'].setValue(this.setDate(studentCourseMulti.a_secondClassDaytimeOnlyDeliveryDate));
      this.f['a_secondClassDaytimeOnlyExpirationDate'].setValue(this.setDate(studentCourseMulti.a_secondClassDaytimeOnlyExpirationDate));
      this.f['a_secondClassDaytimeOnlyStatus'].setValue(studentCourseMulti.a_secondClassDaytimeOnlyStatus ?? "");
      this.f['a_secondClassDaytimeOnlyApplicationDate'].setValue(this.setDate(studentCourseMulti.a_secondClassDaytimeOnlyApplicationDate) ?? "");

      this.f['a_secondClassVisualLineOfSight'].setValue(studentCourseMulti.a_secondClassVisualLineOfSight);
      this.f['a_secondClassVisualLineOfSightNo'].setValue(studentCourseMulti.a_secondClassVisualLineOfSightNo);
      this.f['a_secondClassVisualLineOfSightDate'].setValue(this.setDate(studentCourseMulti.a_secondClassVisualLineOfSightDate));
      this.f['a_secondClassVisualLineOfSightPlace'].setValue(studentCourseMulti.a_secondClassVisualLineOfSightPlace ?? "");
      this.f['a_secondClassVisualLineOfSightResponsible'].setValue(studentCourseMulti.a_secondClassVisualLineOfSightResponsible ?? "");
      this.f['a_secondClassVisualLineOfSightSendingDate'].setValue(this.setDate(studentCourseMulti.a_secondClassVisualLineOfSightSendingDate));
      this.f['a_secondClassVisualLineOfSightDeliveryDate'].setValue(this.setDate(studentCourseMulti.a_secondClassVisualLineOfSightDeliveryDate));
      this.f['a_secondClassVisualLineOfSightExpirationDate'].setValue(this.setDate(studentCourseMulti.a_secondClassVisualLineOfSightExpirationDate));
      this.f['a_secondClassVisualLineOfSightStatus'].setValue(studentCourseMulti.a_secondClassVisualLineOfSightStatus ?? "");
      this.f['a_secondClassVisualLineOfSightApplicationDate'].setValue(this.setDate(studentCourseMulti.a_secondClassVisualLineOfSightApplicationDate) ?? "");

      this.f['a_secondClassWeightLimited'].setValue(studentCourseMulti.a_secondClassWeightLimited);
      this.f['a_secondClassWeightLimitedNo'].setValue(studentCourseMulti.a_secondClassWeightLimitedNo);
      this.f['a_secondClassWeightLimitedDate'].setValue(this.setDate(studentCourseMulti.a_secondClassWeightLimitedDate));
      this.f['a_secondClassWeightLimitedPlace'].setValue(studentCourseMulti.a_secondClassWeightLimitedPlace ?? "");
      this.f['a_secondClassWeightLimitedResponsible'].setValue(studentCourseMulti.a_secondClassWeightLimitedResponsible ?? "");
      this.f['a_secondClassWeightLimitedSendingDate'].setValue(this.setDate(studentCourseMulti.a_secondClassWeightLimitedSendingDate));
      this.f['a_secondClassWeightLimitedDeliveryDate'].setValue(this.setDate(studentCourseMulti.a_secondClassWeightLimitedDeliveryDate));
      this.f['a_secondClassWeightLimitedExpirationDate'].setValue(this.setDate(studentCourseMulti.a_secondClassWeightLimitedExpirationDate));
      this.f['a_secondClassWeightLimitedStatus'].setValue(studentCourseMulti.a_secondClassWeightLimitedStatus ?? "");
      this.f['a_secondClassWeightLimitedApplicationDate'].setValue(this.setDate(studentCourseMulti.a_secondClassWeightLimitedApplicationDate) ?? "");

    }
  }

  private setStudentCourseAirplane(studentCourseAirplane: IStudentCourseAirplane | undefined): void {
    if (studentCourseAirplane) {
      this.f['b_plane'].setValue(studentCourseAirplane.b_plane);

      this.f['b_firstClassBasic'].setValue(studentCourseAirplane.b_firstClassBasic);
      this.f['b_firstClassBasicNo'].setValue(studentCourseAirplane.b_firstClassBasicNo);
      this.f['b_firstClassBasicDate'].setValue(this.setDate(studentCourseAirplane.b_firstClassBasicDate));
      this.f['b_firstClassBasicPlace'].setValue(studentCourseAirplane.b_firstClassBasicPlace ?? "");
      this.f['b_firstClassBasicResponsible'].setValue(studentCourseAirplane.b_firstClassBasicResponsible ?? "");
      this.f['b_firstClassBasicSendingDate'].setValue(this.setDate(studentCourseAirplane.b_firstClassBasicSendingDate));
      this.f['b_firstClassBasicDeliveryDate'].setValue(this.setDate(studentCourseAirplane.b_firstClassBasicDeliveryDate));
      this.f['b_firstClassBasicExpirationDate'].setValue(this.setDate(studentCourseAirplane.b_firstClassBasicExpirationDate));
      this.f['b_firstClassBasicStatus'].setValue(studentCourseAirplane.b_firstClassBasicStatus ?? "");
      this.f['b_firstClassBasicApplicationDate'].setValue(this.setDate(studentCourseAirplane.b_firstClassBasicApplicationDate) ?? "");

      this.f['b_firstClassDaytimeOnly'].setValue(studentCourseAirplane.b_firstClassDaytimeOnly);
      this.f['b_firstClassDaytimeOnlyNo'].setValue(studentCourseAirplane.b_firstClassDaytimeOnlyNo);
      this.f['b_firstClassDaytimeOnlyDate'].setValue(this.setDate(studentCourseAirplane.b_firstClassDaytimeOnlyDate));
      this.f['b_firstClassDaytimeOnlyPlace'].setValue(studentCourseAirplane.b_firstClassDaytimeOnlyPlace ?? "");
      this.f['b_firstClassDaytimeOnlyResponsible'].setValue(studentCourseAirplane.b_firstClassDaytimeOnlyResponsible ?? "");
      this.f['b_firstClassDaytimeOnlySendingDate'].setValue(this.setDate(studentCourseAirplane.b_firstClassDaytimeOnlySendingDate));
      this.f['b_firstClassDaytimeOnlyDeliveryDate'].setValue(this.setDate(studentCourseAirplane.b_firstClassDaytimeOnlyDeliveryDate));
      this.f['b_firstClassDaytimeOnlyExpirationDate'].setValue(this.setDate(studentCourseAirplane.b_firstClassDaytimeOnlyExpirationDate));
      this.f['b_firstClassDaytimeOnlyStatus'].setValue(studentCourseAirplane.b_firstClassDaytimeOnlyStatus ?? "");
      this.f['b_firstClassDaytimeOnlyApplicationDate'].setValue(this.setDate(studentCourseAirplane.b_firstClassDaytimeOnlyApplicationDate) ?? "");

      this.f['b_firstClassVisualLineOfSight'].setValue(studentCourseAirplane.b_firstClassVisualLineOfSight);
      this.f['b_firstClassVisualLineOfSightNo'].setValue(studentCourseAirplane.b_firstClassVisualLineOfSightNo);
      this.f['b_firstClassVisualLineOfSightDate'].setValue(this.setDate(studentCourseAirplane.b_firstClassVisualLineOfSightDate));
      this.f['b_firstClassVisualLineOfSightPlace'].setValue(studentCourseAirplane.b_firstClassVisualLineOfSightPlace ?? "");
      this.f['b_firstClassVisualLineOfSightResponsible'].setValue(studentCourseAirplane.b_firstClassVisualLineOfSightResponsible ?? "");
      this.f['b_firstClassVisualLineOfSightSendingDate'].setValue(this.setDate(studentCourseAirplane.b_firstClassVisualLineOfSightSendingDate));
      this.f['b_firstClassVisualLineOfSightDeliveryDate'].setValue(this.setDate(studentCourseAirplane.b_firstClassVisualLineOfSightDeliveryDate));
      this.f['b_firstClassVisualLineOfSightExpirationDate'].setValue(this.setDate(studentCourseAirplane.b_firstClassVisualLineOfSightExpirationDate));
      this.f['b_firstClassVisualLineOfSightStatus'].setValue(studentCourseAirplane.b_firstClassVisualLineOfSightStatus ?? "");
      this.f['b_firstClassVisualLineOfSightApplicationDate'].setValue(this.setDate(studentCourseAirplane.b_firstClassVisualLineOfSightApplicationDate) ?? "");

      this.f['b_firstClassWeightLimited'].setValue(studentCourseAirplane.b_firstClassWeightLimited);
      this.f['b_firstClassWeightLimitedNo'].setValue(studentCourseAirplane.b_firstClassWeightLimitedNo);
      this.f['b_firstClassWeightLimitedDate'].setValue(this.setDate(studentCourseAirplane.b_firstClassWeightLimitedDate));
      this.f['b_firstClassWeightLimitedPlace'].setValue(studentCourseAirplane.b_firstClassWeightLimitedPlace ?? "");
      this.f['b_firstClassWeightLimitedResponsible'].setValue(studentCourseAirplane.b_firstClassWeightLimitedResponsible ?? "");
      this.f['b_firstClassWeightLimitedSendingDate'].setValue(this.setDate(studentCourseAirplane.b_firstClassWeightLimitedSendingDate));
      this.f['b_firstClassWeightLimitedDeliveryDate'].setValue(this.setDate(studentCourseAirplane.b_firstClassWeightLimitedDeliveryDate));
      this.f['b_firstClassWeightLimitedExpirationDate'].setValue(this.setDate(studentCourseAirplane.b_firstClassWeightLimitedExpirationDate));
      this.f['b_firstClassWeightLimitedStatus'].setValue(studentCourseAirplane.b_firstClassWeightLimitedStatus ?? "");
      this.f['b_firstClassWeightLimitedApplicationDate'].setValue(this.setDate(studentCourseAirplane.b_firstClassWeightLimitedApplicationDate) ?? "");

      this.f['b_secondClassBasic'].setValue(studentCourseAirplane.b_secondClassBasic);
      this.f['b_secondClassBasicNo'].setValue(studentCourseAirplane.b_secondClassBasicNo);
      this.f['b_secondClassBasicDate'].setValue(this.setDate(studentCourseAirplane.b_secondClassBasicDate));
      this.f['b_secondClassBasicPlace'].setValue(studentCourseAirplane.b_secondClassBasicPlace ?? "");
      this.f['b_secondClassBasicResponsible'].setValue(studentCourseAirplane.b_secondClassBasicResponsible ?? "");
      this.f['b_secondClassBasicSendingDate'].setValue(this.setDate(studentCourseAirplane.b_secondClassBasicSendingDate));
      this.f['b_secondClassBasicDeliveryDate'].setValue(this.setDate(studentCourseAirplane.b_secondClassBasicDeliveryDate));
      this.f['b_secondClassBasicExpirationDate'].setValue(this.setDate(studentCourseAirplane.b_secondClassBasicExpirationDate));
      this.f['b_secondClassBasicStatus'].setValue(studentCourseAirplane.b_secondClassBasicStatus ?? "");
      this.f['b_secondClassBasicApplicationDate'].setValue(this.setDate(studentCourseAirplane.b_secondClassBasicApplicationDate) ?? "");

      this.f['b_secondClassDaytimeOnly'].setValue(studentCourseAirplane.b_secondClassDaytimeOnly);
      this.f['b_secondClassDaytimeOnlyNo'].setValue(studentCourseAirplane.b_secondClassDaytimeOnlyNo);
      this.f['b_secondClassDaytimeOnlyDate'].setValue(this.setDate(studentCourseAirplane.b_secondClassDaytimeOnlyDate));
      this.f['b_secondClassDaytimeOnlyPlace'].setValue(studentCourseAirplane.b_secondClassDaytimeOnlyPlace ?? "");
      this.f['b_secondClassDaytimeOnlyResponsible'].setValue(studentCourseAirplane.b_secondClassDaytimeOnlyResponsible ?? "");
      this.f['b_secondClassDaytimeOnlySendingDate'].setValue(this.setDate(studentCourseAirplane.b_secondClassDaytimeOnlySendingDate));
      this.f['b_secondClassDaytimeOnlyDeliveryDate'].setValue(this.setDate(studentCourseAirplane.b_secondClassDaytimeOnlyDeliveryDate));
      this.f['b_secondClassDaytimeOnlyExpirationDate'].setValue(this.setDate(studentCourseAirplane.b_secondClassDaytimeOnlyExpirationDate));
      this.f['b_secondClassDaytimeOnlyStatus'].setValue(studentCourseAirplane.b_secondClassDaytimeOnlyStatus ?? "");
      this.f['b_secondClassDaytimeOnlyApplicationDate'].setValue(this.setDate(studentCourseAirplane.b_secondClassDaytimeOnlyApplicationDate) ?? "");

      this.f['b_secondClassVisualLineOfSight'].setValue(studentCourseAirplane.b_secondClassVisualLineOfSight);
      this.f['b_secondClassVisualLineOfSightNo'].setValue(studentCourseAirplane.b_secondClassVisualLineOfSightNo);
      this.f['b_secondClassVisualLineOfSightDate'].setValue(this.setDate(studentCourseAirplane.b_secondClassVisualLineOfSightDate));
      this.f['b_secondClassVisualLineOfSightPlace'].setValue(studentCourseAirplane.b_secondClassVisualLineOfSightPlace ?? "");
      this.f['b_secondClassVisualLineOfSightResponsible'].setValue(studentCourseAirplane.b_secondClassVisualLineOfSightResponsible ?? "");
      this.f['b_secondClassVisualLineOfSightSendingDate'].setValue(this.setDate(studentCourseAirplane.b_secondClassVisualLineOfSightSendingDate));
      this.f['b_secondClassVisualLineOfSightDeliveryDate'].setValue(this.setDate(studentCourseAirplane.b_secondClassVisualLineOfSightDeliveryDate));
      this.f['b_secondClassVisualLineOfSightExpirationDate'].setValue(this.setDate(studentCourseAirplane.b_secondClassVisualLineOfSightExpirationDate));
      this.f['b_secondClassVisualLineOfSightStatus'].setValue(studentCourseAirplane.b_secondClassVisualLineOfSightStatus ?? "");
      this.f['b_secondClassVisualLineOfSightApplicationDate'].setValue(this.setDate(studentCourseAirplane.b_secondClassVisualLineOfSightApplicationDate) ?? "");

      this.f['b_secondClassWeightLimited'].setValue(studentCourseAirplane.b_secondClassWeightLimited);
      this.f['b_secondClassWeightLimitedNo'].setValue(studentCourseAirplane.b_secondClassWeightLimitedNo);
      this.f['b_secondClassWeightLimitedDate'].setValue(this.setDate(studentCourseAirplane.b_secondClassWeightLimitedDate));
      this.f['b_secondClassWeightLimitedPlace'].setValue(studentCourseAirplane.b_secondClassWeightLimitedPlace ?? "");
      this.f['b_secondClassWeightLimitedResponsible'].setValue(studentCourseAirplane.b_secondClassWeightLimitedResponsible ?? "");
      this.f['b_secondClassWeightLimitedSendingDate'].setValue(this.setDate(studentCourseAirplane.b_secondClassWeightLimitedSendingDate));
      this.f['b_secondClassWeightLimitedDeliveryDate'].setValue(this.setDate(studentCourseAirplane.b_secondClassWeightLimitedDeliveryDate));
      this.f['b_secondClassWeightLimitedExpirationDate'].setValue(this.setDate(studentCourseAirplane.b_secondClassWeightLimitedExpirationDate));
      this.f['b_secondClassWeightLimitedStatus'].setValue(studentCourseAirplane.b_secondClassWeightLimitedStatus ?? "");
      this.f['b_secondClassWeightLimitedApplicationDate'].setValue(this.setDate(studentCourseAirplane.b_secondClassWeightLimitedApplicationDate) ?? "");
    }
  }

  private setStudentCourseHelicopter(studentCourseHelicopter: IStudentCourseHelicopter | undefined): void {
    if (studentCourseHelicopter) {
      this.f['c_rotorcraft'].setValue(studentCourseHelicopter.c_rotorcraft);

      this.f['c_firstClassBasic'].setValue(studentCourseHelicopter.c_firstClassBasic);
      this.f['c_firstClassBasicNo'].setValue(studentCourseHelicopter.c_firstClassBasicNo);
      this.f['c_firstClassBasicDate'].setValue(this.setDate(studentCourseHelicopter.c_firstClassBasicDate));
      this.f['c_firstClassBasicPlace'].setValue(studentCourseHelicopter.c_firstClassBasicPlace ?? "");
      this.f['c_firstClassBasicResponsible'].setValue(studentCourseHelicopter.c_firstClassBasicResponsible ?? "");
      this.f['c_firstClassBasicSendingDate'].setValue(this.setDate(studentCourseHelicopter.c_firstClassBasicSendingDate));
      this.f['c_firstClassBasicDeliveryDate'].setValue(this.setDate(studentCourseHelicopter.c_firstClassBasicDeliveryDate));
      this.f['c_firstClassBasicExpirationDate'].setValue(this.setDate(studentCourseHelicopter.c_firstClassBasicExpirationDate));
      this.f['c_firstClassBasicStatus'].setValue(studentCourseHelicopter.c_firstClassBasicStatus ?? "");
      this.f['c_firstClassBasicApplicationDate'].setValue(this.setDate(studentCourseHelicopter.c_firstClassBasicApplicationDate) ?? "");

      this.f['c_firstClassDaytimeOnly'].setValue(studentCourseHelicopter.c_firstClassDaytimeOnly);
      this.f['c_firstClassDaytimeOnlyNo'].setValue(studentCourseHelicopter.c_firstClassDaytimeOnlyNo);
      this.f['c_firstClassDaytimeOnlyDate'].setValue(this.setDate(studentCourseHelicopter.c_firstClassDaytimeOnlyDate));
      this.f['c_firstClassDaytimeOnlyPlace'].setValue(studentCourseHelicopter.c_firstClassDaytimeOnlyPlace ?? "");
      this.f['c_firstClassDaytimeOnlyResponsible'].setValue(studentCourseHelicopter.c_firstClassDaytimeOnlyResponsible ?? "");
      this.f['c_firstClassDaytimeOnlySendingDate'].setValue(this.setDate(studentCourseHelicopter.c_firstClassDaytimeOnlySendingDate));
      this.f['c_firstClassDaytimeOnlyDeliveryDate'].setValue(this.setDate(studentCourseHelicopter.c_firstClassDaytimeOnlyDeliveryDate));
      this.f['c_firstClassDaytimeOnlyExpirationDate'].setValue(this.setDate(studentCourseHelicopter.c_firstClassDaytimeOnlyExpirationDate));
      this.f['c_firstClassDaytimeOnlyStatus'].setValue(studentCourseHelicopter.c_firstClassDaytimeOnlyStatus ?? "");
      this.f['c_firstClassDaytimeOnlyApplicationDate'].setValue(this.setDate(studentCourseHelicopter.c_firstClassDaytimeOnlyApplicationDate) ?? "");

      this.f['c_firstClassVisualLineOfSight'].setValue(studentCourseHelicopter.c_firstClassVisualLineOfSight);
      this.f['c_firstClassVisualLineOfSightNo'].setValue(studentCourseHelicopter.c_firstClassVisualLineOfSightNo);
      this.f['c_firstClassVisualLineOfSightDate'].setValue(this.setDate(studentCourseHelicopter.c_firstClassVisualLineOfSightDate));
      this.f['c_firstClassVisualLineOfSightPlace'].setValue(studentCourseHelicopter.c_firstClassVisualLineOfSightPlace ?? "");
      this.f['c_firstClassVisualLineOfSightResponsible'].setValue(studentCourseHelicopter.c_firstClassVisualLineOfSightResponsible ?? "");
      this.f['c_firstClassVisualLineOfSightSendingDate'].setValue(this.setDate(studentCourseHelicopter.c_firstClassVisualLineOfSightSendingDate));
      this.f['c_firstClassVisualLineOfSightDeliveryDate'].setValue(this.setDate(studentCourseHelicopter.c_firstClassVisualLineOfSightDeliveryDate));
      this.f['c_firstClassVisualLineOfSightExpirationDate'].setValue(this.setDate(studentCourseHelicopter.c_firstClassVisualLineOfSightExpirationDate));
      this.f['c_firstClassVisualLineOfSightStatus'].setValue(studentCourseHelicopter.c_firstClassVisualLineOfSightStatus ?? "");
      this.f['c_firstClassVisualLineOfSightApplicationDate'].setValue(this.setDate(studentCourseHelicopter.c_firstClassVisualLineOfSightApplicationDate) ?? "");

      this.f['c_firstClassWeightLimited'].setValue(studentCourseHelicopter.c_firstClassWeightLimited);
      this.f['c_firstClassWeightLimitedNo'].setValue(studentCourseHelicopter.c_firstClassWeightLimitedNo);
      this.f['c_firstClassWeightLimitedDate'].setValue(this.setDate(studentCourseHelicopter.c_firstClassWeightLimitedDate));
      this.f['c_firstClassWeightLimitedPlace'].setValue(studentCourseHelicopter.c_firstClassWeightLimitedPlace ?? "");
      this.f['c_firstClassWeightLimitedResponsible'].setValue(studentCourseHelicopter.c_firstClassWeightLimitedResponsible ?? "");
      this.f['c_firstClassWeightLimitedSendingDate'].setValue(this.setDate(studentCourseHelicopter.c_firstClassWeightLimitedSendingDate));
      this.f['c_firstClassWeightLimitedDeliveryDate'].setValue(this.setDate(studentCourseHelicopter.c_firstClassWeightLimitedDeliveryDate));
      this.f['c_firstClassWeightLimitedExpirationDate'].setValue(this.setDate(studentCourseHelicopter.c_firstClassWeightLimitedExpirationDate));
      this.f['c_firstClassWeightLimitedStatus'].setValue(studentCourseHelicopter.c_firstClassWeightLimitedStatus ?? "");
      this.f['c_firstClassWeightLimitedApplicationDate'].setValue(this.setDate(studentCourseHelicopter.c_firstClassWeightLimitedApplicationDate) ?? "");

      this.f['c_secondClassBasic'].setValue(studentCourseHelicopter.c_secondClassBasic);
      this.f['c_secondClassBasicNo'].setValue(studentCourseHelicopter.c_secondClassBasicNo);
      this.f['c_secondClassBasicDate'].setValue(this.setDate(studentCourseHelicopter.c_secondClassBasicDate));
      this.f['c_secondClassBasicPlace'].setValue(studentCourseHelicopter.c_secondClassBasicPlace ?? "");
      this.f['c_secondClassBasicResponsible'].setValue(studentCourseHelicopter.c_secondClassBasicResponsible ?? "");
      this.f['c_secondClassBasicSendingDate'].setValue(this.setDate(studentCourseHelicopter.c_secondClassBasicSendingDate));
      this.f['c_secondClassBasicDeliveryDate'].setValue(this.setDate(studentCourseHelicopter.c_secondClassBasicDeliveryDate));
      this.f['c_secondClassBasicExpirationDate'].setValue(this.setDate(studentCourseHelicopter.c_secondClassBasicExpirationDate));
      this.f['c_secondClassBasicStatus'].setValue(studentCourseHelicopter.c_secondClassBasicStatus ?? "");
      this.f['c_secondClassBasicApplicationDate'].setValue(this.setDate(studentCourseHelicopter.c_secondClassBasicApplicationDate) ?? "");

      this.f['c_secondClassDaytimeOnly'].setValue(studentCourseHelicopter.c_secondClassDaytimeOnly);
      this.f['c_secondClassDaytimeOnlyNo'].setValue(studentCourseHelicopter.c_secondClassDaytimeOnlyNo);
      this.f['c_secondClassDaytimeOnlyDate'].setValue(this.setDate(studentCourseHelicopter.c_secondClassDaytimeOnlyDate));
      this.f['c_secondClassDaytimeOnlyPlace'].setValue(studentCourseHelicopter.c_secondClassDaytimeOnlyPlace ?? "");
      this.f['c_secondClassDaytimeOnlyResponsible'].setValue(studentCourseHelicopter.c_secondClassDaytimeOnlyResponsible ?? "");
      this.f['c_secondClassDaytimeOnlySendingDate'].setValue(this.setDate(studentCourseHelicopter.c_secondClassDaytimeOnlySendingDate));
      this.f['c_secondClassDaytimeOnlyDeliveryDate'].setValue(this.setDate(studentCourseHelicopter.c_secondClassDaytimeOnlyDeliveryDate));
      this.f['c_secondClassDaytimeOnlyExpirationDate'].setValue(this.setDate(studentCourseHelicopter.c_secondClassDaytimeOnlyExpirationDate));
      this.f['c_secondClassDaytimeOnlyStatus'].setValue(studentCourseHelicopter.c_secondClassDaytimeOnlyStatus ?? "");
      this.f['c_secondClassDaytimeOnlyApplicationDate'].setValue(this.setDate(studentCourseHelicopter.c_secondClassDaytimeOnlyApplicationDate) ?? "");

      this.f['c_secondClassVisualLineOfSight'].setValue(studentCourseHelicopter.c_secondClassVisualLineOfSight);
      this.f['c_secondClassVisualLineOfSightNo'].setValue(studentCourseHelicopter.c_secondClassVisualLineOfSightNo);
      this.f['c_secondClassVisualLineOfSightDate'].setValue(this.setDate(studentCourseHelicopter.c_secondClassVisualLineOfSightDate));
      this.f['c_secondClassVisualLineOfSightPlace'].setValue(studentCourseHelicopter.c_secondClassVisualLineOfSightPlace ?? "");
      this.f['c_secondClassVisualLineOfSightResponsible'].setValue(studentCourseHelicopter.c_secondClassVisualLineOfSightResponsible ?? "");
      this.f['c_secondClassVisualLineOfSightSendingDate'].setValue(this.setDate(studentCourseHelicopter.c_secondClassVisualLineOfSightSendingDate));
      this.f['c_secondClassVisualLineOfSightDeliveryDate'].setValue(this.setDate(studentCourseHelicopter.c_secondClassVisualLineOfSightDeliveryDate));
      this.f['c_secondClassVisualLineOfSightExpirationDate'].setValue(this.setDate(studentCourseHelicopter.c_secondClassVisualLineOfSightExpirationDate));
      this.f['c_secondClassVisualLineOfSightStatus'].setValue(studentCourseHelicopter.c_secondClassVisualLineOfSightStatus ?? "");
      this.f['c_secondClassVisualLineOfSightApplicationDate'].setValue(this.setDate(studentCourseHelicopter.c_secondClassVisualLineOfSightApplicationDate) ?? "");

      this.f['c_secondClassWeightLimited'].setValue(studentCourseHelicopter.c_secondClassWeightLimited);
      this.f['c_secondClassWeightLimitedNo'].setValue(studentCourseHelicopter.c_secondClassWeightLimitedNo);
      this.f['c_secondClassWeightLimitedDate'].setValue(this.setDate(studentCourseHelicopter.c_secondClassWeightLimitedDate));
      this.f['c_secondClassWeightLimitedPlace'].setValue(studentCourseHelicopter.c_secondClassWeightLimitedPlace ?? "");
      this.f['c_secondClassWeightLimitedResponsible'].setValue(studentCourseHelicopter.c_secondClassWeightLimitedResponsible ?? "");
      this.f['c_secondClassWeightLimitedSendingDate'].setValue(this.setDate(studentCourseHelicopter.c_secondClassWeightLimitedSendingDate));
      this.f['c_secondClassWeightLimitedDeliveryDate'].setValue(this.setDate(studentCourseHelicopter.c_secondClassWeightLimitedDeliveryDate));
      this.f['c_secondClassWeightLimitedExpirationDate'].setValue(this.setDate(studentCourseHelicopter.c_secondClassWeightLimitedExpirationDate));
      this.f['c_secondClassWeightLimitedStatus'].setValue(studentCourseHelicopter.c_secondClassWeightLimitedStatus ?? "");
      this.f['c_secondClassWeightLimitedApplicationDate'].setValue(this.setDate(studentCourseHelicopter.c_secondClassWeightLimitedApplicationDate) ?? "");

    }
  }

  private setStudentManeuverMode(studentManeuverMode: IStudentManeuverMode | undefined): void {
    if (studentManeuverMode) {
      this.f['mode1'].setValue(studentManeuverMode.mode1);
      this.f['mode2'].setValue(studentManeuverMode.mode2);
      this.f['mode3'].setValue(studentManeuverMode.mode3);
    }
  }

  private setDate(date: any): string | Date {
    return date ? new Date((date as Timestamp).toDate()) : '';
  }

  public changeEtcTextCheckbox(): void {
    this.formEditStudent.controls['etcText'].setValue('');
  }
}
