import { NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class InputPasswordComponent implements OnInit {
  @Input('ngClass') ngClass: NgClass['ngClass'];
  @Input('formControlNameInput') formControlNameInput = '';
  @Input('placeHolderInput') placeHolderInput = '';
  @Input('submitted') submitted: boolean = false;
  @Input('formPassword') formPassword: any = [];
  protected isShowPassword: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  toggleShowPassword() {
    this.isShowPassword = !this.isShowPassword;
  }
}
