<c-card class="mb-3 card-border">
  <c-card-body>
    <div class="attendances__item">
      <div class="attendances__item--header">
        <div class="title">
          <span [cPopoverTrigger]='"hover"' cPopoverPlacement="top" [cPopover]="itemInput.courseContentCurriculum?.length > 10 ? itemInput.courseContentCurriculum : ''">
            {{ itemInput.courseContentCurriculum?.length > 25 ? itemInput.courseContentCurriculum.substring(0, 25) + '...' : itemInput.courseContentCurriculum }}
          </span>
        </div>
        <div class="exam" *ngIf="itemInput.exam">
          <div class="test">
            <span>
              {{ 'student.attendances.create.exam' | translate }}
            </span>
          </div>
          <div class="passing">{{ itemInput.examPass }}</div>
          <div class="point">
            <span>{{ 'student.attendances.list.item.score' | translate }}</span>
            <span>{{ itemInput.score }}</span>
          </div>
        </div>
        <div *ngIf="!itemInput.exam"></div>
        <div class="action">
          <span class="btn-edit"  (click)="redirectEdit()"></span>
          <app-modal-delete-common
            *ngIf="student?.userId === currentUser?.uid"
            [deleteId]="itemInput?.id"
            [titleDelete]="'student.attendances.delete.title' | translate"
            (handleDeleteCommon)="handleDeleteAttendance($event)">
          </app-modal-delete-common>
        </div>
      </div>
      <div class="attendances__item--body">
        <div class="left">
          <div class="date_time">
            <span>{{ 'student.attendances.list.item.courseDate' | translate }}</span>
            <span class="d-inline-block" *ngIf="itemInput.courseDate">{{ formatDate(itemInput.courseDate.toDate(), formatDatePicker) }} </span>
          </div>
          <div class="date_time" *ngIf="!itemInput.exam">
            <span>{{ 'student.attendances.list.item.classTime' | translate }}</span>
            <span class="d-inline-block">{{ itemInput.classTime }} 時間</span>
          </div>
          <div class="date_time">
            <span>{{ 'student.attendances.list.item.actualFlightTime' | translate }}</span>
            <span class="d-inline-block">{{ itemInput.actualFlightTime }}</span>
          </div>
        </div>
        <div class="right">
          <span [cPopoverTrigger]='"hover"' cPopoverPlacement="top" [cPopover]="itemInput.comment?.length > 10 ? itemInput.comment : ''">
            {{ itemInput.comment?.length > 100 ? itemInput.comment.substring(0, 100) + '...' : itemInput.comment }}
          </span>
        </div>
      </div>
    </div>
  </c-card-body>
</c-card>
