import { Timestamp } from "firebase/firestore";

export interface ICompletionRecord {
  examDate: Date | Timestamp,
  examName: string,
  airframeTitle: string,
  airframeNumber: string,
  subject1: number,
  subject2: number,
  subject3_1: number,
  subject3_1_title: string,
  subject3_2: number,
  subject3_2_title: string,
  subject3_3: number,
  subject3_3_title: string,
  subject4: number,
  subject5: number,
  subject6_1: string,
  subject6_2: string,
  remark: string,
  score: number,
  examPass: string,
  course?: string | null,
  createAt: Date
}

export interface ISyncCompletionRecord {
  completionRecord: ICompletionRecord,
  studentDocId: string,
  userId: string,
}
