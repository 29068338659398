import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { CompletionRecordService } from "@app/services/completion-record.service";
import { ICompletionRecord } from "@app/models";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import firebase from "firebase/compat/app";
import { ulid } from "ulid";
import { AlertService, AttendanceService, StudentService } from "@app/services";
import { ActivatedRoute, Router } from "@angular/router";
import { IStudent } from "@app/models/student";
import { Timestamp } from "firebase/firestore";
import { TranslateService } from "@ngx-translate/core";
import { IBreadcrumbItem } from "@coreui/angular-pro";
import { IDataOption, STATUS_EXAM } from '@app/constants';

@Component({
  selector: 'app-create-completion-record',
  templateUrl: './create-completion-record.component.html',
  styleUrls: ['./create-completion-record.component.scss']
})
export class CreateCompletionRecordComponent implements OnInit {
  protected submitted: boolean = false;
  protected isRequest: boolean = false;
  protected formCreateCompletionRecord!: FormGroup;
  protected elementRef!: ElementRef;
  protected currentUser: any;
  private currentStudent!: IStudent;
  protected id: string = '';
  protected course: string | null = '';
  protected userId: string = '';
  private idStudent: string = '';
  protected readonly examPass: IDataOption[] = STATUS_EXAM;
  protected breadcrumbItems: IBreadcrumbItem[] = [];
  protected completionRecord!: ICompletionRecord;
  protected leaderNameItems: any;
  protected airframeTitleItems: any;

  constructor(
    private fb: FormBuilder,
    private completionRecordService: CompletionRecordService,
    private auth: AngularFireAuth,
    private alertService: AlertService,
    private attendanceService: AttendanceService,
    private activatedRoute: ActivatedRoute,
    private studentService: StudentService,
    private router: Router,
    private translateService: TranslateService,
    @Inject(ElementRef) elementRef: ElementRef,
    private translate: TranslateService,
  ) {
    this.elementRef = elementRef;
  }

  private initForm(): void {
    this.formCreateCompletionRecord = this.fb.group({
      examDate: new FormControl('', { validators: [Validators.required] }),
      examName: new FormControl('', { validators: [Validators.required, Validators.maxLength(255)] }),
      airframeTitle: new FormControl('', { validators: [Validators.maxLength(255)] }),
      airframeNumber: new FormControl('', { validators: [Validators.maxLength(255)] }),
      subject1: new FormControl(0, { validators: [Validators.min(-100), Validators.max(0), Validators.pattern(/^-?\d+$/)] }),
      subject2: new FormControl(0, { validators: [Validators.min(-100), Validators.max(0), Validators.pattern(/^-?\d+$/)] }),

      subject3_1: new FormControl(0, { validators: [Validators.min(-100), Validators.max(0), Validators.pattern(/^-?\d+$/)] }),
      subject3_1_title: new FormControl('', { validators: [Validators.maxLength(127)] }),

      subject3_2: new FormControl(0, { validators: [Validators.min(-100), Validators.max(0), Validators.pattern(/^-?\d+$/)] }),
      subject3_2_title: new FormControl('', { validators: [Validators.maxLength(127)] }),

      subject3_3: new FormControl(0, { validators: [Validators.min(-100), Validators.max(0), Validators.pattern(/^-?\d+$/)] }),
      subject3_3_title: new FormControl('', { validators: [Validators.maxLength(127)] }),

      subject4: new FormControl(0, { validators: [Validators.min(-100), Validators.max(0), Validators.pattern(/^-?\d+$/)] }),
      subject5: new FormControl(0, { validators: [Validators.min(-100), Validators.max(0), Validators.pattern(/^-?\d+$/)] }),
      subject6_1: new FormControl(0, { validators: [Validators.min(-100), Validators.max(0), Validators.pattern(/^-?\d+$/)] }),
      subject6_2: new FormControl(0, { validators: [Validators.min(-100), Validators.max(0), Validators.pattern(/^-?\d+$/)] }),
      remark: new FormControl('', { validators: [Validators.maxLength(1024)] }),
      score: new FormControl(100, { validators: [Validators.max(100)] }),
      examPass: new FormControl('合格', { validators: [] }),
    });
  }

  changeScore(value: any = null) {
    const subjectKeys = ['subject1', 'subject2', 'subject3_1', 'subject3_2', 'subject3_3', 'subject4', 'subject5', 'subject6_1', 'subject6_2'];
    let totalScore = 0;
    for (let key of subjectKeys) {
      if (this.formCreateCompletionRecord.controls[key].invalid) {
        return;
      } else {
        if (this.formCreateCompletionRecord.controls[key].value == '') {
          this.formCreateCompletionRecord.controls[key].setValue(0);
        } else {
          totalScore += parseInt(this.formCreateCompletionRecord.controls[key].value);
        }
      }
    }

    this.formCreateCompletionRecord.controls['score'].setValue(100 + totalScore);
  }

  private async getModalData(): Promise<void> {
    const leaderNameData = await this.attendanceService.getItemsWithUserId('instructors', this.currentUser.uid);
    if (leaderNameData.length) this.leaderNameItems = leaderNameData;

    const aircraftData = await this.attendanceService.getItemsWithUserId('aircraft_used', this.currentUser.uid);
    if (aircraftData.length) this.airframeTitleItems = aircraftData;
  }

  get f() {
    return this.formCreateCompletionRecord.controls;
  }

  public async ngOnInit(): Promise<void> {
    this.initForm();

    this.idStudent = this.activatedRoute.snapshot.paramMap.get('id') as string;
    this.course = this.activatedRoute.snapshot.paramMap.get('course') as string;
    this.id = this.activatedRoute.snapshot.paramMap.get('idCompletionRecord') as string;
    await this.getUser().then(() => {
      this.getModalData();
    });

    if (this.idStudent) {
      this.userId = this.activatedRoute.snapshot.queryParamMap.get('user_id') ?? this.currentUser.uid;
      this.currentStudent = await this.studentService.getStudent(this.userId, this.idStudent)
      if (!this.currentStudent) {
        this.router.navigateByUrl('/student')
          .then(() => this.alertService.error(this.translateService.instant('student.not_found')))
      }
      if (this.id) {
        this.completionRecord = await this.completionRecordService.getCompletionRecord(this.userId, this.idStudent, this.id)

        if (!this.completionRecord) {
          this.router.navigateByUrl(`/student/${this.idStudent}/completion-records/${this.course}`)
            .then(() => this.alertService.error(this.translateService.instant('student.completionRecord.not_found')))
        }

        this.setValueForm(this.completionRecord);
      }
    }

    const title: string = this.id ? 'student.completionRecord.update.title' : 'student.completionRecord.create.title';

    this.translate.get([
      'student.listStudent.title',
      'student.completionRecord.list.title',
      title
    ]).subscribe(translations => {
      this.breadcrumbItems = [
        { label: translations['student.listStudent.title'], url: '/student' },
        { label: translations['student.completionRecord.list.title'], url: `/student/${this.idStudent}/completion-records/${this.course}` },
        { label: translations[title], url: `` },
      ];
    });
  }

  public redirectBack(): void {
    let params = {};

    if (this.userId && (this.userId !== this.currentUser.uid)) {
      params = { ...{ user_id: this.userId ?? '' } };
    }

    this.router.navigate(
      [`/student/${this.idStudent}/completion-records/${this.course}`],
      {
        queryParams: params
      });
  }

  private setDate(date: any): string | Date {
    return date ? new Date((date as Timestamp).toDate()) : '';
  }

  private setValueForm(completionRecord: ICompletionRecord) {
    this.f['examDate'].setValue(this.setDate(completionRecord.examDate));
    this.f['examName'].setValue(completionRecord.examName);
    this.f['airframeTitle'].setValue(completionRecord.airframeTitle);
    this.f['airframeNumber'].setValue(completionRecord.airframeNumber);
    this.f['subject1'].setValue(completionRecord.subject1);
    this.f['subject2'].setValue(completionRecord.subject2);
    this.f['subject3_1'].setValue(completionRecord.subject3_1);
    this.f['subject3_1_title'].setValue(completionRecord.subject3_1_title);

    this.f['subject3_2'].setValue(completionRecord.subject3_1);
    this.f['subject3_2_title'].setValue(completionRecord.subject3_1_title);

    this.f['subject3_3'].setValue(completionRecord.subject3_3);
    this.f['subject3_3_title'].setValue(completionRecord.subject3_3_title);

    this.f['subject4'].setValue(completionRecord.subject4);
    this.f['subject5'].setValue(completionRecord.subject5);
    this.f['subject6_1'].setValue(completionRecord.subject6_1);
    this.f['subject6_2'].setValue(completionRecord.subject6_2);

    this.f['remark'].setValue(completionRecord.remark);
    this.f['score'].setValue(completionRecord.score);
    this.f['examPass'].setValue(completionRecord.examPass);
    this.f['examPass'].setValue(completionRecord.examPass);
  }
  
  handleAddItems(event: any, fieldName: string) {
    if (event.items.length > 0) {
      let value = '';
      event.items.forEach((item: any) => {
        if (value == '') {
          value = item.itemName;
        } else {
          value += '、' + item.itemName;
        }
      });
      this.f[fieldName].setValue(this.f[fieldName].value == ''
        ? value
        : this.f[fieldName].value + '、' + value);
    }
  }

  handleAddairframeTitleItems(event: any) {
    if (event.items.length > 0) {
      let title = '';
      let registrationNumber = '';
      event.items.forEach((item: any) => {
        if (title == '') {
          title = item.itemName;
          registrationNumber = item.registrationNumber;
        } else {
          title += '、' + item.itemName;
          registrationNumber += '、' + item.registrationNumber;
        }
      });
      this.f['airframeTitle'].setValue(this.f['airframeTitle'].value == ''
        ? title
        : this.f['airframeTitle'].value + '、' + title);
      this.f['airframeNumber'].setValue(this.f['airframeNumber'].value == ''
      ? registrationNumber
      : this.f['airframeNumber'].value + '、' + registrationNumber);
    }
  }

  public submit(): void {
    this.changeScore();

    for (const key of Object.keys(this.formCreateCompletionRecord.controls)) {
      if (this.formCreateCompletionRecord.controls[key].invalid) {
        const invalidControl = this.elementRef.nativeElement.querySelector('#' + key);
        invalidControl.focus();
        break;
      }
    }
    this.submitted = true;

    if (!this.isRequest && this.submitted && this.formCreateCompletionRecord.valid) {
      this.isRequest = true
      const completionRecord: ICompletionRecord = { ...this.formCreateCompletionRecord.getRawValue() }
      completionRecord.course = this.course
      if (this.id) {
        completionRecord.createAt = this.completionRecord.createAt
        this.completionRecordService.createSyncCompletionRecord(completionRecord, this.currentUser.uid, this.idStudent, this.id)
          .then(() => {
            this.redirectBack();
            this.alertService.success(this.translateService.instant('student.completionRecord.update.success'))
          }).catch(() => this.alertService.error(this.translateService.instant('student.completionRecord.update.error')))
      } else {
        const idCompletionRecord = ulid();
        completionRecord.createAt = new Date()
        this.completionRecordService.createSyncCompletionRecord(completionRecord, this.currentUser.uid, this.idStudent, idCompletionRecord)
          .then(() => {
            this.redirectBack();
            this.alertService.success(this.translateService.instant('student.completionRecord.create.success'))
          }).catch(() => this.alertService.error(this.translateService.instant('student.completionRecord.create.error')))
          .finally(() => this.isRequest = false);
      }
    }
  }

  private async getUser(): Promise<void> {
    const currentUser: firebase.User | null = await this.auth.currentUser;
    if (currentUser) this.currentUser = currentUser;
  }
}
