import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NotifyPopupComponent } from '../notify-popup/notify-popup.component';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-header-user',
  templateUrl: './header-user.component.html',
  styleUrls: ['./header-user.component.scss'],
})
export class HeaderUserComponent implements OnInit {
  protected menuData: any;
  protected jsonFileUrl: string = '/assets/json/menu.json';
  protected sidebarTitle: string = '';
  protected navItems: any;
  @ViewChild('notifyPopupComponent')
  notifyPopupComponent!: NotifyPopupComponent;

  constructor(public router: Router, private renderer: Renderer2, private http: HttpClient) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const body = document.querySelector('body');
        if (body) body.classList.remove('open-menu');
      }
    });
  }

  ngOnInit(): void {
    this.http.get(this.jsonFileUrl).subscribe(res => {
      this.menuData = res;
      this.sidebarTitle = this.menuData?.meta?.name;
      this.navItems = this.menuData?.objects;
    });
  }

  toggleNotifications(): void {
    this.notifyPopupComponent.toggle();
  }

  onClickMenu(event: any): void {
    const checked = event.target.checked;
    console.log(checked)
    if (checked) {
      this.renderer.addClass(document.body, 'open-menu');
      this.renderer.setStyle(document.body, 'overflow', 'hidden');
    } else {
      this.renderer.removeClass(document.body, 'open-menu');
      this.renderer.setStyle(document.body, 'overflow', 'auto');
    }
  }
}
