<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <div class="custom-container">
    <div class="custom__header">
      <c-col class="justify-content-center mb-4">
        <c-row>
          <c-col lg="6">
            <h3>{{ 'student.listStudent.title' | translate }}</h3>
          </c-col>
          <c-col lg="6">
            <div class="list-btn">
              <a href="/#/student/approval" class="form-create-data btn-common btn-title btn-inp">
                <span>{{ 'common.approval_list_btn' | translate }}</span>
              </a>
              <label class="link btn-common btn-title custom-file-label btn-inp">
                <input type="file" (change)="onFileChange($event)" class="custom-file-input">
                CSV入力
              </label>
              <a class="link btn-common btn-title btn-title-export btn-inp" (click)="exportCsvStudent()">
                <span>{{ 'common.export_btn' | translate }}</span>
              </a>
              <a href="/#/student/create" class="form-create-data btn-common btn-title btn-title-create btn-inp">
                <span>{{ 'common.create_btn' | translate }}</span>
              </a>
            </div>
          </c-col>
        </c-row>
      </c-col>
      <c-col class="mb-5">
        <c-card class="card-no-border">
          <c-card-body>
            <form cForm [formGroup]="formSearch" (ngSubmit)="search()">
              <c-col class="mb-3 d-flex justify-content-between">
                <span>{{ 'form.index.searchTitle' | translate }}</span>
                <span class="button-collapse" (click)="toggleCollapse()">
                  <img class="caret-down" [class]="{'caret-down-open': visibleCollapse}" height="20" width="20" src="assets/svg/caret-down.svg" alt="caret-down">
                </span>
              </c-col>
              <div [visible]="visibleCollapse" cCollapse>
                <c-col class="mb-2 d-flex align-items-center col-border">
                  <c-input-group class="d-flex align-items-center">
                    <label for="querySearch">
                      <img src="assets/svg/flight-record/icon-search.svg" alt="icon">
                    </label>
                    <input cFormControl formControlName="querySearch" placeholder="" name="querySearch" id="querySearch" class="input-search-flight">
                  </c-input-group>
                </c-col>
                <c-col>
                  <app-input-checkbox
                    [label]="'student.listStudent.cerf_code' | translate"
                    class="input-group-container"
                    [formControlNameInput]="'cerf_code'"
                    [form]="formSearch.controls"
                    [formGroup]="formSearch"
                    [requiredText]="false"
                  ></app-input-checkbox>
                </c-col>
                <c-col class="gap-2 search__date">
                  <c-col class="mb-6">
                    <c-col class="mb-3">{{ 'form.index.training_start_time' | translate }}</c-col>
                    <c-col class="select-date">
                      <c-date-picker [locale]="currentDatepickerLocale" [placeholder]="formatDatePicker" class="box-select-date" formControlName="startDate" [dayFormat]="dayFormat"></c-date-picker>
                    </c-col>
                  </c-col>
                  <c-col class="mb-6">
                    <c-col class="mb-3 label-none">&nbsp;</c-col>
                    <c-col class="select-date">
                      <c-date-picker [locale]="currentDatepickerLocale" [placeholder]="formatDatePicker" formControlName="endDate" [dayFormat]="dayFormat" class="date-time-date box-select-date"></c-date-picker>
                    </c-col>
                  </c-col>
                </c-col>
                <div class="search_color">
                  <div class="search_color--first">
                    <button type="button" class="button-all check-all-color" [class.active]="checkOptionAll('level')" (click)="onUncheckOptions('level')">
                      {{ 'form.inputLabel.all' | translate }}
                    </button>
                    <div class="color_list">
                      <div *ngFor="let colorName of levels; let i = index" class="color_item">
                        <input formControlName="level" [value]="colorName.name" class="check-box-color d-none" id="color{{ colorName.name }}" name="level" type="radio"/>
                        <label
                          cFormCheckLabel
                          for="color{{ colorName.name }}"
                          class="color-icon"
                          [style.--border-color]="colorName.border ? '1px solid #667085' : 'none'"
                          [style.--color]="colorName.code"
                          [style.--background-color]="colorName.code"
                          [style.border]="colorName.border ? '1px solid #667085' : 'none'">
                        </label>
                        <label class="cursor-pointer" cFormCheckLabel for="color{{ colorName.name }}">{{ colorName.name }}</label>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="courseItems.length > 0" class="search_color--three">
                    <button type="button" class="button-all check-all-color" [class.active]="checkOptionAll('courseItems')" (click)="onUncheckOptions('courseItems')">
                      {{ 'form.inputLabel.all' | translate }}
                    </button>
                    <div class="color_list">
                      <div *ngFor="let item of courseItems; let i = index" class="color_item">
                        <input formControlName="courseItems" [value]="item.itemNumber" class="check-box-color d-none" id="courseItems{{ item.itemNumber }}" type="radio"/>
                        <label
                          cFormCheckLabel
                          for="courseItems{{ item.itemNumber }}"
                          class="color-icon"
                          [style.--border-color]="'1px solid #667085'"
                          [style.--color]="item.color"
                          [style.--background-color]="item.color"
                          [style.border]="'1px solid #667085'">
                        </label>
                        <label class="cursor-pointer" cFormCheckLabel for="color{{ item.itemName }}">{{ item.itemName }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="search_color--two">
                    <button type="button" class="button-all check-all-color" [class.active]="checkOptionAll('status')" (click)="onUncheckOptions('status')">
                      {{ 'form.inputLabel.all' | translate }}
                    </button>
                    <div *ngFor="let status of status" class="color_item_status">
                      <input formControlName="status" [value]="status.name" class="check-box-color d-none" id="color2{{ status.status }}"  name="status" type="radio"/>
                      <label
                        cFormCheckLabel
                        for="color2{{ status.status }}"
                        class="color-icon-status"
                        [style.--border-color]="'1px solid #667085'"
                        [style.border]="'1px solid #667085'">
                      </label>
                      <label class="cursor-pointer" cFormCheckLabel for="color2{{ status.status }}">{{ status.name }}</label>
                    </div>
                  </div>
                  <div class="search_color--two">
                    <button type="button" class="button-all check-all-color" [class.active]="checkOptionAll('statusElearning')" (click)="onUncheckOptions('statusElearning')">
                      {{ 'form.inputLabel.all' | translate }}
                    </button>
                    <div *ngFor="let statusElearning of statusElearning" class="color_item_status">
                      <input formControlName="statusElearning" [value]="statusElearning.name" class="check-box-color d-none" id="color2{{ statusElearning.status }}"  name="statusElearning" type="radio"/>
                      <label
                        cFormCheckLabel
                        for="color2{{ statusElearning.status }}"
                        class="color-icon-status"
                        [style.--border-color]="'1px solid #667085'"
                        [style.border]="'1px solid #667085'">
                      </label>
                      <label class="cursor-pointer" cFormCheckLabel for="color2{{ statusElearning.status }}">{{ statusElearning.name }}</label>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-end mt-3">
                  <button class="btn-common button-clear btn-search " type="submit">{{ 'form.index.btn_search' |translate }}</button>
                </div>
              </div>
            </form>
          </c-card-body>
        </c-card>
      </c-col>
    </div>
    <div class="custom__body">
      <div class="number_filter">{{ "student.listStudent.number_filter" | translate : { number: numberStudentFilter } }}</div>
      <app-student-item
        *ngFor="let item of students| paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }"
        [itemInput]="item"
        (changeVisible)="handleChangeCollapseStudent($event)"
        (updateStatusRequestReleased)="handleUpdateStatusRequestReleased($event)"
        [studentVisibleArr]="studentVisibleArr"
        [dataCurrentUser]="dataCurrentUser"
        [currentUser]="currentUser">
      </app-student-item>
    </div>
  </div>
  <!-- Pagination -->
  <app-pagination
    *ngIf="students && students.length != 0"
    (handleChangePage)="changePage($event)">
  </app-pagination>
  <app-loading [isLoading]="isLoading"></app-loading>
</c-container>
