<app-layout-auth>
  <img id="logo" src="./assets/img/logo-forgot-password.png" alt="logo" width="342" height="278" />
  <h1>{{ 'forgot-password.title' | translate }}</h1>
  <form [formGroup]="formForgotPassword" (ngSubmit)="forgotPassword()" novalidate>
    <app-input-email
      class="input-group-container"
      [formControlNameInput]="'email'"
      [placeHolderInput]="'forgot-password.email' | translate"
      [ngClass]="{
        'is-invalid': (submitted || f['email'].touched) && f['email'].invalid,
        'is-valid': f['email'].touched && f['email'].valid
      }"
      [submitted]="submitted"
      [form]="f"
    ></app-input-email>
    <button cButton [disabled]="loading" type="submit">{{ 'forgot-password.reset' | translate }}</button>
  </form>
</app-layout-auth>
