<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <div class="custom-container">
    <c-col class="justify-content-center mt-4 mb-3">
      <div class="box-title">
        <h3>見積一覧</h3>
        <div class="d-flex justify-content-between btn-row">
          <div class="d-flex gap-10px">
            <a href="/#/quotation/create" class="form-create-data btn-common btn-title btn-title-create">
              <span>{{ 'common.create_btn' | translate }}</span>
            </a>
          </div>
        </div>
      </div>
    </c-col>
    <c-col class="mb-5">
      <c-card class="card-no-border">
        <c-card-body>
          <form cForm [formGroup]="formSearch" (ngSubmit)="search()">
            <c-col class="mb-3 d-flex justify-content-between">
              <span>{{ 'form.index.searchTitle' | translate }}</span>
              <span class="button-collapse" (click)="toggleCollapse()">
                <img class="caret-down" [class]="{'caret-down-open': visibleCollapse}" height="20" width="20" src="assets/svg/caret-down.svg" alt="caret-down">
              </span>
            </c-col>
            <div [visible]="visibleCollapse" cCollapse>
              <c-col class="mb-2 d-flex align-items-center col-border">
                <c-input-group class="d-flex align-items-center">
                  <label for="querySearch">
                    <img src="assets/svg/flight-record/icon-search.svg" alt="icon">
                  </label>
                  <input cFormControl formControlName="querySearch" placeholder="" name="querySearch" id="querySearch" class="input-search-flight">
                </c-input-group>
              </c-col>
              <c-col class="gap-2 search__date">
                <c-col class="mb-6">
                  <c-col class="mb-3">見積作成日</c-col>
                  <c-col class="select-date">
                    <c-date-picker [locale]="currentDatepickerLocale" [placeholder]="formatDatePicker" class="box-select-date" formControlName="startDate" [dayFormat]="dayFormat"></c-date-picker>
                  </c-col>
                </c-col>
                <c-col class="mb-6">
                  <c-col class="mb-3 label-none">&nbsp;</c-col>
                  <c-col class="select-date">
                    <c-date-picker [locale]="currentDatepickerLocale" [placeholder]="formatDatePicker" formControlName="endDate" [dayFormat]="dayFormat" class="date-time-date box-select-date"></c-date-picker>
                  </c-col>
                </c-col>
              </c-col>
              <div class="search_color--two">
                <div class="container">
                  <c-row>
                    <c-col [lg]="2">
                      <button type="button" class="button-all check-all-color" [class.active]="checkOptionAll('quotationItem')" (click)="onUncheckOptions('quotationItem')">
                        {{ 'form.inputLabel.all' | translate }}
                    </button>
                    </c-col>
                    <c-col [lg]="10">
                      <c-row>
                          <c-col *ngFor="let quotationItem of dataListQuotationItem" [md]="6">
                            <div class="color-item-quotationItem mb-2">
                                <input formControlName="quotationItem" [value]="quotationItem.id" class="check-box-color d-none" id="color2{{ quotationItem.id }}" name="quotationItem" type="radio"/>
                                <label
                                    cFormCheckLabel
                                    for="color2{{ quotationItem.id }}"
                                    class="color-icon-quotationItem"
                                    [style.--border-color]="'1px solid #667085'"
                                    [style.border]="'1px solid #667085'">
                                </label>
                                <label class="cursor-pointer" cFormCheckLabel for="color2{{ quotationItem.id }}">{{ quotationItem.itemName }}</label>
                            </div>
                        </c-col>
                      </c-row>
                    </c-col>

                  </c-row>
                </div>
              </div>
              <div class="d-flex justify-content-end mt-3">
                <button class="btn-common button-clear btn-search " type="submit">{{ 'form.index.btn_search' |translate }}</button>
              </div>
            </div>
          </form>
        </c-card-body>
      </c-card>
    </c-col>
    <div class="custom__body">
      <div class="number_filter">{{ "student.listStudent.number_filter_quotation" | translate : { number: listQuotationStudentLength } }}</div>
      <app-quotation-student-item
        *ngFor="let item of listQuotationStudent"
        [itemInput]="item"
        (deleteItem)="handleDeleteQuotationByStudent($event)"

      >
      </app-quotation-student-item>
    </div>
  </div>
</c-container>
