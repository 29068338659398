export interface IDataOption {
  value: string,
  label: string
}

export interface HonorificTitleOption {
  id: string,
  value: string
}

export interface IDataOptionLabelName {
  label: string,
  name: string,
}

export interface IDataOptionStudentCourse {
  key: string,
  firstKey: string,
  masterKey: string
}
