import { Component, OnInit } from '@angular/core';
import { AlertService, NotificationService, UserService, FirebaseAnalyticService } from '@app/services';
import { INotification as INotificationBase } from '@app/models';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Subject, takeUntil } from 'rxjs';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { TranslateService } from '@ngx-translate/core';

interface INotification extends INotificationBase {
  isReadMore?: boolean;
}

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject();
  protected email: any;
  protected id: any;
  protected currentPage: number = 0;
  protected readonly perPage: number = 10;
  protected data: INotification[][] = [];
  protected notifications: INotification[] = [];
  protected isDisableNextPage: boolean = false;
  protected isDisablePreviousPage: boolean = false;
  protected isOnePage: boolean = true;

  constructor(
    private notificationService: NotificationService,
    private angularFireAuth: AngularFireAuth,
    private userService: UserService,
    private alertService: AlertService,
    private analytics: AngularFireAnalytics,
    private firebaseAnalyticService: FirebaseAnalyticService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.analytics.logEvent(this.firebaseAnalyticService.EVENT_NAME.TAB_NOTIFICATION, {
      'content_type': this.firebaseAnalyticService.CONTENT_TYPE,
      'develop_type': this.firebaseAnalyticService.TYPE,
    });
    this.currentUser().then(() => {
      this.notificationService.getAll(this.email, this.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response) => {
            const dataResponse = response.map((a) => {
              const data = a.payload.doc.data() as INotification;
              data.timeAgo = this.notificationService.timeSince(new Date(data.createdAt!.seconds * 1000));
              this.translateService.onLangChange.subscribe(() => {
                data.timeAgo = this.notificationService.timeSince(new Date(data.createdAt!.seconds * 1000));
              });
              data.id = a.payload.doc.id;
              data.isReadMore = this.isReadMore(data);
              return data;
            });
            this.data = [];
            for (let i = 0; i < dataResponse.length; i += this.perPage) {
              this.data.push(dataResponse.slice(i, i + this.perPage));
            }
            this.isOnePage = this.data.length <= 1;

            if (this.currentPage === 0) this.isDisablePreviousPage = true;
            else if (!this.data[this.currentPage]) this.currentPage--;

            this.notifications = this.data[this.currentPage];
          },
          error: (error) => {
            console.log(error);
          }
        });
    });
  }

  private async currentUser() {
    const currentUser = await this.angularFireAuth.currentUser;
    if (currentUser) {
      this.email = currentUser.email;
      this.id = await this.userService.getDocId(this.email);
    }
  }

  delete(notification: INotification) {
    this.notificationService.delete(this.email, this.id, notification.id).then(() => {
      this.showAlert(this.translateService.instant('notification.removedNotification'));
    });
  }

  private showAlert(msg: string) {
    this.alertService.clear();
    this.alertService.success(msg);
  }

  private isReadMore(notification: INotification) {
    let item: INotification | undefined;
    for (let index = 0; index < this.data.length; index++) {
      item = this.data[index].find((item) => item.id === notification.id);
      if (item) break;
    }
    return !!item?.isReadMore;
  }

  toggleReadMore(notification: INotification) {
    notification.isReadMore = !notification.isReadMore;
  }

  readMark(notification: INotification) {
    this.notificationService.readMark(this.email, this.id, notification.id).then();
  }

  readMarkAll() {
    this.notifications.forEach(notification => {
      if (!notification.isRead) this.readMark(notification);
    });
    this.analytics.logEvent(this.firebaseAnalyticService.EVENT_NAME.MARK_ALL_READ, {
      'content_type': this.firebaseAnalyticService.CONTENT_TYPE,
      'develop_type': this.firebaseAnalyticService.TYPE,
    });
  }

  nextPage() {
    this.isDisablePreviousPage = false;
    if (this.currentPage < (this.data.length - 1)) {
      this.currentPage++;
      this.notifications = this.data[this.currentPage];
    }
    if (this.currentPage == (this.data.length - 1)) {
      this.isDisableNextPage = true;
      this.notifications = this.data[this.currentPage];
    }
  }

  previousPage() {
    this.isDisableNextPage = false;
    if ((this.data.length - 1) >= this.currentPage) {
      this.currentPage--;
      this.notifications = this.data[this.currentPage];
    }
    if (this.currentPage == 0) {
      this.isDisablePreviousPage = true;
      this.notifications = this.data[this.currentPage];
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
