<c-container class="min-vh-90">
  <app-header-user></app-header-user>
  <div class="custom-container">
    <c-col [md]="12" class="mb-4 d-flex justify-content-between align-items-center" id="mainTitle">
      <c-col>
        <h4 class="mb-0">{{ 'notification.notify'|translate }}</h4>
      </c-col>
      <c-col class="d-flex justify-content-end">
        <button class="btn-notification-action text-color-base pointer-event" (click)="readMarkAll()">
          <img cImg src="./assets/svg/mark-as-read-active.svg" alt="mark as read" />
          <span class="mb-0 ms-2 text-color-base pointer-event" (click)="readMarkAll()">
            {{ 'notification.readAll'|translate }}
          </span>
        </button>
      </c-col>
    </c-col>
    <c-col [md]="12">
      <c-card>
        <c-card-body class="p-4">
          <c-col *ngFor="let notification of notifications; index as i" class="list-notification">
            <c-col class="py-2 d-flex align-items-start notification" *ngIf="!notification.isDelete">
              <!-- Left -->
              <c-col [lg]="1" [md]="2" class="d-flex notification__logo">
                <img *ngIf="!notification.isRead" class="notification__logo__dot" cImg
                  src="assets/svg/notification/dot.svg" alt="dot" />
                <div *ngIf="notification.isRead" class="notification__logo__dot"></div>
                <img cImg src="assets/svg/header/bell.svg" alt="password reset image" />
              </c-col>
              <c-col [lg]="9" [md]="8" class="ps-3 flex-grow-1">
                <h5 class="mb-1 notification__title" [ngClass]="{ 'notification__title--unread': !notification.isRead }">
                  {{ notification.messageTitle }}
                </h5>
                <div class="mb-0 notification__body" (click)="toggleReadMore(notification)"
                  [ngClass]="{ 'long-text': !notification.isReadMore }" [innerHTML]="notification.messageBody"></div>
              </c-col>
              <!-- Right -->
              <c-col [lg]="2" [md]="2" class="d-flex justify-content-end align-items-center notification__right">
                <div class="mb-0 notification__time" [ngClass]="{ 'notification__time--now': notification.timeAgo === '今' }">
                  {{ notification.timeAgo }}
                </div>
                <div class="notification-action d-none">
                  <c-dropdown direction="dropstart">
                    <button cDropdownToggle class="btn-notification-action">
                      <img cImg src="assets/svg/notification-more-grey.svg" alt="notification more" />
                    </button>
                    <ul cDropdownMenu>
                      <li *ngIf="!notification.isRead">
                        <button cDropdownItem (click)="readMark(notification)">
                          <img cImg src="assets/svg/mark-as-read-grey.svg" alt="mark as read" />
                          <span class="ms-2">{{ 'notification.markAsRead'|translate }}</span>
                        </button>
                      </li>
                      <li>
                        <button cDropdownItem (click)="delete(notification)">
                          <img cImg src="assets/svg/trash.svg" alt="delete notification" />
                          <span class="ms-2">{{ 'notification.removeNotification'|translate }}</span>
                        </button>
                      </li>
                    </ul>
                  </c-dropdown>
                </div>
              </c-col>
            </c-col>
          </c-col>
          <c-col class="mt-4 d-flex justify-content-end" *ngIf="!isOnePage">
            <button class="btn-pagination me-3" [disabled]="isDisablePreviousPage" (click)="previousPage()">
              {{ 'notification.previous'|translate }}
            </button>
            <button class="btn-pagination" [disabled]="isDisableNextPage" (click)="nextPage()">
              {{ 'notification.next'|translate }}
            </button>
          </c-col>
        </c-card-body>
      </c-card>
    </c-col>
  </div>
</c-container>
