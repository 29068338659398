import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { formatDatePicker } from '@app/constants';
import { formatDate } from "@app/helpers";

@Component({
  selector: 'app-quotation-student-item',
  templateUrl: './quotation-student-item.component.html',
  styleUrls: ['./quotation-student-item.component.scss']
})
export class QuotationStudentItemComponent implements OnInit {
  @Input('itemInput') itemInput: any;

  @Output('deleteItem') deleteItem: EventEmitter<any> = new EventEmitter<any>();

  protected color: string = '';
  protected border: string = '1px solid #667085';
  protected readonly formatDate = formatDate;
  protected readonly formatDatePicker: string = formatDatePicker;

  constructor() { }

  ngOnInit(): void {}

  public handleDeleteQuotationStudent(deleteStatus: boolean): void {
    if (deleteStatus) {
      this.deleteItem.emit({id: this.itemInput.id});
    }
  }

}
