<div class="mb-3 form-radio">
  <label *ngIf="label !== ''" class="form-label mb-1">
    {{ label }}
    <c-badge *ngIf="requiredText" color="danger">{{ "form.inputLabel.must" | translate}}</c-badge>
  </label>
  <div class="flex-lg-row d-flex">
    <c-form-check [style.padding-right]="'15px'" *ngFor="let item of dataOption">
      <input
        cFormCheckInput
        type="radio"
        [value]="item?.value"
        [formControlName]="formControlNameInput"
        [name]="formControlNameInput"
        id="{{ formControlNameInput + item?.value }}"
        (change)="changeRadio(item?.value)"
        [attr.disabled]="isDisable ? true : null"
      />
      <label cFormCheckLabel for="{{ formControlNameInput + item?.value }}" class="label_radio">{{ item?.label }}</label>
    </c-form-check>
  </div>
  <c-form-feedback *ngIf="(submitted || form[formControlNameInput]?.touched) && form[formControlNameInput].errors">
    <div *ngIf="form[formControlNameInput]?.errors[getFirstError(form[formControlNameInput]?.errors) || '']">
      <div *ngIf="getFirstError(form[formControlNameInput]?.errors) === 'required'">
        {{ "errorMessages.formValidate.input.required" | translate }}
      </div>
    </div>
  </c-form-feedback>
</div>
