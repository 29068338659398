import { AlertType } from '../constants';

export class Alert {
  id: string = '';
  type: AlertType = 0;
  message: string | undefined;
  autoClose: boolean | undefined;
  keepAfterRouteChange: boolean | undefined;
  fade: boolean | undefined;

  constructor(init?: Partial<Alert>) {
    Object.assign(this, init);
  }
}
