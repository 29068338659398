import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  GENDER_OPTION,
  IDataOption,
  NOT_ISSUED,
  STUDENT,
  TEXT_PASS,
  YES,
  academicExamStatus,
  statusCompletionOption,
  statusElearning,
  useElearning
} from '@app/constants';
import { AlertService, StudentService, UserService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-student-basic',
  templateUrl: './student-basic.component.html',
  styleUrls: ['./student-basic.component.scss'],
})
export class StudentBasicComponent implements OnInit, OnChanges {
  @Input('formGroup') formGroup: any;
  @Input('form') form: any = [];
  @Input('imageUrl') imageUrl: string = '';
  @Input('studentId') studentId: string | null = '';
  @Input('submitted') submitted: boolean = false;
  @Input('eLearnings') eLearnings: boolean = true;
  @Input('learningStatus') learningStatus: string = '';
  @Input('courseItems') courseItems: any = [];
  @ViewChild('fileInput') fileInput!: ElementRef;
  @Output() fileEvent = new EventEmitter<string>();
  protected readonly gender: IDataOption[] = GENDER_OPTION;
  protected readonly status: IDataOption[] = statusCompletionOption;
  protected readonly statusElearning: IDataOption[] = statusElearning;
  protected readonly useElearning: IDataOption[] = useElearning;
  protected readonly academicExamStatus: IDataOption[] = academicExamStatus;
  protected dataElearning: any = {};
  protected currentUser: any;
  protected readonly STUDENT = STUDENT;
  protected readonly TEXT_PASS: string = TEXT_PASS;
  protected isLoading: boolean = false;

  constructor(
    private studentService: StudentService,
    private userService: UserService,
    private router: Router,
    private alertService: AlertService,
    private translateService: TranslateService,
    private auth: AngularFireAuth,
  ) {}

  ngOnInit(): void {
    this.getUser();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes['eLearnings']) {
      this.dataElearning = {
        ...this.dataElearning,
        useElearning: changes['eLearnings'].currentValue,
      };
    };
    if (!!changes['learningStatus']) {
      this.dataElearning = {
        ...this.dataElearning,
        statusElearning: changes['learningStatus'].currentValue
      };
    };
  }

  async getUser(): Promise<void> {
    const currentUser = await this.auth.currentUser;
    if (currentUser) this.currentUser = currentUser;
  }

  onDataFileUpload(file: any): void {
    this.fileEvent.emit(file);
  }

  public changeUseLearning(value: any): void {
    this.eLearnings = value === YES;

    this.dataElearning = {
      useElearning: this.eLearnings,
      statusElearning: this.eLearnings ? NOT_ISSUED : '',
    };

    this.form['statusElearning'].setValue(this.eLearnings ? NOT_ISSUED : '');
  }

  public changeAccountLearning(value: any): void {
    this.dataElearning = {
      ...this.dataElearning,
      statusElearning: value
    };
  }

  public changeAcademicExamStatus(value: any): void {
    if (value === '合格済') {
      this.form['academicExamPassDate'].setValidators([Validators.required]);
      this.form['academicExamPassDate'].updateValueAndValidity();
      this.form['academicExamPassCertificateNumber'].setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(15)]);
      this.form['academicExamPassCertificateNumber'].updateValueAndValidity();
    } else {
      this.form['academicExamPassDate'].setValidators([]);
      this.form['academicExamPassDate'].updateValueAndValidity();
      this.form['academicExamPassCertificateNumber'].setValidators([]);
      this.form['academicExamPassCertificateNumber'].updateValueAndValidity();
    }
  }

  async submitLearning() {
    this.isLoading = true;
    try {
      await this.userService.updateUserLearning(this.currentUser.uid, this.dataElearning);

      if (this.studentId) {
        await this.studentService.updateLearning(this.currentUser.uid, this.studentId, this.dataElearning);
      }

      this.alertService.success(this.translateService.instant('student.editStudent.success'));
    } catch (error) {
      const errorMessage = this.translateService.instant('student.editStudent.error');
      this.alertService.error(errorMessage);
    }
    this.isLoading = false;
  }
}
