<c-col
  class="d-flex common-header"
  [ngClass]="{
    'common-header--blue-25': router.isActive('/account/update-password', true)
  }"
>
  <c-col [lg]="3" [md]="4">
    <a class="d-flex align-items-center" id="header__left" routerLink="/student">
      <img cImg src="assets/img/logo.png" alt="logo" width="40" height="40" />
      <h5 class="mb-0 ps-3" id="header__title">{{ "layout.header.title" | translate }}</h5>
    </a>
  </c-col>
  <c-col [lg]="9" [md]="8" class="menu-pc d-flex align-items-center justify-content-end" >
<!--    <div class="me-4">-->
<!--      <a-->
<!--        class="btn-header"-->
<!--        routerLink="/flight"-->
<!--        routerLinkActive="btn-header&#45;&#45;active"-->
<!--        [routerLinkActiveOptions]="{ exact: false }"-->
<!--      >-->
<!--        <img cImg src="assets/svg/header/report.svg" alt="record" width="24" height="24"/>-->
<!--        <span class="ps-2">{{ "layout.header.menu.record" | translate }}</span>-->
<!--      </a>-->
<!--    </div>-->
<!--    <div class="me-4">-->
<!--      <a-->
<!--        class="btn-header"-->
<!--        routerLink="/airframe"-->
<!--        routerLinkActive="btn-header&#45;&#45;active"-->
<!--        [routerLinkActiveOptions]="{ exact: false }"-->
<!--      >-->
<!--        <img cImg src="assets/svg/header/airframe.svg" alt="airframe" width="24" height="24"/>-->
<!--        <span class="ps-2">{{ "layout.header.menu.airframe" | translate }}</span>-->
<!--      </a>-->
<!--    </div>-->
    <div class="me-4">
      <a
        class="btn-header"
        routerLink="/quotation"
        routerLinkActive="btn-header--active"
        [routerLinkActiveOptions]="{ exact: false }"
      >
        <img cImg src="assets/svg/header/report.svg" alt="report" width="24" height="24"/>
        <span class="ps-2">{{ "layout.header.menu.quotation" | translate }}</span>
      </a>
    </div>
    <div class="me-4">
      <a
        class="btn-header"
        routerLink="/student"
        routerLinkActive="btn-header--active"
        [routerLinkActiveOptions]="{ exact: false }"
      >
        <img cImg src="assets/svg/header/report.svg" alt="report" width="24" height="24"/>
        <span class="ps-2">{{ "layout.header.menu.student" | translate }}</span>
      </a>
    </div>
    <div class="me-4">
      <a
        class="btn-header"
        routerLink="/account"
        routerLinkActive="btn-header--active"
        [routerLinkActiveOptions]="{ exact: false }"
      >
        <img cImg src="assets/svg/header/account.svg" alt="account" width="24" height="24"/>
        <span class="ps-2">{{ "layout.header.menu.account" | translate }}</span>
      </a>
    </div>
    <div>
      <button
        class="btn-header"
        [ngClass]="{
          'btn-header--active': router.isActive('/notification', true)
        }"
        id="notifications-button"
        (click)="toggleNotifications()"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4"
            d="M20.3992 16.33C20.1192 17.08 19.5292 17.65 18.7592 17.91C17.6792 18.27 16.5692 18.54 15.4492 18.73C15.3392 18.75 15.2292 18.7701 15.1192 18.7801C14.9392 18.8101 14.7592 18.83 14.5792 18.85C14.3592 18.8801 14.1292 18.9 13.8992 18.92C13.2692 18.97 12.6492 19 12.0192 19C11.3792 19 10.7392 18.97 10.1092 18.91C9.83923 18.89 9.57923 18.8601 9.31923 18.82C9.16923 18.8 9.01923 18.7801 8.87923 18.76C8.76923 18.74 8.65923 18.73 8.54923 18.71C7.43923 18.53 6.33923 18.26 5.26923 17.9C4.46923 17.63 3.85923 17.06 3.58923 16.33C3.31923 15.61 3.41923 14.77 3.84923 14.05L4.97923 12.17C5.21923 11.76 5.43923 10.97 5.43923 10.49V8.63005C5.43923 5.00005 8.38923 2.05005 12.0192 2.05005C15.6392 2.05005 18.5892 5.00005 18.5892 8.63005V10.49C18.5892 10.97 18.8092 11.76 19.0592 12.17L20.1892 14.05C20.5992 14.75 20.6792 15.57 20.3992 16.33Z" />
          <path
            d="M12.0002 10.76C11.5802 10.76 11.2402 10.42 11.2402 10V6.90001C11.2402 6.48001 11.5802 6.14001 12.0002 6.14001C12.4202 6.14001 12.7602 6.48001 12.7602 6.90001V10C12.7502 10.42 12.4102 10.76 12.0002 10.76Z" />
          <path
            d="M14.8297 20.01C14.4097 21.17 13.2997 22 11.9997 22C11.2097 22 10.4297 21.68 9.87969 21.11C9.55969 20.81 9.31969 20.41 9.17969 20C9.30969 20.02 9.43969 20.03 9.57969 20.05C9.80969 20.08 10.0497 20.11 10.2897 20.13C10.8597 20.18 11.4397 20.21 12.0197 20.21C12.5897 20.21 13.1597 20.18 13.7197 20.13C13.9297 20.11 14.1397 20.1 14.3397 20.07C14.4997 20.05 14.6597 20.03 14.8297 20.01Z" />
          <circle cx="18" cy="4.5" r="2.5" fill="#0086C9" stroke="white"
            *ngIf="notifyPopupComponent.isHasUnreadMessage | async" />
        </svg>
        <span class="ps-2">{{ "layout.header.menu.notification" | translate }}</span>
      </button>
    </div>
  </c-col>
  <c-col class="menu-mb">
    <div class="navbar">
      <div class="container nav-container">
        <input class="checkbox" type="checkbox" name="" id="hamburgerMenu" (click)="onClickMenu($event)"/>
        <label
          for="hamburgerMenu"
          class="hamburger-lines"
        >
          <span class="line line1"></span>
          <span class="line line2"></span>
          <span class="line line3"></span>
        </label>
        <div class="menu-items">
          <li class="item-menu-mb">
            <a
              class="btn-header header-mb"
              routerLink="/quotation"
              routerLinkActive="btn-header--active"
              [routerLinkActiveOptions]="{ exact: false }"
            >
              <img cImg src="assets/svg/header/report.svg" alt="report" width="24" height="24"/>
              <span class="ps-2">{{ "layout.header.menu.quotation" | translate }}</span>
            </a>
          </li>
          <li class="item-menu-mb">
            <a
              class="btn-header header-mb"
              routerLink="/student"
              routerLinkActive="btn-header--active"
              [routerLinkActiveOptions]="{ exact: false }"
            >
              <img cImg src="assets/svg/header/report.svg" alt="report" width="24" height="24"/>
              <span class="ps-2">{{ "layout.header.menu.student" | translate }}</span>
            </a>
          </li>
          <li class="item-menu-mb">
            <a
              class="btn-header header-mb"
              routerLink="/account"
              routerLinkActive="btn-header--active"
              [routerLinkActiveOptions]="{ exact: false }"
            >
              <img cImg src="assets/svg/header/account.svg" alt="account" width="24" height="24"/>
              <span class="ps-2">{{ "layout.header.menu.account" | translate }}</span>
            </a>
          </li>
          <li class="item-menu-mb">
            <a
              class="btn-header header-mb"
              routerLink="/notification"
              routerLinkActive="btn-header--active"
              [routerLinkActiveOptions]="{ exact: false }"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4"
                  d="M20.3992 16.33C20.1192 17.08 19.5292 17.65 18.7592 17.91C17.6792 18.27 16.5692 18.54 15.4492 18.73C15.3392 18.75 15.2292 18.7701 15.1192 18.7801C14.9392 18.8101 14.7592 18.83 14.5792 18.85C14.3592 18.8801 14.1292 18.9 13.8992 18.92C13.2692 18.97 12.6492 19 12.0192 19C11.3792 19 10.7392 18.97 10.1092 18.91C9.83923 18.89 9.57923 18.8601 9.31923 18.82C9.16923 18.8 9.01923 18.7801 8.87923 18.76C8.76923 18.74 8.65923 18.73 8.54923 18.71C7.43923 18.53 6.33923 18.26 5.26923 17.9C4.46923 17.63 3.85923 17.06 3.58923 16.33C3.31923 15.61 3.41923 14.77 3.84923 14.05L4.97923 12.17C5.21923 11.76 5.43923 10.97 5.43923 10.49V8.63005C5.43923 5.00005 8.38923 2.05005 12.0192 2.05005C15.6392 2.05005 18.5892 5.00005 18.5892 8.63005V10.49C18.5892 10.97 18.8092 11.76 19.0592 12.17L20.1892 14.05C20.5992 14.75 20.6792 15.57 20.3992 16.33Z" />
                <path
                  d="M12.0002 10.76C11.5802 10.76 11.2402 10.42 11.2402 10V6.90001C11.2402 6.48001 11.5802 6.14001 12.0002 6.14001C12.4202 6.14001 12.7602 6.48001 12.7602 6.90001V10C12.7502 10.42 12.4102 10.76 12.0002 10.76Z" />
                <path
                  d="M14.8297 20.01C14.4097 21.17 13.2997 22 11.9997 22C11.2097 22 10.4297 21.68 9.87969 21.11C9.55969 20.81 9.31969 20.41 9.17969 20C9.30969 20.02 9.43969 20.03 9.57969 20.05C9.80969 20.08 10.0497 20.11 10.2897 20.13C10.8597 20.18 11.4397 20.21 12.0197 20.21C12.5897 20.21 13.1597 20.18 13.7197 20.13C13.9297 20.11 14.1397 20.1 14.3397 20.07C14.4997 20.05 14.6597 20.03 14.8297 20.01Z" />
                <circle cx="18" cy="4.5" r="2.5" fill="#0086C9" stroke="white"
                  *ngIf="notifyPopupComponent.isHasUnreadMessage | async" />
              </svg>
              <span class="ps-2">{{ "layout.header.menu.notification" | translate }}</span>
            </a>
          </li>
          <li *ngFor="let navItem of navItems" class="item-menu-mb">
            <a routerLink="/iframe" [queryParams]="{ url: navItem?.link }" class="btn-header header-mb" >
              <img cImg src="{{ navItem?.icon }}" alt="Menu icon" width="24" height="24">
              <span class="ps-2">{{ navItem?.name }}</span>
            </a>
          </li>
        </div>
      </div>
    </div>
  </c-col>
  <app-notify-popup #notifyPopupComponent id="notifyPopupComponent"></app-notify-popup>
</c-col>
