import { ColorTypes } from '@app/constants';

export interface ILevel {
  code: string,
  name: string,
  status: string,
  border: boolean
}

export const LevelStudent: ILevel[] = [
  {
    code: ColorTypes['white'],
    name: '一等初学者',
    status: 'primary_1',
    border: true,
  },
  {
    code: ColorTypes['bright_blue'],
    name: '二等初学者',
    status: 'primary_2',
    border: false,
  },
  {
    code: ColorTypes['moderate_green'],
    name: '一等経験者',
    status: 'experienced_1',
    border: false,
  },
  {
    code: ColorTypes['soft_pink'],
    name: '二等経験者',
    status: 'experienced_2',
    border: false,
  },
];
