import { Component, OnInit } from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import { IconSetService } from '@coreui/icons-angular';
import { Title } from '@angular/platform-browser';
import { iconSubset } from './constants';
import { TranslateService } from '@ngx-translate/core';
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { HttpClient } from "@angular/common/http";
import { SettingService, StateService } from './services'
@Component({
  selector: 'body',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  protected title: string = 'UNLC 無人航空機講習 管理システム';
  protected supportLanguage = ['en', 'ja'];
  protected language: string = '';
  protected signInStatus: boolean = false;
  protected leftMenu: boolean = false;

  constructor(
    private router: Router,
    private titleService: Title,
    private iconSetService: IconSetService,
    private translateService: TranslateService,
    private http: HttpClient,
    private stateService :StateService,
    private activatedRoute: ActivatedRoute,
    private settingService: SettingService,
  ) {
    titleService.setTitle(this.title);
    // iconSet singleton
    iconSetService.icons = { ...iconSubset };
    //set translate ja
    this.translateService.addLangs(['en', 'ja']);
    this.translateService.setDefaultLang('ja');
    this.translateService.use('ja');
  }

  ngOnInit(): void {
    this.getSettings();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) return;
    });
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user && localStorage.getItem("iframe") != "false") {
        this.signInStatus = !!user.uid;
      } else {
        this.signInStatus = false;
      }
    })
  }

  private async getSettings(): Promise<void> {
    (await this.settingService.getLeftMenuSetting()).subscribe((items) => {
			if (items.length > 0) {
				let data: any = items[0].payload.doc.data();
				this.leftMenu = data.left_menu ?? true;
			}
		});
  }

  onChange(event: any) {
    localStorage.setItem('lang', (event.target as HTMLInputElement).value);
    this.language = (event.target as HTMLInputElement).value;
    location.reload();
  }
}
