<div class="position-relative">
  <c-toaster placement="top-end" position="fixed" class="mt-3">
    <c-toast *ngFor="let alert of alerts" #toast="cToast" [autohide]="false" [visible]="true" class="align-items-center text-white border-0" color="{{ getColor(alert) }}">
      <div class="d-flex">
        <c-toast-body>
          <span [innerHTML]="alert.message"></span>
        </c-toast-body>
      </div>
    </c-toast>
  </c-toaster>
</div>
