import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";

@Injectable({
  providedIn: 'root'
})
export class InstructorService {
  constructor(private firestore: AngularFirestore) {}

  async getItems(userId: string) {
    return this.firestore.collection('users').doc(userId).collection('instructors', ref => ref.orderBy('sort', 'desc')).snapshotChanges();
  }

  async createItem(data: any, userId: string) {
    let result = await this.firestore.collection('users').doc(userId).collection('instructors').add(data);
    return result;
  }

  updateItem(id: any, sort: number, userId: string) {
    return this.firestore.collection('users').doc(userId).collection('instructors').doc(id).update({
      sort: sort,
    });
  }

  async deleteItem(id: any, userId: string) {
    return this.firestore.collection('users').doc(userId).collection('instructors').doc(id).delete();
  }
}
